import { FC } from "react";
import { ReportVersionFieldsFragment } from "app/api/graph/types";
import { formatISODate } from "app/util/format";
import { DetailsTable } from "app/component/report-version/component/part/content/DetailsTable";
import { testTypeLabel } from "app/api/graph/strings";

type Props = { version: ReportVersionFieldsFragment };

export const TestDetails: FC<Props> = ({ version }) => (
    <DetailsTable
        details={[
            ["Test name", version.testName],
            [
                "Test dates",
                `Consultancy was performed between ${formatISODate(version.testPerformedFrom)} and ${formatISODate(
                    version.testPerformedTo,
                )} inclusive`,
            ],
            ["Test type", version.testType != null ? testTypeLabel(version.testType) : "-"],
            ["Application version", version.testApplicationVersion ?? "-"],
            ["Server version", version.testServerVersion ?? "-"],
            ["Hostname", version.testHostname ?? "-"],
        ]}
    />
);
