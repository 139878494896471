import { FC } from "react";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { EditorThemeClasses } from "lexical";
import { CodeNode } from "@lexical/code";
import { LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ToolbarPlugin } from "app/component/form/text-editor/ToolbarPlugin";
import { ControlledByHtmlPlugin } from "app/component/form/text-editor/HtmlPlugin";
import { OnBlurPlugin } from "app/component/form/text-editor/OnBlurPlugin";
import { CleanPastePlugin } from "app/component/form/text-editor/CleanPastePlugin.tsx";

type Props = {
    value: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    isInvalid?: boolean;
    singleMode?: boolean;
};

const theme: EditorThemeClasses = {
    text: {
        strikethrough: "text-decoration-line-through",
        underline: "text-decoration-underline",
        underlineStrikethrough: "text-decoration-underline-line-through",
    },
    list: {
        nested: {
            listitem: "list-unstyled",
        },
    },
};

const onError = (error: Error) => {
    throw error;
};

const initialConfig: InitialConfigType = {
    namespace: "RichTextEditor",
    theme,
    onError,
    nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode, CodeNode, TableNode, TableCellNode, TableRowNode, LinkNode],
};

export const RichTextEditor: FC<Props> = ({ value, onChange, onBlur: handleBlur }) => {
    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div className="editor-container">
                <ToolbarPlugin />

                <div className="border p-2 mt-2 formatted-content">
                    <RichTextPlugin contentEditable={<ContentEditable />} ErrorBoundary={LexicalErrorBoundary} />
                </div>
            </div>

            <LinkPlugin />
            <HistoryPlugin />
            <TablePlugin />
            <ListPlugin />
            <CleanPastePlugin />
            <ControlledByHtmlPlugin value={value} onChange={onChange} />
            {handleBlur !== undefined && <OnBlurPlugin onBlur={handleBlur} />}
        </LexicalComposer>
    );
};
