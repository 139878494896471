import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { editReportVersionVulnerabilityRoute, reportVersionVulnerabilityRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { EditReportVulnerabilityForm } from "app/component/report-vulnerability/EditReportVulnerabilityForm";

export const EditReportVersionVulnerabilityScreen: FC = () => {
    const { reportId, versionId, vulnerabilityId } = useRouteParams(editReportVersionVulnerabilityRoute);
    const [updated, setUpdated] = useState(false);

    return updated ? (
        <Navigate to={reportVersionVulnerabilityRoute.build({ reportId, versionId, vulnerabilityId })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editReportVersionVulnerabilityRoute} />
                <EditReportVulnerabilityForm
                    vulnerabilityId={vulnerabilityId}
                    versionId={versionId}
                    onUpdate={() => setUpdated(true)}
                />
            </Container>
        </NavigationScreen>
    );
};
