/* eslint-disable @typescript-eslint/no-explicit-any */

import { Breadcrumb } from "react-bootstrap";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import classNames from "classnames";
import { Route, useRouteParams } from "app/route/route";

type Props = {
    route: Route<any>;
    className?: string;
};

type Crumb = {
    path: string;
    name: string;
    isActive: boolean;
};

export const RouteBreadcrumbs: FC<Props> = ({ className, route }) => {
    const params = useRouteParams(route);
    const navigate = useNavigate();

    const buildCrumbs = (node: Route<any>): Crumb[] => {
        const crumbs = node.parent !== undefined ? buildCrumbs(node.parent) : [];

        const crumb = {
            path: node.build(params),
            name: node.name,
            isActive: node === route,
        };

        return [...crumbs, crumb];
    };

    const crumbs = buildCrumbs(route);

    return (
        <Breadcrumb
            className={classNames("bg-light p-3 mb-3", className)}
            listProps={{ className: "align-items-center m-0" }}
        >
            <Button onClick={() => navigate(-1)} className="me-2" size="sm">
                Back
            </Button>
            {crumbs.map((crumb) =>
                crumb.isActive ? (
                    <Breadcrumb.Item key={crumb.path} active>
                        {crumb.name}
                    </Breadcrumb.Item>
                ) : (
                    <Breadcrumb.Item key={crumb.path} linkAs={Link} linkProps={{ to: crumb.path }}>
                        {crumb.name}
                    </Breadcrumb.Item>
                ),
            )}
        </Breadcrumb>
    );
};
