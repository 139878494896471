import { Component, ErrorInfo, ReactNode } from "react";
import { ErrorScreen } from "app/component/screen/ErrorScreen";

type Props = {
    children: ReactNode;
};

type State = {
    error?: Error;
};

export class ErrorBoundary extends Component<Props, State> {
    public state: State = {};

    public static getDerivedStateFromError(error: Error): State {
        return { error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render(): ReactNode {
        if (this.state.error !== undefined) {
            return <ErrorScreen title="Error" message="An error occurred." />;
        }

        return this.props.children;
    }
}
