import { useState } from "react";

export type Result = {
    loading: boolean;
    error: string | undefined;
    download: () => void;
};

export const useDownloadFile = (url: string): Result => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const download = async () => {
        try {
            setLoading(true);
            setError(undefined);

            // Download file into blob
            const response = await fetch(url);
            if (response.status !== 200) {
                return setError("Error downloading file.");
            }
            const blob = new Blob([await response.blob()], { type: response.headers.get("Content-Type") ?? undefined });
            const objectUrl = URL.createObjectURL(blob);

            // Start the download - using a link to start the download is necessary as it's the only way to set a filename
            const link = document.createElement("a");
            link.href = objectUrl;
            link.download =
                response.headers
                    .get("Content-Disposition")
                    ?.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/)?.[1] ?? "download";
            link.click();

            // Clean up (needs to be done in setTimeout for some browsers)
            setTimeout(() => {
                URL.revokeObjectURL(objectUrl);
                link.remove();
            }, 250);
        } catch (e) {
            console.error(e);
            setError("Error downloading file.");
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        download,
    };
};
