import { FC } from "react";
import {
    CreateVulnerabilityMutation,
    useCreateVulnerabilityMutation,
    VulnerabilitySeverity,
} from "app/api/graph/types";
import { FormValues, VulnerabilityForm } from "app/component/vulnerability/VulnerabilityForm";

type Props = {
    initialValues?: Partial<FormValues>;
    onCreate: (
        vulnerability: NonNullable<NonNullable<CreateVulnerabilityMutation["createVulnerability"]>["vulnerability"]>,
    ) => void;
};

const initialValues: FormValues = {
    identifier: "",
    title: "",
    severity: VulnerabilitySeverity.Medium,
    testingTypes: [],
    cvssScore: "",
    cvssVector: "",
    cveScore: "",
    cwe: "",
    owaspCategory: "",
    issueDetail: "",
    advice: "",
};

export const CreateVulnerabilityForm: FC<Props> = ({ onCreate, initialValues: providedInitialValues }) => {
    const [createVulnerability, { error }] = useCreateVulnerabilityMutation({});

    const onSubmit = (values: FormValues) =>
        createVulnerability({ variables: { input: values } })
            .then((response) => response.data?.createVulnerability?.vulnerability)
            .then((vulnerability) => (vulnerability ? onCreate?.(vulnerability) : undefined));

    return (
        <VulnerabilityForm
            initialValues={{ ...initialValues, ...providedInitialValues }}
            onSubmit={onSubmit}
            error={error}
        />
    );
};
