import { FC, FormEvent, ChangeEvent, useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useUploadCompanyLogoMutation } from "app/api/graph/types";
import { companyGraphId } from "app/api/graph/helpers";

type Props = {
    companyId: number;
    onUpload?: () => void;
};

export const UploadCompanyLogoForm: FC<Props> = ({ companyId, onUpload }) => {
    const [file, setFile] = useState<File | undefined>(undefined);
    const [upload, { loading, error }] = useUploadCompanyLogoMutation();

    const handleSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            void upload({
                variables: {
                    input: {
                        id: companyGraphId(companyId),
                        file,
                    },
                },
            }).then(() => onUpload?.());
        },
        [file],
    );

    const handleFileChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setFile(event?.target?.files?.[0]);
        },
        [setFile],
    );

    return (
        <Form onSubmit={handleSubmit}>
            {error && <Alert variant="danger">{error.message}</Alert>}

            <Form.Group className="mb-3">
                <Form.Label>Logo file</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} accept="image/png" />
            </Form.Group>

            <Button type="submit" disabled={loading}>
                Submit
            </Button>
        </Form>
    );
};
