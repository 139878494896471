import { FC } from "react";
import { ReportContactFieldsFragment } from "app/api/graph/types";
import { DetailsTable } from "app/component/report-version/component/part/content/DetailsTable";

type Props = { contact: ReportContactFieldsFragment };

export const ContactDetails: FC<Props> = ({ contact }) => (
    <DetailsTable
        details={[
            ["Name", contact.name],
            ["Position", contact.position],
            ["Email", contact.email],
            ["Mobile", contact.mobile],
            ["Address", contact.address],
            ["Phone", contact.phone],
        ]}
    />
);
