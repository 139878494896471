import { ReportSectionFieldsFragment, ReportSectionIdentifier } from "app/api/graph/types";
import { SectionContent } from "app/component/report-version/component/part/content/SectionContent";
import { PartConfig } from "app/component/report-version/component/part/Part";

export const sectionPart = (
    identifier: ReportSectionIdentifier,
    sections: ReportSectionFieldsFragment[],
): PartConfig => {
    const section = sections.find((section) => section.identifier === identifier);
    return {
        title: section?.name ?? "Section missing",
        content: section ? <SectionContent section={section} /> : null,
    };
};
