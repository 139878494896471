import { FC, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik, FormikHelpers } from "formik";
import { FaKey, FaMobile } from "react-icons/fa";
import { useUpdateUserMutation, useUserQuery } from "app/api/graph/types";
import { userGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { updateUserMobileNumberRoute, userChangePasswordRoute, userSettingsRoute } from "app/route/Routes";
import yup from "app/util/yup";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";
import { useRouteParams } from "app/route/route";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

type FormValues = {
    firstName: string;
    lastName: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
});

export const UserSettingsScreen: FC = () => {
    const { userId } = useRouteParams(userSettingsRoute);
    const { data, loading, error } = useUserQuery({ variables: { userId: userGraphId(userId) } });
    const [updateUser] = useUpdateUserMutation({});
    const user = data?.user;

    const initialValues: FormValues = useMemo(
        () => ({
            firstName: user?.firstName ?? "",
            lastName: user?.lastName ?? "",
        }),
        [user],
    );

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!user) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        updateUser({
            variables: {
                input: {
                    id: user.id,
                    ...values,
                },
            },
        })
            .then(() => handleSuccess(actions, "Settings updated"))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={userSettingsRoute} />
                <h1>Settings</h1>
                <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
                    {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting, status }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <StatusAlert status={status as unknown} />
                            <Form.Group className="mb-3">
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    name="firstName"
                                    type="text"
                                    placeholder="First name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.firstName && !!errors.firstName}
                                />
                                <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    name="lastName"
                                    type="text"
                                    placeholder="Last name"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.lastName && !!errors.lastName}
                                />
                                <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="d-block">Password</Form.Label>
                                <ButtonLink
                                    variant="secondary"
                                    to={userChangePasswordRoute.build({ userId: user._id })}
                                >
                                    <FaKey /> Change password
                                </ButtonLink>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="d-block">Mobile number</Form.Label>
                                <ButtonLink
                                    variant="secondary"
                                    to={updateUserMobileNumberRoute.build({ userId: user._id })}
                                >
                                    <FaMobile /> Change mobile number
                                </ButtonLink>
                            </Form.Group>
                            <BlockButtonGroup>
                                <Button type="submit" disabled={isSubmitting}>
                                    Update
                                </Button>
                            </BlockButtonGroup>
                        </Form>
                    )}
                </Formik>
            </Container>
        </NavigationScreen>
    );
};
