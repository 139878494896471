import { FC, InputHTMLAttributes } from "react";
import { FormControlProps } from "react-bootstrap/FormControl";
import { FormControlSelectChoice } from "app/component/form/control/FormControlSelectChoice";
import { CompanyRole } from "app/api/graph/types";
import { companyRoleLabel } from "app/api/graph/strings";

type Props = Omit<InputHTMLAttributes<HTMLSelectElement> & FormControlProps, "type" | "value" | "onChange"> & {
    value: CompanyRole | undefined;
    onChange: (value: CompanyRole) => void;
};

const options = [CompanyRole.Standard, CompanyRole.Limited, CompanyRole.Owner].map((value) => ({
    label: companyRoleLabel(value),
    value: value,
}));

export const FormControlSelectCompanyRole: FC<Props> = ({ value, onChange, ...props }) => (
    <FormControlSelectChoice {...props} options={options} value={value} onChange={onChange} />
);
