import { FC } from "react";
import { ReportVulnerabilityFieldsFragment } from "app/api/graph/types";
import { VulnerabilitySummary } from "app/component/report-version/component/part/content/VulnerabilitySummary";

type Props = {
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
};

export const Vulnerabilities: FC<Props> = ({ vulnerabilities }) =>
    vulnerabilities.length > 0 ? (
        <>
            {vulnerabilities.map((v) => (
                <VulnerabilitySummary key={v.id} vulnerability={v} id={`vulnerability-${v.issueNumber}`} />
            ))}
        </>
    ) : (
        <p>No vulnerabilities reported.</p>
    );
