import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
    $createParagraphNode,
    $getSelection,
    $isElementNode,
    $isRangeSelection,
    $isRootOrShadowRoot,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_NORMAL,
    ElementFormatType,
    ElementNode,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    INDENT_CONTENT_COMMAND,
    LexicalEditor,
    OUTDENT_CONTENT_COMMAND,
    RangeSelection,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    TextNode,
    UNDO_COMMAND,
} from "lexical";
import { $createCodeNode } from "@lexical/code";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $createHeadingNode, $createQuoteNode, $isHeadingNode, HeadingTagType } from "@lexical/rich-text";
import { $isListNode, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode } from "@lexical/list";
import { $setBlocksType, $isAtNodeEnd } from "@lexical/selection";
import {
    $isTableNode,
    INSERT_TABLE_COMMAND,
    $insertTableRow__EXPERIMENTAL,
    $insertTableColumn__EXPERIMENTAL,
    $deleteTableRow__EXPERIMENTAL,
    $deleteTableColumn__EXPERIMENTAL,
} from "@lexical/table";
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
    FaAlignCenter,
    FaAlignJustify,
    FaAlignLeft,
    FaAlignRight,
    FaBold,
    FaCheck,
    FaCode,
    FaIndent,
    FaItalic,
    FaLink,
    FaListOl,
    FaListUl,
    FaOutdent,
    FaParagraph,
    FaPlus,
    FaRedo,
    FaStrikethrough,
    FaSubscript,
    FaSuperscript,
    FaTable,
    FaTimes,
    FaUnderline,
    FaUndo,
} from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { BsTypeH1, BsTypeH2, BsTypeH3, BsTypeH4, BsTypeH5, BsTypeH6 } from "react-icons/bs";
import { RiCodeBlock } from "react-icons/ri";

type RootType = "root" | "table";

const blockTypes = [
    "paragraph",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "bullet",
    "number",
    "check",
    "quote",
    "code",
] as const;

type BlockType = (typeof blockTypes)[number];

type BlockTypeItem = {
    type: BlockType;
    label: string;
    icon: ReactElement;
    apply: (editor: LexicalEditor, blockType: BlockType) => void;
};

const blockTypeItems: BlockTypeItem[] = [
    {
        type: "paragraph",
        label: "Paragraph",
        icon: <FaParagraph />,
        apply: (editor: LexicalEditor, blockType: BlockType) => {
            if (blockType !== "paragraph") {
                editor.update(() => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        $setBlocksType(selection, () => $createParagraphNode());
                    }
                });
            }
        },
    },
    ...[
        { type: "h1" as HeadingTagType, label: "Heading 1", icon: <BsTypeH1 /> },
        { type: "h2" as HeadingTagType, label: "Heading 2", icon: <BsTypeH2 /> },
        { type: "h3" as HeadingTagType, label: "Heading 3", icon: <BsTypeH3 /> },
        { type: "h4" as HeadingTagType, label: "Heading 4", icon: <BsTypeH4 /> },
        { type: "h5" as HeadingTagType, label: "Heading 5", icon: <BsTypeH5 /> },
        { type: "h6" as HeadingTagType, label: "Heading 6", icon: <BsTypeH6 /> },
    ].map((item) => ({
        ...item,
        apply: (editor: LexicalEditor, blockType: BlockType) => {
            if (blockType !== item.type) {
                editor.update(() => {
                    const selection = $getSelection();
                    $setBlocksType(selection, () => $createHeadingNode(item.type));
                });
            }
        },
    })),
    {
        type: "bullet",
        label: "Bulleted list",
        icon: <FaListUl />,
        apply: (editor: LexicalEditor, blockType: BlockType) => {
            if (blockType !== "bullet") {
                editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
            }
        },
    },
    {
        type: "number",
        label: "Numbered list",
        icon: <FaListOl />,
        apply: (editor: LexicalEditor, blockType: BlockType) => {
            if (blockType !== "number") {
                editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
            }
        },
    },
    {
        type: "quote",
        label: "Block quote",
        icon: <FaListUl />,
        apply: (editor: LexicalEditor, blockType: BlockType) => {
            if (blockType !== "quote") {
                editor.update(() => {
                    const selection = $getSelection();
                    $setBlocksType(selection, () => $createQuoteNode());
                });
            }
        },
    },
    {
        type: "code",
        label: "Block code",
        icon: <RiCodeBlock />,
        apply: (editor: LexicalEditor, blockType: BlockType) => {
            if (blockType !== "code") {
                editor.update(() => {
                    let selection = $getSelection();

                    if (selection !== null) {
                        if (selection.isCollapsed()) {
                            $setBlocksType(selection, () => $createCodeNode());
                        } else {
                            const textContent = selection.getTextContent();
                            const codeNode = $createCodeNode();
                            selection.insertNodes([codeNode]);
                            selection = $getSelection();
                            if ($isRangeSelection(selection)) {
                                selection.insertRawText(textContent);
                            }
                        }
                    }
                });
            }
        },
    },
];

type ElementFormatItem = {
    type: ElementFormatType;
    label: string;
    icon: ReactElement;
    apply: (editor: LexicalEditor, elementFormat: ElementFormatType) => void;
};

const elementFormatItems: ElementFormatItem[] = [
    {
        type: "",
        label: "Align: none",
        icon: <FaAlignLeft />,
        apply: (editor: LexicalEditor) => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "");
        },
    },
    {
        type: "left",
        label: "Align: left",
        icon: <FaAlignLeft />,
        apply: (editor: LexicalEditor) => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        },
    },
    {
        type: "center",
        label: "Align: center",
        icon: <FaAlignCenter />,
        apply: (editor: LexicalEditor) => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        },
    },
    {
        type: "right",
        label: "Align: right",
        icon: <FaAlignRight />,
        apply: (editor: LexicalEditor) => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        },
    },
    {
        type: "justify",
        label: "Align: justify",
        icon: <FaAlignJustify />,
        apply: (editor: LexicalEditor) => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
        },
    },
];

const getSelectedNode = (selection: RangeSelection): TextNode | ElementNode => {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();

    if (anchorNode === focusNode) {
        return anchorNode;
    }

    if (selection.isBackward()) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
    }
};

// Based on https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/ToolbarPlugin/index.tsx
export const ToolbarPlugin: FC = () => {
    const [editor] = useLexicalComposerContext();
    const [rootType, setRootType] = useState<RootType>("root");
    const [blockType, setBlockType] = useState<BlockType>("paragraph");
    const [elementFormat, setElementFormat] = useState<ElementFormatType>("left");
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isSubscript, setIsSubscript] = useState(false);
    const [isSuperscript, setIsSuperscript] = useState(false);
    const [isCode, setIsCode] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [isLinking, setIsLinking] = useState(false);
    const [linkInput, setLinkInput] = useState("");

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === "root"
                    ? anchorNode
                    : $findMatchingParent(anchorNode, (e) => {
                          const parent = e.getParent();
                          return parent !== null && $isRootOrShadowRoot(parent);
                      });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);

            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setIsLink(true);
                setLinkInput(parent.getURL());
            } else if ($isLinkNode(node)) {
                setIsLink(true);
                setLinkInput(node.getURL());
            } else {
                setIsLink(false);
            }

            const tableNode = $findMatchingParent(node, $isTableNode);
            if ($isTableNode(tableNode)) {
                setRootType("table");
            } else {
                setRootType("root");
            }

            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
                    const type = parentList ? parentList.getListType() : element.getListType();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                    if (blockTypes.includes(type as BlockType)) {
                        setBlockType(type as BlockType);
                    }
                }
            }

            const formatNode = $isLinkNode(parent)
                ? $findMatchingParent(node, (parentNode) => $isElementNode(parentNode) && !parentNode.isInline())
                : node;
            setElementFormat(
                $isElementNode(formatNode) ? formatNode.getFormatType() : (parent?.getFormatType() ?? "left"),
            );

            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            setIsSubscript(selection.hasFormat("subscript"));
            setIsSuperscript(selection.hasFormat("superscript"));
            setIsCode(selection.hasFormat("code"));
        }
    }, [
        editor,
        setBlockType,
        setRootType,
        setElementFormat,
        setIsBold,
        setIsItalic,
        setIsUnderline,
        setIsStrikethrough,
        setIsSubscript,
        setIsSuperscript,
        setIsCode,
        setIsLink,
    ]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    $updateToolbar();
                    return false;
                },
                COMMAND_PRIORITY_NORMAL,
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                COMMAND_PRIORITY_NORMAL,
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                COMMAND_PRIORITY_NORMAL,
            ),
        );
    }, [editor, $updateToolbar]);

    const currentBlockTypeItem = blockTypeItems.find((item) => item.type === blockType);
    const currentElementFormatItem = elementFormatItems.find((item) => item.type === elementFormat);

    return (
        <div className="d-flex gap-2 flex-wrap">
            <ButtonGroup>
                <Button
                    disabled={!canUndo}
                    onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
                    title="Undo"
                >
                    <FaUndo />
                </Button>

                <Button
                    disabled={!canRedo}
                    onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
                    title="Redo"
                >
                    <FaRedo />
                </Button>
            </ButtonGroup>

            <Dropdown>
                <Dropdown.Toggle>
                    {currentBlockTypeItem !== undefined ? (
                        <>
                            {currentBlockTypeItem.icon} {currentBlockTypeItem.label}
                        </>
                    ) : (
                        "Unknown"
                    )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {blockTypeItems.map((item) => (
                        <Dropdown.Item
                            key={item.type}
                            onClick={() => item.apply(editor, blockType)}
                            active={blockType === item.type}
                            disabled={blockType === item.type}
                        >
                            {item.icon} {item.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            <ButtonGroup>
                <Button
                    onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
                    active={isBold}
                    title="Format: bold"
                >
                    <FaBold />
                </Button>

                <Button
                    onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
                    active={isItalic}
                    title="Format: italic"
                >
                    <FaItalic />
                </Button>

                <Button
                    onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
                    active={isUnderline}
                    title="Format: underline"
                >
                    <FaUnderline />
                </Button>

                <Button
                    onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")}
                    active={isStrikethrough}
                    title="Format: strikethrough"
                >
                    <FaStrikethrough />
                </Button>

                <Button
                    onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript")}
                    active={isSubscript}
                    title="Format: subscript"
                >
                    <FaSubscript />
                </Button>

                <Button
                    onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript")}
                    active={isSuperscript}
                    title="Format: superscript"
                >
                    <FaSuperscript />
                </Button>

                <Button
                    onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code")}
                    active={isCode}
                    title="Format: code"
                >
                    <FaCode />
                </Button>

                <OverlayTrigger
                    show={isLinking}
                    placement="bottom"
                    overlay={
                        <Tooltip>
                            <InputGroup>
                                <Form.Control
                                    className="flex-1"
                                    value={linkInput}
                                    onChange={(e) => setLinkInput(e.target.value)}
                                />
                                <Button
                                    onClick={() => {
                                        editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkInput);
                                        setIsLinking(false);
                                        setLinkInput("");
                                    }}
                                >
                                    <FaCheck />
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                                        setIsLinking(false);
                                        setLinkInput("");
                                    }}
                                >
                                    <FaTimes />
                                </Button>
                            </InputGroup>
                        </Tooltip>
                    }
                >
                    <Button onClick={() => setIsLinking(!isLinking)} active={isLink} title="Link">
                        <FaLink />
                    </Button>
                </OverlayTrigger>
            </ButtonGroup>

            <Dropdown>
                <Dropdown.Toggle>
                    {currentElementFormatItem !== undefined ? (
                        <>
                            {currentElementFormatItem.icon} {currentElementFormatItem.label}
                        </>
                    ) : (
                        "Unknown"
                    )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {elementFormatItems.map((item) => (
                        <Dropdown.Item
                            key={item.type}
                            onClick={() => item.apply(editor, elementFormat)}
                            active={elementFormat === item.type}
                            disabled={elementFormat === item.type}
                        >
                            {item.icon} {item.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            <ButtonGroup>
                <Button title="Outdent" onClick={() => editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)}>
                    <FaOutdent />
                </Button>

                <Button title="Indent" onClick={() => editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)}>
                    <FaIndent />
                </Button>
            </ButtonGroup>

            <Dropdown>
                <Dropdown.Toggle>
                    <FaPlus /> Insert
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() =>
                            editor.dispatchCommand(INSERT_TABLE_COMMAND, {
                                columns: "3",
                                rows: "3",
                                includeHeaders: false,
                            })
                        }
                    >
                        <FaTable /> Table
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            {rootType === "table" && (
                <Dropdown>
                    <Dropdown.Toggle>
                        <FaTable /> Table
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => editor.update(() => $insertTableRow__EXPERIMENTAL())}>
                            Insert row
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => editor.update(() => $insertTableColumn__EXPERIMENTAL())}>
                            Insert column
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => editor.update(() => $deleteTableRow__EXPERIMENTAL())}>
                            Delete row
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => editor.update(() => $deleteTableColumn__EXPERIMENTAL())}>
                            Delete column
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </div>
    );
};
