import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { Contact } from "app/api/graph/types";
import { editContactRoute, contactsRoute } from "app/route/Routes";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { EditContactForm } from "app/component/contact/EditContactForm";
import { useRouteParams } from "app/route/route";

export const EditContactScreen: FC = () => {
    const { contactId } = useRouteParams(editContactRoute);
    const [contact, setContact] = useState<Pick<Contact, "_id"> | undefined>(undefined);

    return contact ? (
        <Navigate to={contactsRoute.build({})} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editContactRoute} />
                <EditContactForm contactId={contactId} onUpdate={setContact} />
            </Container>
        </NavigationScreen>
    );
};
