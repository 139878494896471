import { FC, InputHTMLAttributes } from "react";
import { FormControlProps } from "react-bootstrap/FormControl";
import { FormControlSelectChoice } from "app/component/form/control/FormControlSelectChoice";
import { ReportRole } from "app/api/graph/types";
import { reportRoleLabel } from "app/api/graph/strings";

type Props = Omit<InputHTMLAttributes<HTMLSelectElement> & FormControlProps, "type" | "value" | "onChange"> & {
    value: ReportRole | undefined;
    onChange: (value: ReportRole) => void;
};

const options = [ReportRole.Standard, ReportRole.Limited].map((value) => ({
    label: reportRoleLabel(value),
    value: value,
}));

export const FormControlSelectReportRole: FC<Props> = ({ value, onChange, ...props }) => (
    <FormControlSelectChoice {...props} options={options} value={value} onChange={onChange} />
);
