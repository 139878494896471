import { Formik, FormikHelpers } from "formik";
import { FC, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import yup from "app/util/yup";
import { useReportConsultantQuery, useUpdateReportConsultantMutation } from "app/api/graph/types";
import { reportConsultantGraphId } from "app/api/graph/helpers";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";

type Props = {
    consultantId: number;
    onUpdate?: () => void;
};

type FormValues = {
    qualifications?: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    qualifications: yup.string().required(),
});

export const EditReportConsultantForm: FC<Props> = ({ consultantId, onUpdate }) => {
    const { data, loading, error } = useReportConsultantQuery({
        variables: { consultantId: reportConsultantGraphId(consultantId) },
    });
    const consultant = data?.reportConsultant;
    const [updateConsultant] = useUpdateReportConsultantMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            qualifications: consultant?.qualifications ?? "",
        }),
        [consultant],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!consultant) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        updateConsultant({ variables: { input: { id: consultant.id, ...values } } })
            .then(() => onUpdate?.())
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, handleChange, handleBlur, values, isSubmitting, errors, touched, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>Qualifications</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="qualifications"
                            placeholder="Qualifications"
                            value={values.qualifications}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.qualifications && !!errors.qualifications}
                        />
                        <Form.Control.Feedback type="invalid">{errors.qualifications}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Update
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
