import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { useVerifyUserEmailMutation } from "app/api/graph/types";
import { CentredScreen } from "app/component/screen/CentredScreen";
import { signInRoute } from "app/route/Routes";
import { userGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

export const ConfirmEmailScreen: FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token") ?? "";
    const userId = parseInt(searchParams.get("userId") ?? "");
    const [confirmEmail, { data, loading, error }] = useVerifyUserEmailMutation();

    useEffect(() => {
        confirmEmail({ variables: { input: { id: userGraphId(userId), token } } }).catch(console.error);
    }, [token, userId]);

    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (loading || !data?.verifyEmailUser) {
        return <LoadingScreen />;
    }

    return (
        <CentredScreen>
            <Alert variant="success">Your email address has been confirmed.</Alert>
            <BlockButtonGroup>
                <ButtonLink to={signInRoute.build({})}>Sign in</ButtonLink>
            </BlockButtonGroup>
        </CentredScreen>
    );
};
