import { FC } from "react";
import { CreateContactMutation, useCreateContactMutation } from "app/api/graph/types";
import { FormValues, ContactForm } from "app/component/contact/ContactForm";

type Props = {
    onCreate: (report: NonNullable<NonNullable<CreateContactMutation["createContact"]>["contact"]>) => void;
};

const initialValues: FormValues = {
    name: "",
};

export const CreateContactForm: FC<Props> = ({ onCreate }) => {
    const [createContact, { error }] = useCreateContactMutation({});

    const onSubmit = (values: FormValues) =>
        createContact({ variables: { input: values } })
            .then((response) => response.data?.createContact?.contact)
            .then((contact) => (contact ? onCreate?.(contact) : undefined));

    return <ContactForm initialValues={initialValues} onSubmit={onSubmit} error={error} />;
};
