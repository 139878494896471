import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaFilter } from "react-icons/fa";
import yup from "app/util/yup";
import { StatusAlert } from "app/component/form/StatusAlert";
import { FormControlDate } from "app/component/form/control/FormControlDate.tsx";
import { SelectTestingTypes } from "app/component/form/select/SelectTestingTypes.tsx";

type Props = {
    initialValues: FormValues;
    onSubmit: (values: FormValues) => void;
};

export type FormValues = {
    from?: Date;
    to?: Date;
    vulnerabilityTitle?: string;
    cvssScore?: string;
    testingTypes?: string[];
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    from: yup.date(),
    to: yup.date(),
    vulnerabilityTitle: yup.string(),
    cvssScore: yup.string(),
    testingTypes: yup.array().of(yup.string().required()),
});

export const ReportVulnerabilityDashboardFiltersForm: FC<Props> = (props) => {
    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={props.initialValues}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                values,
                isSubmitting,
                errors,
                touched,
                status,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>From</Form.Label>
                        <FormControlDate
                            name="from"
                            value={values.from ?? null}
                            onChange={(value) => setFieldValue("from", value)}
                            onBlur={handleBlur}
                            isInvalid={touched.from && !!errors.from}
                        />
                        <Form.Control.Feedback type="invalid">{errors.from}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>To</Form.Label>
                        <FormControlDate
                            name="to"
                            value={values.to ?? null}
                            onChange={(value) => setFieldValue("to", value)}
                            onBlur={handleBlur}
                            isInvalid={touched.to && !!errors.to}
                        />
                        <Form.Control.Feedback type="invalid">{errors.to}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Vulnerability title</Form.Label>
                        <Form.Control
                            type="search"
                            name="vulnerabilityTitle"
                            value={values.vulnerabilityTitle ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.vulnerabilityTitle && !!errors.vulnerabilityTitle}
                        />
                        <Form.Control.Feedback type="invalid">{errors.vulnerabilityTitle}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>CVSS score</Form.Label>
                        <Form.Control
                            type="text"
                            name="cvssScore"
                            value={values.cvssScore ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.cvssScore && !!errors.cvssScore}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cvssScore}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Testing types</Form.Label>
                        <SelectTestingTypes
                            id="testing-types"
                            value={values.testingTypes ?? []}
                            onChange={(value) => setFieldValue("testingTypes", value)}
                            onBlur={() => setFieldTouched("testingTypes")}
                            isInvalid={touched.testingTypes && !!errors.testingTypes}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testingTypes}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        <FaFilter /> Filter
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
