import { Maybe } from "app/api/graph/types";
import { notEmpty } from "app/util/array";

export const nodes = <T>(
    connection: Maybe<{ edges?: Maybe<Array<Maybe<{ node?: Maybe<T> }>>> }> | undefined,
): Array<T> => connection?.edges?.map((edge) => edge?.node)?.filter(notEmpty) ?? [];

export const mapNodes = <T, U>(
    connection: Maybe<{ edges?: Maybe<Array<Maybe<{ node?: Maybe<T> }>>> }> | undefined,
    map: (node: T) => U,
): Array<U> =>
    connection?.edges
        ?.map((edge) => edge?.node)
        ?.filter(notEmpty)
        .map(map) ?? [];
