import { FormikHelpers, FormikProvider, useFormik } from "formik";
import { FC, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import yup from "app/util/yup";
import { useReportContactQuery, useUpdateReportContactMutation } from "app/api/graph/types";
import { reportContactGraphId } from "app/api/graph/helpers";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";

type Props = {
    contactId: number;
    initialValues?: Partial<FormValues>;
    onUpdate?: () => void;
};

export type FormValues = {
    name: string;
    address?: string;
    company?: string;
    email?: string;
    mobile?: string;
    phone?: string;
    position?: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    name: yup.string().required(),
    address: yup.string(),
    company: yup.string(),
    email: yup.string().email(),
    mobile: yup.string(),
    phone: yup.string(),
    position: yup.string(),
});

export const EditReportContactForm: FC<Props> = ({ contactId, initialValues: providedInitialValues, onUpdate }) => {
    const { data, loading, error } = useReportContactQuery({
        variables: { contactId: reportContactGraphId(contactId) },
    });
    const contact = data?.reportContact;
    const [updateContact] = useUpdateReportContactMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            ...{
                name: contact?.name ?? "",
                address: contact?.address ?? "",
                company: contact?.company ?? "",
                email: contact?.email ?? "",
                mobile: contact?.mobile ?? "",
                phone: contact?.phone ?? "",
                position: contact?.position ?? "",
            },
            ...providedInitialValues,
        }),
        [contact, providedInitialValues],
    );

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        updateContact({ variables: { input: { id: contact?.id ?? "", ...values } } })
            .then(() => onUpdate?.())
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    const formik = useFormik({ validationSchema: schema, onSubmit, initialValues, enableReinitialize: true });
    const { handleSubmit, handleChange, handleBlur, values, isSubmitting, errors, touched } = formik;

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!contact) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
                <StatusAlert status={formik.status as unknown} />

                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.email && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                        type="tel"
                        name="mobile"
                        placeholder="Mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.mobile && !!errors.mobile}
                    />
                    <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Company</Form.Label>
                    <Form.Control
                        type="text"
                        name="company"
                        placeholder="Company"
                        value={values.company}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.company && !!errors.company}
                    />
                    <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Position</Form.Label>
                    <Form.Control
                        type="text"
                        name="position"
                        placeholder="Position"
                        value={values.position}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.position && !!errors.position}
                    />
                    <Form.Control.Feedback type="invalid">{errors.position}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="address"
                        placeholder="Address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.address && !!errors.address}
                    />
                    <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                        type="tel"
                        name="phone"
                        placeholder="Phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.phone && !!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" disabled={isSubmitting}>
                    Update
                </Button>
            </Form>
        </FormikProvider>
    );
};
