import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { Report, ReportVersion } from "app/api/graph/types";
import { editReportRoute, editReportVersionRoute, reportRoute } from "app/route/Routes";
import { Maybe } from "app/util/type";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { EditReportForm } from "app/component/report/EditReportForm";
import { useRouteParams } from "app/route/route";

export const EditReportScreen: FC = () => {
    const { reportId } = useRouteParams(editReportRoute);
    const [report, setReport] = useState<
        (Pick<Report, "_id"> & { latestVersion?: Maybe<Pick<ReportVersion, "_id">> }) | undefined
    >(undefined);

    return report?.latestVersion ? (
        <Navigate to={editReportVersionRoute.build({ reportId: report._id, versionId: report.latestVersion?._id })} />
    ) : report ? (
        <Navigate to={reportRoute.build({ reportId: report._id })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editReportRoute} />
                <EditReportForm reportId={reportId} onUpdate={setReport} />
            </Container>
        </NavigationScreen>
    );
};
