import { FC } from "react";
import Container from "react-bootstrap/Container";
import { FaList, FaPlus } from "react-icons/fa";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { createVulnerabilityRoute, testingTypesRoute, vulnerabilitiesRoute } from "app/route/Routes";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { createVulnerabilityRole, listTestingTypesRole } from "app/api/graph/roles";
import { VulnerabilitiesTable } from "app/component/vulnerability/VulnerabilitiesTable";
import { useAuth } from "app/api/AuthContext";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ButtonLink } from "app/component/button/ButtonLink";

export const VulnerabilitiesListScreen: FC = () => {
    const { roles } = useAuth();
    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={vulnerabilitiesRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Vulnerabilities</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {roles?.includes(createVulnerabilityRole) ? (
                            <ButtonLink to={createVulnerabilityRoute.build({})} variant="success">
                                <FaPlus /> New vulnerability
                            </ButtonLink>
                        ) : null}
                        {roles?.includes(listTestingTypesRole) ? (
                            <ButtonLink to={testingTypesRoute.build({})} variant="info">
                                <FaList /> Testing types
                            </ButtonLink>
                        ) : null}
                    </ToolbarButtons>
                </Toolbar>
                <VulnerabilitiesTable />
            </Container>
        </NavigationScreen>
    );
};
