import { FC, useCallback, useEffect } from "react";
import { Part } from "app/component/report-version/component/part/Part";
import { useCurrentPart } from "app/component/report-version/component/part/use-current-part";
import { ReportVersion, useCreateReportReadReceiptMutation } from "app/api/graph/types";
import { useSet } from "app/hook/use-set";

type Props = {
    reportVersion: Pick<ReportVersion, "id">;
    parts: Part[];
};

export const ReadReceiptRecorder: FC<Props> = ({ reportVersion, parts }) => {
    const { currentPart } = useCurrentPart(parts);
    const [createReadReceipt] = useCreateReportReadReceiptMutation();
    const { items, add } = useSet<string>();

    const record = useCallback(
        (part: Part) => {
            // Only log each section once
            if (items.includes(part.sectionNumber)) {
                return;
            }

            add(part.sectionNumber);
            createReadReceipt({
                variables: { input: { reportVersion: reportVersion.id, sectionNumber: part.sectionNumber } },
            }).catch(console.error);
        },
        [items, add, createReadReceipt],
    );

    useEffect(() => {
        if (currentPart) {
            record(currentPart);
        }
    }, [reportVersion, currentPart]);

    return null;
};
