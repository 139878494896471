import { FC } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { deleteVulnerabilityRoute, vulnerabilitiesRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useDeleteAllVersionsOfVulnerabilityMutation, useVulnerabilityQuery } from "app/api/graph/types";
import { vulnerabilityGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { ConfirmDelete } from "app/component/form/ConfirmDelete";
import { formatISODateTime } from "app/util/format";

export const DeleteVulnerabilityScreen: FC = () => {
    const { vulnerabilityId } = useRouteParams(deleteVulnerabilityRoute);
    const { data, loading, error } = useVulnerabilityQuery({
        variables: { vulnerabilityId: vulnerabilityGraphId(vulnerabilityId) },
    });
    const [deleteVulnerability, { data: deleteData, loading: deleting, error: deleteError }] =
        useDeleteAllVersionsOfVulnerabilityMutation({
            variables: { input: { id: vulnerabilityGraphId(vulnerabilityId) } },
        });
    const vulnerability = data?.vulnerability;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!vulnerability) {
        return <ErrorScreen title="Error" message="Not found." />;
    }
    if (deleteData?.deleteAllVersionsOfVulnerability) {
        return <Navigate to={vulnerabilitiesRoute.build({})} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={deleteVulnerabilityRoute} />
                <h1>Delete vulnerability</h1>
                {deleteError ? <Alert variant="danger">{deleteError.message}</Alert> : null}
                <p>Are you sure that you want to permanently delete this vulnerability?</p>
                <Table responsive="sm">
                    <tbody>
                        <tr>
                            <th>Identifier</th>
                            <td>{vulnerability.identifier ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{vulnerability.title ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Created by</th>
                            <td>{vulnerability.createdBy?.fullName ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Created at</th>
                            <td>{formatISODateTime(vulnerability.createdAt)}</td>
                        </tr>
                    </tbody>
                </Table>
                <Alert variant="danger">
                    <p>
                        This will <strong>permanently</strong> delete all versions of this vulnerability - which cannot
                        be reversed.
                    </p>
                    Note: report vulnerabilities that are based on this master vulnerability will be unlinked, but not
                    deleted - they must be deleted separately if needed.
                </Alert>
                <ConfirmDelete onConfirm={() => deleteVulnerability()} deleting={deleting} />
            </Container>
        </NavigationScreen>
    );
};
