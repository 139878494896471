import { FC, useMemo } from "react";
import Alert from "react-bootstrap/Alert";
import { UpdateContactMutation, useContactQuery, useUpdateContactMutation } from "app/api/graph/types";
import { FormValues, ContactForm } from "app/component/contact/ContactForm";
import { contactGraphId } from "app/api/graph/helpers";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";

type Props = {
    contactId: number;
    onUpdate: (contact: NonNullable<NonNullable<UpdateContactMutation["updateContact"]>["contact"]>) => void;
};

export const EditContactForm: FC<Props> = ({ contactId, onUpdate }) => {
    const { data, loading, error } = useContactQuery({
        variables: { contactId: contactGraphId(contactId) },
    });
    const contact = data?.contact;
    const [updateContact, { error: updateError }] = useUpdateContactMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            name: contact?.name ?? "",
            address: contact?.address ?? undefined,
            companyName: contact?.companyName ?? undefined,
            email: contact?.email ?? undefined,
            mobile: contact?.mobile ?? undefined,
            phone: contact?.phone ?? undefined,
            position: contact?.position ?? undefined,
        }),
        [contact],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!contact) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = (values: FormValues) =>
        updateContact({ variables: { input: { id: contact.id, ...values } } })
            .then((response) => response.data?.updateContact?.contact)
            .then((contact) => (contact ? onUpdate?.(contact) : undefined));

    return <ContactForm initialValues={initialValues} onSubmit={onSubmit} error={updateError} />;
};
