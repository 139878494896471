import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import yup from "app/util/yup";
import { StatusAlert } from "app/component/form/StatusAlert";
import { useApplyUserMobileNumberChangeRequestMutation, UserMobileNumberChangeRequest } from "app/api/graph/types";
import { handleError } from "app/api/graph/form";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";

type Props = {
    request: Pick<UserMobileNumberChangeRequest, "id" | "_id">;
    onApply: () => void;
};

type FormValues = {
    challenge: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    challenge: yup.string().required(),
});

const initialValues: FormValues = {
    challenge: "",
};

export const ApplyUserMobileNumberChangeRequestForm: FC<Props> = ({ request, onApply }) => {
    const [applyRequest] = useApplyUserMobileNumberChangeRequestMutation();

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        applyRequest({
            variables: {
                input: {
                    id: request.id,
                    ...values,
                },
            },
        })
            .then((response) =>
                response.data?.applyUserMobileNumberChangeRequest?.userMobileNumberChangeRequest
                    ? onApply()
                    : undefined,
            )
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Alert variant="info">We&lsquo;ve sent you a verification code via SMS.</Alert>
                    <Form.Group className="mb-3">
                        <Form.Label>Enter the code below</Form.Label>
                        <Form.Control
                            name="challenge"
                            type="text"
                            placeholder="XXXXXX"
                            value={values.challenge}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.challenge && !!errors.challenge}
                        />
                        <Form.Control.Feedback type="invalid">{errors.challenge}</Form.Control.Feedback>
                    </Form.Group>
                    <BlockButtonGroup>
                        <Button type="submit" disabled={isSubmitting}>
                            Update mobile number
                        </Button>
                    </BlockButtonGroup>
                </Form>
            )}
        </Formik>
    );
};
