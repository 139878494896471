import { FC } from "react";
import { styled } from "styled-components";
import { ButtonLink } from "app/component/button/ButtonLink";

type Props = {
    title: string;
    message: string;
};

const Page = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper = styled.div`
    width: 100%;
    max-width: 500px;
    padding: 15px;
    margin: 0 auto;
    text-align: center;
`;

export const ErrorScreen: FC<Props> = ({ title, message }) => {
    return (
        <Page>
            <Wrapper>
                <h2>{title}</h2>
                <p>{message}</p>
                <ButtonLink to="/">Go home</ButtonLink>
            </Wrapper>
        </Page>
    );
};
