import { FC } from "react";
import { FaFilePdf } from "react-icons/fa";
import Button, { ButtonProps } from "react-bootstrap/Button";
import { useDownloadReportVersionTestCertificatePdf } from "app/api/hook/use-download-report-version-test-certificate";

type Props = {
    versionId: number;
    titleText?: string;
} & ButtonProps;

export const DownloadReportVersionTestCertificatePdfButton: FC<Props> = ({ versionId, titleText, ...rest }) => {
    const { download, loading: downloading } = useDownloadReportVersionTestCertificatePdf(versionId);

    return (
        <Button variant="primary" onClick={download} disabled={downloading} {...rest}>
            <FaFilePdf /> {titleText ?? "Download test certificate PDF"}
        </Button>
    );
};
