import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaFilter } from "react-icons/fa";
import yup from "app/util/yup";
import { StatusAlert } from "app/component/form/StatusAlert";
import { SelectTestingTypes } from "app/component/form/select/SelectTestingTypes.tsx";

type Props = {
    initialValues: FormValues;
    onSubmit: (values: FormValues) => void;
};

export type FormValues = {
    identifier?: string;
    title?: string;
    testingTypes?: string[];
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    identifier: yup.string(),
    title: yup.string(),
    testingTypes: yup.array().of(yup.string().required()),
});

export const VulnerabilitiesTableFiltersForm: FC<Props> = (props) => {
    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={props.initialValues}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                values,
                isSubmitting,
                errors,
                touched,
                status,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>Identifier</Form.Label>
                        <Form.Control
                            type="search"
                            name="identifier"
                            value={values.identifier ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.identifier && !!errors.identifier}
                        />
                        <Form.Control.Feedback type="invalid">{errors.identifier}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="search"
                            name="title"
                            value={values.title ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Testing types</Form.Label>
                        <SelectTestingTypes
                            id="testing-types"
                            value={values.testingTypes ?? []}
                            onChange={(value) => setFieldValue("testingTypes", value)}
                            onBlur={() => setFieldTouched("testingTypes")}
                            isInvalid={touched.testingTypes && !!errors.testingTypes}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testingTypes}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        <FaFilter /> Filter
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
