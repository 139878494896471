import { FC } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { FaEdit } from "react-icons/fa";
import { mapNodes } from "app/util/graph";
import { useReportVersionSectionsListQuery } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { editReportVersionSectionRoute } from "app/route/Routes";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

type Props = {
    reportId: number;
    versionId: number;
};

export const ReportVersionSectionsTable: FC<Props> = ({ reportId, versionId }) => {
    const { data, loading, error, fetchMore } = useReportVersionSectionsListQuery({
        variables: { versionId: reportVersionGraphId(versionId) },
        notifyOnNetworkStatusChange: true,
    });
    const version = data?.reportVersion;
    const sections = version?.sections;

    if (loading && !sections) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!version || !sections) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const fetchMoreSections = () => fetchMore({ variables: { after: sections.pageInfo.endCursor } });

    return (
        <Table responsive="sm" bordered className="crud-table">
            <thead>
                <tr>
                    <th>Section</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {mapNodes(sections, (section) => (
                    <tr key={section.id}>
                        <td>{section.name}</td>
                        <td>
                            <ButtonLink
                                to={editReportVersionSectionRoute.build({
                                    reportId,
                                    versionId: version._id,
                                    sectionId: section._id,
                                })}
                                variant="warning"
                                size="sm"
                                title="Edit"
                            >
                                <FaEdit />
                            </ButtonLink>
                        </td>
                    </tr>
                ))}
                {sections.edges?.length === 0 ? (
                    <tr>
                        <td colSpan={100}>No sections.</td>
                    </tr>
                ) : null}
                {loading ? (
                    <tr>
                        <td colSpan={100}>
                            <LoadingSpinner />
                        </td>
                    </tr>
                ) : null}
                {sections.pageInfo.hasNextPage ? (
                    <tr>
                        <td colSpan={100}>
                            <BlockButtonGroup>
                                <Button variant="light" onClick={fetchMoreSections}>
                                    Load more
                                </Button>
                            </BlockButtonGroup>
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </Table>
    );
};
