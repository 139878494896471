import { FC, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { useDebounce } from "use-debounce";
import { User, useUserListQuery } from "app/api/graph/types";
import { SelectData, SelectDataProps } from "app/component/form/select/SelectData";

type Model = Pick<User, "id" | "_id" | "firstName" | "lastName" | "fullName" | "email">;

export const SelectUser: FC<SelectDataProps<Model>> = (props) => {
    const [query, setQuery] = useState<string | undefined>(undefined);
    const { data, loading, fetchMore, error } = useUserListQuery({
        variables: { firstName: useDebounce(query, 400)[0] },
        notifyOnNetworkStatusChange: true,
    });

    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }

    return (
        <SelectData
            {...props}
            connection={data?.users}
            label={(value) => `${value?.fullName ?? ""} (${value?.email ?? ""})`}
            isLoading={loading}
            onPaginate={() => fetchMore({ variables: { after: data?.users?.pageInfo?.endCursor } })}
            onInputChange={setQuery}
        />
    );
};
