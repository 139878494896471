import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { Company } from "app/api/graph/types";
import { createCompanyRoute, companyRoute } from "app/route/Routes";
import { CreateCompanyForm } from "app/component/company/CreateCompanyForm";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";

export const CreateCompanyScreen: FC = () => {
    const [company, setCompany] = useState<Pick<Company, "_id"> | undefined>(undefined);

    return company ? (
        <Navigate to={companyRoute.build({ companyId: company._id })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={createCompanyRoute} />
                <CreateCompanyForm onCreate={setCompany} />
            </Container>
        </NavigationScreen>
    );
};
