import { FC } from "react";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { styled } from "styled-components";
import { VulnerabilitySeverity } from "app/api/graph/types";
import { vulnerabilitySeverityColor, vulnerabilitySeverityLabel } from "app/api/graph/strings";

type Props = {
    severity: VulnerabilitySeverity;
    className?: string;
};

const vulnerabilitySeverityDescription = (value: VulnerabilitySeverity): string => {
    switch (value) {
        case VulnerabilitySeverity.Critical:
            return "A threat agent could gain full control over the system or application, render it unusable for users, or access a very large amount of confidential information by using commonly available exploits and techniques.";
        case VulnerabilitySeverity.High:
            return "A threat agent could gain full control over the system or application, render it unusable to users, or access a substantial amount of confidential information. Exploits may be more difficult to obtain, or complex to utilize.";
        case VulnerabilitySeverity.Medium:
            return "A threat agent could gain some level of interactive control or access to data held on the system.";
        case VulnerabilitySeverity.Low:
            return "A threat agent could gain information about the systems, and with which it could be used to leverage further access.";
        case VulnerabilitySeverity.Informational:
            return "Not a threat, but purely informational. Such as a positive comment.";
    }
};

const vulnerabilitySeverityCvssScore = (value: VulnerabilitySeverity): string => {
    switch (value) {
        case VulnerabilitySeverity.Critical:
            return "9.0-10.0";
        case VulnerabilitySeverity.High:
            return "7.0-8.9";
        case VulnerabilitySeverity.Medium:
            return "4.0-6.9";
        case VulnerabilitySeverity.Low:
            return "0.1-3.9";
        case VulnerabilitySeverity.Informational:
            return "N/A";
    }
};

const ColoredBadge = styled(Badge)<{ $color: string }>`
    color: white;
    ${({ $color }) => `background-color: ${$color} !important;`}
`;

export const SeverityBadge: FC<Props> = ({ severity, ...props }) => (
    <OverlayTrigger
        trigger={["hover", "click"]}
        placement="auto"
        overlay={
            <Popover id="severity-tooltip">
                <Popover.Header as="h3">{vulnerabilitySeverityLabel(severity)}</Popover.Header>
                <Popover.Body>
                    <p>{vulnerabilitySeverityDescription(severity)}</p>
                    <div>CVSS range: {vulnerabilitySeverityCvssScore(severity)}</div>
                </Popover.Body>
            </Popover>
        }
    >
        <ColoredBadge $color={vulnerabilitySeverityColor(severity)} {...props}>
            {vulnerabilitySeverityLabel(severity)}
        </ColoredBadge>
    </OverlayTrigger>
);
