import { FC, useMemo } from "react";
import Alert from "react-bootstrap/Alert";
import { UpdateReportMutation, useReportOverviewQuery, useUpdateReportMutation } from "app/api/graph/types";
import { FormValues, ReportForm } from "app/component/report/ReportForm";
import { reportGraphId } from "app/api/graph/helpers";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";

type Props = {
    reportId: number;
    onUpdate: (report: NonNullable<NonNullable<UpdateReportMutation["updateReport"]>["report"]>) => void;
};

export const EditReportForm: FC<Props> = ({ reportId, onUpdate }) => {
    const { data, loading, error } = useReportOverviewQuery({ variables: { reportId: reportGraphId(reportId) } });
    const report = data?.report;
    const [updateReport, { error: updateError }] = useUpdateReportMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            title: report?.title ?? "",
            company: report?.company?.id ?? null,
        }),
        [report],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!report) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = (values: FormValues) =>
        updateReport({ variables: { input: { id: report.id, ...values } } })
            .then((response) => response.data?.updateReport?.report)
            .then((report) => (report ? onUpdate?.(report) : undefined));

    return <ReportForm initialValues={initialValues} onSubmit={onSubmit} error={updateError} />;
};
