import { FC, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { editReportVersionRoute, editReportVersionConsultantRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ContactFieldsFragment, useReportVersionConsultantQuery } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { EditReportContactForm, FormValues } from "app/component/report-contact/EditReportContactForm";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { useAuth } from "app/api/AuthContext.tsx";
import { listUsersRole } from "app/api/graph/roles.ts";
import { SelectContact } from "app/component/form/select/SelectContact.tsx";

export const EditReportVersionConsultantContactScreen: FC = () => {
    const { roles } = useAuth();
    const { reportId, versionId } = useRouteParams(editReportVersionConsultantRoute);
    const [updated, setUpdated] = useState(false);
    const { data, loading, error } = useReportVersionConsultantQuery({
        variables: { versionId: reportVersionGraphId(versionId) },
    });

    const consultant = data?.reportVersion?.consultant;
    const [contact, setContact] = useState<ContactFieldsFragment | undefined>();

    const initialValues: FormValues | undefined = useMemo(
        () =>
            contact !== undefined
                ? {
                      name: contact.name ?? undefined,
                      address: contact.address ?? undefined,
                      company: contact.companyName ?? undefined,
                      email: contact.email ?? undefined,
                      mobile: contact.mobile ?? undefined,
                      phone: contact.phone ?? undefined,
                      position: contact.position ?? undefined,
                  }
                : undefined,
        [contact],
    );

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!consultant?.contact) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    return updated ? (
        <Navigate to={editReportVersionRoute.build({ reportId, versionId })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editReportVersionConsultantRoute} />

                {roles?.includes(listUsersRole) && (
                    <Form.Group className="mb-3">
                        <Form.Label>Contact</Form.Label>
                        <SelectContact
                            value={contact?.id ?? null}
                            onChange={(_id, value) => setContact(value)}
                            id="select-contact"
                        />
                        <Form.Text className="text-muted">
                            Select a contact to automatically populate this form.
                        </Form.Text>
                        <hr />
                    </Form.Group>
                )}

                <EditReportContactForm
                    contactId={consultant.contact._id}
                    initialValues={initialValues}
                    onUpdate={() => setUpdated(true)}
                />
            </Container>
        </NavigationScreen>
    );
};
