import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import {
    CompanyListFieldsFragment,
    ReportListFieldsFragment,
    ReportSectionFieldsFragment,
    ReportVersionFieldsFragment,
    ReportVersionListFieldsFragment,
    ReportVulnerabilityFieldsFragment,
    useReportVersionQuery,
} from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { useReportVersionSections } from "app/api/graph/hook/use-report-version-sections";
import { useReportVersionVulnerabilities } from "app/api/graph/hook/use-report-version-vulnerabilities";
import { useReportVersionsList } from "app/api/graph/hook/use-report-versions-list";

type Result = {
    report?: ReportListFieldsFragment;
    version?: ReportVersionFieldsFragment;
    sections?: ReportSectionFieldsFragment[];
    vulnerabilities?: ReportVulnerabilityFieldsFragment[];
    versions?: ReportVersionListFieldsFragment[];
    company?: CompanyListFieldsFragment;
    loading: boolean;
    error?: ApolloError;
};

export const useReportVersion = (reportId: number, versionId: number): Result => {
    const versionQuery = useReportVersionQuery({
        variables: { versionId: reportVersionGraphId(versionId) },
    });
    const sectionsQuery = useReportVersionSections(versionId);
    const vulnerabilitiesQuery = useReportVersionVulnerabilities(versionId);
    const versionsQuery = useReportVersionsList(reportId);

    return useMemo(
        () => ({
            report: versionQuery.data?.reportVersion?.report,
            version: versionQuery.data?.reportVersion ?? undefined,
            sections: sectionsQuery.sections,
            vulnerabilities: vulnerabilitiesQuery.vulnerabilities,
            versions: versionsQuery.versions,
            company: versionQuery.data?.reportVersion?.report?.company ?? undefined,
            loading:
                versionQuery.loading || sectionsQuery.loading || vulnerabilitiesQuery.loading || versionsQuery.loading,
            error: versionQuery.error || sectionsQuery.error || vulnerabilitiesQuery.error || versionsQuery.error,
        }),
        [versionQuery, sectionsQuery, vulnerabilitiesQuery, versionsQuery],
    );
};
