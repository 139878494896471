import { FC } from "react";
import Container from "react-bootstrap/Container";
import { FaPlus } from "react-icons/fa";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { createReportRoute, reportsRoute } from "app/route/Routes";
import { useAuth } from "app/api/AuthContext";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { createReportRole } from "app/api/graph/roles";
import { ReportsTable } from "app/component/report/ReportsTable";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ButtonLink } from "app/component/button/ButtonLink";

export const ReportsListScreen: FC = () => {
    const { roles } = useAuth();
    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={reportsRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Reports</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {roles?.includes(createReportRole) ? (
                            <ButtonLink to={createReportRoute.build({})} variant="success">
                                <FaPlus /> New report
                            </ButtonLink>
                        ) : null}
                    </ToolbarButtons>
                </Toolbar>
                <ReportsTable />
            </Container>
        </NavigationScreen>
    );
};
