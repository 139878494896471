import { isPast } from "date-fns";
import { ApolloClient, InMemoryCache, from, NormalizedCacheObject, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { relayStylePagination } from "@apollo/client/utilities";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { AuthContextRef } from "app/api/AuthContext";

export const createGraphClient = (): ApolloClient<NormalizedCacheObject> => {
    const httpLink = createUploadLink({
        uri: "/api/graphql",
        credentials: "include",
    });

    const checkAuthLink = new ApolloLink((operation, forward) => {
        // The JWT cookie could have expired, so check for that and sign the user out if so
        // Don't sign out if theres isn't a JWT present, as it could be an anonymous user - although this means that a
        // user deleting the cookie would keep them signed in, but API requests would fail
        const expiresAt = AuthContextRef.current?.expiresAt;
        if (expiresAt && isPast(expiresAt)) {
            AuthContextRef.current?.signOut();
            return null;
        }
        return forward(operation);
    });

    const signOutOnUnauthorizedLink = onError(({ networkError }) => {
        // User's authentication is not longer valid (e.g. user was deleted) so sign them out
        if (networkError && "statusCode" in networkError && [401, 403].includes(networkError.statusCode)) {
            AuthContextRef.current?.signOut();
        }
    });

    return new ApolloClient({
        link: from([checkAuthLink, signOutOnUnauthorizedLink, httpLink as unknown as ApolloLink]),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        contacts: relayStylePagination(["order"]),
                        companies: relayStylePagination(["name", "order"]),
                        companyInvites: relayStylePagination(["order"]),
                        reports: relayStylePagination(["title", "order"]),
                        reportInvites: relayStylePagination(["order"]),
                        testingTypes: relayStylePagination(["order"]),
                        users: relayStylePagination(["firstName", "lastName", "email", "order"]),
                        consultantUsers: relayStylePagination(["firstName", "lastName", "email", "order"]),
                        userNotifications: relayStylePagination(["createdAt", "order"]),
                        vulnerabilities: relayStylePagination([
                            "identifier",
                            "title",
                            "testingTypes",
                            "testingTypes_list",
                            "order",
                        ]),
                        publishedCompanyReportVulnerabilities: relayStylePagination([
                            "identifier",
                            "title",
                            "testingTypes",
                            "testingTypes_list",
                            "dateDiscovered",
                            "order",
                        ]),
                        publishedReportVulnerabilities: relayStylePagination([
                            "identifier",
                            "title",
                            "testingTypes",
                            "testingTypes_list",
                            "dateDiscovered",
                            "order",
                        ]),
                    },
                },
                Company: {
                    fields: {
                        invites: relayStylePagination(["order"]),
                        reports: relayStylePagination(["order"]),
                        users: relayStylePagination(["order"]),
                    },
                },
                Report: {
                    fields: {
                        invites: relayStylePagination(["order"]),
                        versions: relayStylePagination(["order"]),
                        viewers: relayStylePagination(["order"]),
                    },
                },
                ReportVersion: {
                    fields: {
                        sections: relayStylePagination(["order"]),
                        vulnerabilities: relayStylePagination([
                            "status",
                            "severity",
                            "issueNumber",
                            "title",
                            "testingTypes",
                            "testingTypes_list",
                            "order",
                        ]),
                        readReceipts: relayStylePagination(["order"]),
                    },
                },
                ReportVulnerability: {
                    fields: {
                        actions: relayStylePagination(["order"]),
                        comments: relayStylePagination(["order"]),
                        clientNotifications: relayStylePagination(["order"]),
                        testingTypes: relayStylePagination(["order"]),
                    },
                },
                ReportVulnerabilityAction: {
                    fields: {
                        files: relayStylePagination(["order"]),
                    },
                },
                Vulnerability: {
                    fields: {
                        testingTypes: relayStylePagination(["order"]),
                    },
                },
            },
        }),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "cache-and-network",
                nextFetchPolicy: "cache-first",
            },
        },
    });
};
