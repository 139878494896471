import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { usePublishReportVersionMutation } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";

type Props = {
    reportVersionId: number;
    onPublish?: () => void;
};

export const PublishReportVersionForm: FC<Props> = ({ reportVersionId, onPublish }) => {
    const [publish, { loading, error }] = usePublishReportVersionMutation({
        variables: {
            reportVersionId: reportVersionGraphId(reportVersionId),
        },
    });

    return (
        <div>
            {error ? <Alert variant="danger">{error.message}</Alert> : null}
            <Alert variant="warning">
                Do you really want to publish this report version? The report will become available to all company and
                invited viewers, who will be notified by email.
            </Alert>
            <Button
                type="submit"
                variant="warning"
                disabled={loading}
                onClick={() => publish().then(() => onPublish?.())}
            >
                Publish
            </Button>
        </div>
    );
};
