import { FC } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Navigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { useRouteParams } from "app/route/route";
import { deleteCompanyInviteRoute, companyInvitesRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { useDeleteCompanyInviteMutation, useCompanyInviteQuery } from "app/api/graph/types";
import { companyInviteGraphId } from "app/api/graph/helpers";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { formatISODateTime } from "app/util/format";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { LoadingScreen } from "app/component/screen/LoadingScreen";

export const DeleteCompanyInviteScreen: FC = () => {
    const { companyId, inviteId } = useRouteParams(deleteCompanyInviteRoute);
    const { data, loading, error } = useCompanyInviteQuery({
        variables: { inviteId: companyInviteGraphId(inviteId) },
    });
    const [deleteInvite, { data: deleteData, loading: deleting, error: deleteError }] = useDeleteCompanyInviteMutation({
        variables: { inviteId: companyInviteGraphId(inviteId) },
    });
    const invite = data?.companyInvite;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!invite) {
        return <ErrorScreen title="Error" message="Not found." />;
    }
    if (deleteData?.deleteCompanyInvite) {
        return <Navigate to={companyInvitesRoute.build({ companyId })} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={deleteCompanyInviteRoute} />
                <h1>Delete invite</h1>
                {deleteError ? <Alert variant="danger">{deleteError.message}</Alert> : null}
                <p>Are you sure that you want to delete this invite?</p>
                <Table responsive="sm">
                    <tbody>
                        <tr>
                            <th>First name</th>
                            <td>{invite.firstName ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Last name</th>
                            <td>{invite.lastName ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{invite.email ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Invited at</th>
                            <td>{formatISODateTime(invite.createdAt)}</td>
                        </tr>
                    </tbody>
                </Table>
                {invite.accepted ? (
                    <p>Deleting the invite will not delete the user that has already accepted it.</p>
                ) : null}
                <Button variant="danger" disabled={deleting} onClick={() => deleteInvite()}>
                    <FaTrash /> Delete
                </Button>
            </Container>
        </NavigationScreen>
    );
};
