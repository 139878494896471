import { FC } from "react";
import { CreateReportMutation, useCreateReportMutation } from "app/api/graph/types";
import { FormValues, ReportForm } from "app/component/report/ReportForm";

type Props = {
    onCreate: (report: NonNullable<NonNullable<CreateReportMutation["createReport"]>["report"]>) => void;
};

const initialValues: FormValues = {
    title: "",
    company: null,
};

export const CreateReportForm: FC<Props> = ({ onCreate }) => {
    const [createReport, { error }] = useCreateReportMutation({});

    const onSubmit = (values: FormValues) =>
        createReport({ variables: { input: values } })
            .then((response) => response.data?.createReport?.report)
            .then((report) => (report ? onCreate?.(report) : undefined));

    return <ReportForm initialValues={initialValues} onSubmit={onSubmit} error={error} />;
};
