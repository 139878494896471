import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { CompanyContactFieldsFragment } from "app/api/graph/types";
import { companyContactsRoute, createCompanyContactRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { CreateCompanyContactForm } from "app/component/company-contact/CreateCompanyContactForm";

export const CreateCompanyContactScreen: FC = () => {
    const { companyId } = useRouteParams(createCompanyContactRoute);
    const [contact, setContact] = useState<CompanyContactFieldsFragment | undefined>(undefined);

    if (contact != null) {
        return <Navigate to={companyContactsRoute.build({ companyId })} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={createCompanyContactRoute} />
                <CreateCompanyContactForm companyId={companyId} onCreate={setContact} />
            </Container>
        </NavigationScreen>
    );
};
