import { FC, useMemo } from "react";
import { Formik, FormikHelpers } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {
    ReportVulnerabilityFileFieldsFragment,
    useReportVulnerabilityFileQuery,
    useUpdateReportVulnerabilityFileMutation,
} from "app/api/graph/types";
import yup from "app/util/yup.ts";
import { StatusAlert } from "app/component/form/StatusAlert.tsx";
import { RichTextEditor } from "app/component/form/text-editor/RichTextEditor.tsx";
import { handleError } from "app/api/graph/form.ts";
import { reportVulnerabilityFileGraphId } from "app/api/graph/helpers.ts";
import { LoadingSpinner } from "app/component/util/LoadingSpinner.tsx";

type Props = {
    fileId: number;
    onUpdate?: (reportVulnerabilityFile: ReportVulnerabilityFileFieldsFragment) => void;
};

type FormValues = {
    position: number;
    caption?: string;
    description?: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    position: yup.number().required(),
    caption: yup.string(),
    description: yup.string(),
});

export const UpdateReportVulnerabilityFileForm: FC<Props> = ({ fileId, onUpdate }) => {
    const { data, loading, error } = useReportVulnerabilityFileQuery({
        variables: { fileId: reportVulnerabilityFileGraphId(fileId) },
    });
    const reportVulnerabilityFile = data?.reportVulnerabilityFile;
    const [updateVulnerabilityFile] = useUpdateReportVulnerabilityFileMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            position: reportVulnerabilityFile?.position ?? -1,
            caption: reportVulnerabilityFile?.caption ?? undefined,
            description: reportVulnerabilityFile?.description ?? undefined,
        }),
        [reportVulnerabilityFile],
    );

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        updateVulnerabilityFile({
            variables: {
                input: {
                    id: reportVulnerabilityFileGraphId(fileId),
                    position: values.position,
                    caption: values.caption,
                    description: values.description,
                },
            },
        })
            .then((response) =>
                response.data?.updateReportVulnerabilityFile?.reportVulnerabilityFile
                    ? onUpdate?.(response.data?.updateReportVulnerabilityFile?.reportVulnerabilityFile)
                    : undefined,
            )
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!reportVulnerabilityFile) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
                setFieldTouched,
                values,
                isSubmitting,
                errors,
                touched,
                status,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />

                    <Form.Group className="mb-3">
                        <Form.Label>Position</Form.Label>
                        <Form.Control
                            type="number"
                            name="position"
                            value={values.position ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.position && !!errors.position}
                        />
                        <Form.Text>
                            Position/order in the list of files, with 0 being the first. Set to -1 to place at the end.
                            Setting a position used by another file will move that and subsequent files down a position.
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">{errors.position}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Caption</Form.Label>
                        <Form.Control
                            type="text"
                            name="caption"
                            value={values.caption}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.caption && !!errors.caption}
                        />
                        <Form.Control.Feedback type="invalid">{errors.caption}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <RichTextEditor
                            value={values.description ?? ""}
                            onChange={(value) => setFieldValue("description", value)}
                            onBlur={() => setFieldTouched("description")}
                            isInvalid={touched.description && !!errors.description}
                        />
                        <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                    </Form.Group>

                    <Button type="submit" disabled={isSubmitting}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
