import { Theme as ChartTheme } from "@nivo/core";
import { Theme, useTheme } from "app/context/ThemeContext";

export const useChartTheme = (): ChartTheme => {
    const { theme } = useTheme();

    return {
        text: {
            fill: theme === Theme.Dark ? "#A7A7A7" : "#333333",
        },
        tooltip: {
            container: {
                color: "#000000",
            },
        },
    };
};
