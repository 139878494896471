import { parse as parseCookie, serialize as serializeCookie } from "cookie";
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { noop } from "app/util/noop";
import { enumParser } from "app/route/route";

export enum Theme {
    Default = "default",
    Dark = "dark",
}

type Value = {
    theme: Theme;
    setTheme: (theme: Theme) => void;
};

const ThemeContext = createContext<Value>({
    theme: Theme.Default,
    setTheme: noop,
});

const THEME_COOKIE = "app_theme";

const getSavedTheme = (): Theme => {
    const cookies = parseCookie(document.cookie);
    const value = THEME_COOKIE in cookies ? cookies[THEME_COOKIE] : undefined;
    if (value == undefined) {
        return Theme.Default;
    }

    return enumParser(Theme)(value) ?? Theme.Default;
};

const setSavedTheme = (theme: Theme) => {
    document.cookie = serializeCookie(THEME_COOKIE, theme, {
        maxAge: 60 * 60 * 24 * 365, // 1 year
    });
};

export const ThemeProvider: FC<PropsWithChildren> = (props) => {
    const [theme, setTheme] = useState<Theme>(getSavedTheme());

    useEffect(() => {
        // Enable the new theme
        document.querySelectorAll<HTMLLinkElement>(`[data-theme='${theme}']`)[0].disabled = false;
        setTimeout(() => {
            // Disable all other themes
            document
                .querySelectorAll<HTMLLinkElement>(`link[data-theme]:not(link[data-theme='${theme}'])`)
                .forEach((element) => (element.disabled = true));
        }, 100);
    }, [theme]);

    const value: Value = {
        theme,
        setTheme: (theme: Theme) => {
            setTheme(theme);
            setSavedTheme(theme);
        },
    };

    return <ThemeContext.Provider value={value}>{props.children}</ThemeContext.Provider>;
};

export const useTheme: () => Value = () => useContext(ThemeContext);
