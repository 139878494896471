import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./toolbar.scss";

export const ToolbarTitle: FC<PropsWithChildren> = ({ children }) => (
    <Col sm="auto" className="toolbar-title me-auto">
        {children}
    </Col>
);

export const ToolbarButtons: FC<PropsWithChildren> = ({ children }) => (
    <Col className="toolbar-buttons" sm="auto">
        {children}
    </Col>
);

type Props = {
    className?: string;
};

export const Toolbar: FC<PropsWithChildren<Props>> = ({ children, className }) => (
    <Row className={classNames("toolbar align-items-center", className)}>{children}</Row>
);
