import { Formik, FormikHelpers } from "formik";
import { FC, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { formatISO, parseISO, isValid } from "date-fns";
import yup from "app/util/yup";
import { TestType, useReportVersionTestQuery, useUpdateReportVersionMutation } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { FormControlDate } from "app/component/form/control/FormControlDate";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";
import { FormControlSelectTestType } from "app/component/form/control/FormControlSelectTestType";

type Props = {
    versionId: number;
    onUpdate?: () => void;
};

type FormValues = {
    testName: string;
    testType?: TestType | null;
    testPerformedFrom: Date | null;
    testPerformedTo: Date | null;
    testApplicationVersion?: string | null;
    testServerVersion?: string | null;
    testHostname?: string | null;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    testName: yup.string().required(),
    testType: yup.mixed<TestType>().oneOf(Object.values(TestType)).nullable(),
    testPerformedFrom: yup.date().required(),
    testPerformedTo: yup
        .date()
        .required()
        .when("testPerformedFrom", (testPerformedFrom, schema) =>
            testPerformedFrom !== null && isValid(testPerformedFrom) ? schema.min(testPerformedFrom) : schema,
        ),
    testApplicationVersion: yup.string().nullable(),
    testServerVersion: yup.string().nullable(),
    testHostname: yup.string().nullable(),
});

export const EditReportVersionTestForm: FC<Props> = ({ versionId, onUpdate }) => {
    const { data, loading, error } = useReportVersionTestQuery({
        variables: { versionId: reportVersionGraphId(versionId) },
    });
    const version = data?.reportVersion;
    const [updateVersion] = useUpdateReportVersionMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            testName: version?.testName ?? "",
            testType: version?.testType ?? null,
            testPerformedFrom: version?.testPerformedFrom ? parseISO(version.testPerformedFrom) : null,
            testPerformedTo: version?.testPerformedTo ? parseISO(version.testPerformedTo) : null,
            testApplicationVersion: version?.testApplicationVersion ?? null,
            testServerVersion: version?.testServerVersion ?? null,
            testHostname: version?.testHostname ?? null,
        }),
        [version],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!version) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        updateVersion({
            variables: {
                input: {
                    id: version.id,
                    ...values,
                    testPerformedFrom: values.testPerformedFrom ? formatISO(values.testPerformedFrom) : undefined,
                    testPerformedTo: values.testPerformedTo ? formatISO(values.testPerformedTo) : undefined,
                },
            },
        })
            .then(() => onUpdate?.())
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                values,
                isSubmitting,
                errors,
                touched,
                status,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>Test name</Form.Label>
                        <Form.Control
                            type="text"
                            name="testName"
                            placeholder="Title"
                            value={values.testName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.testName && !!errors.testName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Test type</Form.Label>
                        <FormControlSelectTestType
                            name="testType"
                            value={values.testType ?? undefined}
                            onChange={(value) => setFieldValue("testType", value)}
                            onBlur={() => setFieldTouched("testType")}
                            isInvalid={touched.testType && !!errors.testType}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testType}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Test performed from</Form.Label>
                        <FormControlDate
                            name="testPerformedFrom"
                            placeholder="Test performed from"
                            value={values.testPerformedFrom}
                            onChange={(value) => setFieldValue("testPerformedFrom", value)}
                            onBlur={handleBlur}
                            isInvalid={touched.testPerformedFrom && !!errors.testPerformedFrom}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testPerformedFrom}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Test performed to</Form.Label>
                        <FormControlDate
                            name="testPerformedTo"
                            placeholder="Test performed to"
                            value={values.testPerformedTo}
                            onChange={(value) => setFieldValue("testPerformedTo", value)}
                            onBlur={handleBlur}
                            isInvalid={touched.testPerformedTo && !!errors.testPerformedTo}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testPerformedTo}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Application version</Form.Label>
                        <Form.Control
                            type="text"
                            name="testApplicationVersion"
                            placeholder="Application version"
                            value={values.testApplicationVersion ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.testApplicationVersion && !!errors.testApplicationVersion}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testApplicationVersion}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Server version</Form.Label>
                        <Form.Control
                            type="text"
                            name="testServerVersion"
                            placeholder="Server version"
                            value={values.testServerVersion ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.testServerVersion && !!errors.testServerVersion}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testServerVersion}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Hostname</Form.Label>
                        <Form.Control
                            type="text"
                            name="testHostname"
                            placeholder="Hostname"
                            value={values.testHostname ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.testHostname && !!errors.testHostname}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testHostname}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Update
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
