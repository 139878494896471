import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useReportVulnerabilityCommentsQuery } from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import { CreateReportVulnerabilityCommentForm } from "app/component/report-vulnerability-comment/CreateReportVulnerabilityCommentForm";
import { mapNodes } from "app/util/graph";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { ReportVulnerabilityComment } from "app/component/report-vulnerability-comment/ReportVulnerabilityComment";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";

type Props = {
    vulnerabilityId: number;
};

export const ReportVulnerabilityComments: FC<Props> = ({ vulnerabilityId }) => {
    const { data, loading, error, fetchMore } = useReportVulnerabilityCommentsQuery({
        variables: {
            vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId),
        },
        notifyOnNetworkStatusChange: true,
    });
    const comments = data?.reportVulnerability?.comments;

    const fetchMoreComments = () => fetchMore({ variables: { after: comments?.pageInfo.endCursor } });

    return (
        <>
            <CreateReportVulnerabilityCommentForm vulnerabilityId={vulnerabilityId} />
            <div className="mt-3">
                {error ? <Alert variant="danger">{error.message}</Alert> : null}
                {mapNodes(comments, (comment) => (
                    <ReportVulnerabilityComment key={comment.id} comment={comment} vulnerabilityId={vulnerabilityId} />
                ))}
                {!loading && comments?.edges?.length === 0 ? <Alert variant="info">No comments yet.</Alert> : null}
                {loading ? <LoadingSpinner /> : null}
                {data?.reportVulnerability?.comments?.pageInfo.hasNextPage ? (
                    <BlockButtonGroup>
                        <Button variant="light" disabled={loading} onClick={fetchMoreComments}>
                            Load more
                        </Button>
                    </BlockButtonGroup>
                ) : null}
            </div>
        </>
    );
};
