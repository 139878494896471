import classNames from "classnames";
import { ReactElement, InputHTMLAttributes } from "react";
import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";

type Option<Value> = {
    label: string;
    value: Value;
};

type Props<Value> = Omit<
    InputHTMLAttributes<HTMLSelectElement> & FormControlProps,
    "type" | "value" | "onChange" | "options"
> & {
    options: Option<Value>[];
    value: Value | undefined;
    onChange: (value: Value | undefined) => void;
};

export const FormControlSelectChoice = <Value extends string>({
    options,
    value,
    onChange,
    isValid,
    isInvalid,
    placeholder,
    ...props
}: Props<Value>): ReactElement => {
    return (
        <Form.Control
            {...props}
            as="select"
            value={value}
            onChange={(e) => onChange(e.target.value ? (e.target.value as Value) : undefined)}
            className={classNames({ "is-valid": isValid, "is-invalid": isInvalid })}
        >
            <option>{placeholder}</option>
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Form.Control>
    );
};
