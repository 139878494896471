import { FC, useMemo } from "react";
import Alert from "react-bootstrap/Alert";
import { parseISO, formatISO } from "date-fns";
import {
    FormValues,
    ReportVulnerabilityActionForm,
} from "app/component/report-vulnerability-action/ReportVulnerabilityActionForm";
import {
    ReportMediaObjectFieldsFragment,
    useReportVulnerabilityActionQuery,
    useUpdateReportVulnerabilityActionMutation,
} from "app/api/graph/types";
import { reportVulnerabilityActionGraphId } from "app/api/graph/helpers";
import { mapNodes } from "app/util/graph";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";

type Props = {
    actionId: number;
    versionId: number;
    onUpdate?: () => void;
};

export const EditReportVulnerabilityActionForm: FC<Props> = ({ actionId, onUpdate, versionId }) => {
    const { data, loading, error } = useReportVulnerabilityActionQuery({
        variables: { actionId: reportVulnerabilityActionGraphId(actionId) },
    });
    const action = data?.reportVulnerabilityAction;
    const [updateVulnerabilityAction, { error: updateError }] = useUpdateReportVulnerabilityActionMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            dateActioned: action?.dateActioned ? parseISO(action?.dateActioned) : null,
            description: action?.description ?? "",
            clientReference: action?.clientReference ?? "",
        }),
        [action],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!action) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = (values: FormValues, files: ReportMediaObjectFieldsFragment[]) =>
        updateVulnerabilityAction({
            variables: {
                input: {
                    id: action.id,
                    ...values,
                    dateActioned: values.dateActioned ? formatISO(values.dateActioned) : "",
                    files: files.map((file) => file.id),
                },
            },
        }).then(() => onUpdate?.());

    return (
        <ReportVulnerabilityActionForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            error={updateError}
            files={mapNodes(action.files, (file) => file)}
            versionId={versionId}
        />
    );
};
