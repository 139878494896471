import { FC, Fragment } from "react";
import Table from "react-bootstrap/Table";
import { Maybe } from "app/api/graph/types";

type DetailsTableData = [string, Maybe<string> | undefined][];

export const DetailsTable: FC<{ details: DetailsTableData }> = ({ details }) => (
    <Table className="table-layout-fixed">
        <tbody>
            {details.map(([title, value]) =>
                value !== undefined && value !== null && value?.length > 0 ? (
                    <tr key={title}>
                        <th>{title}</th>
                        <td>{value}</td>
                    </tr>
                ) : (
                    <Fragment key={title} />
                ),
            )}
        </tbody>
    </Table>
);
