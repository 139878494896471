import { FC } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { deleteReportVersionVulnerabilityActionRoute, reportVersionVulnerabilityActionsRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { useDeleteReportVulnerabilityActionMutation, useReportVulnerabilityActionQuery } from "app/api/graph/types";
import { reportVulnerabilityActionGraphId } from "app/api/graph/helpers";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { formatISODate } from "app/util/format";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { ConfirmDelete } from "app/component/form/ConfirmDelete";

export const DeleteReportVersionVulnerabilityActionScreen: FC = () => {
    const { reportId, versionId, vulnerabilityId, actionId } = useRouteParams(
        deleteReportVersionVulnerabilityActionRoute,
    );
    const { data, loading, error } = useReportVulnerabilityActionQuery({
        variables: { actionId: reportVulnerabilityActionGraphId(actionId) },
    });
    const [deleteAction, { data: deleteData, loading: deleting, error: deleteError }] =
        useDeleteReportVulnerabilityActionMutation({
            variables: { actionId: reportVulnerabilityActionGraphId(actionId) },
        });
    const action = data?.reportVulnerabilityAction;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!action) {
        return <ErrorScreen title="Error" message="Not found." />;
    }
    if (deleteData?.deleteReportVulnerabilityAction) {
        return <Navigate to={reportVersionVulnerabilityActionsRoute.build({ reportId, versionId, vulnerabilityId })} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={deleteReportVersionVulnerabilityActionRoute} />
                <h1>{formatISODate(action?.dateActioned)}</h1>
                {deleteError ? <Alert variant="danger">{deleteError.message}</Alert> : null}
                <p>Are you sure that you want to permanently delete this action from this report version?</p>
                <ConfirmDelete onConfirm={() => deleteAction()} deleting={deleting} />
            </Container>
        </NavigationScreen>
    );
};
