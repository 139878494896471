import { FC } from "react";
import Container from "react-bootstrap/Container";
import { FaPlus } from "react-icons/fa";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { testingTypesRoute, createTestingTypeRoute } from "app/route/Routes";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { TestingTypesTable } from "app/component/testing-type/TestingTypesTable";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ButtonLink } from "app/component/button/ButtonLink";

export const TestingTypesListScreen: FC = () => {
    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={testingTypesRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Testing types</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        <ButtonLink to={createTestingTypeRoute.build({})} variant="success">
                            <FaPlus /> New testing type
                        </ButtonLink>
                    </ToolbarButtons>
                </Toolbar>
                <TestingTypesTable />
            </Container>
        </NavigationScreen>
    );
};
