import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import yup from "app/util/yup";
import { StatusAlert } from "app/component/form/StatusAlert";
import { isValidPhoneNumber } from "app/util/phone";
import { useCreateUserMobileNumberChangeRequestMutation, UserMobileNumberChangeRequest } from "app/api/graph/types";
import { formatE164 } from "app/util/format";
import { handleError } from "app/api/graph/form";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";

type Props = {
    onCreate: (request: Pick<UserMobileNumberChangeRequest, "id" | "_id">) => void;
};

type FormValues = {
    toValue: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    toValue: yup
        .string()
        .required()
        .test("is-phone-number", "Invalid phone number", (value) => isValidPhoneNumber(value)),
});

const initialValues: FormValues = {
    toValue: "",
};

export const CreateUserMobileNumberChangeRequestForm: FC<Props> = ({ onCreate }) => {
    const [createRequest] = useCreateUserMobileNumberChangeRequestMutation();

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        createRequest({
            variables: {
                input: {
                    toValue: formatE164(values.toValue),
                },
            },
        })
            .then((response) =>
                response.data?.createUserMobileNumberChangeRequest?.userMobileNumberChangeRequest
                    ? onCreate(response.data.createUserMobileNumberChangeRequest.userMobileNumberChangeRequest)
                    : undefined,
            )
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Alert variant="info">
                        We&lsquo;ll send you a verification code to enter before your mobile number is updated.
                    </Alert>
                    <Form.Group className="mb-3">
                        <Form.Label>New mobile number</Form.Label>
                        <Form.Control
                            name="toValue"
                            type="tel"
                            placeholder="Mobile number"
                            value={values.toValue}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.toValue && !!errors.toValue}
                        />
                        <Form.Control.Feedback type="invalid">{errors.toValue}</Form.Control.Feedback>
                    </Form.Group>
                    <BlockButtonGroup>
                        <Button type="submit" disabled={isSubmitting}>
                            Send verification code
                        </Button>
                    </BlockButtonGroup>
                </Form>
            )}
        </Formik>
    );
};
