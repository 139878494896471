import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Navigate } from "react-router-dom";
import yup from "app/util/yup";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { addCompanyUserRoute, companyUsersRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { CompanyRole, useAddUserToCompanyMutation } from "app/api/graph/types";
import { companyGraphId } from "app/api/graph/helpers";
import { SelectUser } from "app/component/form/select/SelectUser";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";
import { FormControlSelectCompanyRole } from "app/component/form/control/FormControlSelectCompanyRole";

type FormValues = {
    user?: string;
    role: CompanyRole;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    user: yup.string().required(),
    role: yup.mixed<CompanyRole>().oneOf(Object.values(CompanyRole)).required(),
});

const initialValues: FormValues = {
    user: undefined,
    role: CompanyRole.Standard,
};

export const AddCompanyUserScreen: FC = () => {
    const { companyId } = useRouteParams(addCompanyUserRoute);
    const [addUser, { data }] = useAddUserToCompanyMutation({});

    if (data?.addUserToCompany) {
        return <Navigate to={companyUsersRoute.build({ companyId })} />;
    }

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        addUser({ variables: { companyId: companyGraphId(companyId), userId: values.user ?? "", role: values.role } })
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={addCompanyUserRoute} />
                <h1>Add user</h1>
                <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
                    {({
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        isSubmitting,
                        errors,
                        touched,
                        status,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <StatusAlert status={status as unknown} />
                            <Form.Group className="mb-3" controlId="select-user">
                                <SelectUser
                                    id="select-user"
                                    placeholder="Select a user..."
                                    value={values.user ?? null}
                                    onChange={(value) => setFieldValue("user", value)}
                                    onBlur={() => setFieldTouched("user")}
                                    isInvalid={touched.user && !!errors.user}
                                />
                                <Form.Control.Feedback type="invalid">{errors.user}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Role</Form.Label>
                                <FormControlSelectCompanyRole
                                    name="role"
                                    value={values.role}
                                    onChange={(value) => setFieldValue("role", value)}
                                    onBlur={() => setFieldTouched("role")}
                                    isInvalid={touched.role && !!errors.role}
                                />
                                <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>
                            </Form.Group>
                            <Button type="submit" disabled={isSubmitting}>
                                Submit
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </NavigationScreen>
    );
};
