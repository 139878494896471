import { FC } from "react";
import { styled } from "styled-components";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";

const Page = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LoadingScreen: FC = () => {
    return (
        <Page>
            <LoadingSpinner />
        </Page>
    );
};
