import { ReactElement } from "react";
import { v4 as uuidv4 } from "uuid";

export type Part = {
    id: string;
    sectionNumber: string;
    title: string;
    content: ReactElement | null;
    children?: Part[];
};

export type PartConfig = {
    title: string;
    content: ReactElement | null;
    children?: PartConfig[];
};

const buildPartsRecursive = (parts: PartConfig[], parentSectionNumber?: string): Part[] =>
    parts.map((part, i) => {
        const sectionNumber = parentSectionNumber === undefined ? `${i + 1}` : `${parentSectionNumber}.${i + 1}`;
        return {
            id: uuidv4(),
            sectionNumber,
            title: part.title,
            content: part.content,
            children: part.children ? buildPartsRecursive(part.children, sectionNumber) : undefined,
        };
    });

export const buildParts = (parts: PartConfig[]): Part[] => buildPartsRecursive(parts);

export const flatParts = (parts: Part[]): Part[] =>
    parts.reduce((parts, part) => {
        return [...parts, part, ...flatParts(part.children ?? [])];
    }, [] as Part[]);
