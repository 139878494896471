import { FC, InputHTMLAttributes } from "react";
import { FormControlProps } from "react-bootstrap/FormControl";
import { FormControlSelectChoice } from "app/component/form/control/FormControlSelectChoice";

type Props = Omit<InputHTMLAttributes<HTMLSelectElement> & FormControlProps, "type" | "value" | "onChange"> & {
    value: string | undefined;
    onChange: (value: string) => void;
};

const options = [
    "A01:2021-Broken Access Control",
    "A02:2021-Cryptographic Failures",
    "A03:2021-Injection",
    "A04:2021-Insecure Design",
    "A05:2021-Security Misconfiguration",
    "A06:2021-Vulnerable and Outdated Components",
    "A07:2021-Identification and Authentication Failures",
    "A08:2021-Software and Data Integrity Failures",
    "A09:2021-Security Logging and Monitoring Failures",
    "A10:2021-Server-Side Request Forgery",
    "API1:2023 - Broken Object Level Authorization",
    "API2:2023 - Broken Authentication",
    "API3:2023 - Broken Object Property Level Authorization",
    "API4:2023 - Unrestricted Resource Consumption",
    "API5:2023 - Broken Function Level Authorization",
    "API6:2023 - Unrestricted Access to Sensitive Business Flows",
    "API7:2023 - Server-Side Request Forgery",
    "API8:2023 - Security Misconfiguration",
    "API9:2023 - Improper Inventory Management",
    "API10:2023 - Unsafe Consumption of APIs",
    "Not Applicable",
].map((item) => ({
    value: item,
    label: item,
}));

export const FormControlSelectOwaspCategory: FC<Props> = ({ value, onChange, ...props }) => (
    <FormControlSelectChoice {...props} options={options} value={value} onChange={onChange} />
);
