import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { ContactFieldsFragment } from "app/api/graph/types";
import { contactsRoute, createContactRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { CreateContactForm } from "app/component/contact/CreateContactForm";

export const CreateContactScreen: FC = () => {
    const [contact, setContact] = useState<ContactFieldsFragment | undefined>(undefined);

    if (contact != null) {
        return <Navigate to={contactsRoute.build({})} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={createContactRoute} />
                <CreateContactForm onCreate={setContact} />
            </Container>
        </NavigationScreen>
    );
};
