import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate, useLocation } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { Vulnerability } from "app/api/graph/types";
import { createVulnerabilityRoute, vulnerabilityRoute } from "app/route/Routes";
import { CreateVulnerabilityForm } from "app/component/vulnerability/CreateVulnerabilityForm";
import { FormValues } from "app/component/vulnerability/VulnerabilityForm";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";

export type LocationState = {
    initialValues: Partial<FormValues>;
};

const isLocationState = (value: unknown): value is LocationState =>
    typeof value === "object" && value !== null && "initialValues" in value;

export const CreateVulnerabilityScreen: FC = () => {
    const [vulnerability, setVulnerability] = useState<Pick<Vulnerability, "_id"> | undefined>(undefined);
    const location = useLocation();

    return vulnerability ? (
        <Navigate to={vulnerabilityRoute.build({ vulnerabilityId: vulnerability._id })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={createVulnerabilityRoute} />
                <CreateVulnerabilityForm
                    initialValues={isLocationState(location.state) ? location.state.initialValues : undefined}
                    onCreate={setVulnerability}
                />
            </Container>
        </NavigationScreen>
    );
};
