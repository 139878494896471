import { FC } from "react";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { companyReportVulnerabilitiesRoute } from "app/route/Routes";
import { Toolbar, ToolbarTitle } from "app/component/util/Toolbar";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useRouteParams } from "app/route/route.ts";
import { PublishedCompanyReportVulnerabilitiesTable } from "app/component/company/PublishedCompanyReportVulnerabilitiesTable.tsx";
import { FormValues } from "app/component/report-vulnerability/ReportVulnerabilitiesTableFiltersForm";

export type LocationState = {
    initialFilters: FormValues;
};

const isLocationState = (value: unknown): value is LocationState =>
    typeof value === "object" && value !== null && "initialFilters" in value;

export const CompanyReportVulnerabilitiesListScreen: FC = () => {
    const { companyId } = useRouteParams(companyReportVulnerabilitiesRoute);
    const location = useLocation();

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={companyReportVulnerabilitiesRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Company vulnerabilities</h1>
                    </ToolbarTitle>
                </Toolbar>
                <PublishedCompanyReportVulnerabilitiesTable
                    companyId={companyId}
                    initialFilters={isLocationState(location.state) ? location.state.initialFilters : undefined}
                />
            </Container>
        </NavigationScreen>
    );
};
