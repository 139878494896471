import { FC, PropsWithChildren } from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";

export type SortDirection = "ASC" | "DESC" | undefined;
const sortDirections: SortDirection[] = [undefined, "ASC", "DESC"];

type Props = {
    direction: SortDirection;
    onChange: (sortDirection: SortDirection) => void;
};

const Icon: FC<{ direction: SortDirection }> = ({ direction }) => {
    switch (direction) {
        case "ASC":
            return <FaSortUp />;
        case "DESC":
            return <FaSortDown />;
        default:
            return null;
    }
};

export const SortableTableHeader: FC<PropsWithChildren<Props>> = ({ direction, onChange, children }) => {
    const nextDirection = () => {
        const nextIndex = (sortDirections.indexOf(direction) + 1) % sortDirections.length;
        onChange(sortDirections[nextIndex]);
    };

    return (
        <th onClick={() => nextDirection()} style={{ cursor: "pointer", userSelect: "none" }}>
            <Icon direction={direction} /> {children}
        </th>
    );
};
