import { useMemo } from "react";
import { Company, Report } from "app/api/graph/types";
import { useAuth } from "app/api/AuthContext";
import {
    createReportInviteRole,
    listReportInvitesRole,
    listReportReadReceiptsRole,
    updateReportRole,
} from "app/api/graph/roles";
import { useCompanyPermissions } from "app/api/graph/hook/use-company-permissions.ts";

type Result = {
    canListUserInvites: boolean;
    canCreateUserInvite: boolean;
    canDeleteUserInvite: boolean;
    canEdit: boolean;
    canViewReadReceipts: boolean;
    canListUsers: boolean;
    canAddUser: boolean;
    canAddUserFromCompany: boolean;
    canRemoveUser: boolean;
};

export const useReportPermissions = (
    report:
        | (Pick<Report, "id" | "_id" | "__typename"> & {
              company?: Pick<Company, "id" | "_id" | "__typename"> | undefined | null;
          })
        | undefined
        | null,
): Result => {
    const { roles } = useAuth();
    const companyPermissions = useCompanyPermissions(report?.company?.id);

    return useMemo(
        () => ({
            canListUserInvites:
                (roles?.includes(listReportInvitesRole) || companyPermissions.canListReportUserInvites) ?? false,
            canCreateUserInvite:
                (roles?.includes(createReportInviteRole) || companyPermissions.canCreateReportUserInvite) ?? false,
            canDeleteUserInvite:
                (roles?.includes(createReportInviteRole) || companyPermissions.canDeleteReportUserInvite) ?? false,
            canEdit: roles?.includes(updateReportRole) ?? false,
            canViewReadReceipts: roles?.includes(listReportReadReceiptsRole) ?? false,
            canListUsers: (roles?.includes(updateReportRole) || companyPermissions.canListReportUsers) ?? false,
            canAddUser: (roles?.includes(updateReportRole) || false) ?? false,
            canAddUserFromCompany: (roles?.includes(updateReportRole) || companyPermissions.canAddReportUser) ?? false,
            canRemoveUser: (roles?.includes(updateReportRole) || companyPermissions.canRemoveReportUser) ?? false,
        }),
        [report],
    );
};
