import { Formik, FormikHelpers } from "formik";
import { FC, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import yup from "app/util/yup";
import { useReportVersionVersionQuery, useUpdateReportVersionMutation } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";

type Props = {
    versionId: number;
    onUpdate?: () => void;
};

type FormValues = {
    versionName: string;
    versionNumber: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    versionName: yup.string().required(),
    versionNumber: yup.string().required(),
});

export const EditReportVersionVersionForm: FC<Props> = ({ versionId, onUpdate }) => {
    const { data, loading, error } = useReportVersionVersionQuery({
        variables: { versionId: reportVersionGraphId(versionId) },
    });
    const version = data?.reportVersion;
    const [updateVersion] = useUpdateReportVersionMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            versionName: version?.versionName ?? "",
            versionNumber: version?.versionNumber ?? "",
        }),
        [version],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!version) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        updateVersion({ variables: { input: { id: version.id, ...values } } })
            .then(() => onUpdate?.())
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, handleChange, handleBlur, values, isSubmitting, errors, touched, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>Version name</Form.Label>
                        <Form.Control
                            type="text"
                            name="versionName"
                            placeholder="Title"
                            value={values.versionName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.versionName && !!errors.versionName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.versionName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Version number</Form.Label>
                        <Form.Control
                            type="text"
                            name="versionNumber"
                            placeholder="Title"
                            value={values.versionNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.versionNumber && !!errors.versionNumber}
                        />
                        <Form.Control.Feedback type="invalid">{errors.versionNumber}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Update
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
