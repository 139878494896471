import { FC } from "react";
import Badge from "react-bootstrap/Badge";
import { ReportVulnerabilityStatus } from "app/api/graph/types";
import { reportVulnerabilityStatusColor, reportVulnerabilityStatusLabel } from "app/api/graph/strings";

type Props = {
    status: ReportVulnerabilityStatus;
    className?: string;
};

export const ReportVulnerabilityStatusBadge: FC<Props> = ({ status, ...props }) => (
    <Badge style={{ background: reportVulnerabilityStatusColor(status), color: "white" }} {...props}>
        {reportVulnerabilityStatusLabel(status)}
    </Badge>
);
