import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import { FaArrowDown, FaRedo, FaTrash, FaUserPlus } from "react-icons/fa";
import { mapNodes } from "app/util/graph";
import { formatISODateTime } from "app/util/format";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { CompanyInviteListFieldsFragment, useCompanyInvitesListQuery } from "app/api/graph/types";
import {
    createCompanyInviteRoute,
    deleteCompanyInviteRoute,
    companyInvitesRoute,
    resendCompanyInviteRoute,
} from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { companyGraphId } from "app/api/graph/helpers";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { companyRoleLabel } from "app/api/graph/strings";
import { ButtonLink } from "app/component/button/ButtonLink";
import { useCompanyPermissions } from "app/api/graph/hook/use-company-permissions.ts";

const whoLabel = (invite: CompanyInviteListFieldsFragment) => {
    if (invite.acceptedBy) {
        return invite.acceptedBy.fullName;
    }

    const name = [invite.firstName, invite.lastName].filter((n) => n?.length ?? 0 > 0).join(" ");
    if (name.length > 0) {
        return invite.email ? `${name} (${invite.email})` : name;
    } else if (invite.email) {
        return invite.email;
    }

    return "Unknown";
};

export const CompanyInvitesListScreen: FC = () => {
    const { companyId } = useRouteParams(companyInvitesRoute);
    const { canCreateUserInvite, canDeleteUserInvite, canUpdateUserInvite } = useCompanyPermissions(
        companyGraphId(companyId),
    );
    const { data, loading, error, fetchMore } = useCompanyInvitesListQuery({
        notifyOnNetworkStatusChange: true,
        variables: { companyId: companyGraphId(companyId) },
    });
    const invites = data?.company?.invites;

    if (!invites && loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!invites) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    const pageInfo = invites.pageInfo;
    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={companyInvitesRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Invites</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {canCreateUserInvite ? (
                            <ButtonLink to={createCompanyInviteRoute.build({ companyId })} variant="success">
                                <FaUserPlus /> Invite
                            </ButtonLink>
                        ) : null}
                    </ToolbarButtons>
                </Toolbar>
                <Table responsive="sm" bordered className="crud-table">
                    <thead>
                        <tr>
                            <th>Who</th>
                            <th>Role</th>
                            <th>Invited at</th>
                            <th>Invited by</th>
                            <th>Expires at</th>
                            <th>Status</th>
                            {canDeleteUserInvite || canUpdateUserInvite ? <th>Actions</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {mapNodes(invites, (invite) => (
                            <tr key={invite.id}>
                                <td>{whoLabel(invite)}</td>
                                <td>{companyRoleLabel(invite.role)}</td>
                                <td>{formatISODateTime(invite.createdAt)}</td>
                                <td>{invite.createdBy?.fullName ?? "-"}</td>
                                <td>{formatISODateTime(invite.expiresAt)}</td>
                                <td>
                                    {invite.accepted ? (
                                        <Badge bg="success">Accepted</Badge>
                                    ) : invite.expired ? (
                                        <Badge bg="danger">Expired</Badge>
                                    ) : (
                                        <Badge bg="info">Pending</Badge>
                                    )}
                                </td>
                                <td>
                                    {!invite.accepted && canUpdateUserInvite ? (
                                        <ButtonLink
                                            to={resendCompanyInviteRoute.build({
                                                companyId,
                                                inviteId: invite._id,
                                            })}
                                            variant="warning"
                                            size="sm"
                                            title="Resend"
                                        >
                                            <FaRedo />
                                        </ButtonLink>
                                    ) : null}
                                    {canDeleteUserInvite ? (
                                        <ButtonLink
                                            to={deleteCompanyInviteRoute.build({
                                                companyId,
                                                inviteId: invite._id,
                                            })}
                                            variant="danger"
                                            size="sm"
                                            title="Delete"
                                        >
                                            <FaTrash />
                                        </ButtonLink>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {invites.edges?.length === 0 ? (
                    <Alert variant="info">There are no invites for this company.</Alert>
                ) : null}
                {pageInfo?.hasNextPage ? (
                    <Button
                        onClick={() => fetchMore({ variables: { after: pageInfo.endCursor } })}
                        disabled={loading}
                        variant="light"
                    >
                        <FaArrowDown /> Load more
                    </Button>
                ) : null}
            </Container>
        </NavigationScreen>
    );
};
