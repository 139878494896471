import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { styled } from "styled-components";
import { Navigation } from "app/route/Navigation";
import { AuthActionAlerts } from "app/component/auth/AuthActionAlerts";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Content = styled.div`
    flex: 1;
`;

type Props = {
    hasPadding?: boolean;
};

export const NavigationScreen: FC<PropsWithChildren<Props>> = ({ hasPadding, children }) => {
    return (
        <Wrapper>
            <Navigation />
            <Content className={classNames({ "py-4": hasPadding ?? true })}>
                <AuthActionAlerts />
                {children}
            </Content>
        </Wrapper>
    );
};
