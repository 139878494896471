import { FC } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { mapNodes } from "app/util/graph";
import { formatISODate } from "app/util/format";
import { useCompanyReportsQuery } from "app/api/graph/types";
import { companyGraphId } from "app/api/graph/helpers";
import { reportRoute } from "app/route/Routes";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";

type Props = {
    companyId: number;
};

export const CompanyReportsTable: FC<Props> = ({ companyId }) => {
    const { data, loading, error, fetchMore } = useCompanyReportsQuery({
        variables: { companyId: companyGraphId(companyId), order: { createdAt: "DESC" } },
        notifyOnNetworkStatusChange: true,
    });
    const reports = data?.company?.reports;

    if (loading && !reports) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!reports) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const fetchMoreVersions = () => fetchMore({ variables: { after: reports.pageInfo.endCursor } });

    return (
        <Table responsive="sm" bordered className="crud-table">
            <thead>
                <tr>
                    <th>Report</th>
                    <th>Date</th>
                    <th>Author</th>
                </tr>
            </thead>
            <tbody>
                {mapNodes(reports, (report) => (
                    <tr key={report.id}>
                        <td>
                            <Link to={reportRoute.build({ reportId: report._id })}>{report.title}</Link>
                        </td>
                        <td>{formatISODate(report.createdAt)}</td>
                        <td>{report.createdBy?.fullName ?? "-"}</td>
                    </tr>
                ))}
                {reports.edges?.length === 0 ? (
                    <tr>
                        <td colSpan={100}>No reports.</td>
                    </tr>
                ) : null}
                {loading ? (
                    <tr>
                        <td colSpan={100}>
                            <LoadingSpinner />
                        </td>
                    </tr>
                ) : null}
                {reports.pageInfo.hasNextPage ? (
                    <tr>
                        <td colSpan={100}>
                            <BlockButtonGroup>
                                <Button variant="light" onClick={fetchMoreVersions}>
                                    Load more
                                </Button>
                            </BlockButtonGroup>
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </Table>
    );
};
