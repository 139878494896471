import { ComputedDatum, ResponsivePie } from "@nivo/pie";
import { CSSProperties, FC, useMemo } from "react";
import { usePrint } from "app/hook/use-print";
import { ReportVulnerabilityListFieldsFragment, VulnerabilitySeverity } from "app/api/graph/types";
import { vulnerabilitySeverityColor, vulnerabilitySeverityLabel } from "app/api/graph/strings";
import { useChartTheme } from "app/hook/use-chart-theme";

type Props = {
    vulnerabilities: ReportVulnerabilityListFieldsFragment[];
    style?: CSSProperties;
};

type Item = {
    id: string;
    label: string;
    value: number;
    color: string;
};

const margin = { top: 20, right: 20, bottom: 20, left: 20 };

export const ReportVulnerabilitySeverityChart: FC<Props> = ({ vulnerabilities, style }) => {
    const theme = useChartTheme();
    const data: Item[] = useMemo(
        () =>
            Object.values(VulnerabilitySeverity).map(
                (severity: VulnerabilitySeverity): Item => ({
                    id: severity,
                    label: vulnerabilitySeverityLabel(severity),
                    value: vulnerabilities.filter((vulnerability) => vulnerability.severity === severity).length,
                    color: vulnerabilitySeverityColor(severity),
                }),
            ),
        [vulnerabilities],
    );
    const { isPrint } = usePrint();

    return (
        <div style={{ height: "300px", ...style }}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <ResponsivePie
                data={data}
                margin={margin}
                colors={{ datum: "data.color" }}
                arcLinkLabel={(d: ComputedDatum<Item>) => `${d.label}: ${d.value}`}
                arcLinkLabelsColor={{ from: "color" }}
                arcLinkLabelsSkipAngle={1}
                enableArcLabels={false}
                animate={!isPrint}
                theme={theme}
            />
        </div>
    );
};
