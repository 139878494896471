import { FC, useMemo } from "react";
import Alert from "react-bootstrap/Alert";
import {
    UpdateVulnerabilityMutation,
    useVulnerabilityQuery,
    useUpdateVulnerabilityMutation,
    VulnerabilitySeverity,
} from "app/api/graph/types";
import { FormValues, VulnerabilityForm } from "app/component/vulnerability/VulnerabilityForm";
import { vulnerabilityGraphId } from "app/api/graph/helpers";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { mapNodes } from "app/util/graph.ts";

type Props = {
    vulnerabilityId: number;
    onUpdate: (
        vulnerability: NonNullable<NonNullable<UpdateVulnerabilityMutation["updateVulnerability"]>["vulnerability"]>,
    ) => void;
};

export const EditVulnerabilityForm: FC<Props> = ({ vulnerabilityId, onUpdate }) => {
    const { data, loading, error } = useVulnerabilityQuery({
        variables: { vulnerabilityId: vulnerabilityGraphId(vulnerabilityId) },
    });
    const vulnerability = data?.vulnerability;
    const [updateVulnerability, { error: updateError }] = useUpdateVulnerabilityMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            identifier: vulnerability?.identifier ?? "",
            title: vulnerability?.title ?? "",
            severity: vulnerability?.severity ?? VulnerabilitySeverity.Medium,
            testingTypes: mapNodes(vulnerability?.testingTypes, (item) => item.id) ?? [],
            cvssScore: vulnerability?.cvssScore ?? "",
            cvssVector: vulnerability?.cvssVector ?? "",
            cveScore: vulnerability?.cveScore ?? "",
            cwe: vulnerability?.cwe ?? "",
            owaspCategory: vulnerability?.owaspCategory ?? "",
            issueDetail: vulnerability?.issueDetail ?? "",
            advice: vulnerability?.advice ?? "",
            replacePrevious: false,
        }),
        [vulnerability],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!vulnerability) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = ({ identifier, ...values }: FormValues) =>
        updateVulnerability({ variables: { input: { id: vulnerability.id, ...values } } })
            .then((response) => response.data?.updateVulnerability?.vulnerability)
            .then((vulnerability) => (vulnerability ? onUpdate?.(vulnerability) : undefined));

    return (
        <VulnerabilityForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            error={updateError}
            readOnlyIdentifier={true}
        />
    );
};
