import { FC } from "react";
import { FaFile, FaFileCode, FaFileCsv, FaFileDownload, FaFileExcel } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import { useDownloadFile } from "app/api/hook/use-download-file";

export const DownloadUserSecurityEventUserSummaryButton: FC = () => {
    const links = [
        {
            file: useDownloadFile(`/api/user_security_events/user_summary.xlsx`),
            title: "Download XLSX",
            icon: <FaFileExcel />,
        },
        {
            file: useDownloadFile(`/api/user_security_events/user_summary.xls`),
            title: "Download XLS",
            icon: <FaFileExcel />,
        },
        {
            file: useDownloadFile(`/api/user_security_events/user_summary.ods`),
            title: "Download ODS",
            icon: <FaFile />,
        },
        {
            file: useDownloadFile(`/api/user_security_events/user_summary.csv`),
            title: "Download CSV",
            icon: <FaFileCsv />,
        },
        {
            file: useDownloadFile(`/api/user_security_events/user_summary.html`),
            title: "Download HTML",
            icon: <FaFileCode />,
        },
    ];

    return (
        <Dropdown>
            <Dropdown.Toggle variant="info" id="dropdown-basic">
                <FaFileDownload /> User security events user summary
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {links.map((link) => (
                    <Dropdown.Item key={link.title} onClick={link.file.download} disabled={link.file.loading}>
                        {link.icon} {link.title}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
