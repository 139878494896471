import { FC } from "react";
import Container from "react-bootstrap/Container";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { userRoute } from "app/route/Routes";
import { useUserQuery } from "app/api/graph/types";
import { useRouteParams } from "app/route/route";
import { userGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";

export const UserScreen: FC = () => {
    const { userId } = useRouteParams(userRoute);
    const { data, loading, error } = useUserQuery({ variables: { userId: userGraphId(userId) } });
    const user = data?.user;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!user) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={userRoute} />
                <h1>{user.fullName}</h1>
            </Container>
        </NavigationScreen>
    );
};
