import { Formik, FormikHelpers } from "formik";
import { FC, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ApolloError } from "@apollo/client";
import yup from "app/util/yup";
import { RichTextEditor } from "app/component/form/text-editor/RichTextEditor";
import { FormControlDate } from "app/component/form/control/FormControlDate";
import { MediaObjects } from "app/component/form/media-objects/MediaObjects";
import { ReportMediaObjectFieldsFragment } from "app/api/graph/types";
import { StatusAlert } from "app/component/form/StatusAlert";
import { handleError, handleSuccess } from "app/api/graph/form";

type Props = {
    initialValues: FormValues;
    onSubmit: (values: FormValues, files: ReportMediaObjectFieldsFragment[]) => Promise<void>;
    error?: ApolloError;
    files?: ReportMediaObjectFieldsFragment[];
    versionId: number;
};

export type FormValues = {
    dateActioned: Date | null;
    description: string;
    clientReference?: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    dateActioned: yup.date().required(),
    description: yup.string().required(),
    clientReference: yup.string(),
});

export const ReportVulnerabilityActionForm: FC<Props> = (props) => {
    const [files, setFiles] = useState<ReportMediaObjectFieldsFragment[]>(props.files ?? []);
    const [uploading, setUploading] = useState(false);
    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        props
            .onSubmit(values, files)
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={props.initialValues}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                values,
                isSubmitting,
                errors,
                touched,
                status,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>Date actioned</Form.Label>
                        <FormControlDate
                            name="dateActioned"
                            value={values.dateActioned}
                            onChange={(value) => setFieldValue("dateActioned", value)}
                            onBlur={handleBlur}
                            isInvalid={touched.dateActioned && !!errors.dateActioned}
                        />
                        <Form.Control.Feedback type="invalid">{errors.dateActioned}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Client reference</Form.Label>
                        <Form.Control
                            type="text"
                            name="clientReference"
                            placeholder="Client reference"
                            value={values.clientReference}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.clientReference && !!errors.clientReference}
                        />
                        <Form.Control.Feedback type="invalid">{errors.clientReference}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <RichTextEditor
                            value={values.description}
                            onChange={(value) => setFieldValue("description", value)}
                            onBlur={() => setFieldTouched("description")}
                            isInvalid={touched.description && !!errors.description}
                        />
                        <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                    </Form.Group>
                    <MediaObjects
                        initialValues={files}
                        onChange={(values) => setFiles(values)}
                        versionId={props.versionId}
                        onProcessingChange={setUploading}
                    />
                    <Button type="submit" disabled={isSubmitting || uploading}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
