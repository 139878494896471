import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { styled } from "styled-components";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import {
    createReportVersionVulnerabilityActionRoute,
    deleteReportVersionVulnerabilityActionRoute,
    editReportVersionVulnerabilityActionRoute,
    reportVersionVulnerabilityActionRoute,
    reportVersionVulnerabilityActionsRoute,
} from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import { mapNodes } from "app/util/graph";
import { formatISODate, formatISODateTime } from "app/util/format";
import { useReportVulnerabilityActionsListQuery } from "app/api/graph/types";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { useAuth } from "app/api/AuthContext";
import {
    createVulnerabilityActionRole,
    deleteVulnerabilityActionRole,
    updateVulnerabilityActionRole,
} from "app/api/graph/roles";
import { HtmlContent } from "app/component/util/HtmlContent";
import { SortableTableHeader, SortDirection } from "app/component/table/SortableTableHeader";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

const Snippet = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const ReportVersionVulnerabilityActionsScreen: FC = () => {
    const { roles } = useAuth();
    const [dateActionedOrder, setDateActionedOrder] = useState<SortDirection>("DESC");
    const [clientReferenceOrder, setClientReferenceOrder] = useState<SortDirection>(undefined);
    const [createdAtOrder, setCreatedAtOrder] = useState<SortDirection>(undefined);
    const { reportId, versionId, vulnerabilityId } = useRouteParams(reportVersionVulnerabilityActionsRoute);
    const { data, loading, error, fetchMore } = useReportVulnerabilityActionsListQuery({
        variables: {
            vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId),
            order: {
                dateActioned: dateActionedOrder,
                clientReference: clientReferenceOrder,
                createdAt: createdAtOrder,
            },
        },
        notifyOnNetworkStatusChange: true,
    });
    const vulnerability = data?.reportVulnerability;
    const actions = vulnerability?.actions;

    if (loading && !vulnerability) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!vulnerability || !actions) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    const fetchMoreActions = () => fetchMore({ variables: { after: actions.pageInfo.endCursor } });

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={reportVersionVulnerabilityActionsRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Actions</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {roles?.includes(createVulnerabilityActionRole) ? (
                            <ButtonLink
                                to={createReportVersionVulnerabilityActionRoute.build({
                                    reportId,
                                    versionId,
                                    vulnerabilityId,
                                })}
                                variant="success"
                            >
                                <FaPlus /> Create
                            </ButtonLink>
                        ) : null}
                    </ToolbarButtons>
                </Toolbar>
                <Table responsive="sm" bordered className="crud-table">
                    <thead>
                        <tr>
                            <SortableTableHeader direction={dateActionedOrder} onChange={setDateActionedOrder}>
                                Actioned on
                            </SortableTableHeader>
                            <SortableTableHeader direction={clientReferenceOrder} onChange={setClientReferenceOrder}>
                                Client reference
                            </SortableTableHeader>
                            <SortableTableHeader direction={createdAtOrder} onChange={setCreatedAtOrder}>
                                Created at
                            </SortableTableHeader>
                            <th>Created by</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mapNodes(actions, (action) => (
                            <tr key={action.id}>
                                <td>
                                    <Link
                                        to={reportVersionVulnerabilityActionRoute.build({
                                            reportId,
                                            versionId,
                                            vulnerabilityId: vulnerability._id,
                                            actionId: action._id,
                                        })}
                                    >
                                        {formatISODate(action.dateActioned)}
                                    </Link>
                                </td>
                                <td>{action.clientReference}</td>
                                <td>{formatISODateTime(action.createdAt)}</td>
                                <td>{action.createdBy?.fullName ?? "-"}</td>
                                <td>
                                    <Snippet>
                                        <HtmlContent html={action.description} />
                                    </Snippet>
                                </td>
                                <td>
                                    <ButtonGroup>
                                        {roles?.includes(updateVulnerabilityActionRole) ? (
                                            <ButtonLink
                                                to={editReportVersionVulnerabilityActionRoute.build({
                                                    reportId,
                                                    versionId,
                                                    vulnerabilityId: vulnerability._id,
                                                    actionId: action._id,
                                                })}
                                                variant="warning"
                                                size="sm"
                                                title="Edit"
                                            >
                                                <FaEdit />
                                            </ButtonLink>
                                        ) : null}
                                        {roles?.includes(deleteVulnerabilityActionRole) ? (
                                            <ButtonLink
                                                to={deleteReportVersionVulnerabilityActionRoute.build({
                                                    reportId,
                                                    versionId,
                                                    vulnerabilityId: vulnerability._id,
                                                    actionId: action._id,
                                                })}
                                                variant="danger"
                                                size="sm"
                                                title="Delete"
                                            >
                                                <FaTrash />
                                            </ButtonLink>
                                        ) : null}
                                    </ButtonGroup>
                                </td>
                            </tr>
                        ))}
                        {actions.edges?.length === 0 ? (
                            <tr>
                                <td colSpan={100}>No actions.</td>
                            </tr>
                        ) : null}
                        {loading ? (
                            <tr>
                                <td colSpan={100}>
                                    <LoadingSpinner />
                                </td>
                            </tr>
                        ) : null}
                        {actions.pageInfo.hasNextPage ? (
                            <tr>
                                <td colSpan={100}>
                                    <BlockButtonGroup>
                                        <Button variant="light" onClick={fetchMoreActions}>
                                            Load more
                                        </Button>
                                    </BlockButtonGroup>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </Table>
            </Container>
        </NavigationScreen>
    );
};
