import { FC, useMemo } from "react";
import Alert from "react-bootstrap/Alert";
import { UpdateTestingTypeMutation, useTestingTypeQuery, useUpdateTestingTypeMutation } from "app/api/graph/types";
import { FormValues, TestingTypeForm } from "app/component/testing-type/TestingTypeForm";
import { testingTypeGraphId } from "app/api/graph/helpers";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";

type Props = {
    testingTypeId: number;
    onUpdate: (
        testingType: NonNullable<NonNullable<UpdateTestingTypeMutation["updateTestingType"]>["testingType"]>,
    ) => void;
};

export const EditTestingTypeForm: FC<Props> = ({ testingTypeId, onUpdate }) => {
    const { data, loading, error } = useTestingTypeQuery({
        variables: { testingTypeId: testingTypeGraphId(testingTypeId) },
    });
    const testingType = data?.testingType;
    const [updateTestingType, { error: updateError }] = useUpdateTestingTypeMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            name: testingType?.name ?? "",
        }),
        [testingType],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!testingType) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = (values: FormValues) =>
        updateTestingType({ variables: { input: { id: testingType.id, ...values } } })
            .then((response) => response.data?.updateTestingType?.testingType)
            .then((testingType) => (testingType ? onUpdate?.(testingType) : undefined));

    return <TestingTypeForm initialValues={initialValues} onSubmit={onSubmit} error={updateError} />;
};
