import { FC, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { editReportVersionRoute, editReportVersionContactRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { EditReportContactForm, FormValues } from "app/component/report-contact/EditReportContactForm";
import { CompanyContactFieldsFragment, useReportVersionContactQuery } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { SelectCompanyContact } from "app/component/form/select/SelectCompanyContact.tsx";

export const EditReportVersionContactScreen: FC = () => {
    const { reportId, versionId } = useRouteParams(editReportVersionContactRoute);
    const [updated, setUpdated] = useState(false);
    const { data, loading, error } = useReportVersionContactQuery({
        variables: { versionId: reportVersionGraphId(versionId) },
    });
    const contact = data?.reportVersion?.contact;

    const companyId = data?.reportVersion?.report?.company?._id;
    const [companyContact, setCompanyContact] = useState<CompanyContactFieldsFragment | undefined>();

    const initialValues: FormValues | undefined = useMemo(
        () =>
            companyContact !== undefined
                ? {
                      name: companyContact.name ?? undefined,
                      address: companyContact.address ?? undefined,
                      company: companyContact.companyName ?? undefined,
                      email: companyContact.email ?? undefined,
                      mobile: companyContact.mobile ?? undefined,
                      phone: companyContact.phone ?? undefined,
                      position: companyContact.position ?? undefined,
                  }
                : undefined,
        [companyContact],
    );

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!contact) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    return updated ? (
        <Navigate to={editReportVersionRoute.build({ reportId, versionId })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editReportVersionContactRoute} />

                {companyId !== undefined && (
                    <Form.Group className="mb-3">
                        <Form.Label>Company contact</Form.Label>
                        <SelectCompanyContact
                            value={companyContact?.id ?? null}
                            onChange={(_id, value) => setCompanyContact(value)}
                            id="select-company-contact"
                            companyId={companyId}
                        />
                        <Form.Text className="text-muted">
                            Select a company contact to automatically populate this form.
                        </Form.Text>
                        <hr />
                    </Form.Group>
                )}

                <EditReportContactForm
                    contactId={contact._id}
                    initialValues={initialValues}
                    onUpdate={() => setUpdated(true)}
                />
            </Container>
        </NavigationScreen>
    );
};
