import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import {
    Report,
    ReportVersion,
    ReportVulnerabilityFieldsFragment,
    useReportVulnerabilitiesQuery,
} from "app/api/graph/types";
import { reportGraphId } from "app/api/graph/helpers";
import { mapNodes } from "app/util/graph";
import { useFetchAll } from "app/api/graph/hook/use-fetch-all";

type Result = {
    report?: Pick<Report, "id" | "_id">;
    version?: Pick<ReportVersion, "id" | "_id">;
    vulnerabilities?: ReportVulnerabilityFieldsFragment[];
    loading: boolean;
    error?: ApolloError;
};

export const useReportVulnerabilities = (reportId: number): Result => {
    const { data, loading, error, fetchMore } = useReportVulnerabilitiesQuery({
        variables: { reportId: reportGraphId(reportId) },
        notifyOnNetworkStatusChange: true,
    });
    const report = data?.report ?? undefined;
    const version = data?.report?.latestVersion ?? undefined;
    const vulnerabilities = data?.report?.latestVersion?.vulnerabilities;
    const pageInfo = data?.report?.latestVersion?.vulnerabilities?.pageInfo;
    useFetchAll({ loading, pageInfo, fetchMore });

    return useMemo(
        () => ({ report, version, vulnerabilities: mapNodes(vulnerabilities, (n) => n), loading, error }),
        [report, version, vulnerabilities, loading, error],
    );
};
