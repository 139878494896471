import { FC } from "react";
import { formatISO } from "date-fns";
import { ReportMediaObjectFieldsFragment, useCreateReportVulnerabilityActionMutation } from "app/api/graph/types";
import {
    FormValues,
    ReportVulnerabilityActionForm,
} from "app/component/report-vulnerability-action/ReportVulnerabilityActionForm";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";

type Props = {
    vulnerabilityId: number;
    versionId: number;
    onCreate?: () => void;
};

const initialValues: FormValues = {
    dateActioned: new Date(),
    description: "",
    clientReference: "",
};

export const CreateReportVulnerabilityActionForm: FC<Props> = ({ vulnerabilityId, versionId, onCreate }) => {
    const [createVulnerabilityAction, { error }] = useCreateReportVulnerabilityActionMutation({});

    const onSubmit = (values: FormValues, files: ReportMediaObjectFieldsFragment[]) =>
        createVulnerabilityAction({
            variables: {
                input: {
                    vulnerability: reportVulnerabilityGraphId(vulnerabilityId),
                    ...values,
                    dateActioned: values.dateActioned ? formatISO(values.dateActioned) : "",
                    files: files.map((file) => file.id),
                },
            },
        }).then(() => onCreate?.());

    return (
        <ReportVulnerabilityActionForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            error={error}
            versionId={versionId}
        />
    );
};
