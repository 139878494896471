import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import { ReportVersionListFieldsFragment, useReportVersionsListQuery } from "app/api/graph/types";
import { reportGraphId } from "app/api/graph/helpers";
import { mapNodes } from "app/util/graph";
import { useFetchAll } from "app/api/graph/hook/use-fetch-all";

type Result = {
    versions?: ReportVersionListFieldsFragment[];
    loading: boolean;
    error?: ApolloError;
};

export const useReportVersionsList = (reportId: number): Result => {
    const { data, loading, error, fetchMore } = useReportVersionsListQuery({
        variables: { reportId: reportGraphId(reportId) },
        notifyOnNetworkStatusChange: true,
    });
    const versions = data?.report?.versions;
    const pageInfo = versions?.pageInfo;
    useFetchAll({ loading, pageInfo, fetchMore });

    return useMemo(
        () => ({
            versions: mapNodes(versions, (n) => n),
            loading,
            error,
        }),
        [versions, loading, error],
    );
};
