import { FC } from "react";
import Button from "react-bootstrap/Button";
import { FaTrash } from "react-icons/fa";
import { styled } from "styled-components";
import {
    ReportVulnerabilityCommentCursorConnection,
    ReportVulnerabilityCommentFieldsFragment,
    useDeleteReportVulnerabilityCommentMutation,
} from "app/api/graph/types";
import { HtmlContent } from "app/component/util/HtmlContent";
import { formatISODateTimeRelative } from "app/util/format";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import { notEmpty } from "app/util/array";
import { useAuth } from "app/api/AuthContext";
import { deleteVulnerabilityCommentRole } from "app/api/graph/roles";

type Props = {
    comment: ReportVulnerabilityCommentFieldsFragment;
    vulnerabilityId: number;
};

const Author = styled.div`
    font-weight: 500;
`;

export const ReportVulnerabilityComment: FC<Props> = ({ comment, vulnerabilityId }) => {
    const { roles } = useAuth();
    const [deleteComment, { loading: deleting }] = useDeleteReportVulnerabilityCommentMutation({
        variables: { commentId: comment.id },
        update: (cache, { data }) => {
            if (!data?.deleteReportVulnerabilityComment) {
                return;
            }

            cache.modify({
                id: cache.identify({
                    __typename: "ReportVulnerability",
                    id: reportVulnerabilityGraphId(vulnerabilityId),
                }),
                fields: {
                    comments: (cached: ReportVulnerabilityCommentCursorConnection, { readField }) => ({
                        ...cached,
                        edges: (cached.edges ?? [])
                            .filter(notEmpty)
                            .filter((edge) => readField("id", readField("node", edge)) !== comment.id),
                    }),
                },
            });
        },
    });

    const confirmDeleteComment = () => {
        if (confirm("Are you sure that you want to delete this comment?")) {
            return deleteComment();
        }
    };

    return (
        <div>
            <div className="d-flex mb-1">
                <Author>{comment.createdBy?.fullName ?? "-"}</Author>
                <div className="text-muted ms-auto">{formatISODateTimeRelative(comment.createdAt)}</div>
                {roles?.includes(deleteVulnerabilityCommentRole) ? (
                    <Button
                        size="sm"
                        variant="danger"
                        onClick={() => confirmDeleteComment()}
                        disabled={deleting}
                        className="ms-2"
                    >
                        <FaTrash />
                    </Button>
                ) : null}
            </div>
            <HtmlContent html={comment.message} />
        </div>
    );
};
