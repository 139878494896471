import { useMemo } from "react";
import { ReportVersion, ReportVersionStatus } from "app/api/graph/types";
import { useAuth } from "app/api/AuthContext";
import { updateReportRole } from "app/api/graph/roles";

type Result = {
    canEdit: boolean;
};

export const useReportVersionPermissions = (
    version: Pick<ReportVersion, "id" | "_id" | "__typename" | "status"> | undefined | null,
): Result => {
    const { roles } = useAuth();

    return useMemo(
        () => ({
            canEdit: (roles?.includes(updateReportRole) && version?.status === ReportVersionStatus.Draft) ?? false,
        }),
        [version],
    );
};
