import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { FaTrash } from "react-icons/fa";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { companyUsersRoute, removeCompanyUserRoute } from "app/route/Routes";
import { useRemoveUserFromCompanyMutation } from "app/api/graph/types";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { companyGraphId, userGraphId } from "app/api/graph/helpers";

export const RemoveCompanyUserScreen: FC = () => {
    const { companyId, userId } = useRouteParams(removeCompanyUserRoute);
    const [removeUser, { data, loading, error }] = useRemoveUserFromCompanyMutation({
        variables: {
            companyId: companyGraphId(companyId),
            userId: userGraphId(userId),
        },
    });

    if (data?.removeUserFromCompany) {
        return <Navigate to={companyUsersRoute.build({ companyId })} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={removeCompanyUserRoute} />
                <h1>Remove user from company?</h1>
                {error ? <Alert variant="danger">{error.message}</Alert> : null}
                <p>Are you sure that you want to remove this user from this company?</p>
                <Button variant="danger" disabled={loading} onClick={() => removeUser()}>
                    <FaTrash /> Delete
                </Button>
            </Container>
        </NavigationScreen>
    );
};
