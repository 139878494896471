import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { updateUserMobileNumberRoute, userSettingsRoute } from "app/route/Routes";
import { UserMobileNumberChangeRequest } from "app/api/graph/types";
import { CreateUserMobileNumberChangeRequestForm } from "app/component/user/CreateUserMobileNumberChangeRequestForm";
import { ApplyUserMobileNumberChangeRequestForm } from "app/component/user/ApplyUserMobileNumberChangeRequestForm";
import { useRouteParams } from "app/route/route";
import { addMobileNumberAction, useAuth } from "app/api/AuthContext";

export const UpdateUserMobileNumberScreen: FC = () => {
    const { clearAction } = useAuth();
    const { userId } = useRouteParams(updateUserMobileNumberRoute);
    const [request, setRequest] = useState<Pick<UserMobileNumberChangeRequest, "id" | "_id"> | undefined>();
    const [applied, setApplied] = useState(false);

    if (applied) {
        return <Navigate to={userSettingsRoute.build({ userId })} />;
    }

    const onApply = () => {
        clearAction(addMobileNumberAction);
        setApplied(true);
    };

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={updateUserMobileNumberRoute} />
                <h1>Update mobile number</h1>
                {!request ? (
                    <CreateUserMobileNumberChangeRequestForm onCreate={setRequest} />
                ) : (
                    <ApplyUserMobileNumberChangeRequestForm request={request} onApply={onApply} />
                )}
            </Container>
        </NavigationScreen>
    );
};
