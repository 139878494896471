import { FC, useMemo } from "react";
import Alert from "react-bootstrap/Alert";
import { UpdateCompanyMutation, useCompanyQuery, useUpdateCompanyMutation } from "app/api/graph/types";
import { FormValues, CompanyForm } from "app/component/company/CompanyForm";
import { companyGraphId } from "app/api/graph/helpers";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";

type Props = {
    companyId: number;
    onUpdate: (company: NonNullable<NonNullable<UpdateCompanyMutation["updateCompany"]>["company"]>) => void;
};

export const EditCompanyForm: FC<Props> = ({ companyId, onUpdate }) => {
    const { data, loading, error } = useCompanyQuery({ variables: { companyId: companyGraphId(companyId) } });
    const company = data?.company;
    const [updateCompany, { error: updateError }] = useUpdateCompanyMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            name: company?.name ?? "",
        }),
        [company],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!company) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = (values: FormValues) =>
        updateCompany({ variables: { input: { id: company.id, ...values } } })
            .then((response) => response.data?.updateCompany?.company)
            .then((company) => (company ? onUpdate?.(company) : undefined));

    return <CompanyForm initialValues={initialValues} onSubmit={onSubmit} error={updateError} />;
};
