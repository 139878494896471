import { FC } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { deleteReportVersionVulnerabilityRoute, reportVersionVulnerabilitiesRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { useDeleteReportVulnerabilityMutation, useReportVulnerabilityQuery } from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { ConfirmDelete } from "app/component/form/ConfirmDelete";

export const DeleteReportVersionVulnerabilityScreen: FC = () => {
    const { reportId, versionId, vulnerabilityId } = useRouteParams(deleteReportVersionVulnerabilityRoute);
    const { data, loading, error } = useReportVulnerabilityQuery({
        variables: { vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId) },
    });
    const [deleteVulnerability, { data: deleteData, loading: deleting, error: deleteError }] =
        useDeleteReportVulnerabilityMutation({
            variables: { vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId) },
        });
    const vulnerability = data?.reportVulnerability;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!vulnerability) {
        return <ErrorScreen title="Error" message="Not found." />;
    }
    if (deleteData?.deleteReportVulnerability) {
        return <Navigate to={reportVersionVulnerabilitiesRoute.build({ reportId, versionId })} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={deleteReportVersionVulnerabilityRoute} />
                <h1>
                    <strong>{vulnerability.issueNumber}</strong> {vulnerability.title}
                </h1>
                {deleteError ? <Alert variant="danger">{deleteError.message}</Alert> : null}
                <p>Are you sure that you want to permanently delete this vulnerability from this report version?</p>
                <ConfirmDelete onConfirm={() => deleteVulnerability()} deleting={deleting} />
            </Container>
        </NavigationScreen>
    );
};
