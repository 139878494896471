import { FC } from "react";
import Table from "react-bootstrap/Table";
import { ReportVersionListFieldsFragment } from "app/api/graph/types";
import { formatISODate } from "app/util/format";

type Props = {
    currentVersion: ReportVersionListFieldsFragment;
    versions: ReportVersionListFieldsFragment[];
};

export const VersionControl: FC<Props> = ({ currentVersion, versions }) => (
    <Table responsive="sm">
        <thead>
            <tr>
                <th>Date</th>
                <th>Author</th>
                <th>Stage</th>
                <th>Version</th>
            </tr>
        </thead>
        <tbody>
            {versions.map((version) => (
                <tr key={version.id}>
                    <td>{formatISODate(version.createdAt)}</td>
                    <td>{version.createdBy?.fullName}</td>
                    <td>{version.versionName}</td>
                    <td>
                        {version.versionNumber} {version.id === currentVersion.id ? `(currently viewing)` : null}
                    </td>
                </tr>
            ))}
        </tbody>
    </Table>
);
