import { FC } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { deleteCompanyContactRoute, companyContactsRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useDeleteCompanyContactMutation, useCompanyContactQuery } from "app/api/graph/types";
import { companyContactGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { ConfirmDelete } from "app/component/form/ConfirmDelete";
import { formatISODateTime } from "app/util/format";

export const DeleteCompanyContactScreen: FC = () => {
    const { companyId, contactId } = useRouteParams(deleteCompanyContactRoute);
    const { data, loading, error } = useCompanyContactQuery({
        variables: { companyContactId: companyContactGraphId(contactId) },
    });
    const [deleteCompanyContact, { data: deleteData, loading: deleting, error: deleteError }] =
        useDeleteCompanyContactMutation({
            variables: { input: { id: companyContactGraphId(contactId) } },
        });
    const companyContact = data?.companyContact;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!companyContact) {
        return <ErrorScreen title="Error" message="Not found." />;
    }
    if (deleteData?.deleteCompanyContact) {
        return <Navigate to={companyContactsRoute.build({ companyId })} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={deleteCompanyContactRoute} />
                <h1>Delete company contact</h1>
                {deleteError ? <Alert variant="danger">{deleteError.message}</Alert> : null}
                <p>Are you sure that you want to permanently delete this company contact?</p>
                <Table responsive="sm">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>{companyContact.name ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Created by</th>
                            <td>{companyContact.createdBy?.fullName ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Created at</th>
                            <td>{formatISODateTime(companyContact.createdAt)}</td>
                        </tr>
                    </tbody>
                </Table>
                <Alert variant="danger">
                    <p>
                        This will <strong>permanently</strong> delete the company contact - which cannot be reversed.
                    </p>
                </Alert>
                <ConfirmDelete onConfirm={() => deleteCompanyContact()} deleting={deleting} />
            </Container>
        </NavigationScreen>
    );
};
