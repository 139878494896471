import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { CompanyContact } from "app/api/graph/types";
import { editCompanyContactRoute, companyContactsRoute } from "app/route/Routes";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { EditCompanyContactForm } from "app/component/company-contact/EditCompanyContactForm";
import { useRouteParams } from "app/route/route";

export const EditCompanyContactScreen: FC = () => {
    const { companyId, contactId } = useRouteParams(editCompanyContactRoute);
    const [contact, setCompanyContact] = useState<Pick<CompanyContact, "_id"> | undefined>(undefined);

    return contact ? (
        <Navigate to={companyContactsRoute.build({ companyId })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editCompanyContactRoute} />
                <EditCompanyContactForm companyContactId={contactId} onUpdate={setCompanyContact} />
            </Container>
        </NavigationScreen>
    );
};
