import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { companyRoute, uploadCompanyLogoRoute } from "app/route/Routes";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useRouteParams } from "app/route/route";
import { UploadCompanyLogoForm } from "app/component/company/UploadCompanyLogoForm";

export const UploadCompanyLogoScreen: FC = () => {
    const { companyId } = useRouteParams(uploadCompanyLogoRoute);
    const [updated, setUpdated] = useState(false);

    return updated ? (
        <Navigate to={companyRoute.build({ companyId: companyId })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={uploadCompanyLogoRoute} />
                <UploadCompanyLogoForm companyId={companyId} onUpload={() => setUpdated(true)} />
            </Container>
        </NavigationScreen>
    );
};
