import { FC } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { FaUserMinus } from "react-icons/fa";
import { mapNodes } from "app/util/graph";
import { useCompanyUsersQuery } from "app/api/graph/types";
import { companyGraphId } from "app/api/graph/helpers";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { removeCompanyUserRoute } from "app/route/Routes";
import { useAuth } from "app/api/AuthContext";
import { updateCompanyRole } from "app/api/graph/roles";
import { companyRoleLabel } from "app/api/graph/strings";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";
import { useCompanyPermissions } from "app/api/graph/hook/use-company-permissions.ts";

type Props = {
    companyId: number;
};

export const CompanyUsersTable: FC<Props> = ({ companyId }) => {
    const { roles } = useAuth();
    const { data, loading, error, fetchMore } = useCompanyUsersQuery({
        variables: { companyId: companyGraphId(companyId) },
        notifyOnNetworkStatusChange: true,
    });
    const companyUsers = data?.company?.users;
    const { canRemoveUser } = useCompanyPermissions(companyGraphId(companyId));

    if (loading && !companyUsers) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!companyUsers) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const fetchMoreUsers = () => fetchMore({ variables: { after: companyUsers.pageInfo.endCursor } });

    return (
        <Table responsive="sm" bordered className="crud-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Role</th>
                    {roles?.includes(updateCompanyRole) ? <th>Actions</th> : null}
                </tr>
            </thead>
            <tbody>
                {mapNodes(companyUsers, (companyUser) => (
                    <tr key={companyUser.id}>
                        <td>{companyUser.user.fullName}</td>
                        <td>{companyRoleLabel(companyUser.role)}</td>
                        {canRemoveUser ? (
                            <td>
                                {canRemoveUser && (
                                    <ButtonLink
                                        variant="danger"
                                        to={removeCompanyUserRoute.build({ companyId, userId: companyUser.user._id })}
                                        size="sm"
                                        title="Remove"
                                    >
                                        <FaUserMinus />
                                    </ButtonLink>
                                )}
                            </td>
                        ) : null}
                    </tr>
                ))}
                {companyUsers.edges?.length === 0 ? (
                    <tr>
                        <td colSpan={100}>No users.</td>
                    </tr>
                ) : null}
                {loading ? (
                    <tr>
                        <td colSpan={100}>
                            <LoadingSpinner />
                        </td>
                    </tr>
                ) : null}
                {companyUsers.pageInfo.hasNextPage ? (
                    <tr>
                        <td colSpan={100}>
                            <BlockButtonGroup>
                                <Button variant="light" onClick={fetchMoreUsers}>
                                    Load more
                                </Button>
                            </BlockButtonGroup>
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </Table>
    );
};
