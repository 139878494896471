import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { createReportVersionVulnerabilityFileRoute, reportVersionVulnerabilityRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { CreateReportVulnerabilityFileForm } from "app/component/report-vulnerability-file/CreateReportVulnerabilityFileForm.tsx";

export const CreateReportVersionVulnerabilityFileScreen: FC = () => {
    const { reportId, versionId, vulnerabilityId } = useRouteParams(createReportVersionVulnerabilityFileRoute);
    const [created, setCreated] = useState(false);

    return created ? (
        <Navigate to={reportVersionVulnerabilityRoute.build({ reportId, versionId, vulnerabilityId })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={createReportVersionVulnerabilityFileRoute} />
                <CreateReportVulnerabilityFileForm
                    reportId={reportId}
                    versionId={versionId}
                    vulnerabilityId={vulnerabilityId}
                    onCreate={() => setCreated(true)}
                />
            </Container>
        </NavigationScreen>
    );
};
