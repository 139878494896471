import { FC } from "react";
import { Accordion } from "react-bootstrap";
import { ReportVulnerabilityFieldsFragment } from "app/api/graph/types";
import { ReportVulnerabilityTestingTypeChart } from "app/component/report-vulnerability/component/ReportVulnerabilityTestingTypeChart.tsx";

type Props = {
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
    title?: string;
    onClick?: (testingTypeId: string | undefined) => void;
};

export const VulnerabilityTestingTypesCard: FC<Props> = ({ vulnerabilities, title, onClick }) => (
    <Accordion className="mb-3" defaultActiveKey={vulnerabilities.length > 0 ? "0" : null}>
        <Accordion.Item eventKey="0">
            <Accordion.Button className="bg-light py-2">{title ?? "Number of vulnerabilities"}</Accordion.Button>
            <Accordion.Body>
                {vulnerabilities.length > 0 ? (
                    <ReportVulnerabilityTestingTypeChart
                        vulnerabilities={vulnerabilities}
                        style={{ height: "250px" }}
                        onClick={onClick}
                    />
                ) : (
                    <p>No vulnerabilities reported.</p>
                )}
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
);
