import { FC, useState } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaBell, FaRegBell } from "react-icons/fa";
import { UserNotificationsOverlay } from "app/component/user-notification/UserNotificationsOverlay";
import { useGetUserNotificationsQuery } from "app/api/graph/types";
import { notEmpty } from "app/util/array";

export const UserNotificationsIcon: FC = () => {
    const [show, setShow] = useState(false);
    const { data } = useGetUserNotificationsQuery({
        pollInterval: 60000,
    });
    const numUnread =
        data?.userNotifications?.edges
            ?.map((edge) => edge?.node)
            .filter(notEmpty)
            .filter((node) => node.readAt === null).length ?? 0;

    return (
        <OverlayTrigger
            trigger={["click"]}
            placement="bottom-end"
            overlay={({ style, ...props }) => (
                <div {...props} style={{ ...style, zIndex: 999 }} onMouseLeave={() => setShow(false)}>
                    <UserNotificationsOverlay onItemClick={() => setShow(false)} />
                </div>
            )}
            show={show}
            onToggle={setShow}
        >
            <Button variant="default">{numUnread > 0 ? <FaBell color="#c00102" /> : <FaRegBell />}</Button>
        </OverlayTrigger>
    );
};
