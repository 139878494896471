export const reportMediaObjectGraphId = (id: number): string => `/api/report_media_objects/${id}`;
export const reportClientGraphId = (id: number): string => `/api/report_clients/${id}`;
export const reportConsultantGraphId = (id: number): string => `/api/report_consultants/${id}`;
export const reportContactGraphId = (id: number): string => `/api/report_contacts/${id}`;
export const reportInviteGraphId = (id: number): string => `/api/report_invites/${id}`;
export const reportSectionGraphId = (id: number): string => `/api/report_sections/${id}`;
export const reportVersionGraphId = (id: number): string => `/api/report_versions/${id}`;
export const reportVulnerabilityGraphId = (id: number): string => `/api/report_vulnerabilities/${id}`;
export const reportVulnerabilityActionGraphId = (id: number): string => `/api/report_vulnerability_actions/${id}`;
export const reportVulnerabilityFileGraphId = (id: number): string => `/api/report_vulnerability_files/${id}`;
export const reportVulnerabilityCommentGraphId = (id: number): string => `/api/report_vulnerability_comments/${id}`;
export const reportGraphId = (id: number): string => `/api/reports/${id}`;
export const userGraphId = (id: number): string => `/api/users/${id}`;
export const companyGraphId = (id: number): string => `/api/companies/${id}`;
export const companyContactGraphId = (id: number): string => `/api/company_contacts/${id}`;
export const companyInviteGraphId = (id: number): string => `/api/company_invites/${id}`;
export const vulnerabilityGraphId = (id: number): string => `/api/vulnerabilities/${id}`;
export const userNotificationGraphId = (id: number): string => `/api/user_notifications/${id}`;
export const contactGraphId = (id: number): string => `/api/contacts/${id}`;
export const testingTypeGraphId = (id: number): string => `/api/testing_types/${id}`;
