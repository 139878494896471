import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { TestingType } from "app/api/graph/types";
import { editTestingTypeRoute, testingTypesRoute } from "app/route/Routes";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { EditTestingTypeForm } from "app/component/testing-type/EditTestingTypeForm";
import { useRouteParams } from "app/route/route";

export const EditTestingTypeScreen: FC = () => {
    const { testingTypeId } = useRouteParams(editTestingTypeRoute);
    const [testingType, setTestingType] = useState<Pick<TestingType, "_id"> | undefined>(undefined);

    return testingType ? (
        <Navigate to={testingTypesRoute.build({})} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editTestingTypeRoute} />
                <EditTestingTypeForm testingTypeId={testingTypeId} onUpdate={setTestingType} />
            </Container>
        </NavigationScreen>
    );
};
