import { FC } from "react";
import { ReportClientFieldsFragment } from "app/api/graph/types";
import { DetailsTable } from "app/component/report-version/component/part/content/DetailsTable";

type Props = { client: ReportClientFieldsFragment };

export const ClientDetails: FC<Props> = ({ client }) => (
    <DetailsTable
        details={[
            ["Client contact name", client?.contact?.name],
            ["Client position", client?.contact?.position],
            ["Client contact email", client?.contact?.email],
            ["Client contact mobile", client?.contact?.mobile],
            ["Client address", client?.contact?.address],
            ["Client phone", client?.contact?.phone],
        ]}
    />
);
