import { ReactNode, MouseEvent, forwardRef } from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";
import { LinkProps, useHref, useLinkClickHandler } from "react-router-dom";

interface ButtonLinkProps extends ButtonProps, Pick<LinkProps, "replace" | "state" | "to"> {
    children?: ReactNode;
}

export const ButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
    ({ onClick, replace = false, state, target, to, ...props }, ref) => {
        const href = useHref(to);
        const handleClick = useLinkClickHandler(to, { replace, state: state as unknown, target });

        return (
            <Button
                {...props}
                href={href}
                onClick={(event) => {
                    onClick?.(event);
                    if (!event.defaultPrevented) {
                        handleClick(event as unknown as MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>);
                    }
                }}
                ref={ref}
                target={target}
            />
        );
    },
);

ButtonLink.displayName = "ButtonLink";
