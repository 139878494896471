import { isAfter, isBefore, isEqual } from "date-fns";

export const isAfterOrEqual = (date: Date, dateToCompare: Date): boolean =>
    isEqual(date, dateToCompare) || isAfter(date, dateToCompare);

export const isBeforeOrEqual = (date: Date, dateToCompare: Date): boolean =>
    isEqual(date, dateToCompare) || isBefore(date, dateToCompare);

export const isBetween = (date: Date, start: Date, end: Date): boolean =>
    isAfterOrEqual(date, start) && isBefore(date, end);
