import { FC, InputHTMLAttributes } from "react";
import { FormControlProps } from "react-bootstrap/FormControl";
import { formatISO, isValid, parseISO } from "date-fns";
import Form from "react-bootstrap/Form";

type Props = Omit<InputHTMLAttributes<HTMLInputElement> & FormControlProps, "type" | "value" | "onChange"> & {
    value: Date | null;
    onChange: (value: Date | null) => void;
};

const formatValue = (value: Date | null) =>
    value !== null && isValid(value) ? formatISO(value, { representation: "date" }) : "";

export const FormControlDate: FC<Props> = ({ value, onChange, ...props }) => (
    <Form.Control
        {...props}
        type="date"
        value={formatValue(value)}
        onChange={(e) => {
            const date = parseISO(e.target.value);
            onChange?.(isValid(date) ? date : null);
        }}
    />
);
