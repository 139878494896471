import { FC } from "react";
import { Part } from "app/component/report-version/component/part/Part";
import { ReportPart } from "app/component/report-version/component/part/ReportPart";

type Props = { parts: Part[] };

export const ReportParts: FC<Props> = ({ parts }) => (
    <>
        {parts.map((part) => (
            <ReportPart key={part.id} part={part} />
        ))}
    </>
);
