import { FC } from "react";
import { CreateCompanyMutation, useCreateCompanyMutation } from "app/api/graph/types";
import { FormValues, CompanyForm } from "app/component/company/CompanyForm";

type Props = {
    onCreate: (company: NonNullable<NonNullable<CreateCompanyMutation["createCompany"]>["company"]>) => void;
};

const initialValues: FormValues = {
    name: "",
};

export const CreateCompanyForm: FC<Props> = ({ onCreate }) => {
    const [createCompany, { error }] = useCreateCompanyMutation({});

    const onSubmit = (values: FormValues) =>
        createCompany({ variables: { input: values } })
            .then((response) => response.data?.createCompany?.company)
            .then((company) => (company ? onCreate?.(company) : undefined));

    return <CompanyForm initialValues={initialValues} onSubmit={onSubmit} error={error} />;
};
