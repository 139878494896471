import { FC } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { deleteReportVersionVulnerabilityFileRoute, reportVersionVulnerabilityRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { useDeleteReportVulnerabilityFileMutation, useReportVulnerabilityFileQuery } from "app/api/graph/types";
import { reportVulnerabilityFileGraphId } from "app/api/graph/helpers";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { ConfirmDelete } from "app/component/form/ConfirmDelete";

export const DeleteReportVersionVulnerabilityFileScreen: FC = () => {
    const { reportId, versionId, vulnerabilityId, fileId } = useRouteParams(deleteReportVersionVulnerabilityFileRoute);
    const { data, loading, error } = useReportVulnerabilityFileQuery({
        variables: { fileId: reportVulnerabilityFileGraphId(fileId) },
    });
    const [deleteFile, { data: deleteData, loading: deleting, error: deleteError }] =
        useDeleteReportVulnerabilityFileMutation({
            variables: { input: { id: reportVulnerabilityFileGraphId(fileId) } },
        });
    const file = data?.reportVulnerabilityFile;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!file) {
        return <ErrorScreen title="Error" message="Not found." />;
    }
    if (deleteData?.deleteReportVulnerabilityFile) {
        return <Navigate to={reportVersionVulnerabilityRoute.build({ reportId, versionId, vulnerabilityId })} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={deleteReportVersionVulnerabilityFileRoute} />
                <h1>{file.reportMediaObject.originalName}</h1>
                {deleteError ? <Alert variant="danger">{deleteError.message}</Alert> : null}
                <p>Are you sure that you want to permanently delete this file from this report version?</p>
                <ConfirmDelete onConfirm={() => deleteFile()} deleting={deleting} />
            </Container>
        </NavigationScreen>
    );
};
