import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FC, useMemo } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaEdit, FaEye } from "react-icons/fa";
import { TableOfContents } from "app/component/report-version/component/part/TableOfContents";
import { useReportVersion } from "app/api/graph/hook/use-report-version";
import {
    ReportListFieldsFragment,
    ReportSectionFieldsFragment,
    ReportVersionFieldsFragment,
    ReportVersionListFieldsFragment,
    ReportVersionStatus,
    ReportVulnerabilityFieldsFragment,
} from "app/api/graph/types";
import { Part } from "app/component/report-version/component/part/Part";
import { ReportParts } from "app/component/report-version/component/part/ReportParts";
import { editReportVersionRoute, reportVersionReadReceiptsRoute } from "app/route/Routes";
import { useReportPermissions } from "app/api/graph/hook/use-report-permissions";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import "./report-version.scss";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { useCss } from "app/hook/use-css";
import { DownloadReportVersionPdfButton } from "app/component/report-version/component/DownloadReportVersionPdfButton";
import { DownloadReportVersionVulnerabilitiesSpreadsheetButton } from "app/component/report-version/component/DownloadReportVersionVulnerabilitiesSpreadsheetButton";
import { ReadReceiptRecorder } from "app/component/report-version/component/ReadReceiptRecorder";
import { buildTemplate, Template } from "app/component/report-version/component/template/template-builder";
import { useAuth } from "app/api/AuthContext";
import { testerRole } from "app/api/graph/roles";
import { ButtonLink } from "app/component/button/ButtonLink";

type Props = {
    reportId: number;
    versionId: number;
    template: Template;
};

const pageCss = `
    @page {
        size: A4;
        margin: 2cm 1cm;
    }

    @media print {
        html, body {
        width: 210mm;
        height: 297mm;
    }

    body {
        -webkit-print-color-adjust:exact;
    }
`;

const ReportVersionInner: FC<{
    template: Template;
    report: ReportListFieldsFragment;
    version: ReportVersionFieldsFragment;
    sections: ReportSectionFieldsFragment[];
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
    versions: ReportVersionListFieldsFragment[];
}> = ({ template, report, version, sections, vulnerabilities, versions }) => {
    const { canEdit, canViewReadReceipts } = useReportPermissions(report);
    useCss(pageCss);

    const parts: Part[] = useMemo(
        () => buildTemplate(template, { version, sections, vulnerabilities, versions }),
        [template, version, sections, vulnerabilities, versions],
    );

    return (
        <div className="report-version">
            <ReadReceiptRecorder parts={parts} reportVersion={version} />
            <Toolbar className="d-print-none">
                <ToolbarTitle>
                    <h1>{report.title}</h1>
                </ToolbarTitle>
                <ToolbarButtons>
                    <ButtonGroup className="me-2">
                        <DownloadReportVersionPdfButton versionId={version._id} />
                        <DownloadReportVersionVulnerabilitiesSpreadsheetButton versionId={version._id} />
                        {canViewReadReceipts ? (
                            <ButtonLink
                                variant="success"
                                to={reportVersionReadReceiptsRoute.build({
                                    reportId: report._id,
                                    versionId: version._id,
                                })}
                            >
                                <FaEye /> Read receipts
                            </ButtonLink>
                        ) : null}
                    </ButtonGroup>
                    {canEdit ? (
                        <ButtonLink
                            to={editReportVersionRoute.build({ reportId: report._id, versionId: version._id })}
                            variant="warning"
                        >
                            <FaEdit /> Edit
                        </ButtonLink>
                    ) : null}
                </ToolbarButtons>
            </Toolbar>
            <hr className="mb-5 d-print-none" />
            <Row>
                <Col lg={3} className="report-version-toc d-print-none">
                    <TableOfContents parts={parts} />
                </Col>
                <Col lg={9}>
                    <ReportParts parts={parts} />
                </Col>
            </Row>
        </div>
    );
};

export const ReportVersion: FC<Props> = ({ reportId, versionId, template }) => {
    const { report, version, sections, vulnerabilities, versions, loading, error } = useReportVersion(
        reportId,
        versionId,
    );
    const { roles } = useAuth();

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!report || !version || !sections || !vulnerabilities || !versions) {
        return <Alert variant="danger">Not found.</Alert>;
    }
    if (version.status === ReportVersionStatus.Draft && !roles?.includes(testerRole)) {
        return (
            <Alert variant="danger">
                This report version hasn&lsquo;t been published yet, please check again later.
            </Alert>
        );
    }

    return (
        <ReportVersionInner
            template={template}
            report={report}
            version={version}
            sections={sections}
            vulnerabilities={vulnerabilities}
            versions={versions}
        />
    );
};
