import { FC, InputHTMLAttributes, useState } from "react";
import { FormControlProps } from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import classNames from "classnames";

type Props = InputHTMLAttributes<HTMLInputElement> & FormControlProps;

export const FormControlPassword: FC<Props> = (props) => {
    const [show, setShow] = useState(false);
    return (
        <InputGroup className={classNames({ "is-invalid": props.isInvalid, "is-valid": props.isValid })}>
            <Form.Control {...props} type={show ? "text" : "password"} />
            <Button variant="outline-secondary" onClick={() => setShow(!show)} tabIndex={-1}>
                {show ? <FaEyeSlash /> : <FaEye />}
            </Button>
        </InputGroup>
    );
};
