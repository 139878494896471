import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { useRouteParams } from "app/route/route";
import { createReportVersionVulnerabilityRetestRequestRoute } from "app/route/Routes";
import { useCreateReportVulnerabilityRetestRequestMutation } from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";

export const CreateReportVulnerabilityRetestRequestScreen: FC = () => {
    const { vulnerabilityId } = useRouteParams(createReportVersionVulnerabilityRetestRequestRoute);
    const [create, { data, loading, error }] = useCreateReportVulnerabilityRetestRequestMutation({
        variables: { input: { vulnerability: reportVulnerabilityGraphId(vulnerabilityId) } },
    });

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={createReportVersionVulnerabilityRetestRequestRoute} />
                {data?.createReportVulnerabilityRetestRequest ? (
                    <Alert variant="success">A re-test has been requested.</Alert>
                ) : (
                    <>
                        {error ? <Alert variant="danger">{error.message}</Alert> : null}
                        <Alert variant="warning">
                            Are you sure that you want to request a re-test of this vulnerability? This will notify the
                            tester.
                        </Alert>
                        <Button variant="primary" disabled={loading} onClick={() => create()}>
                            Request re-test
                        </Button>
                    </>
                )}
            </Container>
        </NavigationScreen>
    );
};
