import { FC, useState } from "react";
import { formatISO } from "date-fns";
import { mapNodes } from "app/util/graph";
import { useReportVersionVulnerabilitiesListQuery } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { FormValues } from "app/component/report-vulnerability/ReportVulnerabilitiesTableFiltersForm";
import {
    BaseReportVulnerabilitiesTable,
    Order,
} from "app/component/report-vulnerability/BaseReportVulnerabilitiesTable.tsx";

type Props = {
    reportId: number;
    versionId: number;
    initialFilters?: FormValues;
};

export const ReportVersionVulnerabilitiesTable: FC<Props> = ({ versionId, initialFilters }) => {
    const [order, setOrder] = useState<Order>({
        issueNumber: "ASC",
        title: undefined,
        severity: undefined,
        status: "DESC",
        discovered: undefined,
    });
    const [filters, setFilters] = useState<FormValues>(initialFilters ?? {});
    const { data, loading, error, fetchMore } = useReportVersionVulnerabilitiesListQuery({
        variables: {
            versionId: reportVersionGraphId(versionId),
            order: order,
            issueNumber: filters.issueNumber,
            title: filters.title,
            status: filters.status,
            severity: filters.severity,
            testingTypes: filters.testingTypes,
            dateDiscovered: {
                after: filters.dateDiscoveredFrom ? formatISO(filters.dateDiscoveredFrom) : undefined,
                before: filters.dateDiscoveredTo ? formatISO(filters.dateDiscoveredTo) : undefined,
            },
            cvssScore: filters.cvssScore,
        },
        notifyOnNetworkStatusChange: true,
    });
    const version = data?.reportVersion;
    const vulnerabilities = version?.vulnerabilities;

    const fetchMoreVulnerabilities = () => fetchMore({ variables: { after: vulnerabilities?.pageInfo.endCursor } });

    return (
        <BaseReportVulnerabilitiesTable
            loading={loading}
            error={error}
            vulnerabilities={vulnerabilities != null ? mapNodes(vulnerabilities, (item) => item) : undefined}
            filters={filters}
            order={order}
            hasMore={vulnerabilities?.pageInfo.hasNextPage ?? false}
            setFilters={setFilters}
            setOrder={setOrder}
            fetchMore={fetchMoreVulnerabilities}
        />
    );
};
