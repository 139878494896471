import { FC } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { ButtonGroup } from "react-bootstrap";
import { FaEdit, FaFileAlt } from "react-icons/fa";
import { mapNodes } from "app/util/graph";
import { formatISODate } from "app/util/format";
import { ReportVersionListFieldsFragment, useReportVersionsListQuery } from "app/api/graph/types";
import { reportGraphId } from "app/api/graph/helpers";
import { editReportVersionRoute, reportVersionRoute } from "app/route/Routes";
import { useReportVersionPermissions } from "app/api/graph/hook/use-report-version-permissions";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { reportVersionStatusLabel } from "app/api/graph/strings";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

type Props = {
    reportId: number;
};

const Row: FC<{ reportId: number; version: ReportVersionListFieldsFragment }> = ({ reportId, version }) => {
    const { canEdit } = useReportVersionPermissions(version);
    return (
        <tr>
            <td>{formatISODate(version.createdAt)}</td>
            <td>{version.createdBy?.fullName ?? "-"}</td>
            <td>{version.versionName}</td>
            <td>{version.versionNumber}</td>
            <td>{reportVersionStatusLabel(version.status)}</td>
            <td>
                <ButtonGroup>
                    <ButtonLink
                        to={reportVersionRoute.build({ reportId, versionId: version._id })}
                        variant="info"
                        size="sm"
                        title="Report"
                    >
                        <FaFileAlt />
                    </ButtonLink>
                    {canEdit ? (
                        <ButtonLink
                            to={editReportVersionRoute.build({ reportId, versionId: version._id })}
                            variant="warning"
                            size="sm"
                            title="Edit"
                        >
                            <FaEdit />
                        </ButtonLink>
                    ) : null}
                </ButtonGroup>
            </td>
        </tr>
    );
};

export const ReportVersionsTable: FC<Props> = ({ reportId }) => {
    const { data, loading, error, fetchMore } = useReportVersionsListQuery({
        variables: { reportId: reportGraphId(reportId) },
        notifyOnNetworkStatusChange: true,
    });
    const versions = data?.report?.versions;

    if (loading && !versions) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!versions) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const fetchMoreVersions = () => fetchMore({ variables: { after: versions.pageInfo.endCursor } });

    return (
        <Table responsive="sm" bordered className="crud-table">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Author</th>
                    <th>Stage</th>
                    <th>Version</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {mapNodes(versions, (version) => (
                    <Row key={version.id} version={version} reportId={reportId} />
                ))}
                {versions.edges?.length === 0 ? (
                    <tr>
                        <td colSpan={100}>No versions.</td>
                    </tr>
                ) : null}
                {loading ? (
                    <tr>
                        <td colSpan={100}>
                            <LoadingSpinner />
                        </td>
                    </tr>
                ) : null}
                {versions.pageInfo.hasNextPage ? (
                    <tr>
                        <td colSpan={100}>
                            <BlockButtonGroup>
                                <Button variant="light" onClick={fetchMoreVersions}>
                                    Load more
                                </Button>
                            </BlockButtonGroup>
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </Table>
    );
};
