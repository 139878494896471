import { FC } from "react";
import Container from "react-bootstrap/Container";
import { FaPlus } from "react-icons/fa";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { contactsRoute, createContactRoute } from "app/route/Routes";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { ContactsTable } from "app/component/contact/ContactsTable";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ButtonLink } from "app/component/button/ButtonLink";

export const ContactsListScreen: FC = () => {
    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={contactsRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Contacts</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        <ButtonLink to={createContactRoute.build({})} variant="success">
                            <FaPlus /> New contact
                        </ButtonLink>
                    </ToolbarButtons>
                </Toolbar>
                <ContactsTable />
            </Container>
        </NavigationScreen>
    );
};
