import { FC } from "react";
import Container from "react-bootstrap/Container";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { useQueryParams, useRouteParams } from "app/route/route";
import { reportVersionRoute } from "app/route/Routes";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ReportVersion } from "app/component/report-version/component/ReportVersion";
import { Template } from "app/component/report-version/component/template/template-builder";

export const ReportVersionScreen: FC = () => {
    const { reportId, versionId } = useRouteParams(reportVersionRoute);
    const { template } = useQueryParams(reportVersionRoute);

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={reportVersionRoute} className="d-print-none" />
                <ReportVersion reportId={reportId} versionId={versionId} template={template ?? Template.Main} />
            </Container>
        </NavigationScreen>
    );
};
