import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { FC, useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, CLEAR_HISTORY_COMMAND, EditorState, LexicalEditor } from "lexical";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";

type Props = {
    value: string;
    onChange: (value: string) => void;
};

const exportHtml = (editor: LexicalEditor): Promise<string> =>
    new Promise((resolve) => {
        editor.read(() => {
            resolve($generateHtmlFromNodes(editor));
        });
    });

const importHtml = (html: string, editor: LexicalEditor): Promise<undefined> =>
    new Promise((resolve) => {
        editor.update(() => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(html, "text/html");
            const nodes = $generateNodesFromDOM(editor, dom);
            const root = $getRoot();

            root.clear();
            root.select().insertNodes(nodes);

            editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);

            resolve(undefined);
        });
    });

export const ControlledByHtmlPlugin: FC<Props> = ({ value, onChange }) => {
    const [editor] = useLexicalComposerContext();
    const [html, setHtml] = useState("");

    const handleChange = useCallback(
        async (_: EditorState, editor: LexicalEditor) => {
            const newHtml = await exportHtml(editor);
            onChange(newHtml);
            setHtml(newHtml);
        },
        [onChange, setHtml],
    );

    useEffect(() => {
        if (value !== html) {
            void importHtml(value, editor);
        }
    }, [editor, value, html]);

    return <OnChangePlugin onChange={handleChange} />;
};
