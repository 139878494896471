import classNames from "classnames";
import { FC, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import {
    ActualFileObject,
    FilePondErrorDescription,
    FilePondFile,
    FilePondInitialFile,
    LoadServerConfigFunction,
    ProcessServerConfigFunction,
} from "filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
    ReportMediaObjectFieldsFragment,
    ReportMediaObjectFieldsFragmentDoc,
    useUploadReportMediaObjectMutation,
} from "app/api/graph/types";
import { client } from "app/api/ApiProvider";
import { notEmpty } from "app/util/array";
import "./media-objects.scss";
import { reportVersionGraphId } from "app/api/graph/helpers";

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

type Props = {
    initialValues: ReportMediaObjectFieldsFragment[];
    onChange?: (values: ReportMediaObjectFieldsFragment[]) => void;
    onProcessingChange?: (value: boolean) => void;
    readonly?: boolean;
    versionId: number;
    allowMultiple?: boolean;
};

// This should match (or be a subset of) those in the @Assert\File annotation on ReportMediaObject::$file
const acceptedMimeTypes = [
    "application/msword",
    "application/pdf",
    "application/rtf",
    "application/vnd.ms-excel",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/x-pdf",
    "image/bmp",
    "image/png",
    "image/jpeg",
    "image/webp",
    "text/plain",
];

// This is used for display only - should contain the common file extensions that cover the acceptedMimeTypes
const acceptedFileExtensions = [
    "doc",
    "docx",
    "pdf",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "bmp",
    "png",
    "jpg",
    "jpeg",
    "webp",
    "txt",
];

const maxFileSize = "50MB";

export const MediaObjects: FC<Props> = ({
    initialValues,
    onChange,
    onProcessingChange,
    readonly,
    versionId,
    allowMultiple,
}) => {
    const [upload] = useUploadReportMediaObjectMutation({});
    const [fileItems, setFileItems] = useState<Array<FilePondFile>>([]);
    const [files, setFiles] = useState<Array<FilePondInitialFile | ActualFileObject | Blob | string>>(
        initialValues.map(
            (value): FilePondInitialFile => ({
                source: value.id,
                options: {
                    type: "local",
                },
            }),
        ),
    );

    // Upload a file to the API
    const process: ProcessServerConfigFunction = async (_fieldName, file, _metadata, load, error, progress) => {
        if (readonly) {
            return error("Read only.");
        }

        progress(false, 0, 0);

        try {
            const { data, errors } = await upload({ variables: { file, versionId: reportVersionGraphId(versionId) } });

            if (errors) {
                return error(errors.map((error) => error.message).join("\n"));
            }

            const mediaObject = data?.uploadReportMediaObject?.reportMediaObject;
            if (!mediaObject) {
                return error("Upload failed.");
            }

            return load(mediaObject.id);
        } catch (e) {
            console.error(e);
            return error("Error processing file");
        }
    };

    // Load the initial, already uploaded, files
    const load: LoadServerConfigFunction = async (source, load, error, progress) => {
        progress(false, 0, 0);

        try {
            const mediaObject = initialValues.find((mediaObject) => mediaObject.id === source);
            if (!mediaObject?.contentUrl) {
                return error("Not found.");
            }

            const response = await fetch(mediaObject.contentUrl);
            const blob = await response.blob();

            if (mediaObject.originalName) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                blob.name = mediaObject.originalName;
            }

            return load(blob);
        } catch (e) {
            console.error(e);
            return error("Error loading file");
        }
    };

    const open = (file: FilePondFile) => window.open(URL.createObjectURL(file.file));

    const onUpdateFiles = (fileItems: FilePondFile[]) => {
        // Update the internal list of files for FilePond to use
        setFiles(fileItems.map((fileItem) => fileItem.file));

        // Keep track of the file items so we can access them to trigger a refresh in onFileProcessed
        setFileItems(fileItems);

        // Map the files back onto MediaObjects and let the parent know they've changed
        onChange?.(
            fileItems
                .map((fileItem) =>
                    client.readFragment<ReportMediaObjectFieldsFragment>({
                        id: client.cache.identify({ __typename: "ReportMediaObject", id: fileItem.serverId }),
                        fragment: ReportMediaObjectFieldsFragmentDoc,
                    }),
                )
                .filter(notEmpty),
        );
    };

    const onFileProcessStart = () => onProcessingChange?.(true);

    // Workaround so that props.onChange is called with the uploaded file included, as FilePond doesn't call onUpdateFiles after processing
    const onFileProcessed = (error: FilePondErrorDescription) => {
        if (error) {
            onProcessingChange?.(false);
            return console.error(error);
        }

        onUpdateFiles(fileItems);
        onProcessingChange?.(false);
    };

    return (
        <FilePond
            maxParallelUploads={1}
            allowMultiple={allowMultiple ?? true}
            allowReorder={false}
            allowReplace={false}
            allowDirectoriesOnly={false}
            allowPaste={false}
            allowRevert={!readonly}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            allowRemove={!readonly}
            allowProcess={!readonly}
            allowBrowse={!readonly}
            allowDrop={!readonly}
            files={files}
            name="files"
            onupdatefiles={onUpdateFiles}
            onprocessfilestart={onFileProcessStart}
            onprocessfile={onFileProcessed}
            onactivatefile={open}
            server={{ process, load }}
            acceptedFileTypes={acceptedMimeTypes}
            itemInsertLocation="after"
            dropValidation={true}
            labelIdle={`
              <div class="label-idle">
                  <div>Drag & drop files here or <span class="filepond--label-action">browse</span></div>
                  <div class="info-label">Accepted file types: ${acceptedFileExtensions.join(", ")}</div>
                  <div class="info-label">Max size: ${maxFileSize}</div>
              </div>
            `}
            className={classNames("media-objects", { readonly })}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            maxFileSize={maxFileSize}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            credits={false}
        />
    );
};
