import { FC } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik, FormikHelpers } from "formik";
import yup from "app/util/yup";
import { CentredScreen } from "app/component/screen/CentredScreen";
import { useResetUserPasswordMutation } from "app/api/graph/types";
import logo from "app/asset/image/istorm_logo.png";
import { signInRoute } from "app/route/Routes";
import { userGraphId } from "app/api/graph/helpers";
import { StatusAlert } from "app/component/form/StatusAlert";
import { handleError, handleSuccess } from "app/api/graph/form";
import { FormControlPassword } from "app/component/form/control/FormControlPassword";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";

type FormValues = {
    newPassword: string;
    confirmPassword: string;
};

const initialValues: FormValues = {
    newPassword: "",
    confirmPassword: "",
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    newPassword: yup.string().required(),
    confirmPassword: yup
        .string()
        .required()
        .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

export const ResetPasswordScreen: FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token") ?? "";
    const userId = parseInt(searchParams.get("userId") ?? "");
    const [resetPassword, { data }] = useResetUserPasswordMutation();

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        resetPassword({ variables: { input: { id: userGraphId(userId), token, newPassword: values.newPassword } } })
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    if (data?.resetPasswordUser) {
        return <Navigate to={signInRoute.build({})} replace={true} />;
    }

    return (
        <CentredScreen>
            <Link to="/">
                <img src={logo} alt="iSTORM®" className="img-fluid mb-3" />
            </Link>
            <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting, status }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <StatusAlert status={status as unknown} />
                        <Form.Group className="mb-3">
                            <Form.Label>New password</Form.Label>
                            <FormControlPassword
                                name="newPassword"
                                placeholder="New password"
                                value={values.newPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.newPassword && !!errors.newPassword}
                            />
                            <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Confirm password</Form.Label>
                            <FormControlPassword
                                name="confirmPassword"
                                placeholder="New password"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                            />
                            <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                        </Form.Group>
                        <BlockButtonGroup>
                            <Button type="submit" disabled={isSubmitting}>
                                Change password
                            </Button>
                        </BlockButtonGroup>
                    </Form>
                )}
            </Formik>
        </CentredScreen>
    );
};
