import { FC, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import { styled } from "styled-components";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaEdit, FaFilter, FaList, FaUsers } from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { endOfDay, startOfDay, subYears } from "date-fns";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import {
    companyReportsRoute,
    companyReportVulnerabilitiesRoute,
    companyRoute,
    companyUsersRoute,
    editCompanyRoute,
} from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { ReportVulnerabilityStatus, useCompanyQuery } from "app/api/graph/types";
import { companyGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar.tsx";
import { ButtonLink } from "app/component/button/ButtonLink.tsx";
import { updateCompanyRole } from "app/api/graph/roles.ts";
import { useAuth } from "app/api/AuthContext.tsx";
import { usePublishedCompanyReportVulnerabilities } from "app/api/graph/hook/use-published-company-report-vulnerabilities.ts";
import { VulnerabilityListCard } from "app/component/report/component/VulnerabilityListCard.tsx";
import { NumberOfVulnerabilitiesCard } from "app/component/report/component/NumberOfVulnerabilitiesCard.tsx";
import { VulnerabilityTimelineCard } from "app/component/report/component/VulnerabilityTimelineCard.tsx";
import {
    FormValues,
    ReportVulnerabilityDashboardFiltersForm,
} from "app/component/report-vulnerability/ReportVulnerabilityDashboardFiltersForm.tsx";
import { formatDate } from "app/util/format.ts";
import { VulnerabilityTestingTypesCard } from "app/component/report/component/VulnerabilityTestingTypesCard.tsx";
import { TopVulnerabilitiesListCard } from "app/component/report/component/TopVulnerabilitiesListCard.tsx";
import { LocationState } from "app/component/company/CompanyReportVulnerabilitiesListScreen.tsx";
import { useCompanyReportVersionsList } from "app/api/graph/hook/use-company-report-versions-list.ts";
import { ReportVersionsCard } from "app/component/report/component/ReportVersionsCard.tsx";
import { ReportVersionsTimelineCard } from "app/component/report/component/ReportVersionsTimelineCard.tsx";

const CompanyLogo = styled.img`
    max-width: 80px;
    max-height: 30px;
`;

export const CompanyScreen: FC = () => {
    const { companyId } = useRouteParams(companyRoute);
    const { roles } = useAuth();
    const navigate = useNavigate();

    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState<FormValues>({
        from: subYears(startOfDay(new Date()), 1),
        to: endOfDay(new Date()),
    });

    const {
        data: companyData,
        loading: loadingCompany,
        error: companyError,
    } = useCompanyQuery({ variables: { companyId: companyGraphId(companyId) } });
    const company = companyData?.company;

    const vulnerabilityFilters = {
        dateDiscoveredFrom: filters.from,
        dateDiscoveredTo: filters.to,
        title: filters.vulnerabilityTitle,
        cvssScore: filters.cvssScore,
        testingTypes: filters.testingTypes,
    };
    const {
        vulnerabilities,
        loading: loadingVulnerabilities,
        error: vulnerabilitiesError,
    } = usePublishedCompanyReportVulnerabilities(companyId, vulnerabilityFilters);
    const openVulnerabilities = useMemo(
        () => vulnerabilities?.filter((v) => v.status === ReportVulnerabilityStatus.Open),
        [vulnerabilities],
    );
    const resolvedVulnerabilities = useMemo(
        () =>
            vulnerabilities?.filter(
                (v) =>
                    v.status === ReportVulnerabilityStatus.Resolved ||
                    v.status === ReportVulnerabilityStatus.VerifiedResolved,
            ),
        [vulnerabilities],
    );
    const riskAcceptedVulnerabilities = useMemo(
        () => vulnerabilities?.filter((v) => v.status === ReportVulnerabilityStatus.RiskAccepted),
        [vulnerabilities],
    );
    const informationalVulnerabilities = useMemo(
        () => vulnerabilities?.filter((v) => v.status === ReportVulnerabilityStatus.Informational),
        [vulnerabilities],
    );

    const {
        versions,
        loading: loadingVersions,
        error: versionsError,
    } = useCompanyReportVersionsList(companyId, {
        publishedAtFrom: filters.from !== undefined ? startOfDay(filters.from) : undefined,
        publishedAtTo: filters.to !== undefined ? endOfDay(filters.to) : undefined,
    });

    if (loadingCompany || loadingVulnerabilities || loadingVersions) {
        return <LoadingScreen />;
    }
    if (companyError) {
        return <ErrorScreen title="Error" message={companyError.message} />;
    }
    if (vulnerabilitiesError) {
        return <ErrorScreen title="Error" message={vulnerabilitiesError.message} />;
    }
    if (versionsError) {
        return <ErrorScreen title="Error" message={versionsError.message} />;
    }
    if (company == null || vulnerabilities == null || versions == null) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    const listFilters: LocationState["initialFilters"] = {
        title: filters.vulnerabilityTitle,
        cvssScore: filters.cvssScore,
        testingTypes: filters.testingTypes,
        dateDiscoveredFrom: filters.from,
        dateDiscoveredTo: filters.to,
    };

    return (
        <NavigationScreen>
            <Container fluid>
                <RouteBreadcrumbs route={companyRoute} />

                <Toolbar>
                    <ToolbarTitle>
                        <div className="d-flex flex-wrap align-items-stretch gap-2">
                            {company.logo?.contentUrl != null && (
                                <div className="d-flex align-items-center">
                                    <CompanyLogo src={company.logo.contentUrl} />
                                </div>
                            )}

                            <span className="fs-2">{company.name}</span>

                            <div className="d-flex align-items-center">
                                <Button
                                    className="me-2"
                                    variant="primary"
                                    size="sm"
                                    onClick={() => setShowFilters(true)}
                                >
                                    <FaFilter />
                                </Button>

                                {filters.from !== undefined &&
                                    filters.to !== undefined &&
                                    `${formatDate(filters.from)} - ${formatDate(filters.to)}`}
                                {filters.from === undefined &&
                                    filters.to !== undefined &&
                                    `to ${formatDate(filters.to)}`}
                                {filters.from !== undefined &&
                                    filters.to === undefined &&
                                    `from ${formatDate(filters.from)}`}
                            </div>
                        </div>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        <ButtonGroup>
                            {roles?.includes(updateCompanyRole) ? (
                                <ButtonLink to={editCompanyRoute.build({ companyId: company._id })} variant="warning">
                                    <FaEdit /> Edit
                                </ButtonLink>
                            ) : null}
                            <ButtonLink to={companyUsersRoute.build({ companyId })} variant="info">
                                <FaUsers /> Users
                            </ButtonLink>

                            <ButtonLink to={companyReportsRoute.build({ companyId })} variant="primary">
                                <FaList /> Reports
                            </ButtonLink>
                        </ButtonGroup>
                    </ToolbarButtons>
                </Toolbar>

                <Row>
                    <Col>
                        <NumberOfVulnerabilitiesCard
                            title={`Vulnerabilities by severity (${vulnerabilities?.length ?? 0})`}
                            vulnerabilities={vulnerabilities ?? []}
                        />
                        <NumberOfVulnerabilitiesCard
                            title={`Open vulnerabilities by severity (${openVulnerabilities?.length ?? 0})`}
                            vulnerabilities={openVulnerabilities ?? []}
                        />
                        <VulnerabilityTimelineCard
                            vulnerabilities={vulnerabilities ?? []}
                            dateFrom={filters.from}
                            dateTo={filters.to}
                        />
                        <ReportVersionsTimelineCard versions={versions} dateFrom={filters.from} dateTo={filters.to} />
                    </Col>
                    <Col>
                        <VulnerabilityTestingTypesCard
                            title={`Vulnerabilities by testing type (${vulnerabilities?.length ?? 0})`}
                            vulnerabilities={vulnerabilities ?? []}
                            onClick={(testingTypeId) =>
                                navigate(companyReportVulnerabilitiesRoute.build({ companyId }), {
                                    state: {
                                        initialFilters: {
                                            ...listFilters,
                                            testingTypes: testingTypeId !== undefined ? [testingTypeId] : undefined,
                                        },
                                    } as LocationState,
                                })
                            }
                        />
                        <TopVulnerabilitiesListCard
                            title="Top master vulnerabilities"
                            vulnerabilities={vulnerabilities ?? []}
                        />
                        <VulnerabilityListCard
                            title="Open vulnerabilities"
                            vulnerabilities={openVulnerabilities ?? []}
                            buttons={
                                <ButtonGroup>
                                    <ButtonLink
                                        to={companyReportVulnerabilitiesRoute.build({ companyId })}
                                        state={
                                            {
                                                initialFilters: {
                                                    ...listFilters,
                                                    status: ReportVulnerabilityStatus.Open,
                                                },
                                            } as LocationState
                                        }
                                        variant="info"
                                        size="sm"
                                    >
                                        <FaList />
                                    </ButtonLink>
                                </ButtonGroup>
                            }
                        />
                        <VulnerabilityListCard
                            title="Resolved vulnerabilities"
                            vulnerabilities={resolvedVulnerabilities ?? []}
                            buttons={
                                <ButtonGroup>
                                    <ButtonLink
                                        to={companyReportVulnerabilitiesRoute.build({ companyId })}
                                        state={
                                            {
                                                initialFilters: {
                                                    ...listFilters,
                                                    status: ReportVulnerabilityStatus.Resolved,
                                                },
                                            } as LocationState
                                        }
                                        variant="info"
                                        size="sm"
                                    >
                                        <FaList />
                                    </ButtonLink>
                                </ButtonGroup>
                            }
                        />
                        <VulnerabilityListCard
                            title="Risk accepted vulnerabilities"
                            vulnerabilities={riskAcceptedVulnerabilities ?? []}
                            buttons={
                                <ButtonGroup>
                                    <ButtonLink
                                        to={companyReportVulnerabilitiesRoute.build({ companyId })}
                                        state={
                                            {
                                                initialFilters: {
                                                    ...listFilters,
                                                    status: ReportVulnerabilityStatus.RiskAccepted,
                                                },
                                            } as LocationState
                                        }
                                        variant="info"
                                        size="sm"
                                    >
                                        <FaList />
                                    </ButtonLink>
                                </ButtonGroup>
                            }
                        />
                        <VulnerabilityListCard
                            title="Informational"
                            vulnerabilities={informationalVulnerabilities ?? []}
                            buttons={
                                <ButtonGroup>
                                    <ButtonLink
                                        to={companyReportVulnerabilitiesRoute.build({ companyId })}
                                        state={
                                            {
                                                initialFilters: {
                                                    ...listFilters,
                                                    status: ReportVulnerabilityStatus.Informational,
                                                },
                                            } as LocationState
                                        }
                                        variant="info"
                                        size="sm"
                                    >
                                        <FaList />
                                    </ButtonLink>
                                </ButtonGroup>
                            }
                        />
                        <ReportVersionsCard versions={versions} />
                    </Col>
                </Row>

                <Modal show={showFilters} onHide={() => setShowFilters(false)}>
                    <Modal.Body>
                        <ReportVulnerabilityDashboardFiltersForm
                            initialValues={filters}
                            onSubmit={(value) => {
                                setFilters(value);
                                setShowFilters(false);
                            }}
                        />
                    </Modal.Body>
                </Modal>
            </Container>
        </NavigationScreen>
    );
};
