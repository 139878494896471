import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Navigate } from "react-router-dom";
import yup from "app/util/yup";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { addReportUserRoute, reportUsersRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { ReportRole, useAddUserToReportMutation } from "app/api/graph/types";
import { reportGraphId } from "app/api/graph/helpers";
import { SelectUser } from "app/component/form/select/SelectUser";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";
import { FormControlSelectReportRole } from "app/component/form/control/FormControlSelectReportRole";

type FormValues = {
    user?: string;
    role: ReportRole;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    user: yup.string().required(),
    role: yup.mixed<ReportRole>().oneOf(Object.values(ReportRole)).required(),
});

const initialValues: FormValues = {
    user: undefined,
    role: ReportRole.Standard,
};

export const AddReportUserScreen: FC = () => {
    const { reportId } = useRouteParams(addReportUserRoute);
    const [addUser, { data }] = useAddUserToReportMutation({});

    if (data?.addUserToReport) {
        return <Navigate to={reportUsersRoute.build({ reportId })} />;
    }

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        addUser({ variables: { reportId: reportGraphId(reportId), userId: values.user ?? "", role: values.role } })
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={addReportUserRoute} />
                <h1>Add user</h1>
                <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
                    {({
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        isSubmitting,
                        errors,
                        touched,
                        status,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <StatusAlert status={status as unknown} />
                            <Form.Group className="mb-3" controlId="select-user">
                                <SelectUser
                                    id="select-user"
                                    placeholder="Select a user..."
                                    value={values.user ?? null}
                                    onChange={(value) => setFieldValue("user", value)}
                                    onBlur={() => setFieldTouched("user")}
                                    isInvalid={touched.user && !!errors.user}
                                />
                                <Form.Control.Feedback type="invalid">{errors.user}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Role</Form.Label>
                                <FormControlSelectReportRole
                                    name="role"
                                    value={values.role}
                                    onChange={(value) => setFieldValue("role", value)}
                                    onBlur={() => setFieldTouched("role")}
                                    isInvalid={touched.role && !!errors.role}
                                />
                                <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>
                            </Form.Group>
                            <Button type="submit" disabled={isSubmitting}>
                                Submit
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </NavigationScreen>
    );
};
