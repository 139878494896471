import classNames from "classnames";
import { FC } from "react";
import { Part } from "app/component/report-version/component/part/Part";
import "./table-of-contents.scss";
import { useCurrentPart } from "app/component/report-version/component/part/use-current-part";

type Props = { parts: Part[] };

const Item: FC<{ part: Part; currentPart?: Part }> = ({ part, currentPart }) => {
    return (
        <li className={classNames({ active: part === currentPart })}>
            <a href={`#${part.sectionNumber}`} className="text-decoration-none">
                {part.sectionNumber} {part.title}
            </a>
            <ul>{part.children?.map((part) => <Item key={part.id} part={part} currentPart={currentPart} />)}</ul>
        </li>
    );
};

export const TableOfContents: FC<Props> = ({ parts }) => {
    const { currentPart } = useCurrentPart(parts);

    return (
        <ul className="part-toc">
            {parts.map((part) => (
                <Item key={part.id} part={part} currentPart={currentPart} />
            ))}
        </ul>
    );
};
