import { FC } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { deleteReportRoute, reportsRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useDeleteReportMutation, useReportOverviewQuery } from "app/api/graph/types";
import { reportGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { ConfirmDelete } from "app/component/form/ConfirmDelete";
import { formatISODateTime } from "app/util/format";

export const DeleteReportScreen: FC = () => {
    const { reportId } = useRouteParams(deleteReportRoute);
    const { data, loading, error } = useReportOverviewQuery({
        variables: { reportId: reportGraphId(reportId) },
    });
    const [deleteReport, { data: deleteData, loading: deleting, error: deleteError }] = useDeleteReportMutation({
        variables: { input: { id: reportGraphId(reportId) } },
    });
    const report = data?.report;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!report) {
        return <ErrorScreen title="Error" message="Not found." />;
    }
    if (deleteData?.deleteReport) {
        return <Navigate to={reportsRoute.build({})} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={deleteReportRoute} />
                <h1>Delete report</h1>
                {deleteError ? <Alert variant="danger">{deleteError.message}</Alert> : null}
                <p>Are you sure that you want to permanently delete this report?</p>
                <Table responsive="sm">
                    <tbody>
                        <tr>
                            <th>Title</th>
                            <td>{report.title ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Company</th>
                            <th>{report.company?.name ?? "-"}</th>
                        </tr>
                        <tr>
                            <th>Created by</th>
                            <td>{report.createdBy?.fullName ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Created at</th>
                            <td>{formatISODateTime(report.createdAt)}</td>
                        </tr>
                    </tbody>
                </Table>
                <Alert variant="danger">
                    <p>
                        This will <strong>permanently</strong> delete the report - which cannot be reversed.
                    </p>
                    Note: this will permanently delete the report and all data under it (versions, written sections,
                    vulnerabilities, actions, files, etc.). Users invited to view the report will not be deleted.
                </Alert>
                <ConfirmDelete onConfirm={() => deleteReport()} deleting={deleting} />
            </Container>
        </NavigationScreen>
    );
};
