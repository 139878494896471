import { FC } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { ButtonGroup } from "react-bootstrap";
import { FaClipboardList, FaEdit, FaTrash } from "react-icons/fa";
import { ApolloError } from "@apollo/client";
import { formatISODate } from "app/util/format";
import { ReportVulnerabilityListFieldsFragment } from "app/api/graph/types";
import {
    reportVersionVulnerabilityActionsRoute,
    editReportVersionVulnerabilityRoute,
    reportVersionVulnerabilityRoute,
    deleteReportVersionVulnerabilityRoute,
} from "app/route/Routes";
import { useAuth } from "app/api/AuthContext";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { SeverityBadge } from "app/component/report-vulnerability/component/SeverityBadge";
import { deleteReportVulnerabilityRole, updateReportVulnerabilityRole } from "app/api/graph/roles";
import { SortableTableHeader, SortDirection } from "app/component/table/SortableTableHeader";
import {
    FormValues,
    ReportVulnerabilitiesTableFiltersForm,
} from "app/component/report-vulnerability/ReportVulnerabilitiesTableFiltersForm";
import { ReportVulnerabilityStatusBadge } from "app/component/report-vulnerability/component/ReportVulnerabilityStatusBadge";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

export type Order = {
    issueNumber: SortDirection | undefined;
    title: SortDirection | undefined;
    severity: SortDirection | undefined;
    status: SortDirection | undefined;
    discovered: SortDirection | undefined;
};

type Props = {
    loading: boolean;
    error: ApolloError | undefined;
    vulnerabilities: ReportVulnerabilityListFieldsFragment[] | undefined;
    filters: FormValues;
    order: Order;
    hasMore: boolean;
    setFilters: (values: FormValues) => void;
    setOrder: (order: Order) => void;
    fetchMore: () => void;
};

export const BaseReportVulnerabilitiesTable: FC<Props> = ({
    loading,
    error,
    vulnerabilities,
    filters,
    order,
    hasMore,
    setFilters,
    setOrder,
    fetchMore,
}) => {
    const { roles } = useAuth();

    if (loading && vulnerabilities == null) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (vulnerabilities == null) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    return (
        <Row>
            <Col>
                <Table responsive="sm" bordered className="crud-table">
                    <thead>
                        <tr>
                            <SortableTableHeader
                                direction={order.issueNumber}
                                onChange={(direction) => setOrder({ ...order, issueNumber: direction })}
                            >
                                Issue #
                            </SortableTableHeader>
                            <SortableTableHeader
                                direction={order.title}
                                onChange={(direction) => setOrder({ ...order, title: direction })}
                            >
                                Vulnerability
                            </SortableTableHeader>
                            <SortableTableHeader
                                direction={order.severity}
                                onChange={(direction) => setOrder({ ...order, severity: direction })}
                            >
                                Severity
                            </SortableTableHeader>
                            <SortableTableHeader
                                direction={order.status}
                                onChange={(direction) => setOrder({ ...order, status: direction })}
                            >
                                Status
                            </SortableTableHeader>
                            <SortableTableHeader
                                direction={order.discovered}
                                onChange={(direction) => setOrder({ ...order, discovered: direction })}
                            >
                                Discovered
                            </SortableTableHeader>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vulnerabilities.map((vulnerability) => (
                            <tr key={vulnerability.id}>
                                <td>
                                    <Link
                                        to={reportVersionVulnerabilityRoute.build({
                                            reportId: vulnerability.reportVersion.report._id,
                                            versionId: vulnerability.reportVersion._id,
                                            vulnerabilityId: vulnerability._id,
                                        })}
                                    >
                                        {vulnerability.issueNumber}
                                    </Link>
                                </td>
                                <td>{vulnerability.title}</td>
                                <td>
                                    <SeverityBadge severity={vulnerability.severity} />
                                </td>
                                <td>
                                    <ReportVulnerabilityStatusBadge status={vulnerability.status} />
                                </td>
                                <td>{formatISODate(vulnerability.dateDiscovered)}</td>
                                <td>
                                    <ButtonGroup>
                                        {roles?.includes(updateReportVulnerabilityRole) ? (
                                            <ButtonLink
                                                to={editReportVersionVulnerabilityRoute.build({
                                                    reportId: vulnerability.reportVersion.report._id,
                                                    versionId: vulnerability.reportVersion._id,
                                                    vulnerabilityId: vulnerability._id,
                                                })}
                                                variant="warning"
                                                size="sm"
                                                title="Edit"
                                            >
                                                <FaEdit />
                                            </ButtonLink>
                                        ) : null}
                                        {roles?.includes(deleteReportVulnerabilityRole) ? (
                                            <ButtonLink
                                                to={deleteReportVersionVulnerabilityRoute.build({
                                                    reportId: vulnerability.reportVersion.report._id,
                                                    versionId: vulnerability.reportVersion._id,
                                                    vulnerabilityId: vulnerability._id,
                                                })}
                                                variant="danger"
                                                size="sm"
                                                title="Delete"
                                            >
                                                <FaTrash />
                                            </ButtonLink>
                                        ) : null}
                                        <ButtonLink
                                            to={reportVersionVulnerabilityActionsRoute.build({
                                                reportId: vulnerability.reportVersion.report._id,
                                                versionId: vulnerability.reportVersion._id,
                                                vulnerabilityId: vulnerability._id,
                                            })}
                                            variant="info"
                                            size="sm"
                                            title="Actions"
                                        >
                                            <FaClipboardList />
                                        </ButtonLink>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        ))}
                        {vulnerabilities.length === 0 ? (
                            <tr>
                                <td colSpan={100}>No vulnerabilities reported.</td>
                            </tr>
                        ) : null}
                        {loading ? (
                            <tr>
                                <td colSpan={100}>
                                    <LoadingSpinner />
                                </td>
                            </tr>
                        ) : null}
                        {hasMore ? (
                            <tr>
                                <td colSpan={100}>
                                    <BlockButtonGroup>
                                        <Button variant="light" onClick={fetchMore}>
                                            Load more
                                        </Button>
                                    </BlockButtonGroup>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </Table>
            </Col>
            <Col md={3}>
                <ReportVulnerabilitiesTableFiltersForm initialValues={filters} onSubmit={setFilters} />
            </Col>
        </Row>
    );
};
