import { FC, useState } from "react";
import { Navigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useRouteParams } from "app/route/route";
import { editReportVersionRoute, newReportVersionRoute } from "app/route/Routes";
import { ReportVersion } from "app/api/graph/types";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { NewReportVersionForm } from "app/component/report/NewReportVersionForm";

export const NewReportVersionScreen: FC = () => {
    const { reportId } = useRouteParams(newReportVersionRoute);
    const [newVersion, setNewVersion] = useState<Pick<ReportVersion, "id" | "_id"> | undefined>(undefined);

    return newVersion ? (
        <Navigate to={editReportVersionRoute.build({ reportId, versionId: newVersion._id })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={newReportVersionRoute} />
                <NewReportVersionForm reportId={reportId} onCreate={setNewVersion} />
            </Container>
        </NavigationScreen>
    );
};
