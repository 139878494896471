import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { TestingTypeFieldsFragment } from "app/api/graph/types";
import { testingTypesRoute, createTestingTypeRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { CreateTestingTypeForm } from "app/component/testing-type/CreateTestingTypeForm";

export const CreateTestingTypeScreen: FC = () => {
    const [testingType, setTestingType] = useState<TestingTypeFieldsFragment | undefined>(undefined);

    if (testingType != null) {
        return <Navigate to={testingTypesRoute.build({})} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={createTestingTypeRoute} />
                <CreateTestingTypeForm onCreate={setTestingType} />
            </Container>
        </NavigationScreen>
    );
};
