import { FC } from "react";
import { CreateTestingTypeMutation, useCreateTestingTypeMutation } from "app/api/graph/types";
import { FormValues, TestingTypeForm } from "app/component/testing-type/TestingTypeForm";

type Props = {
    onCreate: (report: NonNullable<NonNullable<CreateTestingTypeMutation["createTestingType"]>["testingType"]>) => void;
};

const initialValues: FormValues = {
    name: "",
};

export const CreateTestingTypeForm: FC<Props> = ({ onCreate }) => {
    const [createTestingType, { error }] = useCreateTestingTypeMutation({});

    const onSubmit = (values: FormValues) =>
        createTestingType({ variables: { input: values } })
            .then((response) => response.data?.createTestingType?.testingType)
            .then((testingType) => (testingType ? onCreate?.(testingType) : undefined));

    return <TestingTypeForm initialValues={initialValues} onSubmit={onSubmit} error={error} />;
};
