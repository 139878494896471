import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { editReportVersionRoute, editReportVersionConsultantRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useReportVersionConsultantQuery } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { EditReportConsultantForm } from "app/component/report-consultant/EditReportConsultantForm";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";

export const EditReportVersionConsultantScreen: FC = () => {
    const { reportId, versionId } = useRouteParams(editReportVersionConsultantRoute);
    const [updated, setUpdated] = useState(false);
    const { data, loading, error } = useReportVersionConsultantQuery({
        variables: { versionId: reportVersionGraphId(versionId) },
    });
    const consultant = data?.reportVersion?.consultant;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!consultant) {
        return <ErrorScreen title="Error" message="Not found" />;
    }

    return updated ? (
        <Navigate to={editReportVersionRoute.build({ reportId, versionId })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editReportVersionConsultantRoute} />
                <EditReportConsultantForm consultantId={consultant._id} onUpdate={() => setUpdated(true)} />
            </Container>
        </NavigationScreen>
    );
};
