import { FC } from "react";
import Container from "react-bootstrap/Container";
import { FaPlus } from "react-icons/fa";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { createReportVersionVulnerabilityRoute, editReportVersionVulnerabilitiesRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ReportVersionVulnerabilitiesTable } from "app/component/report-vulnerability/ReportVersionVulnerabilitiesTable";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { ButtonLink } from "app/component/button/ButtonLink";

export const EditReportVersionVulnerabilitiesScreen: FC = () => {
    const { reportId, versionId } = useRouteParams(editReportVersionVulnerabilitiesRoute);

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editReportVersionVulnerabilitiesRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Vulnerabilities</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        <ButtonLink
                            to={createReportVersionVulnerabilityRoute.build({ reportId, versionId })}
                            variant="success"
                        >
                            <FaPlus /> Create
                        </ButtonLink>
                    </ToolbarButtons>
                </Toolbar>
                <ReportVersionVulnerabilitiesTable reportId={reportId} versionId={versionId} />
            </Container>
        </NavigationScreen>
    );
};
