import { FC } from "react";
import Table from "react-bootstrap/Table";
import { ReportVulnerabilityFieldsFragment } from "app/api/graph/types";
import { SeverityBadge } from "app/component/report-vulnerability/component/SeverityBadge";
import { ReportVulnerabilityStatusBadge } from "app/component/report-vulnerability/component/ReportVulnerabilityStatusBadge";

type Props = {
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
};

export const VulnerabilitiesSummary: FC<Props> = ({ vulnerabilities }) => {
    if (vulnerabilities.length === 0) {
        return <p>No vulnerabilities reported.</p>;
    }

    return (
        <Table responsive="sm">
            <thead>
                <tr>
                    <th>Issue</th>
                    <th>Severity</th>
                    <th>Status</th>
                    <th># of issues</th>
                    <th>Title</th>
                </tr>
            </thead>
            <tbody>
                {vulnerabilities.map((vulnerability) => (
                    <tr key={vulnerability.id}>
                        <td>
                            <a href={`#vulnerability-${vulnerability.issueNumber}`}>{vulnerability.issueNumber}</a>
                        </td>
                        <td>
                            <SeverityBadge severity={vulnerability.severity} />
                        </td>
                        <td>
                            <ReportVulnerabilityStatusBadge status={vulnerability.status} />
                        </td>
                        <td>{vulnerability.numberOfIssues}</td>
                        <td>{vulnerability.title}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};
