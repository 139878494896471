import { FC } from "react";
import Container from "react-bootstrap/Container";
import { FaPlus } from "react-icons/fa";
import { useRouteParams } from "app/route/route";
import { newReportVersionRoute, reportVersionsRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { ReportVersionsTable } from "app/component/report-version/ReportVersionsTable";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useAuth } from "app/api/AuthContext";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { updateReportRole } from "app/api/graph/roles";
import { ButtonLink } from "app/component/button/ButtonLink";

export const ReportVersionsScreen: FC = () => {
    const { roles } = useAuth();
    const { reportId } = useRouteParams(reportVersionsRoute);

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={reportVersionsRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Versions</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {roles?.includes(updateReportRole) ? (
                            <ButtonLink to={newReportVersionRoute.build({ reportId })} variant="success">
                                <FaPlus /> New version
                            </ButtonLink>
                        ) : null}
                    </ToolbarButtons>
                </Toolbar>
                <ReportVersionsTable reportId={reportId} />
            </Container>
        </NavigationScreen>
    );
};
