import { FC } from "react";
import Container from "react-bootstrap/Container";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { editReportVersionSectionsRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ReportVersionSectionsTable } from "app/component/report-section/ReportVersionSectionsTable";

export const EditReportVersionSectionsScreen: FC = () => {
    const { reportId, versionId } = useRouteParams(editReportVersionSectionsRoute);

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editReportVersionSectionsRoute} />
                <ReportVersionSectionsTable reportId={reportId} versionId={versionId} />
            </Container>
        </NavigationScreen>
    );
};
