import { FC, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { useDebounce } from "use-debounce";
import { useSelectVulnerabilityQuery, VulnerabilityFieldsFragment } from "app/api/graph/types";
import { SelectData, SelectDataProps } from "app/component/form/select/SelectData";

export const SelectVulnerability: FC<SelectDataProps<VulnerabilityFieldsFragment>> = (props) => {
    const [query, setQuery] = useState<string | undefined>(undefined);
    const { data, loading, fetchMore, error } = useSelectVulnerabilityQuery({
        variables: { title: useDebounce(query, 400)[0] },
        notifyOnNetworkStatusChange: true,
    });

    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }

    return (
        <SelectData
            {...props}
            connection={data?.vulnerabilities}
            label={(value) => `(${value.identifier}) ${value.title}`}
            isLoading={loading}
            onPaginate={() => fetchMore({ variables: { after: data?.vulnerabilities?.pageInfo?.endCursor } })}
            onInputChange={setQuery}
        />
    );
};
