import { FC } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { reportVersionRoute, reportVersionPublishRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { ReportVersionStatus, useReportVersionQuery } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { PublishReportVersionForm } from "app/component/report-version/PublishReportVersionForm";

export const PublishReportVersionScreen: FC = () => {
    const { reportId, versionId } = useRouteParams(reportVersionPublishRoute);
    const { data, loading, error } = useReportVersionQuery({
        variables: { versionId: reportVersionGraphId(versionId) },
    });
    const reportVersion = data?.reportVersion;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!reportVersion) {
        return <ErrorScreen title="Error" message="Not found" />;
    }
    if (reportVersion.status === ReportVersionStatus.Published) {
        return <Navigate to={reportVersionRoute.build({ reportId, versionId })} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={reportVersionPublishRoute} />
                <h1>{reportVersion.report.title}</h1>
                <h2>
                    {reportVersion.versionName} ({reportVersion.versionNumber})
                </h2>
                <PublishReportVersionForm reportVersionId={versionId} />
            </Container>
        </NavigationScreen>
    );
};
