import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ApolloError } from "@apollo/client";
import Alert from "react-bootstrap/Alert";
import yup from "app/util/yup";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";
import { VulnerabilitySeverity } from "app/api/graph/types";
import { RichTextEditor } from "app/component/form/text-editor/RichTextEditor";
import { FormControlSelectVulnerabilitySeverity } from "app/component/form/control/FormControlSelectVulnerabilitySeverity";
import { FormControlSelectOwaspCategory } from "app/component/form/control/FormControlSelectOwaspCategory";
import { SelectTestingTypes } from "app/component/form/select/SelectTestingTypes.tsx";

type Props = {
    initialValues: FormValues;
    onSubmit: (values: FormValues) => Promise<void>;
    error?: ApolloError;
    readOnlyIdentifier?: boolean;
};

export type FormValues = {
    identifier: string;
    title: string;
    severity: VulnerabilitySeverity;
    testingTypes: string[];
    cvssScore?: string;
    cvssVector?: string;
    cveScore?: string;
    cwe?: string;
    owaspCategory?: string;
    issueDetail?: string;
    advice?: string;
    replacePrevious?: boolean;
};

export const schema: yup.ObjectSchema<FormValues> = yup.object({
    identifier: yup.string().required(),
    title: yup.string().required(),
    severity: yup.mixed<VulnerabilitySeverity>().oneOf(Object.values(VulnerabilitySeverity)).required(),
    testingTypes: yup.array().of<string>(yup.string().required()).required(),
    cvssScore: yup.string(),
    cvssVector: yup.string(),
    cveScore: yup.string(),
    cwe: yup.string(),
    owaspCategory: yup.string(),
    issueDetail: yup.string(),
    advice: yup.string(),
    replacePrevious: yup.boolean(),
});

export const VulnerabilityForm: FC<Props> = (props) => {
    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        props
            .onSubmit(values)
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={props.initialValues}>
            {({
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
                setFieldTouched,
                values,
                isSubmitting,
                errors,
                touched,
                status,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>Identifier</Form.Label>
                        <Form.Control
                            type="text"
                            name="identifier"
                            value={values.identifier}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.identifier && !!errors.identifier}
                            disabled={props.readOnlyIdentifier}
                        />
                        <Form.Control.Feedback type="invalid">{errors.identifier}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Severity</Form.Label>
                        <FormControlSelectVulnerabilitySeverity
                            name="severity"
                            value={values.severity}
                            onChange={(value) => setFieldValue("severity", value)}
                            onBlur={() => setFieldTouched("severity")}
                            isInvalid={touched.severity && !!errors.severity}
                        />
                        <Form.Control.Feedback type="invalid">{errors.severity}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Testing types</Form.Label>
                        <SelectTestingTypes
                            id="testing-types"
                            value={values.testingTypes}
                            onChange={(value) => setFieldValue("testingTypes", value)}
                            onBlur={() => setFieldTouched("testingTypes")}
                            isInvalid={touched.testingTypes && !!errors.testingTypes}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testingTypes}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>CVSS score</Form.Label>
                        <Form.Control
                            type="text"
                            name="cvssScore"
                            value={values.cvssScore ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.cvssScore && !!errors.cvssScore}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cvssScore}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>CVSS vector</Form.Label>
                        <Form.Control
                            type="text"
                            name="cvssVector"
                            value={values.cvssVector ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.cvssVector && !!errors.cvssVector}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cvssVector}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>CVE score</Form.Label>
                        <Form.Control
                            type="text"
                            name="cveScore"
                            value={values.cveScore ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.cveScore && !!errors.cveScore}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cveScore}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>CWE</Form.Label>
                        <Form.Control
                            type="text"
                            name="cwe"
                            value={values.cwe ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.cwe && !!errors.cwe}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cwe}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>OWASP category</Form.Label>
                        <FormControlSelectOwaspCategory
                            name="owaspCategory"
                            value={values.owaspCategory}
                            onChange={(value) => setFieldValue("owaspCategory", value)}
                            onBlur={() => setFieldTouched("owaspCategory")}
                            isInvalid={touched.owaspCategory && !!errors.owaspCategory}
                        />
                        <Form.Control.Feedback type="invalid">{errors.owaspCategory}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Issue detail</Form.Label>
                        <RichTextEditor
                            value={values.issueDetail ?? ""}
                            onChange={(value) => setFieldValue("issueDetail", value)}
                            onBlur={() => setFieldTouched("issueDetail")}
                            isInvalid={touched.issueDetail && !!errors.issueDetail}
                        />
                        <Form.Control.Feedback type="invalid">{errors.issueDetail}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Advice</Form.Label>
                        <RichTextEditor
                            value={values.advice ?? ""}
                            onChange={(value) => setFieldValue("advice", value)}
                            onBlur={() => setFieldTouched("advice")}
                            isInvalid={touched.advice && !!errors.advice}
                        />
                        <Form.Control.Feedback type="invalid">{errors.advice}</Form.Control.Feedback>
                    </Form.Group>
                    {values.replacePrevious !== undefined ? (
                        <Form.Group className="mb-3">
                            <Form.Check
                                id="replace-previous"
                                type="checkbox"
                                name="replacePrevious"
                                label="Replace previous version?"
                                checked={values.replacePrevious ?? false}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.replacePrevious && !!errors.replacePrevious}
                                feedback={errors.replacePrevious}
                            />
                            <Form.Text muted>
                                Whether or not to update report vulnerabilities with this new version.
                            </Form.Text>
                            {values.replacePrevious ? (
                                <Alert variant="warning">
                                    This will update report vulnerabilities that are based on the latest version of this
                                    vulnerability, while those based on older versions will be untouched. Unmodified
                                    fields on report vulnerabilities will be updated to match this new version, while
                                    modified fields (those that are identical to this master vulnerability) will be
                                    untouched.
                                </Alert>
                            ) : (
                                <Alert variant="warning">
                                    A new version of this vulnerability will be created. Report vulnerabilities attached
                                    to the current (or previous) version of this vulnerability will not be updated. This
                                    new version will only be used by new report vulnerabilities created after this
                                    update.
                                </Alert>
                            )}
                        </Form.Group>
                    ) : null}
                    <Button type="submit" disabled={isSubmitting}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
