import { CustomLayer, Layer, ResponsiveLine } from "@nivo/line";
import {
    differenceInCalendarMonths,
    eachDayOfInterval,
    format,
    max,
    min,
    parseISO,
    startOfDay,
    Interval,
} from "date-fns";
import { CSSProperties, FC, useMemo, useState } from "react";
import { usePrint } from "app/hook/use-print";
import { ReportVulnerabilityListFieldsFragment, VulnerabilitySeverity } from "app/api/graph/types";
import { vulnerabilitySeverityColor, vulnerabilitySeverityLabel } from "app/api/graph/strings";
import { isBeforeOrEqual, isBetween } from "app/util/date";
import { notEmpty, range } from "app/util/array";
import { useChartTheme } from "app/hook/use-chart-theme";

type Props = {
    vulnerabilities: ReportVulnerabilityListFieldsFragment[];
    style?: CSSProperties;
    dateFrom?: Date;
    dateTo?: Date;
};

type Datum = {
    x: string;
    y: number;
};

type Item = {
    id: string;
    color: string;
    data: Datum[];
};

const margin = { top: 10, right: 10, bottom: 90, left: 40 };

enum TickPeriod {
    Day,
    Month,
}

const calculateDateInterval = (vulnerabilities: ReportVulnerabilityListFieldsFragment[]): Interval => {
    const dates = [
        ...vulnerabilities.map((vulnerability) => vulnerability.dateDiscovered),
        ...vulnerabilities.map((vulnerability) => vulnerability.dateResolved).filter(notEmpty),
    ].map((d) => startOfDay(parseISO(d)));

    return {
        start: min(dates),
        end: max(dates),
    };
};

const getDefaultTickPeriod = (vulnerabilities: ReportVulnerabilityListFieldsFragment[]): TickPeriod => {
    const interval = calculateDateInterval(vulnerabilities);
    return differenceInCalendarMonths(interval.end, interval.start) < 1 ? TickPeriod.Day : TickPeriod.Month;
};

const parseData = (vulnerabilities: ReportVulnerabilityListFieldsFragment[]): Item[] => {
    const interval = calculateDateInterval(vulnerabilities);
    const range = eachDayOfInterval(interval);

    return Object.values(VulnerabilitySeverity)
        .reverse()
        .map(
            (severity: VulnerabilitySeverity): Item => ({
                id: vulnerabilitySeverityLabel(severity),
                color: vulnerabilitySeverityColor(severity),
                data: range.map(
                    (date): Datum => ({
                        x: format(date, "yyyy-MM-dd"),
                        // Count the number of issues that were open on this date, for this severity
                        y: vulnerabilities
                            .filter((vulnerability) => vulnerability.severity === severity)
                            .filter((vulnerability) => {
                                const dateDiscovered = startOfDay(parseISO(vulnerability.dateDiscovered));
                                const dateClosedRaw = vulnerability.dateResolved ?? vulnerability.riskAcceptedAt;
                                const dateClosed = dateClosedRaw ? startOfDay(parseISO(dateClosedRaw)) : undefined;
                                // Open issue, count it towards every day since its discovery
                                if (!dateClosed) {
                                    return isBeforeOrEqual(dateDiscovered, date);
                                }
                                // Issue was already resolved on (or before) its discovery date, so never count it
                                if (isBeforeOrEqual(dateClosed, dateDiscovered)) {
                                    return false;
                                }
                                // Count the vulnerability on the dates that it was open
                                return isBetween(date, dateDiscovered, dateClosed);
                            }).length,
                    }),
                ),
            }),
        );
};

const calculateTicksY = (data: Item[]) =>
    data.length > 0
        ? range(
              0,
              Math.max(
                  0,
                  ...data
                      .map((item) => (item.data.length > 0 ? item.data.map((datum) => datum.y) : null))
                      .filter(notEmpty)
                      .flat(),
              ),
          )
        : [];

const styleById: Record<string, CSSProperties> = {
    [vulnerabilitySeverityLabel(VulnerabilitySeverity.Critical)]: {
        strokeWidth: 5,
    },
    [vulnerabilitySeverityLabel(VulnerabilitySeverity.High)]: {
        strokeWidth: 4,
    },
    [vulnerabilitySeverityLabel(VulnerabilitySeverity.Medium)]: {
        strokeWidth: 3,
    },
    [vulnerabilitySeverityLabel(VulnerabilitySeverity.Low)]: {
        strokeWidth: 2,
    },
    default: {
        strokeWidth: 1,
    },
};

const customLines: CustomLayer = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }) => (
        <path
            key={id}
            d={
                lineGenerator(
                    data.map((d) => ({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        x: xScale(d.data.x ?? 0) as number,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        y: yScale(d.data.y ?? 0) as number,
                    })),
                ) ?? undefined
            }
            fill="none"
            stroke={color}
            style={styleById[id] || styleById.default}
        />
    ));
};

export const ReportVulnerabilityTimelineChart: FC<Props> = ({ vulnerabilities, style, dateFrom, dateTo }) => {
    const theme = useChartTheme();
    const defaultTickPeriod = useMemo(() => getDefaultTickPeriod(vulnerabilities), [vulnerabilities]);
    const [tickPeriod, setTickPeriod] = useState<TickPeriod>(defaultTickPeriod);
    const data = useMemo(() => parseData(vulnerabilities), [vulnerabilities, tickPeriod]);
    const ticksY = useMemo(() => calculateTicksY(data), [data]);
    const { isPrint } = usePrint();

    return (
        <div style={{ height: "300px", ...style }} className="d-flex flex-column">
            <div className="text-end">
                <select value={tickPeriod} onChange={(e) => setTickPeriod(parseInt(e.target.value))}>
                    <option value={TickPeriod.Day}>Day</option>
                    <option value={TickPeriod.Month}>Month</option>
                </select>
            </div>
            <ResponsiveLine
                data={data}
                margin={margin}
                animate={!isPrint}
                colors={{ datum: "color" }}
                xFormat="time:%Y-%m-%d"
                xScale={{
                    format: "%Y-%m-%d",
                    precision: tickPeriod === TickPeriod.Month ? "month" : "day",
                    type: "time",
                    useUTC: false,
                    min: dateFrom !== undefined ? format(dateFrom, "yyyy-MM-dd") : undefined,
                    max: dateTo !== undefined ? format(dateTo, "yyyy-MM-dd") : undefined,
                }}
                yScale={{ type: "linear", min: 0, max: "auto", stacked: false, reverse: false }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickRotation: -45,
                    format: tickPeriod === TickPeriod.Month ? "%b" : "%b %d",
                    tickValues: 5,
                }}
                axisLeft={{
                    tickValues: ticksY,
                }}
                gridYValues={ticksY}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                    {
                        anchor: "bottom",
                        direction: "row",
                        translateY: 90,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: "circle",
                    },
                ]}
                layers={
                    [
                        "grid",
                        "markers",
                        "axes",
                        "areas",
                        "crosshair",
                        customLines,
                        "points",
                        "slices",
                        "mesh",
                        "legends",
                    ] as Layer[]
                }
                theme={theme}
            />
        </div>
    );
};
