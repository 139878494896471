import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "app/api/AuthContext";
import { useAcceptCompanyInviteMutation, useCompanyInviteByTokenQuery } from "app/api/graph/types";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { CentredScreen } from "app/component/screen/CentredScreen";
import { registerRoute, companyRoute, signInRoute } from "app/route/Routes";
import logo from "app/asset/image/istorm_logo.png";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

export const AcceptCompanyInviteScreen: FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token") ?? "";
    const { data, loading, error } = useCompanyInviteByTokenQuery({ variables: { token } });
    const invite = data?.retrieveCompanyInvite;
    const company = invite?.company;
    const { isSignedIn } = useAuth();
    const [acceptInvite, { data: acceptData, loading: accepting, error: acceptError }] =
        useAcceptCompanyInviteMutation();

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!invite || !company) {
        return <ErrorScreen title="Error" message="Invalid invite." />;
    }
    if (acceptData) {
        return <Navigate to={companyRoute.build({ companyId: company._id })} />;
    }

    const handleAccept = () => acceptInvite({ variables: { input: { id: invite.id, token } } });

    return (
        <CentredScreen>
            <img src={logo} alt="iSTORM®" className="img-fluid mb-3" />

            {acceptError ? <Alert variant="danger">{acceptError.message}</Alert> : null}

            <Alert variant="info">You have been invited to join the company &quot;{company.name}&quot;.</Alert>

            {isSignedIn ? (
                <BlockButtonGroup>
                    <Button onClick={handleAccept} disabled={accepting}>
                        Accept invite
                    </Button>
                </BlockButtonGroup>
            ) : (
                <Alert variant="warning">
                    <p>You need to be signed in to accept the invite.</p>
                    <BlockButtonGroup>
                        <ButtonLink
                            to={registerRoute.build({})}
                            state={{
                                from: location,
                                firstName: invite.firstName,
                                lastName: invite.lastName,
                                email: invite.email,
                            }}
                            variant="success"
                        >
                            Register
                        </ButtonLink>
                        <ButtonLink to={signInRoute.build({})} state={{ from: location }} variant="info">
                            Sign in
                        </ButtonLink>
                    </BlockButtonGroup>
                </Alert>
            )}
        </CentredScreen>
    );
};
