import { useEffect, useState } from "react";

export const useNow = (interval = 60000): Date => {
    const [now, setNow] = useState<Date>(new Date());

    useEffect(() => {
        const id = setInterval(() => setNow(new Date()), interval);
        return () => clearInterval(id);
    });

    return now;
};
