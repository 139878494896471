import {
    CompanyRole,
    ReportRole,
    ReportVersionStatus,
    ReportVulnerabilityStatus,
    TestType,
    VulnerabilitySeverity,
} from "app/api/graph/types";

export const companyRoleLabel = (value: CompanyRole): string => {
    switch (value) {
        case CompanyRole.Owner:
            return "Owner";
        case CompanyRole.Standard:
            return "Standard";
        case CompanyRole.Limited:
            return "Limited";
    }
};

export const reportRoleLabel = (value: ReportRole): string => {
    switch (value) {
        case ReportRole.Standard:
            return "Standard";
        case ReportRole.Limited:
            return "Limited";
    }
};

export const reportVersionStatusLabel = (value: ReportVersionStatus): string => {
    switch (value) {
        case ReportVersionStatus.Draft:
            return "Draft";
        case ReportVersionStatus.Published:
            return "Published";
    }
};

export const vulnerabilitySeverityLabel = (value: VulnerabilitySeverity): string => {
    switch (value) {
        case VulnerabilitySeverity.Critical:
            return "Critical";
        case VulnerabilitySeverity.High:
            return "High";
        case VulnerabilitySeverity.Medium:
            return "Medium";
        case VulnerabilitySeverity.Low:
            return "Low";
        case VulnerabilitySeverity.Informational:
            return "Informational";
    }
};

export const vulnerabilitySeverityColor = (value: VulnerabilitySeverity): string => {
    switch (value) {
        case VulnerabilitySeverity.Critical:
            return "#702fa0";
        case VulnerabilitySeverity.High:
            return "#c00000";
        case VulnerabilitySeverity.Medium:
            return "#ffc003";
        case VulnerabilitySeverity.Low:
            return "#00b050";
        case VulnerabilitySeverity.Informational:
            return "#1389ef";
    }
};

export const reportVulnerabilityStatusLabel = (value: ReportVulnerabilityStatus): string => {
    switch (value) {
        case ReportVulnerabilityStatus.Open:
            return "Open";
        case ReportVulnerabilityStatus.Resolved:
            return "Resolved (unverified)";
        case ReportVulnerabilityStatus.RiskAccepted:
            return "Risk accepted";
        case ReportVulnerabilityStatus.VerifiedResolved:
            return "Resolved (verified)";
        case ReportVulnerabilityStatus.Informational:
            return "Informational";
    }
};

export const reportVulnerabilityStatusColor = (value: ReportVulnerabilityStatus): string => {
    switch (value) {
        case ReportVulnerabilityStatus.Open:
            return "#c00000";
        case ReportVulnerabilityStatus.Resolved:
        case ReportVulnerabilityStatus.VerifiedResolved:
            return "#00b050";
        case ReportVulnerabilityStatus.RiskAccepted:
            return "#ffc003";
        case ReportVulnerabilityStatus.Informational:
            return "#1389ef";
    }
};

export const testTypeLabel = (value: TestType): string => {
    switch (value) {
        case TestType.WebApp:
            return "Web app";
        case TestType.InternalInfrastructure:
            return "Internal infrastructure";
        case TestType.ExternalInfrastructure:
            return "External infrastructure";
        case TestType.Wifi:
            return "WiFi";
        case TestType.Phishing:
            return "Phishing";
        case TestType.RedTeamAssessment:
            return "Red team assessment";
        case TestType.InternalExternalInfra:
            return "Internal & external infra";
        case TestType.AppInfraCloud:
            return "App & infra & cloud";
        case TestType.CloudAssessment:
            return "Cloud assessment";
        case TestType.PenetrationTest:
            return "Penetration test";
        case TestType.NotSpecified:
            return "Not specified";
    }
};
