import { FC, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { useAuth } from "app/api/AuthContext";
import { ErrorScreen } from "app/component/screen/ErrorScreen";

export type Props = {
    // Note: this "one of" - not all roles will be required
    roles?: string[];
};

export const AuthRoute: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
    const { isSignedIn, accessToken, roles } = useAuth();
    const location = useLocation();
    const canView = !props.roles || props.roles.length === 0 || props.roles.some((role) => roles?.includes(role));

    if (!isSignedIn) {
        return <Navigate to="/sign-in" state={{ from: location }} />;
    }

    if (accessToken == null) {
        return <LoadingScreen />;
    }

    if (!canView) {
        return <ErrorScreen title="Access denied" message="You don't have access to this page." />;
    }

    return <>{children}</>;
};
