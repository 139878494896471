import { PartConfig } from "app/component/report-version/component/part/Part";
import { TestDetails } from "app/component/report-version/component/part/content/TestDetails";
import { ClientDetails } from "app/component/report-version/component/part/content/ClientDetails";
import { ConsultantDetails } from "app/component/report-version/component/part/content/ConsultantDetails";
import { ContactDetails } from "app/component/report-version/component/part/content/ContactDetails";
import { VersionControl } from "app/component/report-version/component/part/content/VersionControl";
import { sectionPart } from "app/component/report-version/component/part/section-part";
import { ResultsSummary } from "app/component/report-version/component/part/content/ResultsSummary";
import { RiskDefinitions } from "app/component/report-version/component/part/content/RiskDefinitions";
import { VulnerabilitiesSummary } from "app/component/report-version/component/part/content/VulnerabilitiesSummary";
import { vulnerabilitySeverityLabel } from "app/api/graph/strings";
import { Vulnerabilities } from "app/component/report-version/component/part/content/Vulnerabilities";
import { TemplateData } from "app/component/report-version/component/template/template-builder";
import { ReportSectionIdentifier, VulnerabilitySeverity } from "app/api/graph/types";

type Options = {
    anonymised?: boolean;
};

export const buildTemplateMainConfig = (
    { version, sections, vulnerabilities, versions }: TemplateData,
    options?: Options,
): PartConfig[] => [
    {
        title: "Test details",
        content: <TestDetails version={version} />,
        children: [
            {
                title: "Client",
                content: version.client ? (
                    options?.anonymised ? (
                        <p>Anonymised.</p>
                    ) : (
                        <ClientDetails client={version.client} />
                    )
                ) : null,
            },
            {
                title: "Consultant",
                content: version.consultant ? <ConsultantDetails consultant={version.consultant} /> : null,
            },
        ],
    },
    {
        title: "iSTORM® contact details",
        content: version.contact ? <ContactDetails contact={version.contact} /> : null,
    },
    {
        title: "Version control",
        content: <VersionControl currentVersion={version} versions={versions} />,
    },
    {
        ...sectionPart(ReportSectionIdentifier.ExecutiveSummaryIntro, sections),
        children: [
            sectionPart(ReportSectionIdentifier.TargetObjectives, sections),
            {
                ...sectionPart(ReportSectionIdentifier.Scope, sections),
                children: [sectionPart(ReportSectionIdentifier.Exclusions, sections)],
            },
            sectionPart(ReportSectionIdentifier.ConsultantExecutiveSummary, sections),
            {
                title: "Results summary",
                content: <ResultsSummary vulnerabilities={vulnerabilities} />,
            },
            {
                title: "Risk definitions",
                content: <RiskDefinitions />,
            },
        ],
    },
    {
        title: "Technical",
        content: (
            <p>
                Full technical descriptions of security issues found are illustrated in this section. The intended
                audience is staff of a technical nature. Detailed remediation advice for each issue can be found at the
                end of this section.
            </p>
        ),
        children: [
            sectionPart(ReportSectionIdentifier.ConsultantTechnicalSummary, sections),
            {
                title: "Vulnerability summary",
                content: <VulnerabilitiesSummary vulnerabilities={vulnerabilities} />,
            },
            {
                title: "Detailed Technical Findings",
                content: <div />,
                children: Object.values(VulnerabilitySeverity)
                    .reverse()
                    .map((severity: VulnerabilitySeverity) => ({
                        title: `${vulnerabilitySeverityLabel(severity)}-risk issues`,
                        content: (
                            <Vulnerabilities vulnerabilities={vulnerabilities.filter((v) => v.severity === severity)} />
                        ),
                    })),
            },
        ],
    },
];
