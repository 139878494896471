import { FC } from "react";
import Table from "react-bootstrap/Table";
import { SeverityBadge } from "app/component/report-vulnerability/component/SeverityBadge";
import { VulnerabilitySeverity } from "app/api/graph/types";

const RiskDefinitionsTable: FC = () => (
    <Table responsive="sm">
        <thead>
            <tr>
                <th>Risk rating</th>
                <th>Description</th>
                <th>CVSS range</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <SeverityBadge severity={VulnerabilitySeverity.Critical} />
                </td>
                <td>
                    A threat agent could gain full control over the system or application, render it unusable for users,
                    or access a very large amount of confidential information by using commonly available techniques.
                </td>
                <td>9.0-10.0</td>
            </tr>
            <tr>
                <td>
                    <SeverityBadge severity={VulnerabilitySeverity.High} />
                </td>
                <td>
                    A threat agent could gain full control over the system or application, render it unusable to users,
                    or access a substantial amount of confidential information. Exploits may be more difficult to
                    obtain, or complex to utilize.
                </td>
                <td>7.0-8.9</td>
            </tr>
            <tr>
                <td>
                    <SeverityBadge severity={VulnerabilitySeverity.Medium} />
                </td>
                <td>
                    A threat agent could gain some level of interactive control or access to data held on the system.
                </td>
                <td>4.0-6.9</td>
            </tr>
            <tr>
                <td>
                    <SeverityBadge severity={VulnerabilitySeverity.Low} />
                </td>
                <td>
                    A threat agent could gain information about the systems, and with which it could be used to leverage
                    further access.
                </td>
                <td>0.1-3.9</td>
            </tr>
        </tbody>
    </Table>
);

export const RiskDefinitions: FC = () => {
    return (
        <div>
            <p>The following risk categories as defined by iSTORM® are detailed below.</p>

            <p>
                <em>
                    Please note that in some cases the penetration testing consultant may override the Risk Rating in
                    such a way that they are not directly tied to the CVSS in the manner stated in the table below. When
                    this occurs, it is because the penetration tester feels that various real-life factors are not
                    adequately taken into account by the CVSS score and as such the Risk Rating should be independent of
                    the CVSS score.
                </em>
            </p>

            <RiskDefinitionsTable />
        </div>
    );
};
