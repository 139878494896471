import { FC } from "react";
import { Accordion } from "react-bootstrap";
import { ReportVulnerabilitySeverityChart } from "app/component/report-vulnerability/component/ReportVulnerabilitySeverityChart";
import { ReportVulnerabilityFieldsFragment } from "app/api/graph/types";

type Props = {
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
    title?: string;
};

export const NumberOfVulnerabilitiesCard: FC<Props> = ({ vulnerabilities, title }) => (
    <Accordion className="mb-3" defaultActiveKey={vulnerabilities.length > 0 ? "0" : null}>
        <Accordion.Item eventKey="0">
            <Accordion.Button className="bg-light py-2">{title ?? "Number of vulnerabilities"}</Accordion.Button>
            <Accordion.Body>
                {vulnerabilities.length > 0 ? (
                    <ReportVulnerabilitySeverityChart vulnerabilities={vulnerabilities} style={{ height: "250px" }} />
                ) : (
                    <p>No vulnerabilities reported.</p>
                )}
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
);
