import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { Vulnerability } from "app/api/graph/types";
import { editVulnerabilityRoute, vulnerabilityRoute } from "app/route/Routes";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { EditVulnerabilityForm } from "app/component/vulnerability/EditVulnerabilityForm";
import { useRouteParams } from "app/route/route";

export const EditVulnerabilityScreen: FC = () => {
    const { vulnerabilityId } = useRouteParams(editVulnerabilityRoute);
    const [vulnerability, setVulnerability] = useState<Pick<Vulnerability, "_id"> | undefined>(undefined);

    return vulnerability ? (
        <Navigate to={vulnerabilityRoute.build({ vulnerabilityId: vulnerability._id })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editVulnerabilityRoute} />
                <EditVulnerabilityForm vulnerabilityId={vulnerabilityId} onUpdate={setVulnerability} />
            </Container>
        </NavigationScreen>
    );
};
