import { FC } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik, FormikHelpers } from "formik";
import yup from "app/util/yup";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { userChangePasswordRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useResetUserPasswordMutation } from "app/api/graph/types";
import { userGraphId } from "app/api/graph/helpers";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";
import { FormControlPassword } from "app/component/form/control/FormControlPassword";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";

type FormValues = {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
};

const initialValues: FormValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    oldPassword: yup.string().required(),
    newPassword: yup.string().required(),
    confirmPassword: yup
        .string()
        .required()
        .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

export const UserChangePasswordScreen: FC = () => {
    const { userId } = useRouteParams(userChangePasswordRoute);
    const [changePassword] = useResetUserPasswordMutation();

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        changePassword({
            variables: {
                input: {
                    id: userGraphId(userId),
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword,
                },
            },
        })
            .then(() => handleSuccess(actions, "Password updated successfully"))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={userChangePasswordRoute} />
                <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues} initialStatus={""}>
                    {({ handleSubmit, handleChange, handleBlur, values, touched, errors, status, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <StatusAlert status={status as unknown} />
                            <Form.Group className="mb-3">
                                <Form.Label>Old password</Form.Label>
                                <FormControlPassword
                                    name="oldPassword"
                                    placeholder="Old password"
                                    value={values.oldPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.oldPassword && !!errors.oldPassword}
                                />
                                <Form.Control.Feedback type="invalid">{errors.oldPassword}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>New password</Form.Label>
                                <FormControlPassword
                                    name="newPassword"
                                    placeholder="New password"
                                    value={values.newPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.newPassword && !!errors.newPassword}
                                />
                                <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Confirm password</Form.Label>
                                <FormControlPassword
                                    name="confirmPassword"
                                    placeholder="Confirm password"
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                                />
                                <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                            </Form.Group>
                            <BlockButtonGroup>
                                <Button type="submit" disabled={isSubmitting}>
                                    Change password
                                </Button>
                            </BlockButtonGroup>
                        </Form>
                    )}
                </Formik>
            </Container>
        </NavigationScreen>
    );
};
