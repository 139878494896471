import { FC } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaEdit, FaTrash } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { deleteVulnerabilityRoute, editVulnerabilityRoute, vulnerabilityRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { useVulnerabilityQuery } from "app/api/graph/types";
import { vulnerabilityGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { SeverityBadge } from "app/component/report-vulnerability/component/SeverityBadge";
import { HtmlContent } from "app/component/util/HtmlContent";
import { useAuth } from "app/api/AuthContext";
import { deleteReportVulnerabilityRole, updateVulnerabilityRole } from "app/api/graph/roles";
import { ButtonLink } from "app/component/button/ButtonLink";
import { mapNodes } from "app/util/graph.ts";

export const VulnerabilityScreen: FC = () => {
    const { roles } = useAuth();
    const { vulnerabilityId } = useRouteParams(vulnerabilityRoute);
    const { data, loading, error } = useVulnerabilityQuery({
        variables: { vulnerabilityId: vulnerabilityGraphId(vulnerabilityId) },
    });
    const vulnerability = data?.vulnerability;
    const testingTypes = mapNodes(vulnerability?.testingTypes, (types) => types);

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!vulnerability) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={vulnerabilityRoute} />
                <Row>
                    <Col xs="auto" className="ms-auto">
                        <ButtonGroup>
                            {roles?.includes(updateVulnerabilityRole) ? (
                                <ButtonLink
                                    to={editVulnerabilityRoute.build({
                                        vulnerabilityId,
                                    })}
                                    variant="warning"
                                >
                                    <FaEdit /> Edit
                                </ButtonLink>
                            ) : null}
                            {roles?.includes(deleteReportVulnerabilityRole) ? (
                                <ButtonLink
                                    to={deleteVulnerabilityRoute.build({
                                        vulnerabilityId,
                                    })}
                                    variant="danger"
                                >
                                    <FaTrash /> Delete
                                </ButtonLink>
                            ) : null}
                        </ButtonGroup>
                    </Col>
                </Row>
                <h1>
                    <SeverityBadge severity={vulnerability.severity} className="me-2" />
                    <strong className="me-1">{vulnerability.identifier}</strong> {vulnerability.title}
                </h1>
                <Table responsive="sm">
                    <tbody>
                        <tr>
                            <th>Testing types</th>
                            <td>
                                {testingTypes.length > 0
                                    ? testingTypes.map((testingType) => (
                                          <Badge key={testingType.id}>{testingType.name}</Badge>
                                      ))
                                    : "None assigned"}
                            </td>
                        </tr>
                        <tr>
                            <th>CVSS score</th>
                            <td>{vulnerability.cvssScore ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>CVSS vector</th>
                            <td>{vulnerability.cvssVector ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>CVE score</th>
                            <td>{vulnerability.cveScore ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>CWE</th>
                            <td>{vulnerability.cwe ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>OWASP category</th>
                            <td>{vulnerability.owaspCategory ?? "-"}</td>
                        </tr>
                    </tbody>
                </Table>

                <h2>Issue detail</h2>
                <HtmlContent html={vulnerability.issueDetail ?? "-"} />

                <h2>Remediation advice and references</h2>
                <HtmlContent html={vulnerability.advice ?? "-"} />
            </Container>
        </NavigationScreen>
    );
};
