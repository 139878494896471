import * as yup from "yup";
import { formatDate } from "app/util/format";

yup.setLocale({
    mixed: {
        default: "Invalid value",
        required: "Required",
    },
    number: {
        min: "Must be ${min} or more",
        max: "Must be ${max} or less",
    },
    string: {
        email: "Invalid email address",
    },
    date: {
        min: ({ min }) => `Must be ${typeof min === "string" ? min : formatDate(min)} or later`,
        max: ({ max }) => `Must be ${typeof max === "string" ? max : formatDate(max)} or sooner`,
    },
});

// This file has to be imported instead of yup directly, as it needs to run before any schemas are built, otherwise the localisation doesn't work - this is due to the order in which modules are run - putting this in index.tsx doesn't work as consts in lower down modules are built first
export default yup;
