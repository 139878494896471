import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { editReportVersionVulnerabilityActionRoute, reportVersionVulnerabilityRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { EditReportVulnerabilityActionForm } from "app/component/report-vulnerability-action/EditReportVulnerabilityActionForm";

export const EditReportVersionVulnerabilityActionScreen: FC = () => {
    const { reportId, versionId, vulnerabilityId, actionId } = useRouteParams(
        editReportVersionVulnerabilityActionRoute,
    );
    const [updated, setUpdated] = useState(false);

    return updated ? (
        <Navigate to={reportVersionVulnerabilityRoute.build({ reportId, versionId, vulnerabilityId })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editReportVersionVulnerabilityActionRoute} />
                <EditReportVulnerabilityActionForm
                    actionId={actionId}
                    versionId={versionId}
                    onUpdate={() => setUpdated(true)}
                />
            </Container>
        </NavigationScreen>
    );
};
