import { FC } from "react";
import { FaFile, FaFileCode, FaFileCsv, FaFileDownload, FaFileExcel } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import { useDownloadFile } from "app/api/hook/use-download-file";

type Props = {
    versionId: number;
};

export const DownloadReportVersionVulnerabilitiesSpreadsheetButton: FC<Props> = ({ versionId }) => {
    const links = [
        {
            file: useDownloadFile(`/api/report_versions/${versionId}/vulnerabilities.xlsx`),
            title: "Download XLSX",
            icon: <FaFileExcel />,
        },
        {
            file: useDownloadFile(`/api/report_versions/${versionId}/vulnerabilities.xls`),
            title: "Download XLS",
            icon: <FaFileExcel />,
        },
        {
            file: useDownloadFile(`/api/report_versions/${versionId}/vulnerabilities.ods`),
            title: "Download ODS",
            icon: <FaFile />,
        },
        {
            file: useDownloadFile(`/api/report_versions/${versionId}/vulnerabilities.csv`),
            title: "Download CSV",
            icon: <FaFileCsv />,
        },
        {
            file: useDownloadFile(`/api/report_versions/${versionId}/vulnerabilities.html`),
            title: "Download HTML",
            icon: <FaFileCode />,
        },
    ];

    return (
        <Dropdown>
            <Dropdown.Toggle variant="info" id="dropdown-basic">
                <FaFileDownload /> Vulnerabilities
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {links.map((link) => (
                    <Dropdown.Item key={link.title} onClick={link.file.download} disabled={link.file.loading}>
                        {link.icon} {link.title}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
