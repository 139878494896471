import { FC } from "react";
import Container from "react-bootstrap/Container";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaPlus } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { createReportVersionVulnerabilityRoute, reportVersionVulnerabilitiesRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ReportVersionVulnerabilitiesTable } from "app/component/report-vulnerability/ReportVersionVulnerabilitiesTable";
import { useAuth } from "app/api/AuthContext";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { createReportVulnerabilityRole } from "app/api/graph/roles";
import { ButtonLink } from "app/component/button/ButtonLink";
import { FormValues } from "app/component/report-vulnerability/ReportVulnerabilitiesTableFiltersForm";

export type LocationState = {
    initialFilters: FormValues;
};

const isLocationState = (value: unknown): value is LocationState =>
    typeof value === "object" && value !== null && "initialFilters" in value;

export const ReportVersionVulnerabilitiesScreen: FC = () => {
    const { roles } = useAuth();
    const { reportId, versionId } = useRouteParams(reportVersionVulnerabilitiesRoute);
    const location = useLocation();

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={reportVersionVulnerabilitiesRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Vulnerabilities</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {roles?.includes(createReportVulnerabilityRole) ? (
                            <ButtonGroup>
                                <ButtonLink
                                    to={createReportVersionVulnerabilityRoute.build({ reportId, versionId })}
                                    variant="success"
                                >
                                    <FaPlus /> Create
                                </ButtonLink>
                            </ButtonGroup>
                        ) : null}
                    </ToolbarButtons>
                </Toolbar>
                <ReportVersionVulnerabilitiesTable
                    reportId={reportId}
                    versionId={versionId}
                    initialFilters={isLocationState(location.state) ? location.state.initialFilters : undefined}
                />
            </Container>
        </NavigationScreen>
    );
};
