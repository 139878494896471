import { FC, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { mapNodes } from "app/util/graph";
import { useVulnerabilityListQuery } from "app/api/graph/types";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { vulnerabilityRoute, deleteVulnerabilityRoute, editVulnerabilityRoute } from "app/route/Routes";
import { SortableTableHeader, SortDirection } from "app/component/table/SortableTableHeader";
import { deleteVulnerabilityRole, updateVulnerabilityRole } from "app/api/graph/roles";
import { formatISODateTime } from "app/util/format";
import { useAuth } from "app/api/AuthContext";
import {
    VulnerabilitiesTableFiltersForm,
    FormValues,
} from "app/component/vulnerability/VulnerabilitiesTableFiltersForm";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

export const VulnerabilitiesTable: FC = () => {
    const { roles } = useAuth();
    const [identifierOrder, setIdentifierOrder] = useState<SortDirection>("ASC");
    const [titleOrder, setTitleOrder] = useState<SortDirection>(undefined);
    const [createdAtOrder, setCreatedAtOrder] = useState<SortDirection>(undefined);
    const [filters, setFilters] = useState<FormValues>({
        identifier: undefined,
        title: undefined,
        testingTypes: undefined,
    });
    const { data, loading, error, fetchMore } = useVulnerabilityListQuery({
        variables: { order: { identifier: identifierOrder, title: titleOrder, createdAt: createdAtOrder }, ...filters },
        notifyOnNetworkStatusChange: true,
    });
    const vulnerabilities = data?.vulnerabilities;

    if (loading && !vulnerabilities) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!vulnerabilities) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const fetchMoreVulnerabilities = () => fetchMore({ variables: { after: vulnerabilities.pageInfo.endCursor } });
    const showActions = roles?.includes(updateVulnerabilityRole) || roles?.includes(deleteVulnerabilityRole);

    return (
        <Row>
            <Col>
                <Table responsive="sm" bordered className="crud-table">
                    <thead>
                        <tr>
                            <SortableTableHeader direction={identifierOrder} onChange={setIdentifierOrder}>
                                Identifier
                            </SortableTableHeader>
                            <SortableTableHeader direction={titleOrder} onChange={setTitleOrder}>
                                Title
                            </SortableTableHeader>
                            <SortableTableHeader direction={createdAtOrder} onChange={setCreatedAtOrder}>
                                Created at
                            </SortableTableHeader>
                            {showActions ? <th>Actions</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {mapNodes(vulnerabilities, (vulnerability) => (
                            <tr key={vulnerability.id}>
                                <td>
                                    <Link to={vulnerabilityRoute.build({ vulnerabilityId: vulnerability._id })}>
                                        {vulnerability.identifier}
                                    </Link>
                                </td>
                                <td>{vulnerability.title}</td>
                                <td>{formatISODateTime(vulnerability.createdAt)}</td>
                                {showActions ? (
                                    <td>
                                        {roles?.includes(updateVulnerabilityRole) ? (
                                            <ButtonGroup>
                                                <ButtonLink
                                                    to={editVulnerabilityRoute.build({
                                                        vulnerabilityId: vulnerability._id,
                                                    })}
                                                    variant="warning"
                                                    size="sm"
                                                    title="edit"
                                                >
                                                    <FaEdit />
                                                </ButtonLink>
                                            </ButtonGroup>
                                        ) : null}
                                        {roles?.includes(deleteVulnerabilityRole) ? (
                                            <ButtonGroup>
                                                <ButtonLink
                                                    to={deleteVulnerabilityRoute.build({
                                                        vulnerabilityId: vulnerability._id,
                                                    })}
                                                    variant="danger"
                                                    size="sm"
                                                    title="Delete"
                                                >
                                                    <FaTrash />
                                                </ButtonLink>
                                            </ButtonGroup>
                                        ) : null}
                                    </td>
                                ) : null}
                            </tr>
                        ))}
                        {vulnerabilities.edges?.length === 0 ? (
                            <tr>
                                <td colSpan={100}>No vulnerabilities.</td>
                            </tr>
                        ) : null}
                        {loading ? (
                            <tr>
                                <td colSpan={100}>
                                    <LoadingSpinner />
                                </td>
                            </tr>
                        ) : null}
                        {vulnerabilities.pageInfo.hasNextPage ? (
                            <tr>
                                <td colSpan={100}>
                                    <BlockButtonGroup>
                                        <Button variant="light" onClick={fetchMoreVulnerabilities}>
                                            Load more
                                        </Button>
                                    </BlockButtonGroup>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </Table>
            </Col>
            <Col md={3}>
                <VulnerabilitiesTableFiltersForm initialValues={filters} onSubmit={setFilters} />
            </Col>
        </Row>
    );
};
