import { FC } from "react";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { FaMoon, FaSun } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import logo from "app/asset/image/istorm_logo.png";
import { useAuth } from "app/api/AuthContext";
import {
    companiesRoute,
    contactsRoute,
    reportsRoute,
    reportVulnerabilitiesOverviewRoute,
    userChangePasswordRoute,
    userSettingsRoute,
    usersRoute,
    vulnerabilitiesRoute,
} from "app/route/Routes";
import {
    listContactsRole,
    listReportVulnerabilitiesRole,
    listUsersRole,
    listVulnerabilitiesRole,
} from "app/api/graph/roles";
import { Theme, useTheme } from "app/context/ThemeContext";
import { UserNotificationsIcon } from "app/component/user-notification/UserNotificationsIcon";

export const Navigation: FC = () => {
    const { id, isSignedIn, username, signOut, roles } = useAuth();
    const { theme, setTheme } = useTheme();

    return isSignedIn && id ? (
        <Navbar expand="md" variant={theme === Theme.Dark ? "dark" : "light"} className="d-print-none">
            <Container fluid>
                <Navbar.Brand as={Link} to="/">
                    <img src={logo} alt="iSTORM®" height={25} className="d-inline-block align-top" />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navigation-navbar-collapse" className="ms-auto" />
                <Navbar.Collapse id="navigation-navbar-collapse">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to={reportsRoute.build({})}>
                            Reports
                        </Nav.Link>
                        <Nav.Link as={Link} to={companiesRoute.build({})}>
                            Companies
                        </Nav.Link>
                        {roles?.includes(listUsersRole) ? (
                            <Nav.Link as={Link} to={usersRoute.build({})}>
                                Users
                            </Nav.Link>
                        ) : null}
                        {roles?.includes(listVulnerabilitiesRole) ? (
                            <Nav.Link as={Link} to={vulnerabilitiesRoute.build({})}>
                                Vulnerabilities
                            </Nav.Link>
                        ) : null}
                        {roles?.includes(listContactsRole) ? (
                            <Nav.Link as={Link} to={contactsRoute.build({})}>
                                Contacts
                            </Nav.Link>
                        ) : null}
                        {roles?.includes(listReportVulnerabilitiesRole) ? (
                            <Nav.Link as={Link} to={reportVulnerabilitiesOverviewRoute.build({})}>
                                Report vulnerabilities overview
                            </Nav.Link>
                        ) : null}
                    </Nav>
                    <Nav className="align-items-sm-center">
                        <NavDropdown title={username ?? "Account"} id="navigation-navbar-account-dropdown">
                            <NavDropdown.Item as={Link} to={userSettingsRoute.build({ userId: id })}>
                                Settings
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={userChangePasswordRoute.build({ userId: id })}>
                                Change password
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => setTheme(theme === Theme.Default ? Theme.Dark : Theme.Default)}
                            >
                                <Form.Check
                                    readOnly
                                    type="switch"
                                    id="theme-toggle"
                                    label={
                                        <div style={{ lineHeight: "19px" }}>
                                            {theme === Theme.Dark ? <FaSun /> : <FaMoon />}{" "}
                                        </div>
                                    }
                                    checked={theme === Theme.Dark}
                                />
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={signOut}>Sign out</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                <UserNotificationsIcon />
            </Container>
        </Navbar>
    ) : null;
};
