import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Navigate } from "react-router-dom";
import yup from "app/util/yup";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { addReportUserFromCompanyRoute, reportUsersRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { ReportRole, useAddCompanyUserToReportMutation, useReportOverviewQuery } from "app/api/graph/types";
import { reportGraphId } from "app/api/graph/helpers";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";
import { FormControlSelectReportRole } from "app/component/form/control/FormControlSelectReportRole";
import { SelectCompanyUser } from "app/component/form/select/SelectCompanyUser.tsx";
import { LoadingScreen } from "app/component/screen/LoadingScreen.tsx";
import { ErrorScreen } from "app/component/screen/ErrorScreen.tsx";

type FormValues = {
    companyUser?: string;
    role: ReportRole;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    companyUser: yup.string().required(),
    role: yup.mixed<ReportRole>().oneOf(Object.values(ReportRole)).required(),
});

const initialValues: FormValues = {
    companyUser: undefined,
    role: ReportRole.Standard,
};

export const AddReportUserFromCompanyScreen: FC = () => {
    const { reportId } = useRouteParams(addReportUserFromCompanyRoute);
    const [addCompanyUser, { data }] = useAddCompanyUserToReportMutation({});

    const {
        data: reportData,
        error: reportError,
        loading: loadingReport,
    } = useReportOverviewQuery({ variables: { reportId: reportGraphId(reportId) } });
    const report = reportData?.report;
    const company = report?.company;

    if (data?.addCompanyUserToReport) {
        return <Navigate to={reportUsersRoute.build({ reportId })} />;
    }

    if (loadingReport) {
        return <LoadingScreen />;
    }
    if (reportError) {
        return <ErrorScreen title="Error" message={reportError.message} />;
    }
    if (!report || !company) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        addCompanyUser({
            variables: {
                reportId: reportGraphId(reportId),
                companyUserId: values.companyUser ?? "",
                role: values.role,
            },
        })
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={addReportUserFromCompanyRoute} />
                <h1>Add company user</h1>
                <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
                    {({
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        isSubmitting,
                        errors,
                        touched,
                        status,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <StatusAlert status={status as unknown} />
                            <Form.Group className="mb-3" controlId="select-user">
                                <SelectCompanyUser
                                    companyId={company.id}
                                    id="select-user"
                                    placeholder="Select a company user..."
                                    value={values.companyUser ?? null}
                                    onChange={(value) => setFieldValue("companyUser", value)}
                                    onBlur={() => setFieldTouched("companyUser")}
                                    isInvalid={touched.companyUser && !!errors.companyUser}
                                />
                                <Form.Control.Feedback type="invalid">{errors.companyUser}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Role</Form.Label>
                                <FormControlSelectReportRole
                                    name="role"
                                    value={values.role}
                                    onChange={(value) => setFieldValue("role", value)}
                                    onBlur={() => setFieldTouched("role")}
                                    isInvalid={touched.role && !!errors.role}
                                />
                                <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>
                            </Form.Group>
                            <Button type="submit" disabled={isSubmitting}>
                                Submit
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </NavigationScreen>
    );
};
