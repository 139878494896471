import { FC, useMemo } from "react";
import Alert from "react-bootstrap/Alert";
import { parseISO, formatISO } from "date-fns";
import {
    useReportVulnerabilityQuery,
    useUpdateReportVulnerabilityMutation,
    VulnerabilitySeverity,
} from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import { FormValues, ReportVulnerabilityForm } from "app/component/report-vulnerability/ReportVulnerabilityForm";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { mapNodes } from "app/util/graph";

type Props = {
    vulnerabilityId: number;
    versionId: number;
    onUpdate?: () => void;
};

export const EditReportVulnerabilityForm: FC<Props> = ({ vulnerabilityId, versionId, onUpdate }) => {
    const { data, loading, error } = useReportVulnerabilityQuery({
        variables: { vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId) },
    });
    const vulnerability = data?.reportVulnerability;
    const [updateVulnerability, { error: updateError }] = useUpdateReportVulnerabilityMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            baseVulnerability: vulnerability?.baseVulnerability?.id ?? null,
            title: vulnerability?.title ?? "",
            severity: vulnerability?.severity ?? VulnerabilitySeverity.Medium,
            testingTypes: mapNodes(vulnerability?.testingTypes, (item) => item.id) ?? [],
            dateDiscovered: vulnerability?.dateDiscovered ? parseISO(vulnerability?.dateDiscovered) : null,
            numberOfIssues: vulnerability?.numberOfIssues ?? "",
            cvssScore: vulnerability?.cvssScore ?? undefined,
            cvssVector: vulnerability?.cvssVector ?? undefined,
            cveScore: vulnerability?.cveScore ?? undefined,
            cwe: vulnerability?.cwe ?? undefined,
            owaspCategory: vulnerability?.owaspCategory ?? undefined,
            affectedHosts: vulnerability?.affectedHosts ?? undefined,
            issueDetail: vulnerability?.issueDetail ?? undefined,
            advice: vulnerability?.advice ?? undefined,
        }),
        [vulnerability],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!vulnerability) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = (values: FormValues) =>
        updateVulnerability({
            variables: {
                input: {
                    id: vulnerability.id,
                    ...values,
                    dateDiscovered: values.dateDiscovered ? formatISO(values.dateDiscovered) : "",
                },
            },
        }).then(() => onUpdate?.());

    return (
        <ReportVulnerabilityForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            error={updateError}
            versionId={versionId}
        />
    );
};
