import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import yup from "app/util/yup";
import { RichTextEditor } from "app/component/form/text-editor/RichTextEditor";
import {
    ReportVulnerabilityCommentCursorConnection,
    useCreateReportVulnerabilityCommentMutation,
} from "app/api/graph/types";
import { StatusAlert } from "app/component/form/StatusAlert";
import { handleError, handleSuccess } from "app/api/graph/form";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";

type Props = {
    vulnerabilityId: number;
};

type FormValues = {
    message: string;
    emailParticipants: boolean;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    message: yup.string().required(),
    emailParticipants: yup.boolean().required(),
});

const initialValues: FormValues = {
    message: "",
    emailParticipants: false,
};

export const CreateReportVulnerabilityCommentForm: FC<Props> = ({ vulnerabilityId }) => {
    const [createComment] = useCreateReportVulnerabilityCommentMutation({
        update: (cache, { data }) => {
            const node = data?.createReportVulnerabilityComment?.reportVulnerabilityComment;
            if (node) {
                cache.modify({
                    id: cache.identify({
                        __typename: "ReportVulnerability",
                        id: reportVulnerabilityGraphId(vulnerabilityId),
                    }),
                    fields: {
                        comments: (cached: ReportVulnerabilityCommentCursorConnection) => ({
                            ...cached,
                            edges: [{ __typename: "ReportVulnerabilityCommentEdge", node }, ...(cached.edges ?? [])],
                        }),
                    },
                });
            }
        },
    });

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        createComment({
            variables: { input: { reportVulnerability: reportVulnerabilityGraphId(vulnerabilityId), ...values } },
        })
            .then(() => handleSuccess(actions))
            .then(() => actions.resetForm())
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                status,
                isSubmitting,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <RichTextEditor
                            value={values.message}
                            onChange={(value) => setFieldValue("message", value)}
                            onBlur={() => setFieldTouched("message")}
                            isInvalid={touched.message && !!errors.message}
                        />
                        <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check
                            id="email-participants"
                            type="checkbox"
                            name="emailParticipants"
                            label="Email participants"
                            checked={values.emailParticipants ?? false}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.emailParticipants && !!errors.emailParticipants}
                            feedback={errors.emailParticipants}
                        />
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
