import { ApolloQueryResult } from "@apollo/client";
import { useEffect } from "react";
import { Maybe } from "app/api/graph/types";

type PageInfo = { hasNextPage: boolean; endCursor?: Maybe<string> };
type FetchMore = (options: { variables: { after: string } }) => Promise<ApolloQueryResult<unknown>>;
type Options = { loading: boolean; pageInfo: PageInfo | undefined; fetchMore: FetchMore };

export const useFetchAll = ({ loading, pageInfo, fetchMore }: Options): void => {
    useEffect(() => {
        if (!loading && pageInfo?.hasNextPage && pageInfo.endCursor) {
            fetchMore({ variables: { after: pageInfo?.endCursor } }).catch(console.error);
        }
    }, [pageInfo, loading]);
};
