export const notEmpty = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined;

export const notEmptyString = (value: string | null | undefined): boolean =>
    value !== null && value !== undefined && value.length > 0;

export const transpose = <T>(array: Array<Array<T>>): Array<Array<T>> =>
    array[0].map((_, colIndex) => array.map((row) => row[colIndex]));

export const range = (start: number, end: number): Array<number> =>
    Array.from(Array(1 + end - start).keys()).map((value) => start + value);
