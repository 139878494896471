import { FC } from "react";
import { ReportConsultantFieldsFragment } from "app/api/graph/types";
import { DetailsTable } from "app/component/report-version/component/part/content/DetailsTable";

type Props = { consultant: ReportConsultantFieldsFragment };

export const ConsultantDetails: FC<Props> = ({ consultant }) => (
    <DetailsTable
        details={[
            ["Consultant name", consultant?.contact?.name],
            ["Consultant position", consultant?.contact?.position],
            ["Consultant email", consultant?.contact?.email],
            ["Consultant mobile", consultant?.contact?.mobile],
            ["Consultant address", consultant?.contact?.address],
            ["Consultant phone", consultant?.contact?.phone],
            ["Consultant Qualifications", consultant?.qualifications],
        ]}
    />
);
