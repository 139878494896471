import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import { formatISO } from "date-fns";
import { ReportVulnerabilityFieldsFragment, usePublishedCompanyReportVulnerabilitiesQuery } from "app/api/graph/types";
import { companyGraphId } from "app/api/graph/helpers";
import { mapNodes } from "app/util/graph";
import { useFetchAll } from "app/api/graph/hook/use-fetch-all";

type Result = {
    vulnerabilities?: ReportVulnerabilityFieldsFragment[];
    loading: boolean;
    error?: ApolloError;
};

type Filters = {
    dateDiscoveredFrom?: Date;
    dateDiscoveredTo?: Date;
    title?: string;
    cvssScore?: string;
    testingTypes?: string[];
};

export const usePublishedCompanyReportVulnerabilities = (companyId: number, filters?: Filters): Result => {
    const { data, loading, error, fetchMore } = usePublishedCompanyReportVulnerabilitiesQuery({
        variables: {
            companyId: companyGraphId(companyId),
            ...(filters && {
                title: filters.title,
                dateDiscovered: {
                    after: filters.dateDiscoveredFrom ? formatISO(filters.dateDiscoveredFrom) : undefined,
                    before: filters.dateDiscoveredTo ? formatISO(filters.dateDiscoveredTo) : undefined,
                },
                cvssScore: filters.cvssScore,
                testingTypes: filters.testingTypes,
            }),
        },
        notifyOnNetworkStatusChange: true,
    });
    const vulnerabilities = data?.publishedCompanyReportVulnerabilities;
    const pageInfo = data?.publishedCompanyReportVulnerabilities?.pageInfo;
    useFetchAll({ loading, pageInfo, fetchMore });

    return useMemo(
        () => ({ vulnerabilities: mapNodes(vulnerabilities, (n) => n), loading, error }),
        [vulnerabilities, loading, error],
    );
};
