import { FC } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { deleteReportInviteRoute, reportInvitesRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { useDeleteReportInviteMutation, useReportInviteQuery } from "app/api/graph/types";
import { reportInviteGraphId } from "app/api/graph/helpers";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { formatISODateTime } from "app/util/format";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ConfirmDelete } from "app/component/form/ConfirmDelete";

export const DeleteReportInviteScreen: FC = () => {
    const { reportId, inviteId } = useRouteParams(deleteReportInviteRoute);
    const { data, loading, error } = useReportInviteQuery({
        variables: { inviteId: reportInviteGraphId(inviteId) },
    });
    const [deleteInvite, { data: deleteData, loading: deleting, error: deleteError }] = useDeleteReportInviteMutation({
        variables: { inviteId: reportInviteGraphId(inviteId) },
    });
    const invite = data?.reportInvite;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!invite) {
        return <ErrorScreen title="Error" message="Not found." />;
    }
    if (deleteData?.deleteReportInvite) {
        return <Navigate to={reportInvitesRoute.build({ reportId })} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={deleteReportInviteRoute} />
                <h1>Delete invite</h1>
                {deleteError ? <Alert variant="danger">{deleteError.message}</Alert> : null}
                <p>Are you sure that you want to permanently delete this invite?</p>
                <Table responsive="sm">
                    <tbody>
                        <tr>
                            <th>First name</th>
                            <td>{invite.firstName ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Last name</th>
                            <td>{invite.lastName ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{invite.email ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Invited at</th>
                            <td>{formatISODateTime(invite.createdAt)}</td>
                        </tr>
                    </tbody>
                </Table>
                {invite.accepted ? (
                    <p>Deleting the invite will not delete the user that has already accepted it.</p>
                ) : null}
                <ConfirmDelete onConfirm={() => deleteInvite()} deleting={deleting} />
            </Container>
        </NavigationScreen>
    );
};
