import { FC } from "react";
import Container from "react-bootstrap/Container";
import { FaUserPlus, FaUsers } from "react-icons/fa";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import {
    addReportUserRoute,
    reportInvitesRoute,
    reportUsersRoute,
    createReportInviteRoute,
    addReportUserFromCompanyRoute,
} from "app/route/Routes";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ButtonLink } from "app/component/button/ButtonLink";
import { ReportUsersTable } from "app/component/report/ReportUsersTable.tsx";
import { useReportPermissions } from "app/api/graph/hook/use-report-permissions.ts";
import { useRouteParams } from "app/route/route.ts";
import { reportGraphId } from "app/api/graph/helpers.ts";
import { useReportOverviewQuery } from "app/api/graph/types.ts";
import { LoadingScreen } from "app/component/screen/LoadingScreen.tsx";
import { ErrorScreen } from "app/component/screen/ErrorScreen.tsx";

export const ReportUsersListScreen: FC = () => {
    const { reportId } = useRouteParams(reportUsersRoute);
    const { data, error, loading } = useReportOverviewQuery({ variables: { reportId: reportGraphId(reportId) } });
    const report = data?.report;
    const { canAddUser, canAddUserFromCompany, canListUserInvites, canCreateUserInvite } = useReportPermissions(report);

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!report) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={reportUsersRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Report users</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        <ButtonGroup>
                            {canListUserInvites ? (
                                <ButtonLink variant="info" to={reportInvitesRoute.build({ reportId })}>
                                    <FaUsers /> Invites
                                </ButtonLink>
                            ) : null}
                            {canCreateUserInvite ? (
                                <ButtonLink variant="success" to={createReportInviteRoute.build({ reportId })}>
                                    <FaUserPlus /> Invite
                                </ButtonLink>
                            ) : null}
                        </ButtonGroup>
                        <ButtonGroup className="ms-2">
                            {canAddUser ? (
                                <ButtonLink variant="success" to={addReportUserRoute.build({ reportId })}>
                                    <FaUserPlus /> Add
                                </ButtonLink>
                            ) : null}
                            {canAddUserFromCompany ? (
                                <ButtonLink variant="success" to={addReportUserFromCompanyRoute.build({ reportId })}>
                                    <FaUserPlus /> Add from company
                                </ButtonLink>
                            ) : null}
                        </ButtonGroup>
                    </ToolbarButtons>
                </Toolbar>
                <ReportUsersTable reportId={reportId} />
            </Container>
        </NavigationScreen>
    );
};
