import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { editReportVersionRoute, editReportVersionTestRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { EditReportVersionTestForm } from "app/component/report-version/EditReportVersionTestForm";

export const EditReportVersionTestScreen: FC = () => {
    const { reportId, versionId } = useRouteParams(editReportVersionTestRoute);
    const [updated, setUpdated] = useState(false);

    return updated ? (
        <Navigate to={editReportVersionRoute.build({ reportId, versionId })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editReportVersionTestRoute} />
                <EditReportVersionTestForm versionId={versionId} onUpdate={() => setUpdated(true)} />
            </Container>
        </NavigationScreen>
    );
};
