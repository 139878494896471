import { FC } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

export type Option = {
    label: string;
    value: number | string;
};

export type SelectProps = {
    onBlur?: () => void;
    isValid?: boolean;
    isInvalid?: boolean;
    disabled?: boolean;
    id: string;
    placeholder?: string;
};

type Props = SelectProps & {
    options: Option[];
    value: Option[];
    onChange: (options: Option[]) => void;
};

export const MultiSelectChoice: FC<Props> = ({
    options,
    value,
    onChange,
    onBlur,
    isValid,
    isInvalid,
    disabled,
    id,
    placeholder,
}) => {
    return (
        <Typeahead
            options={options}
            selected={value}
            onChange={onChange}
            onBlur={() => onBlur?.()}
            isValid={isValid}
            isInvalid={isInvalid}
            disabled={disabled}
            labelKey={"label"}
            id={id}
            clearButton={true}
            placeholder={placeholder}
            multiple={true}
        />
    );
};
