import { FC, InputHTMLAttributes } from "react";
import { FormControlProps } from "react-bootstrap/FormControl";
import { FormControlSelectChoice } from "app/component/form/control/FormControlSelectChoice";
import { VulnerabilitySeverity } from "app/api/graph/types";
import { vulnerabilitySeverityLabel } from "app/api/graph/strings";

type Props = Omit<InputHTMLAttributes<HTMLSelectElement> & FormControlProps, "type" | "value" | "onChange"> & {
    value: VulnerabilitySeverity | undefined;
    onChange: (value: VulnerabilitySeverity) => void;
};

const options = [
    VulnerabilitySeverity.Critical,
    VulnerabilitySeverity.High,
    VulnerabilitySeverity.Medium,
    VulnerabilitySeverity.Low,
    VulnerabilitySeverity.Informational,
].map((value) => ({
    label: vulnerabilitySeverityLabel(value),
    value: value,
}));

export const FormControlSelectVulnerabilitySeverity: FC<Props> = ({ value, onChange, ...props }) => (
    <FormControlSelectChoice {...props} options={options} value={value} onChange={onChange} />
);
