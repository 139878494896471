import { FC, useMemo } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { FaInfoCircle } from "react-icons/fa";
import { Formik, FormikHelpers } from "formik";
import yup from "app/util/yup";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { editUserRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { useUpdateUserMutation, useUserQuery } from "app/api/graph/types";
import { userGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { SelectRoles } from "app/component/form/select/SelectRoles";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";

type FormValues = {
    roles: string[];
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    roles: yup.array().of(yup.string().required()).required(),
});

export const EditUserScreen: FC = () => {
    const { userId } = useRouteParams(editUserRoute);
    const { data, loading, error } = useUserQuery({ variables: { userId: userGraphId(userId) } });
    const [updateUser] = useUpdateUserMutation();
    const user = data?.user;

    const initialValues = useMemo(
        () => ({
            roles: user?.roles ?? [],
        }),
        [user],
    );

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        updateUser({ variables: { input: { id: userGraphId(userId), ...values } } })
            .then(() => handleSuccess(actions, "User updated"))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!user) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editUserRoute} />
                <h1>{user.fullName}</h1>
                <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
                    {({
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        status,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <StatusAlert status={status as unknown} />
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col>
                                        <Form.Label>Roles</Form.Label>
                                        <SelectRoles
                                            id="select-roles"
                                            placeholder="Roles"
                                            roles={values.roles}
                                            onChange={(roles) => setFieldValue("roles", roles)}
                                            onBlur={() => setFieldTouched("roles")}
                                            isInvalid={touched.roles && !!errors.roles}
                                        />
                                        <Form.Text className="text-muted">
                                            Role changes won&lsquo;t take effect until the next time the user logs in.
                                        </Form.Text>
                                        <Form.Control.Feedback type="invalid">{errors.roles}</Form.Control.Feedback>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title className="mb-3">
                                                    <FaInfoCircle /> Roles
                                                </Card.Title>
                                                <Card.Subtitle>Tester</Card.Subtitle>
                                                <ul>
                                                    <li>Report invites: list</li>
                                                    <li>Report vulnerabilities: list, create, update, delete</li>
                                                    <li>Report vulnerability actions: list, create, update, delete</li>
                                                    <li>Master vulnerabilities: list, create, update, delete</li>
                                                    <li>Testing types: list, create, update, delete</li>
                                                </ul>
                                                <Card.Subtitle>Consultant</Card.Subtitle>
                                                <ul>
                                                    <li>Companies: list, create, update</li>
                                                    <li>Company invites: list, create, delete</li>
                                                    <li>Reports: list, create, update</li>
                                                    <li>Report invites: list, create, delete</li>
                                                    <li>Users: list</li>
                                                    <li>Report vulnerabilities: list, create, update, delete</li>
                                                    <li>Report vulnerability actions: list, create, update, delete</li>
                                                    <li>Master vulnerabilities: list, create, update, delete</li>
                                                    <li>Contacts: list, create, update, delete</li>
                                                    <li>Testing types: list, create, update, delete</li>
                                                </ul>
                                                <Card.Subtitle>Super admin</Card.Subtitle>
                                                <ul>
                                                    <li>All permissions</li>
                                                </ul>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Button type="submit" disabled={isSubmitting}>
                                Submit
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Container>
        </NavigationScreen>
    );
};
