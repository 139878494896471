import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ApolloError } from "@apollo/client";
import yup from "app/util/yup";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";
import { SelectCompany } from "app/component/form/select/SelectCompany";

type Props = {
    initialValues: FormValues;
    onSubmit: (values: FormValues) => Promise<void>;
    error?: ApolloError;
};

export type FormValues = {
    title: string;
    company: string | null | undefined;
};

export const schema: yup.ObjectSchema<FormValues> = yup.object({
    title: yup.string().required(),
    company: yup.string().nullable(),
});

export const ReportForm: FC<Props> = (props) => {
    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        props
            .onSubmit(values)
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={props.initialValues}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                values,
                isSubmitting,
                errors,
                touched,
                status,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            placeholder="Title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="select-company">
                        <Form.Label>Company</Form.Label>
                        <SelectCompany
                            id="select-company"
                            placeholder="Select a company..."
                            value={values.company ?? null}
                            onChange={async (value) => {
                                await setFieldValue("company", value);
                                // Clear system as it may not belong to the newly selected company
                                await setFieldValue("system", undefined);
                            }}
                            onBlur={() => setFieldTouched("company")}
                            isInvalid={touched.company && !!errors.company}
                        />
                        <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
