import { FC } from "react";
import Container from "react-bootstrap/Container";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { UsersTable } from "app/component/user/UsersTable";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { usersRoute } from "app/route/Routes";
import { useAuth } from "app/api/AuthContext.tsx";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar.tsx";
import { viewUserSecurityEventsRole } from "app/api/graph/roles.ts";
import { DownloadUserSecurityEventUserSummaryButton } from "app/component/user-security-event/DownloadUserSecurityEventUserSummaryButton.tsx";

export const UsersScreen: FC = () => {
    const { roles } = useAuth();
    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={usersRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Users</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {roles?.includes(viewUserSecurityEventsRole) ? (
                            <DownloadUserSecurityEventUserSummaryButton />
                        ) : null}
                    </ToolbarButtons>
                </Toolbar>
                <UsersTable />
            </Container>
        </NavigationScreen>
    );
};
