import { FC, useState } from "react";
import Button from "react-bootstrap/Button";
import { FaTrash } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";

type Props = {
    onConfirm: () => void;
    deleting: boolean;
};

export const ConfirmDelete: FC<Props> = ({ onConfirm, deleting }) => {
    const [value, setValue] = useState("");

    return (
        <Form>
            <InputGroup>
                <Button variant="danger" onClick={() => onConfirm()} disabled={value !== "DELETE" || deleting}>
                    <FaTrash /> Delete
                </Button>
                <Form.Control value={value} onChange={(e) => setValue(e.target.value)} placeholder="Type 'DELETE'" />
            </InputGroup>
        </Form>
    );
};
