/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-object-type */

import { compile } from "path-to-regexp";
import { useLocation, useParams } from "react-router-dom";
import { withoutUndefinedValues } from "app/util/object";

type Parser<T> = (s: string) => T;
type ParserMap<K extends string = string> = Record<K, Parser<any>>;
export type TypedParams<P extends ParserMap> = {
    [K in keyof P]: ReturnType<P[K]>;
};
export type StringParams<P extends ParserMap> = {
    [K in keyof P]: string;
};
type ParsedParams = Record<string, unknown>;

export type Route<
    FullParams extends ParserMap = {},
    ParentParams extends ParserMap = {},
    QueryParams extends ParserMap = {},
> = {
    path: string;
    template: string;
    name: string;
    parent?: Route<ParentParams>;
    build: (params: TypedParams<FullParams>, queryParams?: TypedParams<QueryParams>) => string;
    parseParams: (params: StringParams<FullParams>) => TypedParams<FullParams>;
    parseQueryParams: (params: URLSearchParams) => TypedParams<QueryParams>;
};

export const intParser: Parser<number> = (param) => parseInt(param);
export const stringParser: Parser<string> = (param) => param;

type StandardEnum<T extends string, TEnumValue extends string> = { [key in T]: TEnumValue };
export const enumParser = <T extends string, TEnumValue extends string>(
    enumVariable: StandardEnum<T, TEnumValue>,
): Parser<TEnumValue | undefined> => {
    const enumValues = Object.values(enumVariable);
    return (value: string) => {
        if (!enumValues.includes(value)) {
            return undefined;
        }
        const key = Object.keys(enumVariable).find(
            (k) => enumVariable[k as keyof StandardEnum<T, TEnumValue>] === value,
        ) as keyof StandardEnum<T, TEnumValue>;
        return key ? enumVariable[key] : undefined;
    };
};

const parseParams = (
    params: Record<string, string>,
    parsers: Record<string, Parser<any>>,
    parentParams: ParsedParams = {},
): ParsedParams =>
    Object.entries(parsers).reduce((result, [key, parser]) => {
        result[key] = parser(params[key]);
        return result;
    }, parentParams);

const convertToStringParams = <P extends ParserMap>(params: TypedParams<P>): StringParams<P> =>
    Object.fromEntries(Object.entries(params).map(([key, value]) => [key, String(value)])) as StringParams<P>;

export const route = <
    Params extends ParserMap = {},
    ParentParams extends ParserMap = {},
    QueryParams extends ParserMap = {},
>(
    path: string,
    name: string,
    parsers?: Params,
    parent?: Route<ParentParams>,
    queryParsers?: QueryParams,
): Route<Params & ParentParams, ParentParams, QueryParams> => {
    const template = (parent?.template ?? "") + path;
    const build = compile(template);

    return {
        path,
        template,
        name,
        parent,
        build: (params, queryParams) => {
            const url = build(convertToStringParams(params ?? {}));
            const urlParams = new URLSearchParams(withoutUndefinedValues(queryParams));
            urlParams.forEach((v, k) => {
                if (v === undefined) {
                    urlParams.delete(k);
                }
            });
            return Array.from(urlParams).length === 0 ? url : url + "?" + urlParams.toString();
        },
        parseParams: (params) =>
            (parsers !== undefined
                ? parseParams(params, parsers, parent?.parseParams(params))
                : {}) as unknown as TypedParams<Params & ParentParams>,
        parseQueryParams: (params: URLSearchParams) =>
            (queryParsers !== undefined
                ? parseParams(Object.fromEntries(params), queryParsers)
                : {}) as unknown as TypedParams<QueryParams>,
    };
};

export const useRouteParams = <Params extends ParserMap, ParentParams extends ParserMap, QueryParams extends ParserMap>(
    route: Route<Params, ParentParams, QueryParams>,
): TypedParams<Params> => {
    const params = useParams();
    return route.parseParams(params as unknown as StringParams<Params>);
};

export const useQueryParams = <Params extends ParserMap, ParentParams extends ParserMap, QueryParams extends ParserMap>(
    route: Route<Params, ParentParams, QueryParams>,
): TypedParams<QueryParams> => {
    const params = new URLSearchParams(useLocation().search);
    return route.parseQueryParams(params);
};
