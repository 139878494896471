import { FC } from "react";
import { FaEnvelope, FaMobile, FaPhone } from "react-icons/fa";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { MfaMethod } from "app/api/AuthContext";

type Props = {
    value: string;
    remember: boolean;
    supportedMethods: MfaMethod[];
    selectedMethod: string | undefined;
    challenged: boolean;
    onSelectedMethodChange: (method: string) => void;
    onChange: (value: string) => void;
    onRememberChange: (value: boolean) => void;
    onBlur?: () => void;
    isInvalid?: boolean;
    name?: string;
};

type SupportedMethod = {
    icon: JSX.Element;
    challengedMessage: string;
};

const supportedMethods: Record<string, SupportedMethod> = {
    call: {
        icon: <FaPhone />,
        challengedMessage: "We'll call you now.",
    },
    email: {
        icon: <FaEnvelope />,
        challengedMessage: "We've sent you an email.",
    },
    sms: {
        icon: <FaMobile />,
        challengedMessage: "We've sent you an SMS.",
    },
};

export const MfaChallengeInput: FC<Props> = (props) => {
    if (props.supportedMethods.length === 0) {
        return (
            <Alert variant="danger">
                Multi-factor authentication is required, but this account does not support any methods.
            </Alert>
        );
    }

    if (props.selectedMethod === undefined) {
        return (
            <>
                <Alert variant="info">Multi-factor authentication is required.</Alert>
                <p>Select how you want to verify:</p>
                <ListGroup>
                    {props.supportedMethods.map((method) => (
                        <ListGroup.Item key={method.id} onClick={() => props.onSelectedMethodChange(method.id)} action>
                            <span className="me-2">{supportedMethods[method.id]?.icon}</span>
                            {method.label}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </>
        );
    }

    const selectedMethod = supportedMethods[props.selectedMethod] ?? undefined;
    if (selectedMethod === undefined) {
        return <Alert variant="danger">The MFA method ({props.selectedMethod}) is currently not supported.</Alert>;
    }

    return (
        <>
            <Alert variant="info">
                {selectedMethod.icon} {selectedMethod.challengedMessage}
            </Alert>
            <p>Enter the code below:</p>
            <Form.Control
                name={props.name}
                type="string"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                onBlur={props.onBlur}
                isInvalid={props.isInvalid}
                placeholder="XXXXXX"
            />
            <Form.Check
                id="mfa-remember"
                className="mt-2"
                type="checkbox"
                label="Remember this device for 24 hours"
                checked={props.remember}
                onChange={(e) => props.onRememberChange(e.target.checked)}
            />
        </>
    );
};
