import { FC } from "react";
import { Accordion } from "react-bootstrap";
import { ReportVulnerabilityFieldsFragment } from "app/api/graph/types";
import { ReportVulnerabilityTimelineChart } from "app/component/report-vulnerability/component/ReportVulnerabilityTimelineChart";

type Props = {
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
    dateFrom?: Date;
    dateTo?: Date;
};

export const VulnerabilityTimelineCard: FC<Props> = ({ vulnerabilities, dateFrom, dateTo }) => (
    <Accordion className="mb-3" defaultActiveKey={vulnerabilities.length > 0 ? "0" : null}>
        <Accordion.Item eventKey="0">
            <Accordion.Button className="bg-light py-2">Open vulnerabilities timeline</Accordion.Button>
            <Accordion.Body>
                {vulnerabilities.length > 0 ? (
                    <ReportVulnerabilityTimelineChart
                        vulnerabilities={vulnerabilities}
                        style={{ height: "250px" }}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                    />
                ) : (
                    <p>No vulnerabilities reported.</p>
                )}
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
);
