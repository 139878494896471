import { FC, InputHTMLAttributes } from "react";
import { FormControlProps } from "react-bootstrap/FormControl";
import { FormControlSelectChoice } from "app/component/form/control/FormControlSelectChoice";
import { TestType } from "app/api/graph/types";
import { testTypeLabel } from "app/api/graph/strings";

type Props = Omit<InputHTMLAttributes<HTMLSelectElement> & FormControlProps, "type" | "value" | "onChange"> & {
    value: TestType | undefined;
    onChange: (value: TestType) => void;
};

const options = [
    TestType.WebApp,
    TestType.InternalInfrastructure,
    TestType.ExternalInfrastructure,
    TestType.Wifi,
    TestType.Phishing,
    TestType.RedTeamAssessment,
    TestType.InternalExternalInfra,
    TestType.AppInfraCloud,
    TestType.CloudAssessment,
    TestType.PenetrationTest,
    TestType.NotSpecified,
].map((value) => ({
    label: testTypeLabel(value),
    value: value,
}));

export const FormControlSelectTestType: FC<Props> = ({ value, onChange, ...props }) => (
    <FormControlSelectChoice {...props} options={options} value={value} onChange={onChange} />
);
