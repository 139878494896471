import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { EditReportVersionVersionForm } from "app/component/report-version/EditReportVersionVersionForm";
import { editReportVersionRoute, editReportVersionVersionRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";

export const EditReportVersionVersionScreen: FC = () => {
    const { reportId, versionId } = useRouteParams(editReportVersionVersionRoute);
    const [updated, setUpdated] = useState(false);

    return updated ? (
        <Navigate to={editReportVersionRoute.build({ reportId, versionId })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editReportVersionVersionRoute} />
                <EditReportVersionVersionForm versionId={versionId} onUpdate={() => setUpdated(true)} />
            </Container>
        </NavigationScreen>
    );
};
