import { FC } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaEdit, FaTrash } from "react-icons/fa";
import { mapNodes } from "app/util/graph";
import { useTestingTypeListQuery } from "app/api/graph/types";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { deleteTestingTypeRoute, editTestingTypeRoute } from "app/route/Routes";
import { deleteTestingTypeRole, updateTestingTypeRole } from "app/api/graph/roles";
import { useAuth } from "app/api/AuthContext";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

export const TestingTypesTable: FC = () => {
    const { roles } = useAuth();
    const { data, loading, error, fetchMore } = useTestingTypeListQuery({
        notifyOnNetworkStatusChange: true,
    });
    const testingTypes = data?.testingTypes;

    if (loading && !testingTypes) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!testingTypes) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const fetchMoreTestingType = () => fetchMore({ variables: { after: testingTypes.pageInfo.endCursor } });
    const showActions = roles?.includes(updateTestingTypeRole) || roles?.includes(deleteTestingTypeRole);

    return (
        <Table responsive="sm" bordered className="crud-table">
            <thead>
                <tr>
                    <th>Name</th>
                    {showActions ? <th>Actions</th> : null}
                </tr>
            </thead>
            <tbody>
                {mapNodes(testingTypes, (testingType) => (
                    <tr key={testingType.id}>
                        <td>{testingType.name}</td>
                        {showActions ? (
                            <td>
                                <ButtonGroup>
                                    <ButtonLink
                                        to={editTestingTypeRoute.build({
                                            testingTypeId: testingType._id,
                                        })}
                                        variant="warning"
                                        size="sm"
                                        title="Edit"
                                    >
                                        <FaEdit />
                                    </ButtonLink>
                                </ButtonGroup>
                                <ButtonGroup>
                                    <ButtonLink
                                        to={deleteTestingTypeRoute.build({
                                            testingTypeId: testingType._id,
                                        })}
                                        variant="danger"
                                        size="sm"
                                        title="Delete"
                                    >
                                        <FaTrash />
                                    </ButtonLink>
                                </ButtonGroup>
                            </td>
                        ) : null}
                    </tr>
                ))}
                {testingTypes.edges?.length === 0 ? (
                    <tr>
                        <td colSpan={100}>No testing types.</td>
                    </tr>
                ) : null}
                {loading ? (
                    <tr>
                        <td colSpan={100}>
                            <LoadingSpinner />
                        </td>
                    </tr>
                ) : null}
                {testingTypes.pageInfo.hasNextPage ? (
                    <tr>
                        <td colSpan={100}>
                            <BlockButtonGroup>
                                <Button variant="light" onClick={fetchMoreTestingType}>
                                    Load more
                                </Button>
                            </BlockButtonGroup>
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </Table>
    );
};
