import classNames from "classnames";
import { Component, ReactElement } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Maybe } from "app/api/graph/types";
import { mapNodes } from "app/util/graph";

type Model = { id?: string };

export interface SelectDataMultipleProps<T extends Model> {
    value: string[] | null;
    onChange: (value: string[] | null, option?: T[]) => void;
    onInputChange?: (value: string) => void;
    onBlur?: () => void;
    isValid?: boolean;
    isInvalid?: boolean;
    disabled?: boolean;
    id: string;
    placeholder?: string;
}

export interface Props<T extends Model> extends SelectDataMultipleProps<T> {
    connection?: Maybe<{
        pageInfo: {
            hasNextPage: boolean;
        };
        edges?: Maybe<Array<Maybe<{ node?: Maybe<T> }>>>;
    }>;
    label: (option: T) => string;
    onPaginate: () => void;
    isLoading?: boolean;
}

export class SelectDataMultiple<T extends Model> extends Component<Props<T>> {
    render(): ReactElement {
        const {
            connection,
            label,
            onPaginate,
            isLoading,
            value,
            onChange,
            onInputChange,
            onBlur,
            isValid,
            isInvalid,
            disabled,
            id,
            placeholder,
        } = this.props;
        const options = mapNodes(connection, (node) => node);
        return (
            <Typeahead
                className={classNames({ "is-valid": isValid, "is-invalid": isInvalid })}
                options={options}
                selected={options.filter((o) => o.id !== undefined && value?.includes(o.id))}
                onChange={(values: T[]) =>
                    onChange?.(
                        values.map((item) => item.id).filter((item): item is string => item !== undefined) ?? null,
                        values,
                    )
                }
                onInputChange={(value) => onInputChange?.(value)}
                onBlur={() => onBlur?.()}
                isLoading={isLoading}
                isValid={isValid}
                isInvalid={isInvalid}
                disabled={disabled}
                labelKey={label}
                id={id}
                paginate={connection?.pageInfo?.hasNextPage}
                maxResults={0} // Only used to trigger pagination, which already has a condition
                onPaginate={onPaginate}
                clearButton={true}
                placeholder={placeholder}
                multiple={true}
            />
        );
    }
}
