import { FC } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { FaUserMinus } from "react-icons/fa";
import { mapNodes } from "app/util/graph";
import { useReportUsersQuery } from "app/api/graph/types";
import { reportGraphId } from "app/api/graph/helpers";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { useAuth } from "app/api/AuthContext";
import { updateReportRole } from "app/api/graph/roles";
import { reportRoleLabel } from "app/api/graph/strings";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";
import { useCompanyPermissions } from "app/api/graph/hook/use-company-permissions.ts";
import { removeReportUserRoute } from "app/route/Routes.tsx";

type Props = {
    reportId: number;
};

export const ReportUsersTable: FC<Props> = ({ reportId }) => {
    const { roles } = useAuth();
    const { data, loading, error, fetchMore } = useReportUsersQuery({
        variables: { reportId: reportGraphId(reportId) },
        notifyOnNetworkStatusChange: true,
    });
    const reportUsers = data?.report?.viewers;
    const { canRemoveReportUser } = useCompanyPermissions(data?.report?.company?.id);

    if (loading && !reportUsers) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!reportUsers) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const fetchMoreUsers = () => fetchMore({ variables: { after: reportUsers.pageInfo.endCursor } });

    return (
        <Table responsive="sm" bordered className="crud-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Role</th>
                    {roles?.includes(updateReportRole) ? <th>Actions</th> : null}
                </tr>
            </thead>
            <tbody>
                {mapNodes(reportUsers, (reportUser) => (
                    <tr key={reportUser.id}>
                        <td>{reportUser.user.fullName}</td>
                        <td>{reportRoleLabel(reportUser.role)}</td>
                        {canRemoveReportUser ? (
                            <td>
                                {canRemoveReportUser && (
                                    <ButtonLink
                                        variant="danger"
                                        to={removeReportUserRoute.build({ reportId, userId: reportUser.user._id })}
                                        size="sm"
                                        title="Remove"
                                    >
                                        <FaUserMinus />
                                    </ButtonLink>
                                )}
                            </td>
                        ) : null}
                    </tr>
                ))}
                {reportUsers.edges?.length === 0 ? (
                    <tr>
                        <td colSpan={100}>No users.</td>
                    </tr>
                ) : null}
                {loading ? (
                    <tr>
                        <td colSpan={100}>
                            <LoadingSpinner />
                        </td>
                    </tr>
                ) : null}
                {reportUsers.pageInfo.hasNextPage ? (
                    <tr>
                        <td colSpan={100}>
                            <BlockButtonGroup>
                                <Button variant="light" onClick={fetchMoreUsers}>
                                    Load more
                                </Button>
                            </BlockButtonGroup>
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </Table>
    );
};
