import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import { TestingTypeFieldsFragment, useTestingTypesQuery } from "app/api/graph/types";
import { SelectDataMultiple, SelectDataMultipleProps } from "app/component/form/select/SelectDataMultiple";

type Props = SelectDataMultipleProps<TestingTypeFieldsFragment>;

export const SelectTestingTypes: FC<Props> = ({ ...props }) => {
    const { data, loading, fetchMore, error } = useTestingTypesQuery({
        notifyOnNetworkStatusChange: true,
    });

    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }

    return (
        <SelectDataMultiple
            {...props}
            connection={data?.testingTypes}
            label={(value) => value.name ?? ""}
            isLoading={loading}
            onPaginate={() => fetchMore({ variables: { after: data?.testingTypes?.pageInfo?.endCursor } })}
        />
    );
};
