import { useState } from "react";

type UseSet<T> = {
    items: T[];
    add: (item: T) => void;
    remove: (item: T) => void;
};

export const useSet = <T>(initialItems?: T[]): UseSet<T> => {
    const [items, setItems] = useState<T[]>(initialItems ?? []);

    return {
        items,
        add: (item: T) => setItems([...new Set([...items, item])]),
        remove: (item: T) => setItems(items.filter((i) => i !== item)),
    };
};
