import DOMPurify from "dompurify";
import { FC, HTMLProps, useMemo } from "react";

type Props = { html: string } & HTMLProps<HTMLDivElement>;

export const HtmlContent: FC<Props> = ({ html, ...props }) => {
    const safeHtml = useMemo(() => {
        DOMPurify.addHook("afterSanitizeAttributes", (node) => {
            if (node instanceof HTMLAnchorElement) {
                node.setAttribute("target", "_blank");
                node.setAttribute("rel", "noopener noreferrer");
            }
        });
        const safeHtml = DOMPurify.sanitize(html);
        DOMPurify.removeHook("afterSanitizeAttributes");
        return safeHtml;
    }, [html]);

    return <div {...props} dangerouslySetInnerHTML={{ __html: safeHtml }} />;
};
