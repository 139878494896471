import { FC } from "react";
import Container from "react-bootstrap/Container";
import { FaPlus } from "react-icons/fa";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { companyContactsRoute, createCompanyContactRoute } from "app/route/Routes";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { CompanyContactsTable } from "app/component/company-contact/CompanyContactsTable";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useRouteParams } from "app/route/route";
import { ButtonLink } from "app/component/button/ButtonLink";
import { useAuth } from "app/api/AuthContext.tsx";
import { updateCompanyRole } from "app/api/graph/roles.ts";

export const CompanyContactsListScreen: FC = () => {
    const { companyId } = useRouteParams(companyContactsRoute);
    const { roles } = useAuth();

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={companyContactsRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Company contacts</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {roles?.includes(updateCompanyRole) && (
                            <ButtonLink to={createCompanyContactRoute.build({ companyId })} variant="success">
                                <FaPlus /> New contact
                            </ButtonLink>
                        )}
                    </ToolbarButtons>
                </Toolbar>
                <CompanyContactsTable companyId={companyId} />
            </Container>
        </NavigationScreen>
    );
};
