import { FC, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import { CreateCompanyInviteFieldsFragment } from "app/api/graph/types";
import { acceptCompanyInviteRoute, createCompanyInviteRoute } from "app/route/Routes";
import { useRouteParams } from "app/route/route";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { CreateCompanyInviteForm } from "app/component/company-invite/CreateCompanyInviteForm";

const buildInviteUrl = (invite: CreateCompanyInviteFieldsFragment): string => {
    const url = new URL(acceptCompanyInviteRoute.build({ inviteId: invite._id }), document.baseURI);
    url.searchParams.append("token", invite.token);
    return url.href;
};

export const CreateCompanyInviteScreen: FC = () => {
    const { companyId } = useRouteParams(createCompanyInviteRoute);
    const [invite, setInvite] = useState<CreateCompanyInviteFieldsFragment | undefined>(undefined);
    const inviteUrl = invite ? buildInviteUrl(invite) : undefined;

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={createCompanyInviteRoute} />
                {inviteUrl ? (
                    <Alert variant="info">
                        <p>
                            If you entered an email address, an email will have been sent to the user. Alternatively,
                            you can share the invite URL below with the user:
                        </p>
                        <a href={inviteUrl}>{inviteUrl}</a>
                    </Alert>
                ) : (
                    <CreateCompanyInviteForm companyId={companyId} onCreate={setInvite} />
                )}
            </Container>
        </NavigationScreen>
    );
};
