import { styled } from "styled-components";
import { FC, useMemo } from "react";
import { Part } from "app/component/report-version/component/part/Part";

const Container = styled.div`
    page-break-inside: avoid;
`;

type Props = { part: Part; level?: number };

export const ReportPart: FC<Props> = ({ part, level = 1 }) => {
    const Heading = `h${level}` as keyof JSX.IntrinsicElements;
    const tocCode = useMemo(
        () =>
            btoa(
                JSON.stringify({
                    level,
                    title: part.title,
                    sectionNumber: part.sectionNumber,
                }),
            ),
        [part, level],
    );
    return (
        <Container id={part.sectionNumber}>
            <Heading>
                {part.sectionNumber} {part.title}
            </Heading>
            <div className="toc-code d-none d-print-block">###TOC:{tocCode}:TOC###</div>
            <div className={"mb-5"}>{part.content}</div>
            {part.children?.map((part) => <ReportPart key={part.id} part={part} level={level + 1} />)}
        </Container>
    );
};
