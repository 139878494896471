import { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { ApiProvider } from "app/api/ApiProvider";
import { ErrorBoundary } from "app/component/app/ErrorBoundary";
import { AppRoutes } from "app/route/Routes";

import { ScrollToTop } from "app/route/ScrollToTop";
import { ThemeProvider } from "app/context/ThemeContext";

export const App: FC = () => {
    return (
        <ErrorBoundary>
            <BrowserRouter>
                <ApiProvider>
                    <ThemeProvider>
                        <ScrollToTop />
                        <AppRoutes />
                    </ThemeProvider>
                </ApiProvider>
            </BrowserRouter>
        </ErrorBoundary>
    );
};
