import { set, formatISO } from "date-fns";
import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import yup from "app/util/yup";
import { FormControlDate } from "app/component/form/control/FormControlDate";
import { useResolveReportVulnerabilityMutation } from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import { StatusAlert } from "app/component/form/StatusAlert";
import { handleError, handleSuccess } from "app/api/graph/form";

type Props = {
    vulnerabilityId: number;
    onResolve?: () => void;
};

type FormValues = {
    dateResolved: Date | null;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    // TODO would be nice to have front-end validation to ensure this isn't before dateResolved
    dateResolved: yup.date().max(new Date()).required(),
});

const initialValues: FormValues = {
    dateResolved: set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
};

export const ResolveReportVulnerabilityForm: FC<Props> = ({ vulnerabilityId, onResolve }) => {
    const [resolve] = useResolveReportVulnerabilityMutation();

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        resolve({
            variables: {
                vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId),
                dateResolved: values.dateResolved ? formatISO(values.dateResolved, { representation: "date" }) : "",
            },
        })
            .then(() => onResolve?.())
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, handleBlur, setFieldValue, values, isSubmitting, errors, touched, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Alert variant="warning">Do you really want to resolve this vulnerability?</Alert>
                    <Form.Group className="mb-3">
                        <Form.Label>Date resolved</Form.Label>
                        <FormControlDate
                            name="dateResolved"
                            value={values.dateResolved}
                            onChange={(value) => setFieldValue("dateResolved", value)}
                            onBlur={handleBlur}
                            isInvalid={touched.dateResolved && !!errors.dateResolved}
                        />
                        <Form.Control.Feedback type="invalid">{errors.dateResolved}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Resolve
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
