import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaFilter } from "react-icons/fa";
import yup from "app/util/yup";
import { StatusAlert } from "app/component/form/StatusAlert";
import { FormControlSelectVulnerabilitySeverity } from "app/component/form/control/FormControlSelectVulnerabilitySeverity";
import { ReportVulnerabilityStatus, VulnerabilitySeverity } from "app/api/graph/types";
import { FormControlSelectReportVulnerabilityStatus } from "app/component/form/control/FormControlSelectReportVulnerabilityStatus";
import { SelectTestingTypes } from "app/component/form/select/SelectTestingTypes.tsx";
import { FormControlDate } from "app/component/form/control/FormControlDate.tsx";

type Props = {
    initialValues: FormValues;
    onSubmit: (values: FormValues) => void;
};

export type FormValues = {
    issueNumber?: number;
    title?: string;
    status?: ReportVulnerabilityStatus;
    severity?: VulnerabilitySeverity;
    testingTypes?: string[];
    dateDiscoveredFrom?: Date;
    dateDiscoveredTo?: Date;
    cvssScore?: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    issueNumber: yup.number(),
    title: yup.string(),
    status: yup.mixed<ReportVulnerabilityStatus>().oneOf(Object.values(ReportVulnerabilityStatus)),
    severity: yup.mixed<VulnerabilitySeverity>().oneOf(Object.values(VulnerabilitySeverity)),
    testingTypes: yup.array().of(yup.string().required()),
    dateDiscoveredFrom: yup.date(),
    dateDiscoveredTo: yup.date(),
    cvssScore: yup.string(),
});

export const ReportVulnerabilitiesTableFiltersForm: FC<Props> = (props) => {
    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        props.onSubmit(values);
        actions.setSubmitting(false);
    };

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={props.initialValues}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                values,
                isSubmitting,
                errors,
                touched,
                status,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>Issue number</Form.Label>
                        <Form.Control
                            type="number"
                            name="issueNumber"
                            value={values.issueNumber ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.issueNumber && !!errors.issueNumber}
                        />
                        <Form.Control.Feedback type="invalid">{errors.issueNumber}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="search"
                            name="title"
                            value={values.title ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Status</Form.Label>
                        <FormControlSelectReportVulnerabilityStatus
                            name="status"
                            value={values.status}
                            onChange={(value) => setFieldValue("status", value)}
                            onBlur={() => setFieldTouched("status")}
                            isInvalid={touched.status && !!errors.status}
                        />
                        <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Severity</Form.Label>
                        <FormControlSelectVulnerabilitySeverity
                            name="severity"
                            value={values.severity}
                            onChange={(value) => setFieldValue("severity", value)}
                            onBlur={() => setFieldTouched("severity")}
                            isInvalid={touched.severity && !!errors.severity}
                        />
                        <Form.Control.Feedback type="invalid">{errors.severity}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Testing types</Form.Label>
                        <SelectTestingTypes
                            id="testing-types"
                            value={values.testingTypes ?? []}
                            onChange={(value) => setFieldValue("testingTypes", value)}
                            onBlur={() => setFieldTouched("testingTypes")}
                            isInvalid={touched.testingTypes && !!errors.testingTypes}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testingTypes}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Date discovered from</Form.Label>
                        <FormControlDate
                            name="dateDiscoveredFrom"
                            value={values.dateDiscoveredFrom ?? null}
                            onChange={(value) => setFieldValue("dateDiscoveredFrom", value)}
                            onBlur={handleBlur}
                            isInvalid={touched.dateDiscoveredFrom && !!errors.dateDiscoveredFrom}
                        />
                        <Form.Control.Feedback type="invalid">{errors.dateDiscoveredFrom}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Date discovered to</Form.Label>
                        <FormControlDate
                            name="dateDiscoveredTo"
                            value={values.dateDiscoveredTo ?? null}
                            onChange={(value) => setFieldValue("dateDiscoveredTo", value)}
                            onBlur={handleBlur}
                            isInvalid={touched.dateDiscoveredTo && !!errors.dateDiscoveredTo}
                        />
                        <Form.Control.Feedback type="invalid">{errors.dateDiscoveredTo}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>CVSS score</Form.Label>
                        <Form.Control
                            type="text"
                            name="cvssScore"
                            value={values.cvssScore ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.cvssScore && !!errors.cvssScore}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cvssScore}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        <FaFilter /> Filter
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
