import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import { ReportVulnerabilityFieldsFragment, useReportVersionVulnerabilitiesQuery } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { mapNodes } from "app/util/graph";
import { useFetchAll } from "app/api/graph/hook/use-fetch-all";

type Result = {
    vulnerabilities?: ReportVulnerabilityFieldsFragment[];
    loading: boolean;
    error?: ApolloError;
};

export const useReportVersionVulnerabilities = (versionId: number): Result => {
    const { data, loading, error, fetchMore } = useReportVersionVulnerabilitiesQuery({
        variables: { versionId: reportVersionGraphId(versionId) },
        notifyOnNetworkStatusChange: true,
    });
    const vulnerabilities = data?.reportVersion?.vulnerabilities;
    const pageInfo = data?.reportVersion?.vulnerabilities?.pageInfo;
    useFetchAll({ loading, pageInfo, fetchMore });

    return useMemo(
        () => ({ vulnerabilities: mapNodes(vulnerabilities, (n) => n), loading, error }),
        [vulnerabilities, loading, error],
    );
};
