import { FC } from "react";
import { Formik, FormikHelpers } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { CreateReportInviteMutation, ReportRole, useCreateReportInviteMutation } from "app/api/graph/types";
import { reportGraphId } from "app/api/graph/helpers";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";
import yup from "app/util/yup";
import { FormControlSelectReportRole } from "app/component/form/control/FormControlSelectReportRole";

type Props = {
    reportId: number;
    onCreate: (
        report: NonNullable<NonNullable<CreateReportInviteMutation["createReportInvite"]>["reportInvite"]>,
    ) => void;
};

export type FormValues = {
    firstName?: string;
    lastName?: string;
    email?: string;
    role: ReportRole;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string(),
    role: yup.mixed<ReportRole>().oneOf(Object.values(ReportRole)).required(),
});

const initialValues: FormValues = {
    role: ReportRole.Standard,
};

export const CreateReportInviteForm: FC<Props> = ({ reportId, onCreate }) => {
    const [createInvite] = useCreateReportInviteMutation({});

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        createInvite({ variables: { input: { ...values, report: reportGraphId(reportId) } } })
            .then(
                (response) =>
                    response.data?.createReportInvite?.reportInvite &&
                    onCreate(response.data.createReportInvite.reportInvite),
            )
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                values,
                isSubmitting,
                errors,
                touched,
                status,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                            type="text"
                            name="firstName"
                            value={values.firstName ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.firstName && !!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                            type="text"
                            name="lastName"
                            value={values.lastName ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.lastName && !!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={values.email ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Text>
                            Entering an email address will automatically email the invite to the user, otherwise you
                            will be given an invite URL to share.
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Role</Form.Label>
                        <FormControlSelectReportRole
                            name="role"
                            value={values.role}
                            onChange={(value) => setFieldValue("role", value)}
                            onBlur={() => setFieldTouched("role")}
                            isInvalid={touched.role && !!errors.role}
                        />
                        <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Invite
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
