import { FC, PropsWithChildren } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaEdit, FaFileAlt, FaPlus, FaUpload } from "react-icons/fa";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { ReportVersionStatus, useReportVersionQuery } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { useRouteParams } from "app/route/route";
import {
    createReportVersionVulnerabilityRoute,
    editReportVersionClientRoute,
    editReportVersionConsultantContactRoute,
    editReportVersionConsultantRoute,
    editReportVersionContactRoute,
    editReportVersionRoute,
    editReportVersionSectionsRoute,
    editReportVersionTestRoute,
    editReportVersionVersionRoute,
    editReportVersionVulnerabilitiesRoute,
    reportVersionPublishRoute,
    reportVersionRoute,
} from "app/route/Routes";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { formatISODate } from "app/util/format";
import { SimpleListGroup } from "app/component/util/SimpleListGroup";
import { ReportVersionVulnerabilitiesTable } from "app/component/report-vulnerability/ReportVersionVulnerabilitiesTable";
import { ReportVersionSectionsTable } from "app/component/report-section/ReportVersionSectionsTable";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { reportVersionStatusLabel } from "app/api/graph/strings";
import { ButtonLink } from "app/component/button/ButtonLink";

const Section: FC<PropsWithChildren> = ({ children }) => <div className="mb-5">{children}</div>;

export const EditReportVersionScreen: FC = () => {
    const { reportId, versionId } = useRouteParams(editReportVersionRoute);
    const { data, loading, error } = useReportVersionQuery({
        variables: { versionId: reportVersionGraphId(versionId) },
    });
    const version = data?.reportVersion;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!version) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editReportVersionRoute} />

                <Toolbar>
                    <ToolbarTitle>
                        <h1>{version.report.title}</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {version.status === ReportVersionStatus.Draft && (
                            <ButtonLink variant="primary" to={reportVersionPublishRoute.build({ reportId, versionId })}>
                                <FaUpload /> Publish
                            </ButtonLink>
                        )}
                        <ButtonLink to={reportVersionRoute.build({ reportId, versionId })} variant="info">
                            <FaFileAlt /> Report
                        </ButtonLink>
                    </ToolbarButtons>
                </Toolbar>
                <Section>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>Version</Card.Title>
                                    <Card.Text>
                                        {version.versionName} ({version.versionNumber}) -{" "}
                                        {reportVersionStatusLabel(version.status)}
                                    </Card.Text>
                                    <Card.Link
                                        as={Link}
                                        to={editReportVersionVersionRoute.build({ reportId, versionId })}
                                    >
                                        Edit
                                    </Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>Test details</Card.Title>
                                    <Card.Text>
                                        {version.testName ? <>{version.testName} </> : null}
                                        {version.testPerformedFrom && version.testPerformedTo ? (
                                            <>
                                                ({formatISODate(version.testPerformedFrom)} -{" "}
                                                {formatISODate(version.testPerformedTo)})
                                            </>
                                        ) : null}
                                    </Card.Text>
                                    <Card.Link as={Link} to={editReportVersionTestRoute.build({ reportId, versionId })}>
                                        Edit
                                    </Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Section>

                <h2>Contacts</h2>
                <Section>
                    <Row>
                        <Col xs={12} sm={6} lg={4}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>Client</Card.Title>
                                </Card.Body>
                                <SimpleListGroup
                                    variant="flush"
                                    items={[
                                        version.client?.contact?.name,
                                        version.client?.contact?.email,
                                        version.client?.contact?.mobile,
                                        version.client?.contact?.company,
                                        version.client?.contact?.position,
                                        version.client?.contact?.address,
                                        version.client?.contact?.phone,
                                    ]}
                                />
                                <Card.Body>
                                    <Card.Link
                                        as={Link}
                                        to={editReportVersionClientRoute.build({ reportId, versionId })}
                                    >
                                        Edit
                                    </Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>Consultant</Card.Title>
                                </Card.Body>
                                <SimpleListGroup
                                    variant="flush"
                                    items={[
                                        version.consultant?.contact?.name,
                                        version.consultant?.qualifications,
                                        version.consultant?.contact?.email,
                                        version.consultant?.contact?.mobile,
                                        version.consultant?.contact?.company,
                                        version.consultant?.contact?.position,
                                        version.consultant?.contact?.address,
                                        version.consultant?.contact?.phone,
                                    ]}
                                />
                                <Card.Body>
                                    <Card.Link
                                        as={Link}
                                        to={editReportVersionConsultantContactRoute.build({ reportId, versionId })}
                                    >
                                        Edit
                                    </Card.Link>
                                    <Card.Link
                                        as={Link}
                                        to={editReportVersionConsultantRoute.build({ reportId, versionId })}
                                    >
                                        Edit qualifications
                                    </Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>Point of contact</Card.Title>
                                </Card.Body>
                                <SimpleListGroup
                                    variant="flush"
                                    items={[
                                        version.contact?.name,
                                        version.contact?.email,
                                        version.contact?.mobile,
                                        version.contact?.company,
                                        version.contact?.position,
                                        version.contact?.address,
                                        version.contact?.phone,
                                    ]}
                                />
                                <Card.Body>
                                    <Card.Link
                                        as={Link}
                                        to={editReportVersionContactRoute.build({ reportId, versionId })}
                                    >
                                        Edit
                                    </Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Section>

                <Section>
                    <Toolbar>
                        <ToolbarTitle>
                            <h2>Written sections</h2>
                        </ToolbarTitle>
                        <ToolbarButtons>
                            <ButtonGroup>
                                <ButtonLink
                                    to={editReportVersionSectionsRoute.build({ reportId, versionId })}
                                    variant="warning"
                                >
                                    <FaEdit /> Edit
                                </ButtonLink>
                            </ButtonGroup>
                        </ToolbarButtons>
                    </Toolbar>
                    <ReportVersionSectionsTable reportId={reportId} versionId={versionId} />
                </Section>

                <Section>
                    <Toolbar>
                        <ToolbarTitle>
                            <h2>Vulnerabilities</h2>
                        </ToolbarTitle>
                        <ToolbarButtons>
                            <ButtonGroup>
                                <ButtonLink
                                    to={createReportVersionVulnerabilityRoute.build({ reportId, versionId })}
                                    variant="success"
                                >
                                    <FaPlus /> Create
                                </ButtonLink>
                                <ButtonLink
                                    to={editReportVersionVulnerabilitiesRoute.build({ reportId, versionId })}
                                    variant="warning"
                                >
                                    <FaEdit /> Edit
                                </ButtonLink>
                            </ButtonGroup>
                        </ToolbarButtons>
                    </Toolbar>
                    <ReportVersionVulnerabilitiesTable reportId={reportId} versionId={versionId} />
                </Section>
            </Container>
        </NavigationScreen>
    );
};
