import { FC } from "react";
import { FaFilePdf } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import { useDownloadReportVersionPdf } from "app/api/hook/use-download-report-version-pdf";
import { Template } from "app/component/report-version/component/template/template-builder";

type Props = {
    versionId: number;
};

export const DownloadReportVersionPdfButton: FC<Props> = ({ versionId }) => {
    const { download, loading: downloading } = useDownloadReportVersionPdf(versionId, Template.Main);

    return (
        <Button variant="primary" onClick={download} disabled={downloading}>
            <FaFilePdf /> Download PDF
        </Button>
    );
};
