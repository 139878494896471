import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { PASTE_COMMAND, COMMAND_PRIORITY_NORMAL, $getSelection } from "lexical";
import { FC, useEffect } from "react";

export const CleanPastePlugin: FC = () => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                PASTE_COMMAND,
                (event) => {
                    if (event instanceof ClipboardEvent) {
                        event.preventDefault();

                        const text = event.clipboardData?.getData("text")?.trim();

                        if (text !== undefined && text?.length > 0) {
                            editor.update(() => {
                                const selection = $getSelection();

                                if (selection !== null) {
                                    selection.insertText(text);
                                }
                            });

                            return true;
                        }
                    }

                    return false;
                },
                COMMAND_PRIORITY_NORMAL,
            ),
        );
    }, [editor]);

    return null;
};
