import Alert from "react-bootstrap/Alert";
import { FC } from "react";
import { Link } from "react-router-dom";
import { addMobileNumberAction, useAuth } from "app/api/AuthContext";
import { updateUserMobileNumberRoute } from "app/route/Routes";

const supportedActions = [addMobileNumberAction];

export const AuthActionAlerts: FC = () => {
    const { id, actions, clearAction } = useAuth();
    if (!id || !actions || actions.length === 0) {
        return null;
    }

    const alerts = actions
        ?.filter((action) => supportedActions.includes(action))
        .map((action) => {
            switch (action) {
                case addMobileNumberAction:
                    return (
                        <Alert
                            className="d-print-none"
                            key={action}
                            variant="warning"
                            dismissible
                            onClose={() => clearAction(addMobileNumberAction)}
                        >
                            <Link to={updateUserMobileNumberRoute.build({ userId: id })}>Click here</Link> to secure
                            your account by adding a mobile number.
                        </Alert>
                    );
                default:
                    console.error("Unhandled auth action");
            }
        });

    return <>{alerts}</>;
};
