import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import { ReportSectionFieldsFragment, useReportVersionSectionsQuery } from "app/api/graph/types";
import { reportVersionGraphId } from "app/api/graph/helpers";
import { mapNodes } from "app/util/graph";
import { useFetchAll } from "app/api/graph/hook/use-fetch-all";

type Result = {
    sections?: ReportSectionFieldsFragment[];
    loading: boolean;
    error?: ApolloError;
};

export const useReportVersionSections = (versionId: number): Result => {
    const { data, loading, error, fetchMore } = useReportVersionSectionsQuery({
        variables: { versionId: reportVersionGraphId(versionId) },
        notifyOnNetworkStatusChange: true,
    });
    const sections = data?.reportVersion?.sections;
    const pageInfo = data?.reportVersion?.sections?.pageInfo;
    useFetchAll({ loading, pageInfo, fetchMore });

    return useMemo(
        () => ({
            sections: mapNodes(sections, (n) => n),
            loading,
            error,
        }),
        [sections, loading, error],
    );
};
