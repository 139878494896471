import { useEffect } from "react";

/**
 * In a single-page app, we can't have an individual stylesheet for each "page". Normally, this is fine as we just style
 * the page components. However, there are some CSS selectors (e.g. @page), that are global that we may only want to run
 * on certain pages. This hook lets you add a dynamic stylesheet to the page that is removed when the component is.
 * @param styles Raw CSS stylesheet string
 */
export const useCss = (styles: string): void => {
    useEffect(() => {
        const styleSheet = document.createElement("style");
        styleSheet.innerText = styles;
        document.head.appendChild(styleSheet);

        return () => styleSheet.remove();
    }, [styles]);
};
