import { Result, useDownloadFile } from "app/api/hook/use-download-file";
import { Template } from "app/component/report-version/component/template/template-builder";

export const useDownloadReportVersionPdf = (versionId: number, template?: Template): Result => {
    const url = new URL(`/api/report_versions/${versionId}/report.pdf`, document.baseURI);
    if (template) {
        url.searchParams.append("template", template);
    }
    return useDownloadFile(url.href);
};
