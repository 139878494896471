import { ApolloError } from "@apollo/client";
import { useMemo } from "react";
import { ReportVulnerabilityActionFieldsFragment, useReportVulnerabilityActionsQuery } from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import { mapNodes } from "app/util/graph";
import { useFetchAll } from "app/api/graph/hook/use-fetch-all";

type Result = {
    actions?: ReportVulnerabilityActionFieldsFragment[];
    loading: boolean;
    error?: ApolloError;
};

export const useReportVulnerabilityActions = (vulnerabilityId: number): Result => {
    const { data, loading, error, fetchMore } = useReportVulnerabilityActionsQuery({
        variables: { vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId), order: { dateActioned: "DESC" } },
        notifyOnNetworkStatusChange: true,
    });
    const actions = data?.reportVulnerability?.actions;
    const pageInfo = data?.reportVulnerability?.actions?.pageInfo;
    useFetchAll({ loading, pageInfo, fetchMore });

    return useMemo(() => ({ actions: mapNodes(actions, (n) => n), loading, error }), [actions, loading, error]);
};
