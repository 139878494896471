import { FC } from "react";
import { formatISO } from "date-fns";
import { useCreateReportVulnerabilityMutation, VulnerabilitySeverity } from "app/api/graph/types";
import { FormValues, ReportVulnerabilityForm } from "app/component/report-vulnerability/ReportVulnerabilityForm";
import { reportVersionGraphId } from "app/api/graph/helpers";

type Props = {
    versionId: number;
    onCreate?: () => void;
};

const initialValues: FormValues = {
    baseVulnerability: null,
    title: "",
    severity: VulnerabilitySeverity.Medium,
    testingTypes: [],
    dateDiscovered: null,
    numberOfIssues: "",
    cvssScore: undefined,
    cvssVector: undefined,
    cveScore: undefined,
    cwe: undefined,
    owaspCategory: undefined,
    affectedHosts: undefined,
    issueDetail: undefined,
    advice: undefined,
};

export const CreateReportVulnerabilityForm: FC<Props> = ({ versionId, onCreate }) => {
    const [createVulnerability, { error }] = useCreateReportVulnerabilityMutation({});

    const onSubmit = (values: FormValues) =>
        createVulnerability({
            variables: {
                input: {
                    reportVersion: reportVersionGraphId(versionId),
                    ...values,
                    dateDiscovered: values.dateDiscovered ? formatISO(values.dateDiscovered) : "",
                },
            },
        }).then(() => onCreate?.());

    return (
        <ReportVulnerabilityForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            error={error}
            versionId={versionId}
        />
    );
};
