export const userRole = "ROLE_USER";
export const testerRole = "ROLE_TESTER";
export const consultantRole = "ROLE_CONSULTANT";
export const superAdminRole = "ROLE_SUPER_ADMIN";
export const listReportsRole = "ROLE_LIST_REPORTS";
export const createReportRole = "ROLE_CREATE_REPORT";
export const updateReportRole = "ROLE_UPDATE_REPORT";
export const deleteReportRole = "ROLE_DELETE_REPORT";
export const transitionReportVersionRole = "ROLE_TRANSITION_REPORT_VERSION";
export const listCompaniesRole = "ROLE_LIST_COMPANIES";
export const createCompanyRole = "ROLE_CREATE_COMPANY";
export const updateCompanyRole = "ROLE_UPDATE_COMPANY";
export const deleteCompanyRole = "ROLE_DELETE_COMPANY";
export const listCompanyInvitesRole = "ROLE_LIST_COMPANY_INVITES";
export const createCompanyInviteRole = "ROLE_CREATE_COMPANY_INVITE";
export const updateCompanyInviteRole = "ROLE_UPDATE_COMPANY_INVITE";
export const deleteCompanyInviteRole = "ROLE_DELETE_COMPANY_INVITE";
export const listReportInvitesRole = "ROLE_LIST_REPORT_INVITES";
export const createReportInviteRole = "ROLE_CREATE_REPORT_INVITE";
export const updateReportInviteRole = "ROLE_UPDATE_REPORT_INVITE";
export const deleteReportInviteRole = "ROLE_DELETE_REPORT_INVITE";
export const createReportVulnerabilityRole = "ROLE_CREATE_REPORT_VULNERABILITY";
export const updateReportVulnerabilityRole = "ROLE_UPDATE_REPORT_VULNERABILITY";
export const deleteReportVulnerabilityRole = "ROLE_DELETE_REPORT_VULNERABILITY";
export const transitionReportVulnerabilityRole = "ROLE_TRANSITION_REPORT_VULNERABILITY";
export const createVulnerabilityActionRole = "ROLE_CREATE_VULNERABILITY_ACTION";
export const updateVulnerabilityActionRole = "ROLE_UPDATE_VULNERABILITY_ACTION";
export const deleteVulnerabilityActionRole = "ROLE_DELETE_VULNERABILITY_ACTION";
export const listReportReadReceiptsRole = "ROLE_LIST_REPORT_READ_RECEIPTS";
export const listUsersRole = "ROLE_LIST_USERS";
export const updateUserRole = "ROLE_UPDATE_USER";
export const deleteUserRole = "ROLE_DELETE_USER";
export const listVulnerabilitiesRole = "ROLE_LIST_VULNERABILITIES";
export const createVulnerabilityRole = "ROLE_CREATE_VULNERABILITY";
export const updateVulnerabilityRole = "ROLE_UPDATE_VULNERABILITY";
export const deleteVulnerabilityRole = "ROLE_DELETE_VULNERABILITY";
export const listVulnerabilityRetestRequestsRole = "ROLE_LIST_VULNERABILITY_RETEST_REQUESTS";
export const createVulnerabilityRetestRequestRole = "ROLE_CREATE_VULNERABILITY_RETEST_REQUEST";
export const updateVulnerabilityRetestRequestRole = "ROLE_UPDATE_VULNERABILITY_RETEST_REQUEST";
export const deleteVulnerabilityRetestRequestRole = "ROLE_DELETE_VULNERABILITY_RETEST_REQUEST";
export const listVulnerabilityClientNotificationsRole = "ROLE_LIST_VULNERABILITY_CLIENT_NOTIFICATIONS";
export const createVulnerabilityClientNotificationRole = "ROLE_CREATE_VULNERABILITY_CLIENT_NOTIFICATION";
export const deleteVulnerabilityCommentRole = "ROLE_DELETE_VULNERABILITY_COMMENT";
export const viewUserSecurityEventsRole = "ROLE_VIEW_USER_SECURITY_EVENTS";
export const listContactsRole = "ROLE_LIST_CONTACTS";
export const createContactRole = "ROLE_CREATE_CONTACT";
export const updateContactRole = "ROLE_UPDATE_CONTACT";
export const deleteContactRole = "ROLE_DELETE_CONTACT";
export const listTestingTypesRole = "ROLE_LIST_TESTING_TYPES";
export const createTestingTypeRole = "ROLE_CREATE_TESTING_TYPE";
export const updateTestingTypeRole = "ROLE_UPDATE_TESTING_TYPE";
export const deleteTestingTypeRole = "ROLE_DELETE_TESTING_TYPE";
export const listReportVulnerabilitiesRole = "ROLE_LIST_REPORT_VULNERABILITIES";
