import { FC, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaTrash, FaUserCog } from "react-icons/fa";
import { mapNodes } from "app/util/graph";
import { useUserListQuery } from "app/api/graph/types";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { deleteUserRoute, editUserRoute } from "app/route/Routes";
import { SortableTableHeader, SortDirection } from "app/component/table/SortableTableHeader";
import { FormValues, UsersTableFiltersForm } from "app/component/user/UsersTableFiltersForm";
import { useAuth } from "app/api/AuthContext";
import { deleteUserRole, updateUserRole } from "app/api/graph/roles";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

export const UsersTable: FC = () => {
    const { roles } = useAuth();
    const [firstNameOrder, setFirstNameOrder] = useState<SortDirection>("ASC");
    const [lastNameOrder, setLastNameOrder] = useState<SortDirection>("ASC");
    const [emailOrder, setEmailOrder] = useState<SortDirection>(undefined);
    const [filters, setFilters] = useState<FormValues>({ firstName: undefined, lastName: undefined, email: undefined });
    const { data, loading, error, fetchMore } = useUserListQuery({
        variables: { order: { firstName: firstNameOrder, lastName: lastNameOrder, email: emailOrder }, ...filters },
        notifyOnNetworkStatusChange: true,
    });
    const users = data?.users;

    if (loading && !users) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!users) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const fetchMoreUsers = () => fetchMore({ variables: { after: users.pageInfo.endCursor } });
    const showActions = roles?.includes(updateUserRole) || roles?.includes(deleteUserRole);

    return (
        <Row>
            <Col>
                <Table responsive="sm" bordered className="crud-table">
                    <thead>
                        <tr>
                            <SortableTableHeader direction={firstNameOrder} onChange={setFirstNameOrder}>
                                First name
                            </SortableTableHeader>
                            <SortableTableHeader direction={lastNameOrder} onChange={setLastNameOrder}>
                                Last name
                            </SortableTableHeader>
                            <SortableTableHeader direction={emailOrder} onChange={setEmailOrder}>
                                Email
                            </SortableTableHeader>
                            {showActions ? <th>Actions</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {mapNodes(users, (user) => (
                            <tr key={user.id}>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.email}</td>
                                {showActions ? (
                                    <td>
                                        {roles?.includes(updateUserRole) ? (
                                            <ButtonLink
                                                variant="warning"
                                                to={editUserRoute.build({ userId: user._id })}
                                                size="sm"
                                                title="Edit"
                                            >
                                                <FaUserCog />
                                            </ButtonLink>
                                        ) : null}
                                        {roles?.includes(deleteUserRole) ? (
                                            <ButtonLink
                                                variant="danger"
                                                to={deleteUserRoute.build({ userId: user._id })}
                                                size="sm"
                                                title="Delete"
                                            >
                                                <FaTrash />
                                            </ButtonLink>
                                        ) : null}
                                    </td>
                                ) : null}
                            </tr>
                        ))}
                        {users.edges?.length === 0 ? (
                            <tr>
                                <td colSpan={100}>No users.</td>
                            </tr>
                        ) : null}
                        {loading ? (
                            <tr>
                                <td colSpan={100}>
                                    <LoadingSpinner />
                                </td>
                            </tr>
                        ) : null}
                        {users.pageInfo.hasNextPage ? (
                            <tr>
                                <td colSpan={100}>
                                    <BlockButtonGroup>
                                        <Button variant="light" onClick={fetchMoreUsers}>
                                            Load more
                                        </Button>
                                    </BlockButtonGroup>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </Table>
            </Col>
            <Col md={3}>
                <UsersTableFiltersForm initialValues={filters} onSubmit={setFilters} />
            </Col>
        </Row>
    );
};
