import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ApolloError } from "@apollo/client";
import yup from "app/util/yup";
import { VulnerabilitySeverity } from "app/api/graph/types";
import { RichTextEditor } from "app/component/form/text-editor/RichTextEditor";
import { FormControlDate } from "app/component/form/control/FormControlDate";
import { FormControlSelectVulnerabilitySeverity } from "app/component/form/control/FormControlSelectVulnerabilitySeverity";
import { StatusAlert } from "app/component/form/StatusAlert";
import { handleError, handleSuccess } from "app/api/graph/form";
import { SelectVulnerability } from "app/component/form/select/SelectVulnerability";
import { FormControlSelectOwaspCategory } from "app/component/form/control/FormControlSelectOwaspCategory";
import { SelectTestingTypes } from "app/component/form/select/SelectTestingTypes.tsx";
import { mapNodes } from "app/util/graph.ts";

type Props = {
    initialValues: FormValues;
    onSubmit: (values: FormValues) => Promise<void>;
    error?: ApolloError;
    versionId: number;
};

export type FormValues = {
    baseVulnerability?: string | null;
    title: string;
    severity: VulnerabilitySeverity;
    testingTypes: string[];
    dateDiscovered: Date | null;
    numberOfIssues: string;
    cvssScore?: string;
    cvssVector?: string;
    cveScore?: string;
    cwe?: string;
    owaspCategory?: string;
    affectedHosts?: string;
    issueDetail?: string;
    advice?: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    baseVulnerability: yup.string().nullable(),
    title: yup.string().required(),
    severity: yup.mixed<VulnerabilitySeverity>().oneOf(Object.values(VulnerabilitySeverity)).required(),
    testingTypes: yup.array().of<string>(yup.string().required()).required(),
    dateDiscovered: yup.date().required(),
    numberOfIssues: yup.string().required(),
    cvssScore: yup.string(),
    cvssVector: yup.string(),
    cveScore: yup.string(),
    cwe: yup.string(),
    owaspCategory: yup.string(),
    affectedHosts: yup.string(),
    issueDetail: yup.string(),
    advice: yup.string(),
});

export const ReportVulnerabilityForm: FC<Props> = (props) => {
    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        props
            .onSubmit(values)
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={props.initialValues}>
            {({
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
                setFieldTouched,
                values,
                isSubmitting,
                errors,
                touched,
                status,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3" controlId="select-vulnerability">
                        <Form.Label>Master vulnerability</Form.Label>
                        <SelectVulnerability
                            id="select-vulnerability"
                            placeholder="(Optional) Select a master vulnerability..."
                            value={values.baseVulnerability ?? null}
                            onChange={async (value, option) => {
                                await setFieldValue("baseVulnerability", value);
                                // Copy values from the master onto this
                                if (value && value !== values.baseVulnerability) {
                                    await setFieldValue("title", option?.title);
                                    await setFieldValue("severity", option?.severity);
                                    await setFieldValue(
                                        "testingTypes",
                                        mapNodes(option?.testingTypes, (item) => item.id),
                                    );
                                    await setFieldValue("cvssScore", option?.cvssScore);
                                    await setFieldValue("cvssVector", option?.cvssVector);
                                    await setFieldValue("cveScore", option?.cveScore);
                                    await setFieldValue("cwe", option?.cwe);
                                    await setFieldValue("owaspCategory", option?.owaspCategory);
                                    await setFieldValue("issueDetail", option?.issueDetail);
                                    await setFieldValue("advice", option?.advice);
                                }
                            }}
                            onBlur={() => setFieldTouched("baseVulnerability")}
                            isInvalid={touched.baseVulnerability && !!errors.baseVulnerability}
                        />
                        <Form.Text muted>The master vulnerability this reported vulnerability is based on.</Form.Text>
                        <Alert variant="info">
                            Changing this value will copy the fields in this section from the master vulnerability onto
                            this reported vulnerability. You may update these fields here with information specific to
                            this reported vulnerability, if required. Basing off a master vulnerability may mean that
                            unmodified fields here may automatically be updated if the master is updated in the future.
                            Modified fields will never be automatically updated, so changes are not lost.
                        </Alert>
                        <Form.Control.Feedback type="invalid">{errors.baseVulnerability}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Severity</Form.Label>
                        <FormControlSelectVulnerabilitySeverity
                            name="severity"
                            value={values.severity}
                            onChange={(value) => setFieldValue("severity", value)}
                            onBlur={() => setFieldTouched("severity")}
                            isInvalid={touched.severity && !!errors.severity}
                        />
                        <Form.Control.Feedback type="invalid">{errors.severity}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Testing types</Form.Label>
                        <SelectTestingTypes
                            id="testing-types"
                            value={values.testingTypes}
                            onChange={(value) => setFieldValue("testingTypes", value)}
                            onBlur={() => setFieldTouched("testingTypes")}
                            isInvalid={touched.testingTypes && !!errors.testingTypes}
                        />
                        <Form.Control.Feedback type="invalid">{errors.testingTypes}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>CVSS score</Form.Label>
                        <Form.Control
                            type="text"
                            name="cvssScore"
                            value={values.cvssScore ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.cvssScore && !!errors.cvssScore}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cvssScore}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>CVSS vector</Form.Label>
                        <Form.Control
                            type="text"
                            name="cvssVector"
                            value={values.cvssVector ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.cvssVector && !!errors.cvssVector}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cvssVector}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>CVE score</Form.Label>
                        <Form.Control
                            type="text"
                            name="cveScore"
                            value={values.cveScore ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.cveScore && !!errors.cveScore}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cveScore}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>CWE</Form.Label>
                        <Form.Control
                            type="text"
                            name="cwe"
                            value={values.cwe ?? ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.cwe && !!errors.cwe}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cwe}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>OWASP category</Form.Label>
                        <FormControlSelectOwaspCategory
                            name="owaspCategory"
                            value={values.owaspCategory}
                            onChange={(value) => setFieldValue("owaspCategory", value)}
                            onBlur={() => setFieldTouched("owaspCategory")}
                            isInvalid={touched.owaspCategory && !!errors.owaspCategory}
                        />
                        <Form.Control.Feedback type="invalid">{errors.owaspCategory}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Issue detail</Form.Label>
                        <RichTextEditor
                            value={values.issueDetail ?? ""}
                            onChange={(value) => setFieldValue("issueDetail", value)}
                            onBlur={() => setFieldTouched("issueDetail")}
                            isInvalid={touched.issueDetail && !!errors.issueDetail}
                        />
                        <Form.Control.Feedback type="invalid">{errors.issueDetail}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Advice</Form.Label>
                        <RichTextEditor
                            value={values.advice ?? ""}
                            onChange={(value) => setFieldValue("advice", value)}
                            onBlur={() => setFieldTouched("advice")}
                            isInvalid={touched.advice && !!errors.advice}
                        />
                        <Form.Control.Feedback type="invalid">{errors.advice}</Form.Control.Feedback>
                    </Form.Group>
                    <hr />
                    <Form.Group className="mb-3">
                        <Form.Label>Date discovered</Form.Label>
                        <FormControlDate
                            name="dateDiscovered"
                            value={values.dateDiscovered}
                            onChange={(value) => setFieldValue("dateDiscovered", value)}
                            onBlur={handleBlur}
                            isInvalid={touched.dateDiscovered && !!errors.dateDiscovered}
                        />
                        <Form.Control.Feedback type="invalid">{errors.dateDiscovered}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Number of issues</Form.Label>
                        <Form.Control
                            type="text"
                            name="numberOfIssues"
                            value={values.numberOfIssues}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.numberOfIssues && !!errors.numberOfIssues}
                        />
                        <Form.Control.Feedback type="invalid">{errors.numberOfIssues}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Affected hosts</Form.Label>
                        <RichTextEditor
                            value={values.affectedHosts ?? ""}
                            onChange={(value) => setFieldValue("affectedHosts", value)}
                            onBlur={() => setFieldTouched("affectedHosts")}
                            isInvalid={touched.affectedHosts && !!errors.affectedHosts}
                        />
                        <Form.Control.Feedback type="invalid">{errors.affectedHosts}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
