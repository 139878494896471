import { FC } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaEdit, FaTrash } from "react-icons/fa";
import { mapNodes } from "app/util/graph";
import { useCompanyContactsQuery } from "app/api/graph/types";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { deleteCompanyContactRoute, editCompanyContactRoute } from "app/route/Routes";
import { deleteCompanyRole, updateCompanyRole } from "app/api/graph/roles";
import { useAuth } from "app/api/AuthContext";
import { companyGraphId } from "app/api/graph/helpers";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

type Props = {
    companyId: number;
};

export const CompanyContactsTable: FC<Props> = ({ companyId }) => {
    const { roles } = useAuth();
    const { data, loading, error, fetchMore } = useCompanyContactsQuery({
        variables: { companyId: companyGraphId(companyId) },
        notifyOnNetworkStatusChange: true,
    });
    const companyContacts = data?.company?.contacts;

    if (loading && !companyContacts) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!companyContacts) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const fetchMoreCompanyContact = () => fetchMore({ variables: { after: companyContacts.pageInfo.endCursor } });
    const showActions = roles?.includes(updateCompanyRole) || roles?.includes(deleteCompanyRole);

    return (
        <Table responsive="sm" bordered className="crud-table">
            <thead>
                <tr>
                    <th>Name</th>
                    {showActions ? <th>Actions</th> : null}
                </tr>
            </thead>
            <tbody>
                {mapNodes(companyContacts, (companyContact) => (
                    <tr key={companyContact.id}>
                        <td>{companyContact.name}</td>
                        {showActions ? (
                            <td>
                                <ButtonGroup>
                                    <ButtonLink
                                        to={editCompanyContactRoute.build({
                                            companyId: companyContact.company._id,
                                            contactId: companyContact._id,
                                        })}
                                        variant="warning"
                                        size="sm"
                                        title="Edit"
                                    >
                                        <FaEdit />
                                    </ButtonLink>
                                </ButtonGroup>
                                <ButtonGroup>
                                    <ButtonLink
                                        to={deleteCompanyContactRoute.build({
                                            companyId: companyContact.company._id,
                                            contactId: companyContact._id,
                                        })}
                                        variant="danger"
                                        size="sm"
                                        title="Delete"
                                    >
                                        <FaTrash />
                                    </ButtonLink>
                                </ButtonGroup>
                            </td>
                        ) : null}
                    </tr>
                ))}
                {companyContacts.edges?.length === 0 ? (
                    <tr>
                        <td colSpan={100}>No company contacts.</td>
                    </tr>
                ) : null}
                {loading ? (
                    <tr>
                        <td colSpan={100}>
                            <LoadingSpinner />
                        </td>
                    </tr>
                ) : null}
                {companyContacts.pageInfo.hasNextPage ? (
                    <tr>
                        <td colSpan={100}>
                            <BlockButtonGroup>
                                <Button variant="light" onClick={fetchMoreCompanyContact}>
                                    Load more
                                </Button>
                            </BlockButtonGroup>
                        </td>
                    </tr>
                ) : null}
            </tbody>
        </Table>
    );
};
