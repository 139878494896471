import { useCallback, useState } from "react";
import { useThrottledCallback } from "use-debounce";
import { flatParts, Part } from "app/component/report-version/component/part/Part";
import { useScroll } from "app/hook/use-scroll";

const scrollOffsetTop = 100;
const scrollThrottleMs = 100;

const findCurrentPart = (parts: Part[]) =>
    flatParts(parts).reduce(
        (current, part) => {
            const element = document.getElementById(part.sectionNumber);
            if (!element || element.getBoundingClientRect().top - scrollOffsetTop >= 0) {
                return current;
            }
            return part;
        },
        parts.length > 0 ? parts[0] : undefined,
    );

type UseCurrentPart = {
    currentPart: Part | undefined;
};

export const useCurrentPart = (parts: Part[]): UseCurrentPart => {
    const [currentPart, setCurrentPart] = useState<Part | undefined>();

    const scrollHandler = useCallback(() => setCurrentPart(findCurrentPart(parts)), [parts]);

    useScroll(useThrottledCallback(scrollHandler, scrollThrottleMs), [parts]);

    return {
        currentPart,
    };
};
