import { FC, useMemo } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik, FormikHelpers } from "formik";
import yup from "app/util/yup";
import { useAuth } from "app/api/AuthContext";
import { signInRoute } from "app/route/Routes";
import { CentredScreen } from "app/component/screen/CentredScreen";
import { useRegisterUserMutation } from "app/api/graph/types";
import logo from "app/asset/image/istorm_logo.png";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";
import { FormControlPassword } from "app/component/form/control/FormControlPassword";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";

type FormValues = {
    firstName: string;
    lastName: string;
    email: string;
    confirmEmail: string;
    plainPassword: string;
    confirmPassword: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    confirmEmail: yup
        .string()
        .email()
        .required()
        .oneOf([yup.ref("email")], "Emails must match"),
    plainPassword: yup.string().required(),
    confirmPassword: yup
        .string()
        .required()
        .oneOf([yup.ref("plainPassword")], "Passwords must match"),
});

type LocationState = {
    from?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
};

const isLocationState = (value: unknown): value is LocationState =>
    typeof value === "object" &&
    value !== null &&
    (("from" in value && typeof (value as LocationState).from === "string") ||
        ("firstName" in value && typeof (value as LocationState).firstName === "string") ||
        ("lastName" in value && typeof (value as LocationState).lastName === "string") ||
        ("email" in value && typeof (value as LocationState).email === "string"));

export const RegisterScreen: FC = () => {
    const { isSignedIn } = useAuth();
    const [registerUser, { data }] = useRegisterUserMutation();
    const location = useLocation();

    const initialValues: FormValues = useMemo(
        () => ({
            firstName: isLocationState(location.state) ? (location.state.firstName ?? "") : "",
            lastName: isLocationState(location.state) ? (location.state.lastName ?? "") : "",
            email: isLocationState(location.state) ? (location.state.email ?? "") : "",
            confirmEmail: "",
            plainPassword: "",
            confirmPassword: "",
        }),
        [location.state],
    );

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        registerUser({
            variables: {
                input: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    plainPassword: values.plainPassword,
                },
            },
        })
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    if (isSignedIn) {
        return (
            <Navigate
                to={signInRoute.build({})}
                state={isLocationState(location.state) ? location.state : undefined}
                replace={true}
            />
        );
    }

    if (data?.createUser) {
        return (
            <CentredScreen>
                <Alert variant="success">
                    We&lsquo;ve sent you an email to verify your email address. Please check your inbox and follow the
                    instructions to continue.
                </Alert>
            </CentredScreen>
        );
    }

    return (
        <CentredScreen>
            <Link to="/">
                <img src={logo} alt="iSTORM®" className="img-fluid mb-3" />
            </Link>
            <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting, status }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <StatusAlert status={status as unknown} />
                        <Form.Group className="mb-3">
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                name="firstName"
                                type="text"
                                placeholder="First name"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.firstName && !!errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                name="lastName"
                                type="text"
                                placeholder="Last name"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.lastName && !!errors.lastName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                name="email"
                                type="email"
                                placeholder="Email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.email && !!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Confirm email</Form.Label>
                            <Form.Control
                                name="confirmEmail"
                                type="confirmEmail"
                                placeholder="Confirm email"
                                value={values.confirmEmail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.confirmEmail && !!errors.confirmEmail}
                            />
                            <Form.Control.Feedback type="invalid">{errors.confirmEmail}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <FormControlPassword
                                name="plainPassword"
                                placeholder="Password"
                                value={values.plainPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.plainPassword && !!errors.plainPassword}
                            />
                            <Form.Control.Feedback type="invalid">{errors.plainPassword}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Confirm password</Form.Label>
                            <FormControlPassword
                                name="confirmPassword"
                                placeholder="Confirm password"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                            />
                            <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                        </Form.Group>
                        <BlockButtonGroup>
                            <Button type="submit" disabled={isSubmitting}>
                                Register
                            </Button>
                        </BlockButtonGroup>
                    </Form>
                )}
            </Formik>
        </CentredScreen>
    );
};
