import { format, formatRelative, parseISO } from "date-fns";
import { parsePhoneNumber } from "libphonenumber-js";
import { Maybe } from "app/util/type";

export const formatPercent = (value: Maybe<number>, decimalPlaces = 0, fallback = "-"): string =>
    typeof value === "number" ? `${(value * 100).toFixed(decimalPlaces)}%` : fallback;

export const formatFloat = (value: Maybe<number>, decimalPlaces = 0, fallback = "-"): string =>
    typeof value === "number" ? value.toFixed(decimalPlaces) : fallback;

export const formatUnit = (value: Maybe<number>, unit: string, decimalPlaces = 0, fallback = "-"): string =>
    typeof value === "number" ? `${value.toFixed(decimalPlaces)} ${unit}` : fallback;

export const formatDate = (value: Maybe<Date>, fallback = "-"): string =>
    value ? format(value, "dd/MM/yyyy") : fallback;

export const formatDateTime = (value: Maybe<Date>, fallback = "-"): string =>
    value ? format(value, "dd/MM/yyyy HH:mm") : fallback;

export const formatISODate = (value: Maybe<string>, fallback = "-"): string =>
    typeof value === "string" ? formatDate(parseISO(value)) : fallback;

export const formatISODateTime = (value: Maybe<string>, fallback = "-"): string =>
    typeof value === "string" ? formatDateTime(parseISO(value)) : fallback;

export const formatISODateTimeRelative = (value: Maybe<string>, fallback = "-"): string =>
    typeof value === "string" ? formatRelative(parseISO(value), new Date()) : fallback;

export const formatE164 = (value: Maybe<string>, fallback = "-"): string =>
    typeof value === "string" ? (parsePhoneNumber(value, "GB").number as string) : fallback;
