import { FC } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { deleteUserRoute, usersRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useDeleteUserMutation, useUserQuery } from "app/api/graph/types";
import { userGraphId } from "app/api/graph/helpers";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { ConfirmDelete } from "app/component/form/ConfirmDelete";

export const DeleteUserScreen: FC = () => {
    const { userId } = useRouteParams(deleteUserRoute);
    const { data, loading, error } = useUserQuery({
        variables: { userId: userGraphId(userId) },
    });
    const [deleteUser, { data: deleteData, loading: deleting, error: deleteError }] = useDeleteUserMutation({
        variables: { input: { id: userGraphId(userId) } },
    });
    const user = data?.user;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!user) {
        return <ErrorScreen title="Error" message="Not found." />;
    }
    if (deleteData?.deleteUser) {
        return <Navigate to={usersRoute.build({})} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={deleteUserRoute} />
                <h1>Delete user</h1>
                {deleteError ? <Alert variant="danger">{deleteError.message}</Alert> : null}
                <p>Are you sure that you want to permanently delete this user?</p>
                <Table responsive="sm">
                    <tbody>
                        <tr>
                            <th>First name</th>
                            <td>{user.firstName ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Last name</th>
                            <td>{user.lastName ?? "-"}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{user.email ?? "-"}</td>
                        </tr>
                    </tbody>
                </Table>
                <Alert variant="danger">
                    <p>
                        This will <strong>permanently</strong> delete the user - which cannot be reversed. The user will
                        not be notified.
                    </p>
                    Note: this will delete the user and some related metadata (e.g. password reset requests) but will
                    not delete data they have created/updated (companies, reports, vulnerabilities, actions, etc.).
                    However, it will no longer be possible to know who created/updated this data if it was this user -
                    this data will be lost forever.
                </Alert>
                <ConfirmDelete onConfirm={() => deleteUser()} deleting={deleting} />
            </Container>
        </NavigationScreen>
    );
};
