import { FC } from "react";
import Table from "react-bootstrap/Table";
import { styled } from "styled-components";
import { FaFile } from "react-icons/fa";
import { ReportVulnerabilityFieldsFragment, VulnerabilitySeverity } from "app/api/graph/types";
import { vulnerabilitySeverityColor, vulnerabilitySeverityLabel } from "app/api/graph/strings";
import { HtmlContent } from "app/component/util/HtmlContent";
import { ReportVulnerabilityStatusBadge } from "app/component/report-vulnerability/component/ReportVulnerabilityStatusBadge";
import { nodes } from "app/util/graph";

const maxFiles = 10;

const SeverityCell = styled.td<{ $severity: VulnerabilitySeverity }>`
    // Extra specificity to override Bootstrap
    && {
        color: white;
        // Override Bootstrap setting all cells to white when printing
        background-color: ${(props) => vulnerabilitySeverityColor(props.$severity)} !important;
    }
`;

type Props = {
    vulnerability: ReportVulnerabilityFieldsFragment;
    id?: string;
};

export const VulnerabilitySummary: FC<Props> = ({ vulnerability, id }) => (
    <>
        <Table responsive="sm" bordered id={id}>
            <tbody>
                <tr>
                    <th colSpan={100}>
                        {vulnerability.issueNumber} {vulnerability.title}
                        <ReportVulnerabilityStatusBadge status={vulnerability.status} className="float-end" />
                    </th>
                </tr>
                <tr>
                    <SeverityCell $severity={vulnerability.severity} rowSpan={5} width={100}>
                        {vulnerabilitySeverityLabel(vulnerability.severity)}
                    </SeverityCell>
                    <th>CVSS score</th>
                    <td>{vulnerability.cvssScore ?? "-"}</td>
                </tr>
                <tr>
                    <th>CVSS vector</th>
                    <td>{vulnerability.cvssVector ?? "-"}</td>
                </tr>
                <tr>
                    <th>CVE score</th>
                    <td>{vulnerability.cveScore ?? "-"}</td>
                </tr>
                <tr>
                    <th>CWE</th>
                    <td>{vulnerability.cwe ?? "-"}</td>
                </tr>
                <tr>
                    <th>OWASP category</th>
                    <td>{vulnerability.owaspCategory ?? "-"}</td>
                </tr>
                <tr>
                    <th colSpan={100}>Affected hosts</th>
                </tr>
                <tr>
                    <td colSpan={100}>
                        <HtmlContent html={vulnerability.affectedHosts ?? ""} />
                    </td>
                </tr>
                <tr>
                    <th colSpan={100}>Issue detail</th>
                </tr>
                <tr>
                    <td colSpan={100}>
                        <HtmlContent html={vulnerability.issueDetail ?? ""} />
                    </td>
                </tr>
                <tr>
                    <th colSpan={100}>Remediation advice and references</th>
                </tr>
                <tr>
                    <td colSpan={100}>
                        <HtmlContent html={vulnerability.advice ?? ""} />
                    </td>
                </tr>
            </tbody>
        </Table>

        <div className="d-flex flex-column gap-3 my-3">
            {nodes(vulnerability.files)
                .slice(0, maxFiles)
                .map((file) => (
                    <div key={file.id}>
                        <figure className="figure">
                            <a href={file.reportMediaObject.contentUrl ?? ""} target="_blank" rel="noopener noreferrer">
                                {file.reportMediaObject.mimeType?.startsWith("image/") ? (
                                    <img
                                        className="figure-img img-fluid"
                                        src={file.reportMediaObject.contentUrl ?? ""}
                                        alt={file.reportMediaObject.originalName ?? "Screenshot"}
                                    />
                                ) : (
                                    <div>
                                        <FaFile />
                                        {file.reportMediaObject.originalName}
                                    </div>
                                )}
                            </a>

                            {file.caption != null && <figcaption className="figure-caption">{file.caption}</figcaption>}
                        </figure>

                        {file.description != null && <HtmlContent html={file.description} />}
                    </div>
                ))}
        </div>
    </>
);
