import { FC, InputHTMLAttributes } from "react";
import { FormControlProps } from "react-bootstrap/FormControl";
import { FormControlSelectChoice } from "app/component/form/control/FormControlSelectChoice";
import { ReportVulnerabilityStatus } from "app/api/graph/types";
import { reportVulnerabilityStatusLabel } from "app/api/graph/strings";

type Props = Omit<InputHTMLAttributes<HTMLSelectElement> & FormControlProps, "type" | "value" | "onChange"> & {
    value: ReportVulnerabilityStatus | undefined;
    onChange: (value: ReportVulnerabilityStatus) => void;
};

const options = [
    ReportVulnerabilityStatus.Open,
    ReportVulnerabilityStatus.Resolved,
    ReportVulnerabilityStatus.VerifiedResolved,
    ReportVulnerabilityStatus.RiskAccepted,
    ReportVulnerabilityStatus.Informational,
].map((value) => ({
    label: reportVulnerabilityStatusLabel(value),
    value,
}));

export const FormControlSelectReportVulnerabilityStatus: FC<Props> = ({ value, onChange, ...props }) => (
    <FormControlSelectChoice {...props} options={options} value={value} onChange={onChange} />
);
