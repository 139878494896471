import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useAcceptReportVulnerabilityRiskMutation } from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";

type Props = {
    vulnerabilityId: number;
    onAcceptRisk?: () => void;
};

export const AcceptRiskReportVulnerabilityForm: FC<Props> = ({ vulnerabilityId, onAcceptRisk }) => {
    const [acceptRisk, { loading, error }] = useAcceptReportVulnerabilityRiskMutation({
        variables: {
            vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId),
        },
    });

    return (
        <div>
            {error ? <Alert variant="danger">{error.message}</Alert> : null}
            <Alert variant="warning">Do you really want to accept the risk of this vulnerability?</Alert>
            <Button
                type="submit"
                variant="warning"
                disabled={loading}
                onClick={() => acceptRisk().then(() => onAcceptRisk?.())}
            >
                Accept risk
            </Button>
        </div>
    );
};
