import { FC } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReportVulnerabilityFieldsFragment, VulnerabilityListFieldsFragment } from "app/api/graph/types";
import { SeverityBadge } from "app/component/report-vulnerability/component/SeverityBadge";
import { vulnerabilityRoute } from "app/route/Routes.tsx";

type Props = {
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
    title?: string;
};

type Group = {
    id: string;
    vulnerability: VulnerabilityListFieldsFragment;
    count: number;
};

export const TopVulnerabilitiesListCard: FC<Props> = ({ vulnerabilities, title }) => {
    const groups: Group[] = Object.entries(
        Object.groupBy(vulnerabilities, (vulnerability) => vulnerability.baseVulnerability?.id ?? ""),
    )
        .filter((entry): entry is [string, ReportVulnerabilityFieldsFragment[]] => entry[1] !== undefined)
        .map(([id, items]) => ({
            id,
            vulnerability: items[0].baseVulnerability,
            count: items.length,
        }))
        .filter((item): item is Group => item.vulnerability != null);

    return (
        <Accordion className="mb-3" defaultActiveKey={groups.length > 0 ? "0" : null}>
            <Accordion.Item eventKey="0">
                <Accordion.Button className="bg-light py-2">
                    {title ?? "Vulnerabilities"} ({groups.length})
                </Accordion.Button>
                <Accordion.Body className="p-0">
                    <ListGroup variant="flush" style={{ maxHeight: "300px", overflowY: "auto" }}>
                        {groups.map(({ id, vulnerability, count }) => (
                            <ListGroup.Item
                                key={id}
                                action
                                as={Link}
                                to={vulnerabilityRoute.build({ vulnerabilityId: vulnerability._id })}
                                className="d-flex justify-content-between align-items-center"
                            >
                                <div>
                                    <div>
                                        <strong>{vulnerability.identifier}</strong> {vulnerability.title} ({count})
                                    </div>
                                </div>
                                <SeverityBadge severity={vulnerability.severity} className="ms-2" />
                            </ListGroup.Item>
                        ))}
                        {groups.length === 0 ? <ListGroup.Item>No master vulnerabilities.</ListGroup.Item> : null}
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
