import { FC } from "react";
import { CreateCompanyContactMutation, useCreateCompanyContactMutation } from "app/api/graph/types";
import { FormValues, CompanyContactForm } from "app/component/company-contact/CompanyContactForm";
import { companyGraphId } from "app/api/graph/helpers";

type Props = {
    companyId: number;
    onCreate: (
        report: NonNullable<NonNullable<CreateCompanyContactMutation["createCompanyContact"]>["companyContact"]>,
    ) => void;
};

const initialValues: FormValues = {
    name: "",
};

export const CreateCompanyContactForm: FC<Props> = ({ companyId, onCreate }) => {
    const [createCompanyContact, { error }] = useCreateCompanyContactMutation({});

    const onSubmit = (values: FormValues) =>
        createCompanyContact({ variables: { input: { ...values, company: companyGraphId(companyId) } } })
            .then((response) => response.data?.createCompanyContact?.companyContact)
            .then((contact) => (contact ? onCreate?.(contact) : undefined));

    return <CompanyContactForm initialValues={initialValues} onSubmit={onSubmit} error={error} />;
};
