import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ApolloError } from "@apollo/client";
import yup from "app/util/yup";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";

type Props = {
    initialValues: FormValues;
    onSubmit: (values: FormValues) => Promise<void>;
    error?: ApolloError;
};

export type FormValues = {
    name: string;
    address?: string;
    companyName?: string;
    email?: string;
    mobile?: string;
    phone?: string;
    position?: string;
};

export const schema: yup.ObjectSchema<FormValues> = yup.object({
    name: yup.string().required(),
    address: yup.string(),
    companyName: yup.string(),
    email: yup.string().email(),
    mobile: yup.string(),
    phone: yup.string(),
    position: yup.string(),
});

export const ContactForm: FC<Props> = (props) => {
    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        props
            .onSubmit(values)
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={props.initialValues}>
            {({ handleSubmit, handleChange, handleBlur, values, isSubmitting, errors, touched, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control
                            type="tel"
                            name="mobile"
                            placeholder="Mobile"
                            value={values.mobile}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.mobile && !!errors.mobile}
                        />
                        <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                            type="text"
                            name="companyName"
                            placeholder="Company"
                            value={values.companyName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.companyName && !!errors.companyName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.companyName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Position</Form.Label>
                        <Form.Control
                            type="text"
                            name="position"
                            placeholder="Position"
                            value={values.position}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.position && !!errors.position}
                        />
                        <Form.Control.Feedback type="invalid">{errors.position}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="address"
                            placeholder="Address"
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.address && !!errors.address}
                        />
                        <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            type="tel"
                            name="phone"
                            placeholder="Phone"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.phone && !!errors.phone}
                        />
                        <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
