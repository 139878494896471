import { FC, useState } from "react";
import { formatISO } from "date-fns";
import { mapNodes } from "app/util/graph";
import { usePublishedCompanyReportVulnerabilitiesListQuery } from "app/api/graph/types";
import { companyGraphId } from "app/api/graph/helpers";
import { FormValues } from "app/component/report-vulnerability/ReportVulnerabilitiesTableFiltersForm";
import {
    BaseReportVulnerabilitiesTable,
    Order,
} from "app/component/report-vulnerability/BaseReportVulnerabilitiesTable.tsx";

type Props = {
    companyId: number;
    initialFilters?: FormValues;
};

export const PublishedCompanyReportVulnerabilitiesTable: FC<Props> = ({ companyId, initialFilters }) => {
    const [order, setOrder] = useState<Order>({
        issueNumber: undefined,
        title: undefined,
        severity: undefined,
        status: undefined,
        discovered: undefined,
    });
    const [filters, setFilters] = useState<FormValues>(initialFilters ?? {});
    const { data, loading, error, fetchMore } = usePublishedCompanyReportVulnerabilitiesListQuery({
        variables: {
            companyId: companyGraphId(companyId),
            order,
            issueNumber: filters.issueNumber,
            title: filters.title,
            status: filters.status,
            severity: filters.severity,
            testingTypes: filters.testingTypes,
            dateDiscovered: {
                after: filters.dateDiscoveredFrom ? formatISO(filters.dateDiscoveredFrom) : undefined,
                before: filters.dateDiscoveredTo ? formatISO(filters.dateDiscoveredTo) : undefined,
            },
            cvssScore: filters.cvssScore,
        },
        notifyOnNetworkStatusChange: true,
    });
    const vulnerabilities = data?.publishedCompanyReportVulnerabilities;

    const fetchMoreVulnerabilities = () => fetchMore({ variables: { after: vulnerabilities?.pageInfo.endCursor } });

    return (
        <BaseReportVulnerabilitiesTable
            loading={loading}
            error={error}
            vulnerabilities={vulnerabilities != null ? mapNodes(vulnerabilities, (item) => item) : undefined}
            filters={filters}
            order={order}
            hasMore={vulnerabilities?.pageInfo.hasNextPage ?? false}
            setFilters={setFilters}
            setOrder={setOrder}
            fetchMore={fetchMoreVulnerabilities}
        />
    );
};
