import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ApolloError } from "@apollo/client";
import yup from "app/util/yup";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";

type Props = {
    initialValues: FormValues;
    onSubmit: (values: FormValues) => Promise<void>;
    error?: ApolloError;
};

export type FormValues = {
    name: string;
};

export const schema: yup.ObjectSchema<FormValues> = yup.object({
    name: yup.string().required(),
});

export const TestingTypeForm: FC<Props> = (props) => {
    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        props
            .onSubmit(values)
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={props.initialValues}>
            {({ handleSubmit, handleChange, handleBlur, values, isSubmitting, errors, touched, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.name && !!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
