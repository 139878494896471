import { FC, useMemo } from "react";
import { Formik, FormikHelpers } from "formik";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import yup from "app/util/yup";
import { reportGraphId } from "app/api/graph/helpers";
import { NewVersionOfReportMutation, useNewVersionOfReportMutation } from "app/api/graph/types";
import { StatusAlert } from "app/component/form/StatusAlert";
import { handleError, handleSuccess } from "app/api/graph/form";

type Props = {
    reportId: number;
    onCreate?: (
        version: NonNullable<
            NonNullable<NonNullable<NewVersionOfReportMutation["newVersionOfReport"]>["report"]>["latestVersion"]
        >,
    ) => void;
};

type FormValues = {
    versionNumber: string;
    versionName: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    versionNumber: yup.string().required(),
    versionName: yup.string().required(),
});

export const NewReportVersionForm: FC<Props> = ({ reportId, onCreate }) => {
    const [createVersion] = useNewVersionOfReportMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            versionNumber: "",
            versionName: "",
        }),
        [],
    );

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        createVersion({
            variables: {
                input: {
                    id: reportGraphId(reportId),
                    ...values,
                },
            },
        })
            .then(
                (response) =>
                    response.data?.newVersionOfReport?.report?.latestVersion &&
                    onCreate?.(response.data.newVersionOfReport.report.latestVersion),
            )
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, handleBlur, handleChange, values, isSubmitting, errors, touched, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Alert variant="info">The latest report version will be copied to create a new version.</Alert>
                    <Form.Group className="mb-3">
                        <Form.Label>Version number</Form.Label>
                        <Form.Control
                            type="text"
                            name="versionNumber"
                            value={values.versionNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.versionNumber && !!errors.versionNumber}
                        />
                        <Form.Control.Feedback type="invalid">{errors.versionNumber}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Version name</Form.Label>
                        <Form.Control
                            type="text"
                            name="versionName"
                            value={values.versionName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.versionName && !!errors.versionName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.versionName}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Create new version
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
