import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { SignInScreen } from "app/component/sign-in/SignInScreen";
import { useAuth } from "app/api/AuthContext";
import { AuthRoute } from "app/route/AuthRoute";
import { DashboardScreen } from "app/component/dashboard/DashboardScreen";
import { ReportsListScreen } from "app/component/report/ReportsListScreen";
import { CreateReportScreen } from "app/component/report/CreateReportScreen";
import { ReportScreen } from "app/component/report/ReportScreen";
import { EditReportVersionScreen } from "app/component/report-version/EditReportVersionScreen";
import { ReportVersionScreen } from "app/component/report-version/ReportVersionScreen";
import { EditReportVersionVersionScreen } from "app/component/report-version/EditReportVersionVersionScreen";
import { enumParser, intParser, route } from "app/route/route";
import { ReportVersionsScreen } from "app/component/report-version/ReportVersionsScreen";
import { EditReportVersionTestScreen } from "app/component/report-version/EditReportVersionTestScreen";
import { EditReportVersionClientScreen } from "app/component/report-version/EditReportVersionClientScreen";
import { EditReportVersionConsultantScreen } from "app/component/report-version/EditReportVersionConsultantScreen";
import { EditReportVersionContactScreen } from "app/component/report-version/EditReportVersionContactScreen";
import { EditReportVersionSectionsScreen } from "app/component/report-version/EditReportVersionSectionsScreen";
import { EditReportVersionVulnerabilitiesScreen } from "app/component/report-version/EditReportVersionVulnerabilitiesScreen";
import { EditReportVersionConsultantContactScreen } from "app/component/report-version/EditReportVersionConsultantContactScreen";
import { EditReportVersionSectionScreen } from "app/component/report-version/EditReportVersionSectionScreen";
import { EditReportVersionVulnerabilityScreen } from "app/component/report-version/EditReportVersionVulnerabilityScreen";
import { CreateReportVersionVulnerabilityScreen } from "app/component/report-version/CreateReportVersionVulnerabilityScreen";
import { NewReportVersionScreen } from "app/component/report/NewReportVersionScreen";
import { ReportInvitesListScreen } from "app/component/report/ReportInvitesListScreen";
import { CreateReportInviteScreen } from "app/component/report/CreateReportInviteScreen";
import { AcceptReportInviteScreen } from "app/component/report-invite/AcceptReportInviteScreen";
import { RegisterScreen } from "app/component/register/RegisterScreen";
import { CreateReportVersionVulnerabilityActionScreen } from "app/component/report-version/CreateReportVersionVulnerabilityActionScreen";
import { ReportVersionVulnerabilityActionsScreen } from "app/component/report-version/ReportVersionVulnerabilityActionsScreen";
import { EditReportVersionVulnerabilityActionScreen } from "app/component/report-version/EditReportVersionVulnerabilityActionScreen";
import { ReportVersionVulnerabilityScreen } from "app/component/report-version/ReportVersionVulnerabilityScreen";
import { ResolveReportVersionVulnerabilityScreen } from "app/component/report-version/ResolveReportVersionVulnerabilityScreen";
import { ReopenReportVersionVulnerabilityScreen } from "app/component/report-version/ReopenReportVersionVulnerabilityScreen";
import { ReportVersionVulnerabilitiesScreen } from "app/component/report-version/ReportVersionVulnerabilitiesScreen";
import { ReportVersionVulnerabilityActionScreen } from "app/component/report-version/ReportVersionVulnerabilityActionScreen";
import { EditReportScreen } from "app/component/report/EditReportScreen";
import { DeleteReportVersionVulnerabilityScreen } from "app/component/report-version/DeleteReportVersionVulnerabilityScreen";
import { DeleteReportInviteScreen } from "app/component/report/DeleteReportInviteScreen";
import { DeleteReportVersionVulnerabilityActionScreen } from "app/component/report-version/DeleteReportVersionVulnerabilityActionScreen";
import { ResetPasswordRequestScreen } from "app/component/reset-password/ResetPasswordRequestScreen";
import { ResetPasswordScreen } from "app/component/reset-password/ResetPasswordScreen";
import { UserChangePasswordScreen } from "app/component/user/UserChangePasswordScreen";
import { ConfirmEmailScreen } from "app/component/confirm-email/ConfirmEmailScreen";
import {
    createReportInviteRole,
    createReportRole,
    createVulnerabilityActionRole,
    createReportVulnerabilityRole,
    deleteReportInviteRole,
    deleteVulnerabilityActionRole,
    deleteReportVulnerabilityRole,
    listReportInvitesRole,
    transitionReportVulnerabilityRole,
    updateReportRole,
    updateVulnerabilityActionRole,
    updateReportVulnerabilityRole,
    updateCompanyRole,
    listUsersRole,
    updateUserRole,
    deleteUserRole,
    deleteCompanyRole,
    deleteReportRole,
    listReportReadReceiptsRole,
    listVulnerabilitiesRole,
    updateVulnerabilityRole,
    deleteVulnerabilityRole,
    testerRole,
    transitionReportVersionRole,
    updateReportInviteRole,
    listContactsRole,
    updateContactRole,
    deleteContactRole,
    createContactRole,
    updateTestingTypeRole,
    deleteTestingTypeRole,
    listTestingTypesRole,
    createTestingTypeRole,
    createCompanyRole,
    createVulnerabilityRole,
    listReportVulnerabilitiesRole,
} from "app/api/graph/roles";
import { EditCompanyScreen } from "app/component/company/EditCompanyScreen";
import { CompanyScreen } from "app/component/company/CompanyScreen";
import { CompaniesListScreen } from "app/component/company/CompaniesListScreen";
import { CreateCompanyScreen } from "app/component/company/CreateCompanyScreen";
import { CompanyInvitesListScreen } from "app/component/company/CompanyInvitesListScreen";
import { CreateCompanyInviteScreen } from "app/component/company/CreateCompanyInviteScreen";
import { DeleteCompanyInviteScreen } from "app/component/company/DeleteCompanyInviteScreen";
import { AcceptCompanyInviteScreen } from "app/component/company-invite/AcceptCompanyInviteScreen";
import { RemoveCompanyUserScreen } from "app/component/company/RemoveCompanyUserScreen";
import { UsersScreen } from "app/component/user/UsersScreen";
import { EditUserScreen } from "app/component/user/EditUserScreen";
import { AddCompanyUserScreen } from "app/component/company/AddCompanyUserScreen";
import { DeleteUserScreen } from "app/component/user/DeleteUserScreen";
import { DeleteCompanyScreen } from "app/component/company/DeleteCompanyScreen";
import { DeleteReportScreen } from "app/component/report/DeleteReportScreen";
import { UserScreen } from "app/component/user/UserScreen";
import { UserSettingsScreen } from "app/component/user/UserSettingsScreen";
import { UpdateUserMobileNumberScreen } from "app/component/user/UpdateUserMobileNumberScreen";
import { ReportVersionReadReceiptsScreen } from "app/component/report-version/ReportVersionReadReceiptsScreen";
import { VulnerabilitiesListScreen } from "app/component/vulnerability/VulnerabilitiesListScreen";
import { CreateVulnerabilityScreen } from "app/component/vulnerability/CreateVulnerabilityScreen";
import { EditVulnerabilityScreen } from "app/component/vulnerability/EditVulnerabilityScreen";
import { DeleteVulnerabilityScreen } from "app/component/vulnerability/DeleteVulnerabilityScreen";
import { VulnerabilityScreen } from "app/component/vulnerability/VulnerabilityScreen";
import { AcceptRiskReportVersionVulnerabilityScreen } from "app/component/report-version/AcceptRiskReportVersionVulnerabilityScreen";
import { ReportVulnerabilityStatus } from "app/api/graph/types";
import { Template } from "app/component/report-version/component/template/template-builder";
import { VerifyResolvedReportVersionVulnerabilityScreen } from "app/component/report-version/VerifyResolvedReportVersionVulnerabilityScreen";
import { UploadCompanyLogoScreen } from "app/component/company/UploadCompanyLogoScreen";
import { PublishReportVersionScreen } from "app/component/report-version/PublishReportVersionScreen";
import { CreateCompanyContactScreen } from "app/component/company/CreateCompanyContactScreen";
import { EditCompanyContactScreen } from "app/component/company/EditCompanyContactScreen";
import { CompanyContactsListScreen } from "app/component/company/CompanyContactsListScreen";
import { DeleteCompanyContactScreen } from "app/component/company/DeleteCompanyContactScreen";
import { ReportVersionTestCertificateScreen } from "app/component/report-version/ReportVersionTestCertificateScreen";
import { ResendCompanyInviteScreen } from "app/component/company/ResendCompanyInviteScreen";
import { ResendReportInviteScreen } from "app/component/report/ResendReportInviteScreen";
import { CreateReportVulnerabilityRetestRequestScreen } from "app/component/report-version/CreateReportVulnerabilityRetestRequestScreen";
import { CreateReportVulnerabilityClientNotificationScreen } from "app/component/report-version/CreateReportVulnerabilityClientNotificationScreen";
import { ContactsListScreen } from "app/component/contact/ContactsListScreen.tsx";
import { CreateContactScreen } from "app/component/contact/CreateContactScreen.tsx";
import { EditContactScreen } from "app/component/contact/EditContactScreen.tsx";
import { DeleteContactScreen } from "app/component/contact/DeleteContactScreen.tsx";
import { EditTestingTypeScreen } from "app/component/testing-type/EditTestingTypeScreen.tsx";
import { DeleteTestingTypeScreen } from "app/component/testing-type/DeleteTestingTypeScreen.tsx";
import { CreateTestingTypeScreen } from "app/component/testing-type/CreateTestingTypeScreen.tsx";
import { TestingTypesListScreen } from "app/component/testing-type/TestingTypesListScreen.tsx";
import { CompanyUsersListScreen } from "app/component/company/CompanyUsersListScreen.tsx";
import { CompanyReportsListScreen } from "app/component/company/CompanyReportsListScreen.tsx";
import { CompanyReportVulnerabilitiesListScreen } from "app/component/company/CompanyReportVulnerabilitiesListScreen.tsx";
import { AllReportVulnerabilitiesListScreen } from "app/component/report-vulnerability/AllReportVulnerabilitiesListScreen.tsx";
import { AllReportVulnerabilitiesOverviewScreen } from "app/component/report-vulnerability/AllReportVulnerabilitiesOverviewScreen.tsx";
import { CreateReportVersionVulnerabilityFileScreen } from "app/component/report-version/CreateReportVersionVulnerabilityFileScreen.tsx";
import { EditReportVersionVulnerabilityFileScreen } from "app/component/report-version/EditReportVersionVulnerabilityFileScreen.tsx";
import { DeleteReportVersionVulnerabilityFileScreen } from "app/component/report-version/DeleteReportVersionVulnerabilityFileScreen.tsx";
import { ReportUsersListScreen } from "app/component/report/ReportUsersListScreen.tsx";
import { AddReportUserScreen } from "app/component/report/AddReportUserScreen.tsx";
import { RemoveReportUserScreen } from "app/component/report/RemoveReportUserScreen.tsx";
import { AddReportUserFromCompanyScreen } from "app/component/report/AddReportUserFromCompanyScreen.tsx";

export const signInRoute = route("/sign-in", "Sign in");
export const registerRoute = route("/register", "Register");
export const resetPasswordRoute = route("/reset-password", "Reset password");
export const confirmEmailRoute = route("/confirm-email", "Confirm email");
export const resetPasswordRequestRoute = route("/reset-password/request", "Reset password");
export const dashboardRoute = route("/dashboard", "Dashboard");

export const companiesRoute = route("/companies", "Companies");
export const createCompanyRoute = route("/create", "Create", {}, companiesRoute);
export const companyRoute = route("/:companyId", "Company", { companyId: intParser }, companiesRoute);
export const editCompanyRoute = route("/edit", "Edit", {}, companyRoute);
export const deleteCompanyRoute = route("/delete", "Delete", {}, companyRoute);
export const uploadCompanyLogoRoute = route("/upload-logo", "Edit", {}, companyRoute);
export const companyInvitesRoute = route("/invites", "Invites", {}, companyRoute);
export const companyInviteRoute = route("/:inviteId", "Invite", { inviteId: intParser }, companyInvitesRoute);
export const deleteCompanyInviteRoute = route("/delete", "Delete", {}, companyInviteRoute);
export const resendCompanyInviteRoute = route("/resend", "Resend", {}, companyInviteRoute);
export const createCompanyInviteRoute = route("/create", "Invite", {}, companyInvitesRoute);
export const acceptReportInviteRoute = route("/report-invites/:inviteId", "Invite", { inviteId: intParser });
export const acceptCompanyInviteRoute = route("/company-invites/:inviteId", "Invite", { inviteId: intParser });
export const companyContactsRoute = route("/contacts", "Contacts", {}, companyRoute);
export const createCompanyContactRoute = route("/create", "Contact", {}, companyContactsRoute);
export const companyContactRoute = route("/:contactId", "Contact", { contactId: intParser }, companyContactsRoute);
export const editCompanyContactRoute = route("/edit", "Edit", {}, companyContactRoute);
export const deleteCompanyContactRoute = route("/delete", "Delete", {}, companyContactRoute);
export const companyUsersRoute = route("/users", "Company users", {}, companyRoute);
export const addCompanyUserRoute = route("/add", "Add user", {}, companyUsersRoute);
export const removeCompanyUserRoute = route("/:userId/remove", "Remove user", { userId: intParser }, companyUsersRoute);
export const companyReportsRoute = route("/reports", "Company reports", {}, companyRoute);
export const companyReportVulnerabilitiesRoute = route(
    "/report-vulnerabilities",
    "Company report vulnerabilities",
    {},
    companyRoute,
);
export const reportsRoute = route("/reports", "Reports");
export const createReportRoute = route("/create", "Create", {}, reportsRoute);
export const reportRoute = route("/:reportId", "Report", { reportId: intParser }, reportsRoute);
export const editReportRoute = route("/edit", "Edit", {}, reportRoute);
export const deleteReportRoute = route("/delete", "Delete", {}, reportRoute);
export const reportVersionsRoute = route("/versions", "Versions", {}, reportRoute);
export const reportInvitesRoute = route("/invites", "Invites", {}, reportRoute);
export const reportInviteRoute = route("/:inviteId", "Invite", { inviteId: intParser }, reportInvitesRoute);
export const deleteReportInviteRoute = route("/invites", "Delete", {}, reportInviteRoute);
export const resendReportInviteRoute = route("/resend", "Resend", {}, reportInviteRoute);
export const createReportInviteRoute = route("/create", "Invite", {}, reportInvitesRoute);
export const reportUsersRoute = route("/users", "Users", {}, reportRoute);
export const addReportUserRoute = route("/add", "Add user", {}, reportUsersRoute);
export const addReportUserFromCompanyRoute = route("/add-from-company", "Add company user", {}, reportUsersRoute);
export const removeReportUserRoute = route("/:userId/remove", "Remove user", { userId: intParser }, reportUsersRoute);
export const newReportVersionRoute = route("/new", "New version", {}, reportVersionsRoute);
export const reportVersionRoute = route("/:versionId", "Version", { versionId: intParser }, reportVersionsRoute, {
    template: enumParser(Template),
});
export const reportVersionTestCertificateRoute = route("/test-certificate", "Test certificate", {}, reportVersionRoute);
export const reportVersionReadReceiptsRoute = route("/read-receipts", "Read receipts", {}, reportVersionRoute);
export const reportVersionPublishRoute = route("/publish", "Publish", {}, reportVersionRoute);
export const reportVersionVulnerabilitiesRoute = route("/vulnerabilities", "Vulnerabilities", {}, reportVersionRoute, {
    status: enumParser(ReportVulnerabilityStatus),
});
export const reportVersionVulnerabilityRoute = route(
    "/:vulnerabilityId",
    "Vulnerability",
    { vulnerabilityId: intParser },
    reportVersionVulnerabilitiesRoute,
);
export const deleteReportVersionVulnerabilityRoute = route("/delete", "Delete", {}, reportVersionVulnerabilityRoute);
export const resolveReportVersionVulnerabilityRoute = route("/resolve", "Resolve", {}, reportVersionVulnerabilityRoute);
export const acceptRiskReportVersionVulnerabilityRoute = route(
    "/accept-risk",
    "Accept risk",
    {},
    reportVersionVulnerabilityRoute,
);
export const verifyResolvedReportVersionVulnerabilityRoute = route(
    "/verify-resolved",
    "Verify resolved",
    {},
    reportVersionVulnerabilityRoute,
);
export const reopenReportVersionVulnerabilityRoute = route("/reopen", "Reopen", {}, reportVersionVulnerabilityRoute);
export const reportVersionVulnerabilityActionsRoute = route("/actions", "Actions", {}, reportVersionVulnerabilityRoute);
export const createReportVersionVulnerabilityActionRoute = route(
    "/create",
    "Create",
    {},
    reportVersionVulnerabilityActionsRoute,
);
export const reportVersionVulnerabilityActionRoute = route(
    "/:actionId",
    "Action",
    { actionId: intParser },
    reportVersionVulnerabilityActionsRoute,
);
export const editReportVersionVulnerabilityActionRoute = route(
    "/edit",
    "Edit",
    {},
    reportVersionVulnerabilityActionRoute,
);
export const deleteReportVersionVulnerabilityActionRoute = route(
    "/delete",
    "Delete",
    {},
    reportVersionVulnerabilityActionRoute,
);
export const reportVersionVulnerabilityFilesRoute = route("/files", "Files", {}, reportVersionVulnerabilityRoute);
export const createReportVersionVulnerabilityFileRoute = route(
    "/create",
    "Create",
    {},
    reportVersionVulnerabilityFilesRoute,
);
export const reportVersionVulnerabilityFileRoute = route(
    "/:fileId",
    "File",
    { fileId: intParser },
    reportVersionVulnerabilityFilesRoute,
);
export const editReportVersionVulnerabilityFileRoute = route("/edit", "Edit", {}, reportVersionVulnerabilityFileRoute);
export const deleteReportVersionVulnerabilityFileRoute = route(
    "/delete",
    "Delete",
    {},
    reportVersionVulnerabilityFileRoute,
);
export const createReportVersionVulnerabilityRetestRequestRoute = route(
    "/retest-requests/create",
    "Request re-test",
    {},
    reportVersionVulnerabilityRoute,
);
export const createReportVersionVulnerabilityClientNotificationRoute = route(
    "/client-notifications/create",
    "Send client notification",
    {},
    reportVersionVulnerabilityRoute,
);
export const editReportVersionRoute = route("/edit", "Edit", {}, reportVersionRoute);
export const editReportVersionVersionRoute = route("/version", "Version", {}, editReportVersionRoute);
export const editReportVersionTestRoute = route("/test", "Test", {}, editReportVersionRoute);
export const editReportVersionClientRoute = route("/client", "Client", {}, editReportVersionRoute);
export const editReportVersionConsultantRoute = route("/consultant", "Consultant", {}, editReportVersionRoute);
export const editReportVersionConsultantContactRoute = route(
    "/contact",
    "Contact",
    {},
    editReportVersionConsultantRoute,
);
export const editReportVersionContactRoute = route("/contact", "Contact", {}, editReportVersionRoute);
export const editReportVersionSectionsRoute = route("/sections", "Sections", {}, editReportVersionRoute);
export const editReportVersionSectionRoute = route(
    "/:sectionId",
    "Section",
    { sectionId: intParser },
    editReportVersionSectionsRoute,
);
export const editReportVersionVulnerabilitiesRoute = route(
    "/vulnerabilities",
    "Vulnerabilities",
    {},
    editReportVersionRoute,
);
export const createReportVersionVulnerabilityRoute = route(
    "/create",
    "Create",
    {},
    editReportVersionVulnerabilitiesRoute,
);
export const editReportVersionVulnerabilityRoute = route(
    "/:vulnerabilityId",
    "Vulnerability",
    { vulnerabilityId: intParser },
    editReportVersionVulnerabilitiesRoute,
);
export const usersRoute = route("/users", "Users");
export const userRoute = route("/user/:userId", "User", { userId: intParser }, usersRoute);
export const editUserRoute = route("/edit", "Edit", {}, userRoute);
export const deleteUserRoute = route("/user/:userId/delete", "Delete", {}, userRoute);
export const userChangePasswordRoute = route("/change-password", "Change password", {}, userRoute);
export const updateUserMobileNumberRoute = route("/update-mobile-number", "Update mobile number", {}, userRoute);
export const userSettingsRoute = route("/settings", "Settings", {}, userRoute);
export const vulnerabilitiesRoute = route("/vulnerabilities", "Vulnerabilities");
export const createVulnerabilityRoute = route("/create", "Create", {}, vulnerabilitiesRoute);
export const vulnerabilityRoute = route(
    "/:vulnerabilityId",
    "Vulnerability",
    { vulnerabilityId: intParser },
    vulnerabilitiesRoute,
);
export const editVulnerabilityRoute = route("/edit", "Edit", {}, vulnerabilityRoute);
export const deleteVulnerabilityRoute = route("/delete", "Delete", {}, vulnerabilityRoute);
export const contactsRoute = route("/contacts", "Contacts");
export const createContactRoute = route("/create", "Create", {}, contactsRoute);
export const contactRoute = route("/:contactId", "Contact", { contactId: intParser }, contactsRoute);
export const editContactRoute = route("/edit", "Edit", {}, contactRoute);
export const deleteContactRoute = route("/delete", "Delete", {}, contactRoute);
export const testingTypesRoute = route("/testing-types", "Testing types");
export const createTestingTypeRoute = route("/create", "Create", {}, testingTypesRoute);
export const testingTypeRoute = route(
    "/:testingTypeId",
    "Testing type",
    { testingTypeId: intParser },
    testingTypesRoute,
);
export const editTestingTypeRoute = route("/edit", "Edit", {}, testingTypeRoute);
export const deleteTestingTypeRoute = route("/delete", "Delete", {}, testingTypeRoute);
export const reportVulnerabilitiesRoute = route("/report-vulnerabilities", "Report vulnerabilities", {});
export const reportVulnerabilitiesOverviewRoute = route(
    "/overview",
    "Report vulnerabilities overview",
    {},
    reportVulnerabilitiesRoute,
);

export const AppRoutes: FC = () => {
    const { isSignedIn } = useAuth();
    return (
        <Routes>
            <Route
                path={editReportVersionVersionRoute.template}
                element={
                    <AuthRoute roles={[updateReportRole]}>
                        <EditReportVersionVersionScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionTestRoute.template}
                element={
                    <AuthRoute roles={[updateReportRole]}>
                        <EditReportVersionTestScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionClientRoute.template}
                element={
                    <AuthRoute roles={[updateReportRole]}>
                        <EditReportVersionClientScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionConsultantContactRoute.template}
                element={
                    <AuthRoute roles={[updateReportRole]}>
                        <EditReportVersionConsultantContactScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionConsultantRoute.template}
                element={
                    <AuthRoute roles={[updateReportRole]}>
                        <EditReportVersionConsultantScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionContactRoute.template}
                element={
                    <AuthRoute roles={[updateReportRole]}>
                        <EditReportVersionContactScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionSectionRoute.template}
                element={
                    <AuthRoute roles={[updateReportRole]}>
                        <EditReportVersionSectionScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionSectionsRoute.template}
                element={
                    <AuthRoute roles={[updateReportRole]}>
                        <EditReportVersionSectionsScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createReportVersionVulnerabilityRoute.template}
                element={
                    <AuthRoute roles={[createReportVulnerabilityRole]}>
                        <CreateReportVersionVulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionVulnerabilityRoute.template}
                element={
                    <AuthRoute roles={[updateReportVulnerabilityRole]}>
                        <EditReportVersionVulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionVulnerabilitiesRoute.template}
                element={
                    <AuthRoute roles={[updateReportVulnerabilityRole]}>
                        <EditReportVersionVulnerabilitiesScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createReportVersionVulnerabilityActionRoute.template}
                element={
                    <AuthRoute roles={[createVulnerabilityActionRole]}>
                        <CreateReportVersionVulnerabilityActionScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportVersionVulnerabilityActionRoute.template}
                element={
                    <AuthRoute>
                        <ReportVersionVulnerabilityActionScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionVulnerabilityActionRoute.template}
                element={
                    <AuthRoute roles={[updateVulnerabilityActionRole]}>
                        <EditReportVersionVulnerabilityActionScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteReportVersionVulnerabilityActionRoute.template}
                element={
                    <AuthRoute roles={[deleteVulnerabilityActionRole]}>
                        <DeleteReportVersionVulnerabilityActionScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createReportVersionVulnerabilityFileRoute.template}
                element={
                    <AuthRoute>
                        <CreateReportVersionVulnerabilityFileScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionVulnerabilityFileRoute.template}
                element={
                    <AuthRoute>
                        <EditReportVersionVulnerabilityFileScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteReportVersionVulnerabilityFileRoute.template}
                element={
                    <AuthRoute>
                        <DeleteReportVersionVulnerabilityFileScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createReportVersionVulnerabilityRetestRequestRoute.template}
                element={
                    <AuthRoute>
                        <CreateReportVulnerabilityRetestRequestScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createReportVersionVulnerabilityClientNotificationRoute.template}
                element={
                    <AuthRoute>
                        <CreateReportVulnerabilityClientNotificationScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportVersionVulnerabilityActionsRoute.template}
                element={
                    <AuthRoute>
                        <ReportVersionVulnerabilityActionsScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteReportVersionVulnerabilityRoute.template}
                element={
                    <AuthRoute roles={[deleteReportVulnerabilityRole]}>
                        <DeleteReportVersionVulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={resolveReportVersionVulnerabilityRoute.template}
                element={
                    <AuthRoute roles={[transitionReportVulnerabilityRole]}>
                        <ResolveReportVersionVulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={acceptRiskReportVersionVulnerabilityRoute.template}
                element={
                    <AuthRoute roles={[transitionReportVulnerabilityRole]}>
                        <AcceptRiskReportVersionVulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={verifyResolvedReportVersionVulnerabilityRoute.template}
                element={
                    <AuthRoute roles={[testerRole]}>
                        <VerifyResolvedReportVersionVulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reopenReportVersionVulnerabilityRoute.template}
                element={
                    <AuthRoute roles={[transitionReportVulnerabilityRole]}>
                        <ReopenReportVersionVulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportVersionVulnerabilityRoute.template}
                element={
                    <AuthRoute>
                        <ReportVersionVulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportVersionVulnerabilitiesRoute.template}
                element={
                    <AuthRoute>
                        <ReportVersionVulnerabilitiesScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportVersionReadReceiptsRoute.template}
                element={
                    <AuthRoute roles={[listReportReadReceiptsRole]}>
                        <ReportVersionReadReceiptsScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportVersionPublishRoute.template}
                element={
                    <AuthRoute roles={[transitionReportVersionRole]}>
                        <PublishReportVersionScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportVersionRoute.template}
                element={
                    <AuthRoute roles={[updateReportRole]}>
                        <EditReportVersionScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={newReportVersionRoute.template}
                element={
                    <AuthRoute roles={[updateReportRole]}>
                        <NewReportVersionScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportVersionTestCertificateRoute.template}
                element={
                    <AuthRoute>
                        <ReportVersionTestCertificateScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportVersionRoute.template}
                element={
                    <AuthRoute>
                        <ReportVersionScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportVersionsRoute.template}
                element={
                    <AuthRoute>
                        <ReportVersionsScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createReportInviteRoute.template}
                element={
                    <AuthRoute roles={[createReportInviteRole]}>
                        <CreateReportInviteScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteReportInviteRoute.template}
                element={
                    <AuthRoute roles={[deleteReportInviteRole]}>
                        <DeleteReportInviteScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={resendReportInviteRoute.template}
                element={
                    <AuthRoute roles={[updateReportInviteRole]}>
                        <ResendReportInviteScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportInvitesRoute.template}
                element={
                    <AuthRoute roles={[listReportInvitesRole]}>
                        <ReportInvitesListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createReportRoute.template}
                element={
                    <AuthRoute roles={[createReportRole]}>
                        <CreateReportScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editReportRoute.template}
                element={
                    <AuthRoute roles={[updateReportRole]}>
                        <EditReportScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteReportRoute.template}
                element={
                    <AuthRoute roles={[deleteReportRole]}>
                        <DeleteReportScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportRoute.template}
                element={
                    <AuthRoute>
                        <ReportScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportUsersRoute.template}
                element={
                    <AuthRoute>
                        <ReportUsersListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={addReportUserRoute.template}
                element={
                    <AuthRoute roles={[updateCompanyRole]}>
                        <AddReportUserScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={addReportUserFromCompanyRoute.template}
                element={
                    <AuthRoute>
                        <AddReportUserFromCompanyScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={removeReportUserRoute.template}
                element={
                    <AuthRoute>
                        <RemoveReportUserScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportsRoute.template}
                element={
                    <AuthRoute>
                        <ReportsListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={dashboardRoute.template}
                element={
                    <AuthRoute>
                        <DashboardScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={companyUsersRoute.template}
                element={
                    <AuthRoute>
                        <CompanyUsersListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={addCompanyUserRoute.template}
                element={
                    <AuthRoute roles={[updateCompanyRole]}>
                        <AddCompanyUserScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={removeCompanyUserRoute.template}
                element={
                    <AuthRoute>
                        <RemoveCompanyUserScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={companyReportsRoute.template}
                element={
                    <AuthRoute>
                        <CompanyReportsListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={companyReportVulnerabilitiesRoute.template}
                element={
                    <AuthRoute>
                        <CompanyReportVulnerabilitiesListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createCompanyInviteRoute.template}
                element={
                    <AuthRoute>
                        <CreateCompanyInviteScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteCompanyInviteRoute.template}
                element={
                    <AuthRoute>
                        <DeleteCompanyInviteScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={resendCompanyInviteRoute.template}
                element={
                    <AuthRoute>
                        <ResendCompanyInviteScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={companyInvitesRoute.template}
                element={
                    <AuthRoute>
                        <CompanyInvitesListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createCompanyContactRoute.template}
                element={
                    <AuthRoute>
                        <CreateCompanyContactScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={companyContactsRoute.template}
                element={
                    <AuthRoute>
                        <CompanyContactsListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editCompanyContactRoute.template}
                element={
                    <AuthRoute>
                        <EditCompanyContactScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteCompanyContactRoute.template}
                element={
                    <AuthRoute>
                        <DeleteCompanyContactScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editCompanyRoute.template}
                element={
                    <AuthRoute roles={[updateCompanyRole]}>
                        <EditCompanyScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={uploadCompanyLogoRoute.template}
                element={
                    <AuthRoute roles={[updateCompanyRole]}>
                        <UploadCompanyLogoScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteCompanyRoute.template}
                element={
                    <AuthRoute roles={[deleteCompanyRole]}>
                        <DeleteCompanyScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createCompanyRoute.template}
                element={
                    <AuthRoute roles={[createCompanyRole]}>
                        <CreateCompanyScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={companyRoute.template}
                element={
                    <AuthRoute>
                        <CompanyScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={companiesRoute.template}
                element={
                    <AuthRoute>
                        <CompaniesListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editVulnerabilityRoute.template}
                element={
                    <AuthRoute roles={[updateVulnerabilityRole]}>
                        <EditVulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteVulnerabilityRoute.template}
                element={
                    <AuthRoute roles={[deleteVulnerabilityRole]}>
                        <DeleteVulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createVulnerabilityRoute.template}
                element={
                    <AuthRoute roles={[createVulnerabilityRole]}>
                        <CreateVulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={vulnerabilityRoute.template}
                element={
                    <AuthRoute>
                        <VulnerabilityScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={vulnerabilitiesRoute.template}
                element={
                    <AuthRoute roles={[listVulnerabilitiesRole]}>
                        <VulnerabilitiesListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={userChangePasswordRoute.template}
                element={
                    <AuthRoute>
                        <UserChangePasswordScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={updateUserMobileNumberRoute.template}
                element={
                    <AuthRoute>
                        <UpdateUserMobileNumberScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={userSettingsRoute.template}
                element={
                    <AuthRoute>
                        <UserSettingsScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editUserRoute.template}
                element={
                    <AuthRoute roles={[updateUserRole]}>
                        <EditUserScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteUserRoute.template}
                element={
                    <AuthRoute roles={[deleteUserRole]}>
                        <DeleteUserScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={userRoute.template}
                element={
                    <AuthRoute>
                        <UserScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={usersRoute.template}
                element={
                    <AuthRoute roles={[listUsersRole]}>
                        <UsersScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editContactRoute.template}
                element={
                    <AuthRoute roles={[updateContactRole]}>
                        <EditContactScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteContactRoute.template}
                element={
                    <AuthRoute roles={[deleteContactRole]}>
                        <DeleteContactScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createContactRoute.template}
                element={
                    <AuthRoute roles={[createContactRole]}>
                        <CreateContactScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={contactsRoute.template}
                element={
                    <AuthRoute roles={[listContactsRole]}>
                        <ContactsListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={editTestingTypeRoute.template}
                element={
                    <AuthRoute roles={[updateTestingTypeRole]}>
                        <EditTestingTypeScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={deleteTestingTypeRoute.template}
                element={
                    <AuthRoute roles={[deleteTestingTypeRole]}>
                        <DeleteTestingTypeScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={createTestingTypeRoute.template}
                element={
                    <AuthRoute roles={[createTestingTypeRole]}>
                        <CreateTestingTypeScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={testingTypesRoute.template}
                element={
                    <AuthRoute roles={[listTestingTypesRole]}>
                        <TestingTypesListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportVulnerabilitiesRoute.template}
                element={
                    <AuthRoute roles={[listReportVulnerabilitiesRole]}>
                        <AllReportVulnerabilitiesListScreen />
                    </AuthRoute>
                }
            />

            <Route
                path={reportVulnerabilitiesOverviewRoute.template}
                element={
                    <AuthRoute roles={[listReportVulnerabilitiesRole]}>
                        <AllReportVulnerabilitiesOverviewScreen />
                    </AuthRoute>
                }
            />

            <Route path={acceptReportInviteRoute.template} element={<AcceptReportInviteScreen />} />

            <Route path={acceptCompanyInviteRoute.template} element={<AcceptCompanyInviteScreen />} />

            <Route path={registerRoute.template} element={<RegisterScreen />} />

            <Route path={signInRoute.template} element={<SignInScreen />} />

            <Route path={resetPasswordRequestRoute.template} element={<ResetPasswordRequestScreen />} />

            <Route path={resetPasswordRoute.template} element={<ResetPasswordScreen />} />

            <Route path={confirmEmailRoute.template} element={<ConfirmEmailScreen />} />

            <Route
                path="/"
                element={
                    isSignedIn ? <Navigate to={dashboardRoute.build({})} /> : <Navigate to={signInRoute.build({})} />
                }
            />
        </Routes>
    );
};
