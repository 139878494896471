import { FC, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { CreateReportInviteFieldsFragment } from "app/api/graph/types";
import { createReportInviteRoute, acceptReportInviteRoute } from "app/route/Routes";
import { CreateReportInviteForm } from "app/component/report-invite/CreateReportInviteForm";
import { useRouteParams } from "app/route/route";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";

const buildInviteUrl = (invite: CreateReportInviteFieldsFragment): string => {
    const url = new URL(acceptReportInviteRoute.build({ inviteId: invite._id }), document.baseURI);
    url.searchParams.append("token", invite.token);
    return url.href;
};

export const CreateReportInviteScreen: FC = () => {
    const { reportId } = useRouteParams(createReportInviteRoute);
    const [invite, setInvite] = useState<CreateReportInviteFieldsFragment | undefined>(undefined);
    const inviteUrl = invite ? buildInviteUrl(invite) : undefined;

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={createReportInviteRoute} />
                {inviteUrl ? (
                    <Alert variant="info">
                        <p>
                            If you entered an email address, an email will have been sent to the user. Alternatively,
                            you can share the invite URL below with the user:
                        </p>
                        <a href={inviteUrl}>{inviteUrl}</a>
                    </Alert>
                ) : (
                    <CreateReportInviteForm reportId={reportId} onCreate={setInvite} />
                )}
            </Container>
        </NavigationScreen>
    );
};
