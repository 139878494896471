import { FC } from "react";
import Container from "react-bootstrap/Container";
import { FaPlus } from "react-icons/fa";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { companyReportsRoute, createReportRoute } from "app/route/Routes";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { createReportRole } from "app/api/graph/roles";
import { useAuth } from "app/api/AuthContext";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ButtonLink } from "app/component/button/ButtonLink";
import { useRouteParams } from "app/route/route.ts";
import { CompanyReportsTable } from "app/component/company/CompanyReportsTable.tsx";

export const CompanyReportsListScreen: FC = () => {
    const { companyId } = useRouteParams(companyReportsRoute);
    const { roles } = useAuth();

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={companyReportsRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Company reports</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {roles?.includes(createReportRole) ? (
                            <ButtonLink variant="success" to={createReportRoute.build({})}>
                                <FaPlus /> Create
                            </ButtonLink>
                        ) : null}
                    </ToolbarButtons>
                </Toolbar>
                <CompanyReportsTable companyId={companyId} />
            </Container>
        </NavigationScreen>
    );
};
