import { isValidPhoneNumber as isValid } from "libphonenumber-js";
import { Maybe } from "app/util/type";

export const isValidPhoneNumber = (value: Maybe<string>): boolean => {
    if (!value) {
        return false;
    }

    return isValid(value, "GB");
};
