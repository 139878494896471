import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import yup from "app/util/yup";
import { useReopenReportVulnerabilityMutation } from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";

type Props = {
    vulnerabilityId: number;
    onReopen?: () => void;
};

type FormValues = {
    reopenedReason: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    reopenedReason: yup.string().required(),
});

const initialValues: FormValues = {
    reopenedReason: "",
};

export const ReopenReportVulnerabilityForm: FC<Props> = ({ vulnerabilityId, onReopen }) => {
    const [reopen] = useReopenReportVulnerabilityMutation();

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        reopen({
            variables: {
                vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId),
                reopenedReason: values.reopenedReason,
            },
        })
            .then(() => onReopen?.())
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, handleChange, handleBlur, values, isSubmitting, errors, touched, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Alert variant="warning">Do you really want to reopen this vulnerability?</Alert>
                    <Form.Group className="mb-3">
                        <Form.Label>Reason for reopening</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="reopenedReason"
                            value={values.reopenedReason}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.reopenedReason && !!errors.reopenedReason}
                        />
                        <Form.Control.Feedback type="invalid">{errors.reopenedReason}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Reopen
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
