import { DependencyList, useEffect } from "react";

export const useScroll = (handle: () => void, deps?: DependencyList): void => {
    useEffect(() => {
        window.addEventListener("scroll", handle);

        // Initially call to allow set-up before a scroll takes place
        handle();

        return () => window.removeEventListener("scroll", handle);
    }, deps);
};
