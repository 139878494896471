import { formatISO, formatRelative, parseISO } from "date-fns";
import { FC } from "react";
import { FaSync } from "react-icons/fa";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import {
    useGetUserNotificationsQuery,
    useMarkReadUserNotificationMutation,
    UserNotificationFieldsFragment,
    UserNotificationType,
} from "app/api/graph/types";
import { mapNodes } from "app/util/graph";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { reportVersionVulnerabilityRoute } from "app/route/Routes";
import { notEmpty } from "app/util/array";

import "./user-notifications-overlay.scss";

// TODO these types could probably be defined in the graph schema - data could be oneof
type NewHighRiskVulnerabilityNotificationData = {
    reportId?: number;
    reportVersionId?: number;
    reportVulnerabilityId?: number;
};

type NewVulnerabilityCommentNotificationData = {
    reportId?: number;
    reportVersionId?: number;
    reportVulnerabilityId?: number;
    reportVulnerabilityCommentId?: number;
};

const notificationUrl = (notification: UserNotificationFieldsFragment): string | undefined => {
    switch (notification.type) {
        case UserNotificationType.NewHighRiskVulnerability: {
            const data = notification.data as NewHighRiskVulnerabilityNotificationData;
            if (data.reportId && data.reportVersionId && data.reportVulnerabilityId) {
                return reportVersionVulnerabilityRoute.build({
                    reportId: data.reportId,
                    versionId: data.reportVersionId,
                    vulnerabilityId: data.reportVulnerabilityId,
                });
            }
            return undefined;
        }
        case UserNotificationType.NewVulnerabilityComment: {
            const data = notification.data as NewVulnerabilityCommentNotificationData;
            if (data.reportId && data.reportVersionId && data.reportVulnerabilityId) {
                return reportVersionVulnerabilityRoute.build({
                    reportId: data.reportId,
                    versionId: data.reportVersionId,
                    vulnerabilityId: data.reportVulnerabilityId,
                });
            }
            return undefined;
        }
        default:
            return undefined;
    }
};

const NotificationItem = ({
    notification,
    onItemClick,
}: {
    notification: UserNotificationFieldsFragment;
    onItemClick: () => void;
}) => {
    const [markRead] = useMarkReadUserNotificationMutation({
        variables: { input: { id: notification.id, read: true } },
        optimisticResponse: {
            markReadUserNotification: {
                userNotification: {
                    ...notification,
                    readAt: formatISO(new Date()),
                },
            },
        },
    });
    const url = notificationUrl(notification) ?? "#";
    const variant = notification.readAt === null ? "info" : undefined;
    const onClick = () => {
        onItemClick();
        if (notification.readAt === null) {
            return markRead();
        }
    };

    return (
        <ListGroup.Item action as={Link} to={url} variant={variant} onClick={() => onClick()}>
            <div>{notification.message}</div>
            <div className="text-muted">
                {notification.createdAt ? formatRelative(parseISO(notification.createdAt), new Date()) : "-"}
            </div>
        </ListGroup.Item>
    );
};

type Props = {
    onItemClick: () => void;
};

export const UserNotificationsOverlay: FC<Props> = (props) => {
    const { data, loading, error, fetchMore } = useGetUserNotificationsQuery({
        notifyOnNetworkStatusChange: true,
    });
    const [markRead] = useMarkReadUserNotificationMutation();
    const notifications = data?.userNotifications;
    const fetchMoreNotifications = () => fetchMore({ variables: { after: notifications?.pageInfo.endCursor } });

    const markAllRead = () =>
        notifications?.edges
            ?.map((edge) => edge?.node)
            ?.filter(notEmpty)
            ?.filter((notification) => notification.readAt === null)
            ?.map((notification) =>
                markRead({
                    variables: { input: { id: notification.id, read: true } },
                    optimisticResponse: {
                        markReadUserNotification: {
                            userNotification: {
                                ...notification,
                                readAt: formatISO(new Date()),
                            },
                        },
                    },
                }),
            );

    return (
        <Card className="user-notifications-overlay">
            <ListGroup variant="flush" className="user-notifications-overlay__list">
                {error ? <ListGroup.Item variant="danger">{error.message}</ListGroup.Item> : null}
                {notifications !== undefined && (notifications?.edges?.length ?? 0) == 0 ? (
                    <ListGroup.Item>You don&lsquo;t have any notifications.</ListGroup.Item>
                ) : (
                    mapNodes(notifications, (notification) => (
                        <NotificationItem notification={notification} onItemClick={props.onItemClick} />
                    ))
                )}
                {!loading && notifications?.pageInfo.hasNextPage ? (
                    <ListGroup.Item action onClick={fetchMoreNotifications} variant="light">
                        <FaSync /> Load more
                    </ListGroup.Item>
                ) : null}
                {loading ? (
                    <ListGroup.Item>
                        <LoadingSpinner />
                    </ListGroup.Item>
                ) : null}
            </ListGroup>

            <Card.Footer>
                <Button variant="link" size="sm" onClick={() => markAllRead()}>
                    Mark all as read
                </Button>
            </Card.Footer>
        </Card>
    );
};
