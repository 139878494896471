import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

// https://reactrouter.com/web/guides/scroll-restoration/scroll-to-top
export const ScrollToTop: FC = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};
