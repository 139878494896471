import { FC } from "react";
import Container from "react-bootstrap/Container";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { useRouteParams } from "app/route/route";
import { reportVersionTestCertificateRoute } from "app/route/Routes";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { TestCertificate } from "app/component/report-version/component/TestCertificate";

export const ReportVersionTestCertificateScreen: FC = () => {
    const { reportId, versionId } = useRouteParams(reportVersionTestCertificateRoute);

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={reportVersionTestCertificateRoute} className="d-print-none" />
                <TestCertificate reportId={reportId} versionId={versionId} />
            </Container>
        </NavigationScreen>
    );
};
