import { FC } from "react";
import { ReportVulnerabilityFieldsFragment, VulnerabilitySeverity } from "app/api/graph/types";
import { ReportVulnerabilitySeverityChart } from "app/component/report-vulnerability/component/ReportVulnerabilitySeverityChart";

type Props = {
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
};

export const ResultsSummary: FC<Props> = ({ vulnerabilities }) => {
    const counts: Record<VulnerabilitySeverity, number> = vulnerabilities.reduce(
        (counts, vulnerability) => {
            if (!counts[vulnerability.severity]) {
                counts[vulnerability.severity] = 0;
            }
            counts[vulnerability.severity]++;
            return counts;
        },
        {} as Record<VulnerabilitySeverity, number>,
    );

    return (
        <div>
            <p>
                The table below summarizes the identified risks into four risk levels. A total of{" "}
                {counts[VulnerabilitySeverity.Critical] ?? 0} critical-, {counts[VulnerabilitySeverity.High] ?? 0}{" "}
                high-, {counts[VulnerabilitySeverity.Medium] ?? 0} medium- and {counts[VulnerabilitySeverity.Low] ?? 0}{" "}
                low-level vulnerabilities were positively identified. Remaining issues, considered
                &quot;informational-level&quot; by iSTORM®, are not included in the summary.
            </p>

            <h5>Summary of identified risk levels</h5>
            {vulnerabilities.length > 0 ? (
                <ReportVulnerabilitySeverityChart vulnerabilities={vulnerabilities} />
            ) : (
                <p>No vulnerabilities reported.</p>
            )}
        </div>
    );
};
