import { CustomLayer, Layer, ResponsiveLine } from "@nivo/line";
import {
    differenceInCalendarMonths,
    eachDayOfInterval,
    format,
    max,
    min,
    parseISO,
    startOfDay,
    Interval,
    isSameDay,
} from "date-fns";
import { CSSProperties, FC, useMemo, useState } from "react";
import { usePrint } from "app/hook/use-print";
import { ReportVersionListFieldsFragment } from "app/api/graph/types";
import { notEmpty, range } from "app/util/array";
import { useChartTheme } from "app/hook/use-chart-theme";

type Props = {
    versions: ReportVersionListFieldsFragment[];
    style?: CSSProperties;
    dateFrom?: Date;
    dateTo?: Date;
};

type Datum = {
    x: string;
    y: number;
};

type Item = {
    id: string;
    color: string;
    data: Datum[];
};

const margin = { top: 10, right: 10, bottom: 50, left: 50 };

enum TickPeriod {
    Day,
    Month,
}

const calculateDateInterval = (versions: ReportVersionListFieldsFragment[]): Interval => {
    const dates = versions
        .map((version) => version.publishedAt)
        .filter((publishedAt): publishedAt is string => publishedAt != null)
        .map((d) => startOfDay(parseISO(d)));

    return {
        start: min(dates),
        end: max(dates),
    };
};

const getDefaultTickPeriod = (versions: ReportVersionListFieldsFragment[]): TickPeriod => {
    const interval = calculateDateInterval(versions);
    return differenceInCalendarMonths(interval.end, interval.start) < 1 ? TickPeriod.Day : TickPeriod.Month;
};

const parseData = (versions: ReportVersionListFieldsFragment[]): Item[] => {
    const interval = calculateDateInterval(versions);
    const range = eachDayOfInterval(interval);

    return [
        {
            id: "Published",
            color: "#c00000",
            data: range.map(
                (date): Datum => ({
                    x: format(date, "yyyy-MM-dd"),
                    // Count the number of versions that were published on this date
                    y: versions.filter((version) => {
                        // TODO fix calc for month tickPeriod
                        if (version.publishedAt == null) {
                            return false;
                        }

                        const datePublished = startOfDay(parseISO(version.publishedAt));

                        return isSameDay(date, datePublished);
                    }).length,
                }),
            ),
        },
    ];
};

const calculateTicksY = (data: Item[]) =>
    data.length > 0
        ? range(
              0,
              Math.max(
                  0,
                  ...data
                      .map((item) => (item.data.length > 0 ? item.data.map((datum) => datum.y) : null))
                      .filter(notEmpty)
                      .flat(),
              ),
          )
        : [];

const styleById: Record<string, CSSProperties> = {
    default: {
        strokeWidth: 1,
    },
};

const customLines: CustomLayer = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }) => (
        <path
            key={id}
            d={
                lineGenerator(
                    data.map((d) => ({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        x: xScale(d.data.x ?? 0) as number,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        y: yScale(d.data.y ?? 0) as number,
                    })),
                ) ?? undefined
            }
            fill="none"
            stroke={color}
            style={styleById[id] || styleById.default}
        />
    ));
};

export const ReportVersionTimelineChart: FC<Props> = ({ versions, style, dateFrom, dateTo }) => {
    const theme = useChartTheme();
    const defaultTickPeriod = useMemo(() => getDefaultTickPeriod(versions), [versions]);
    const [tickPeriod, setTickPeriod] = useState<TickPeriod>(defaultTickPeriod);
    const data = useMemo(() => parseData(versions), [versions, tickPeriod]);
    const ticksY = useMemo(() => calculateTicksY(data), [data, dateFrom, dateTo]);
    const { isPrint } = usePrint();

    return (
        <div style={{ height: "300px", ...style }} className="d-flex flex-column">
            <div className="text-end">
                <select value={tickPeriod} onChange={(e) => setTickPeriod(parseInt(e.target.value))}>
                    <option value={TickPeriod.Day}>Day</option>
                    <option value={TickPeriod.Month}>Month</option>
                </select>
            </div>
            <ResponsiveLine
                data={data}
                margin={margin}
                animate={!isPrint}
                colors={{ datum: "color" }}
                xFormat="time:%Y-%m-%d"
                xScale={{
                    format: "%Y-%m-%d",
                    precision: tickPeriod === TickPeriod.Month ? "month" : "day",
                    type: "time",
                    useUTC: false,
                    min: dateFrom !== undefined ? format(dateFrom, "yyyy-MM-dd") : undefined,
                    max: dateTo !== undefined ? format(dateTo, "yyyy-MM-dd") : undefined,
                }}
                yScale={{ type: "linear", min: 0, max: "auto", stacked: false, reverse: false }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickRotation: -45,
                    format: tickPeriod === TickPeriod.Month ? "%b" : "%b %d",
                    tickValues: 5,
                }}
                axisLeft={{
                    tickValues: ticksY,
                }}
                gridYValues={ticksY}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[]}
                layers={
                    [
                        "grid",
                        "markers",
                        "axes",
                        "areas",
                        "crosshair",
                        customLines,
                        "points",
                        "slices",
                        "mesh",
                        "legends",
                    ] as Layer[]
                }
                theme={theme}
            />
        </div>
    );
};
