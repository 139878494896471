import { CompanyRole, useUserCompanyRolesQuery } from "app/api/graph/types.ts";
import { useAuth } from "app/api/AuthContext.tsx";
import { userGraphId } from "app/api/graph/helpers.ts";
import { mapNodes } from "app/util/graph.ts";
import {
    createCompanyInviteRole,
    createReportInviteRole,
    deleteCompanyInviteRole,
    deleteReportInviteRole,
    listCompanyInvitesRole,
    listReportInvitesRole,
    updateCompanyInviteRole,
    updateCompanyRole,
} from "app/api/graph/roles.ts";

type Result = {
    loading: boolean;
    canListUserInvites: boolean | undefined;
    canCreateUserInvite: boolean | undefined;
    canDeleteUserInvite: boolean | undefined;
    canUpdateUserInvite: boolean | undefined;
    canAddUser: boolean | undefined;
    canListReportUsers: boolean | undefined;
    canRemoveUser: boolean | undefined;
    canAddReportUser: boolean | undefined;
    canRemoveReportUser: boolean | undefined;
    canListReportUserInvites: boolean | undefined;
    canCreateReportUserInvite: boolean | undefined;
    canDeleteReportUserInvite: boolean | undefined;
};

export const useCompanyPermissions = (companyId: string | undefined | null): Result => {
    const { id, roles } = useAuth();

    const { data, loading } = useUserCompanyRolesQuery({
        variables:
            id !== undefined && companyId != null
                ? {
                      userId: userGraphId(id),
                      companyId: companyId,
                  }
                : undefined,
        skip: id === undefined && companyId != null,
    });
    const companyRoles = mapNodes(data?.user?.companies, (item) => item.role);

    return {
        loading,
        canListUserInvites: companyRoles.includes(CompanyRole.Owner) || roles?.includes(listCompanyInvitesRole),
        canCreateUserInvite: companyRoles.includes(CompanyRole.Owner) || roles?.includes(createCompanyInviteRole),
        canDeleteUserInvite: companyRoles.includes(CompanyRole.Owner) || roles?.includes(deleteCompanyInviteRole),
        canUpdateUserInvite: companyRoles.includes(CompanyRole.Owner) || roles?.includes(updateCompanyInviteRole),
        canAddUser: roles?.includes(updateCompanyRole),
        canRemoveUser: companyRoles.includes(CompanyRole.Owner) || roles?.includes(updateCompanyRole),
        canListReportUsers: companyRoles.includes(CompanyRole.Owner) || roles?.includes(updateCompanyRole),
        canAddReportUser: companyRoles.includes(CompanyRole.Owner) || roles?.includes(updateCompanyRole),
        canRemoveReportUser: companyRoles.includes(CompanyRole.Owner) || roles?.includes(updateCompanyRole),
        canListReportUserInvites: companyRoles.includes(CompanyRole.Owner) || roles?.includes(listReportInvitesRole),
        canCreateReportUserInvite: companyRoles.includes(CompanyRole.Owner) || roles?.includes(createReportInviteRole),
        canDeleteReportUserInvite: companyRoles.includes(CompanyRole.Owner) || roles?.includes(deleteReportInviteRole),
    };
};
