import { FC, useMemo } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FaEdit, FaFileAlt, FaList, FaPlus, FaUserFriends } from "react-icons/fa";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { useReportVulnerabilities } from "app/api/graph/hook/use-report-vulnerabilities";
import { useRouteParams } from "app/route/route";
import {
    createReportVersionVulnerabilityRoute,
    editReportVersionRoute,
    newReportVersionRoute,
    reportRoute,
    reportUsersRoute,
    reportVersionRoute,
    reportVersionsRoute,
    reportVersionVulnerabilitiesRoute,
} from "app/route/Routes";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";
import { NumberOfVulnerabilitiesCard } from "app/component/report/component/NumberOfVulnerabilitiesCard";
import { VulnerabilityTimelineCard } from "app/component/report/component/VulnerabilityTimelineCard";
import { VulnerabilityListCard } from "app/component/report/component/VulnerabilityListCard";
import { ReportVersionsCard } from "app/component/report/component/ReportVersionsCard";
import { ReportVulnerabilityStatus, useReportOverviewQuery } from "app/api/graph/types";
import { reportGraphId } from "app/api/graph/helpers";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { useAuth } from "app/api/AuthContext";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { createReportVulnerabilityRole, updateReportRole } from "app/api/graph/roles";
import { ButtonLink } from "app/component/button/ButtonLink";
import { LocationState } from "app/component/report-version/ReportVersionVulnerabilitiesScreen.tsx";
import { useReportVersionsList } from "app/api/graph/hook/use-report-versions-list.ts";
import { useReportPermissions } from "app/api/graph/hook/use-report-permissions.ts";

export const ReportScreen: FC = () => {
    const { roles } = useAuth();
    const { reportId } = useRouteParams(reportRoute);
    const {
        data,
        loading: loadingReport,
        error: reportError,
    } = useReportOverviewQuery({
        variables: { reportId: reportGraphId(reportId) },
    });
    const report = data?.report;
    const { canListUsers } = useReportPermissions(report);

    const {
        version,
        vulnerabilities,
        loading: loadingVulnerabilities,
        error: vulnerabilitiesError,
    } = useReportVulnerabilities(reportId);

    const openVulnerabilities = useMemo(
        () => vulnerabilities?.filter((v) => v.status === ReportVulnerabilityStatus.Open),
        [vulnerabilities],
    );
    const resolvedVulnerabilities = useMemo(
        () =>
            vulnerabilities?.filter(
                (v) =>
                    v.status === ReportVulnerabilityStatus.Resolved ||
                    v.status === ReportVulnerabilityStatus.VerifiedResolved,
            ),
        [vulnerabilities],
    );
    const riskAcceptedVulnerabilities = useMemo(
        () => vulnerabilities?.filter((v) => v.status === ReportVulnerabilityStatus.RiskAccepted),
        [vulnerabilities],
    );
    const informationalVulnerabilities = useMemo(
        () => vulnerabilities?.filter((v) => v.status === ReportVulnerabilityStatus.Informational),
        [vulnerabilities],
    );

    const { versions, loading: loadingVersions, error: versionsError } = useReportVersionsList(reportId);

    if (loadingReport || loadingVulnerabilities || loadingVersions) {
        return <LoadingScreen />;
    }
    if (reportError) {
        return <ErrorScreen title="Error" message={reportError.message} />;
    }
    if (vulnerabilitiesError) {
        return <ErrorScreen title="Error" message={vulnerabilitiesError.message} />;
    }
    if (versionsError) {
        return <ErrorScreen title="Error" message={versionsError.message} />;
    }
    if (!report || !version || !versions) {
        return <ErrorScreen title="Error" message="Not found." />;
    }

    return (
        <NavigationScreen>
            <Container fluid>
                <RouteBreadcrumbs route={reportRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>{report.title}</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        {canListUsers ? (
                            <ButtonGroup className="me-2">
                                <ButtonLink to={reportUsersRoute.build({ reportId })}>
                                    <FaUserFriends /> Users
                                </ButtonLink>
                            </ButtonGroup>
                        ) : null}
                        <ButtonGroup className="me-2">
                            <ButtonLink
                                to={reportVersionRoute.build({ reportId, versionId: version._id })}
                                variant="info"
                            >
                                <FaFileAlt /> View report
                            </ButtonLink>
                            {roles?.includes(updateReportRole) ? (
                                <>
                                    <ButtonLink to={newReportVersionRoute.build({ reportId })} variant="success">
                                        <FaPlus /> New version
                                    </ButtonLink>
                                    <ButtonLink
                                        to={editReportVersionRoute.build({ reportId, versionId: version._id })}
                                        variant="warning"
                                    >
                                        <FaEdit /> Edit version
                                    </ButtonLink>
                                </>
                            ) : null}
                        </ButtonGroup>
                    </ToolbarButtons>
                </Toolbar>
                <Row>
                    <Col>
                        <NumberOfVulnerabilitiesCard
                            title={`Open vulnerabilities by severity (${openVulnerabilities?.length ?? 0})`}
                            vulnerabilities={openVulnerabilities ?? []}
                        />
                        <VulnerabilityTimelineCard vulnerabilities={vulnerabilities ?? []} />
                    </Col>
                    <Col>
                        <VulnerabilityListCard
                            title="Open vulnerabilities"
                            vulnerabilities={openVulnerabilities ?? []}
                            buttons={
                                <ButtonGroup>
                                    <ButtonLink
                                        to={reportVersionVulnerabilitiesRoute.build({
                                            reportId: report._id,
                                            versionId: version._id,
                                        })}
                                        state={
                                            {
                                                initialFilters: { status: ReportVulnerabilityStatus.Open },
                                            } as LocationState
                                        }
                                        variant="info"
                                        size="sm"
                                    >
                                        <FaList />
                                    </ButtonLink>
                                    {roles?.includes(createReportVulnerabilityRole) ? (
                                        <ButtonLink
                                            to={createReportVersionVulnerabilityRoute.build({
                                                reportId: report._id,
                                                versionId: version._id,
                                            })}
                                            variant="success"
                                            size="sm"
                                        >
                                            <FaPlus />
                                        </ButtonLink>
                                    ) : null}
                                </ButtonGroup>
                            }
                        />
                        <VulnerabilityListCard
                            title="Resolved vulnerabilities"
                            vulnerabilities={resolvedVulnerabilities ?? []}
                            buttons={
                                <ButtonGroup>
                                    <ButtonLink
                                        to={reportVersionVulnerabilitiesRoute.build({
                                            reportId: report._id,
                                            versionId: version._id,
                                        })}
                                        state={
                                            {
                                                initialFilters: { status: ReportVulnerabilityStatus.Resolved },
                                            } as LocationState
                                        }
                                        variant="info"
                                        size="sm"
                                    >
                                        <FaList />
                                    </ButtonLink>
                                </ButtonGroup>
                            }
                        />
                        <VulnerabilityListCard
                            title="Risk accepted vulnerabilities"
                            vulnerabilities={riskAcceptedVulnerabilities ?? []}
                            buttons={
                                <ButtonGroup>
                                    <ButtonLink
                                        to={reportVersionVulnerabilitiesRoute.build({
                                            reportId: report._id,
                                            versionId: version._id,
                                        })}
                                        state={
                                            {
                                                initialFilters: { status: ReportVulnerabilityStatus.RiskAccepted },
                                            } as LocationState
                                        }
                                        variant="info"
                                        size="sm"
                                    >
                                        <FaList />
                                    </ButtonLink>
                                </ButtonGroup>
                            }
                        />
                        <VulnerabilityListCard
                            title="Informational"
                            vulnerabilities={informationalVulnerabilities ?? []}
                            buttons={
                                <ButtonGroup>
                                    <ButtonLink
                                        to={reportVersionVulnerabilitiesRoute.build({
                                            reportId: report._id,
                                            versionId: version._id,
                                        })}
                                        state={
                                            {
                                                initialFilters: { status: ReportVulnerabilityStatus.Informational },
                                            } as LocationState
                                        }
                                        variant="info"
                                        size="sm"
                                    >
                                        <FaList />
                                    </ButtonLink>
                                </ButtonGroup>
                            }
                        />
                        <ReportVersionsCard
                            versions={versions}
                            buttons={
                                <ButtonGroup>
                                    <ButtonLink to={reportVersionsRoute.build({ reportId })} variant="info" size="sm">
                                        <FaList />
                                    </ButtonLink>
                                    {roles?.includes(updateReportRole) ? (
                                        <ButtonLink
                                            to={newReportVersionRoute.build({ reportId })}
                                            variant="success"
                                            size="sm"
                                        >
                                            <FaPlus />
                                        </ButtonLink>
                                    ) : null}
                                </ButtonGroup>
                            }
                        />
                    </Col>
                </Row>
            </Container>
        </NavigationScreen>
    );
};
