import { FC, useMemo } from "react";
import { ReportVulnerabilityActionFieldsFragment } from "app/api/graph/types";
import { mapNodes } from "app/util/graph";
import { MediaObjects } from "app/component/form/media-objects/MediaObjects";

type Props = {
    action: ReportVulnerabilityActionFieldsFragment;
    versionId: number;
};

export const ActionFiles: FC<Props> = ({ action, versionId }) => {
    const mediaObjects = useMemo(() => mapNodes(action.files, (o) => o), [action.files]);
    return <MediaObjects initialValues={mediaObjects} versionId={versionId} readonly />;
};
