import { FC } from "react";
import { Accordion } from "react-bootstrap";
import { ReportVersionListFieldsFragment } from "app/api/graph/types.ts";
import { ReportVersionTimelineChart } from "app/component/report-version/component/ReportVersionTimelineChart.tsx";

type Props = {
    versions: ReportVersionListFieldsFragment[];
    dateFrom?: Date;
    dateTo?: Date;
};

export const ReportVersionsTimelineCard: FC<Props> = ({ versions, dateFrom, dateTo }) => {
    return (
        <Accordion className="mb-3" defaultActiveKey={versions.length > 0 ? "0" : null}>
            <Accordion.Item eventKey="0">
                <Accordion.Button className="bg-light py-2">Report versions published</Accordion.Button>
                <Accordion.Body>
                    {versions.length > 0 ? (
                        <ReportVersionTimelineChart
                            versions={versions}
                            style={{ height: "250px" }}
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                        />
                    ) : (
                        <p>No vulnerabilities reported.</p>
                    )}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
