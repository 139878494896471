import { FC } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { useRouteParams } from "app/route/route";
import { reportVersionVulnerabilityRoute, resolveReportVersionVulnerabilityRoute } from "app/route/Routes";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { ResolveReportVulnerabilityForm } from "app/component/report-vulnerability/ResolveReportVulnerabilityForm";
import { ReportVulnerabilityStatus, useReportVulnerabilityQuery } from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { LoadingScreen } from "app/component/screen/LoadingScreen";
import { ErrorScreen } from "app/component/screen/ErrorScreen";

export const ResolveReportVersionVulnerabilityScreen: FC = () => {
    const { reportId, versionId, vulnerabilityId } = useRouteParams(resolveReportVersionVulnerabilityRoute);
    const { data, loading, error } = useReportVulnerabilityQuery({
        variables: { vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId) },
    });
    const vulnerability = data?.reportVulnerability;

    if (loading) {
        return <LoadingScreen />;
    }
    if (error) {
        return <ErrorScreen title="Error" message={error.message} />;
    }
    if (!vulnerability) {
        return <ErrorScreen title="Error" message="Not found" />;
    }
    if (vulnerability.status !== ReportVulnerabilityStatus.Open) {
        return <Navigate to={reportVersionVulnerabilityRoute.build({ reportId, versionId, vulnerabilityId })} />;
    }

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={resolveReportVersionVulnerabilityRoute} />
                <h1>
                    <strong>{vulnerability.issueNumber}</strong> {vulnerability.title}
                </h1>
                <ResolveReportVulnerabilityForm vulnerabilityId={vulnerabilityId} />
            </Container>
        </NavigationScreen>
    );
};
