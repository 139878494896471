import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import { CompanyContactFieldsFragment, useCompanyContactsQuery } from "app/api/graph/types";
import { SelectData, SelectDataProps } from "app/component/form/select/SelectData";
import { companyGraphId } from "app/api/graph/helpers";

type Props = SelectDataProps<CompanyContactFieldsFragment> & {
    companyId: number;
};

export const SelectCompanyContact: FC<Props> = ({ companyId, ...props }) => {
    const { data, loading, fetchMore, error } = useCompanyContactsQuery({
        variables: { companyId: companyGraphId(companyId) },
        notifyOnNetworkStatusChange: true,
    });

    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }

    return (
        <SelectData
            {...props}
            connection={data?.company?.contacts}
            label={(value) => value.name ?? ""}
            isLoading={loading}
            onPaginate={() => fetchMore({ variables: { after: data?.company?.contacts?.pageInfo?.endCursor } })}
        />
    );
};
