import { ApolloError } from "@apollo/client";
import { FormikHelpers } from "formik";

export type Status = {
    variant: string;
    message: string;
};

type Violation = {
    path: string;
    message: string;
};

export const isStatus = (status: unknown): status is Status => {
    return status !== undefined && (status as Status).variant !== undefined && (status as Status).message !== undefined;
};

const getViolations = (error: ApolloError): Violation[] =>
    error.graphQLErrors
        .filter(
            (error) =>
                error.extensions?.category === "user" &&
                error.extensions?.status === 422 &&
                error.extensions?.violations !== undefined,
        )
        .map((error) => error.extensions?.violations as Violation[])
        .flat();

export const handleSuccess = (actions: FormikHelpers<unknown>, successMessage?: string): void => {
    if (successMessage) {
        return actions.setStatus({ variant: "success", message: successMessage } as Status);
    }
    actions.setStatus(undefined);
};

export const handleError = (error: unknown, actions: FormikHelpers<unknown>): void => {
    if (!(error instanceof ApolloError)) {
        actions.setStatus({ variant: "danger", message: "An error has occurred" });
    }

    const apolloError = error as ApolloError;
    const violations = getViolations(apolloError);
    if (violations.length > 0) {
        violations.forEach((violation) => actions.setFieldError(violation.path, violation.message));
        return actions.setStatus({ variant: "danger", message: "Please see the validation errors below." } as Status);
    }

    actions.setStatus({ variant: "danger", message: apolloError.message } as Status);
};
