import { FC } from "react";
import { MultiSelectChoice, Option, SelectProps } from "app/component/form/select/MultiSelectChoice";
import { consultantRole, superAdminRole, testerRole, userRole } from "app/api/graph/roles";

type Props = SelectProps & {
    roles: string[];
    onChange: (roles: string[]) => void;
};

const options: Option[] = [
    {
        value: userRole,
        label: "User",
    },
    {
        value: testerRole,
        label: "Tester",
    },
    {
        value: consultantRole,
        label: "Consultant",
    },
    {
        value: superAdminRole,
        label: "Super admin",
    },
];

export const SelectRoles: FC<Props> = ({ roles, onChange, ...props }) => {
    return (
        <MultiSelectChoice
            {...props}
            options={options}
            value={roles.map((role) => options.find((option) => option.value === role) ?? { value: role, label: role })}
            onChange={(options) => onChange(options.map((option) => option.value as string))}
        />
    );
};
