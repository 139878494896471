import { FC } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik, FormikHelpers } from "formik";
import yup from "app/util/yup";
import { CentredScreen } from "app/component/screen/CentredScreen";
import { useCreateUserPasswordResetRequsetMutation } from "app/api/graph/types";
import logo from "app/asset/image/istorm_logo.png";
import { StatusAlert } from "app/component/form/StatusAlert";
import { handleError, handleSuccess } from "app/api/graph/form";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";

type FormValues = {
    email: string;
};

const initialValues: FormValues = {
    email: "",
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    email: yup.string().email().required(),
});

export const ResetPasswordRequestScreen: FC = () => {
    const [createRequest] = useCreateUserPasswordResetRequsetMutation();

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        createRequest({ variables: { input: values } })
            .then(() =>
                handleSuccess(
                    actions,
                    "If an account with that email address exists, we will send a password rest email. Please check your inbox and follow the instructions to continue.",
                ),
            )
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <CentredScreen>
            <Link to="/">
                <img src={logo} alt="iSTORM®" className="img-fluid mb-3" />
            </Link>
            <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting, status }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <StatusAlert status={status as unknown} />
                        <Form.Group className="mb-3">
                            <Form.Control
                                name="email"
                                type="email"
                                placeholder="Email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.email && !!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Form.Group>
                        <BlockButtonGroup>
                            <Button type="submit" disabled={isSubmitting}>
                                Request password reset
                            </Button>
                        </BlockButtonGroup>
                    </Form>
                )}
            </Formik>
        </CentredScreen>
    );
};
