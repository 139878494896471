import { ApolloProvider } from "@apollo/client";
import { FC, PropsWithChildren } from "react";
import { AuthProvider } from "app/api/AuthContext";
import { createGraphClient } from "app/api/graph/client";

export const client = createGraphClient();

export const ApiProvider: FC<PropsWithChildren> = (props) => {
    return (
        <AuthProvider>
            <ApolloProvider client={client}>{props.children}</ApolloProvider>
        </AuthProvider>
    );
};
