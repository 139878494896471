import { FC } from "react";
import { Formik, FormikHelpers } from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ReportMediaObjectFieldsFragment, useCreateReportVulnerabilityFileMutation } from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";
import yup from "app/util/yup.ts";
import { StatusAlert } from "app/component/form/StatusAlert.tsx";
import { RichTextEditor } from "app/component/form/text-editor/RichTextEditor.tsx";
import { handleError } from "app/api/graph/form.ts";
import { MediaObjects } from "app/component/form/media-objects/MediaObjects.tsx";

type Props = {
    reportId: number;
    versionId: number;
    vulnerabilityId: number;
    onCreate?: () => void;
};

type FormValues = {
    file?: ReportMediaObjectFieldsFragment;
    caption?: string;
    description?: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    file: yup.mixed<ReportMediaObjectFieldsFragment>().required(),
    caption: yup.string(),
    description: yup.string(),
});

const initialValues: FormValues = {
    caption: "",
    description: "",
};

export const CreateReportVulnerabilityFileForm: FC<Props> = ({ versionId, vulnerabilityId, onCreate }) => {
    const [createVulnerabilityFile] = useCreateReportVulnerabilityFileMutation({});

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        createVulnerabilityFile({
            variables: {
                input: {
                    reportVulnerability: reportVulnerabilityGraphId(vulnerabilityId),
                    reportMediaObject: values.file?.id ?? "",
                    caption: values.caption,
                    description: values.description,
                    position: -1,
                },
            },
        })
            .then((response) =>
                response.data?.createReportVulnerabilityFile?.reportVulnerabilityFile ? onCreate?.() : undefined,
            )
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
                setFieldTouched,
                values,
                isSubmitting,
                errors,
                touched,
                status,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />

                    <Form.Group className="mb-3">
                        <Form.Label>File</Form.Label>
                        <div className={errors.file && "is-invalid"}>
                            <MediaObjects
                                initialValues={[]}
                                onChange={(values) => setFieldValue("file", values.length > 0 ? values[0] : undefined)}
                                versionId={versionId}
                                allowMultiple={false}
                            />
                        </div>
                        <Form.Control.Feedback type="invalid">{errors.file}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Caption</Form.Label>
                        <Form.Control
                            type="text"
                            name="caption"
                            value={values.caption}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.caption && !!errors.caption}
                        />
                        <Form.Control.Feedback type="invalid">{errors.caption}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <RichTextEditor
                            value={values.description ?? ""}
                            onChange={(value) => setFieldValue("description", value)}
                            onBlur={() => setFieldTouched("description")}
                            isInvalid={touched.description && !!errors.description}
                        />
                        <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                    </Form.Group>

                    <Button type="submit" disabled={isSubmitting}>
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
