import { ResponsiveBar } from "@nivo/bar";
import { CSSProperties, FC, useMemo } from "react";
import { usePrint } from "app/hook/use-print";
import { ReportVulnerabilityFieldsFragment, TestingTypeListFieldsFragment } from "app/api/graph/types";
import { useChartTheme } from "app/hook/use-chart-theme";
import { mapNodes } from "app/util/graph.ts";

type Props = {
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
    style?: CSSProperties;
    onClick?: (testingTypeId: string | undefined) => void;
};

type Item = {
    id: string;
    label: string;
    value: number;
};

const margin = { top: 20, right: 20, bottom: 20, left: 60 };

export const ReportVulnerabilityTestingTypeChart: FC<Props> = ({ vulnerabilities, style, onClick }) => {
    const theme = useChartTheme();

    const testingTypes = useMemo(
        () =>
            vulnerabilities.reduce((testingTypes, vulnerability) => {
                mapNodes(vulnerability.testingTypes, (testingType) => testingType).forEach((testingType) => {
                    if (testingTypes.find((item) => item.id === testingType.id) === undefined) {
                        testingTypes.push(testingType);
                    }
                });
                return testingTypes;
            }, [] as TestingTypeListFieldsFragment[]),
        [vulnerabilities],
    );

    const data: Item[] = useMemo(() => {
        const items = testingTypes.map(
            (testingType): Item => ({
                id: testingType.id,
                label: testingType.name,
                value: vulnerabilities.filter(
                    (vulnerability) =>
                        mapNodes(vulnerability.testingTypes, (testingType) => testingType).find(
                            (item) => item.id === testingType.id,
                        ) !== undefined,
                ).length,
            }),
        );

        const numOther = vulnerabilities.filter(
            (vulnerability) => vulnerability.testingTypes?.edges?.length === 0,
        ).length;
        if (numOther > 0) {
            return [
                {
                    id: "other",
                    label: "Other",
                    value: numOther,
                },
                ...items,
            ];
        }

        return items;
    }, [testingTypes, vulnerabilities]);
    const { isPrint } = usePrint();

    return (
        <div style={{ height: "300px", ...style }}>
            <ResponsiveBar
                data={data}
                margin={margin}
                layout="horizontal"
                indexBy={"label"}
                axisBottom={null}
                colorBy="indexValue"
                animate={!isPrint}
                theme={theme}
                onClick={({ data }) => onClick?.(data.id !== "other" ? data.id : undefined)}
            />
        </div>
    );
};
