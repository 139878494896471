import { FC, PropsWithChildren } from "react";
import { styled } from "styled-components";

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
`;

export const CentredScreen: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Wrapper>
            <Content>{children}</Content>
        </Wrapper>
    );
};
