import { FC, ReactElement } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { formatISODate } from "app/util/format";
import { reportVersionRoute } from "app/route/Routes";
import { ReportVersionListFieldsFragment } from "app/api/graph/types.ts";

type Props = {
    versions: ReportVersionListFieldsFragment[];
    buttons?: ReactElement;
};

export const ReportVersionsCard: FC<Props> = ({ versions, buttons }) => {
    return (
        <Accordion className="mb-3" defaultActiveKey={versions.length > 0 ? "0" : null}>
            <Accordion.Item eventKey="0">
                <Accordion.Button className="bg-light py-2">
                    <Row className="align-items-center w-100">
                        <Col sm="auto" className="me-auto">
                            Report versions ({versions.length})
                        </Col>
                        <Col sm="auto">{buttons}</Col>
                    </Row>
                </Accordion.Button>
                <Accordion.Body className="p-0">
                    <ListGroup variant="flush" style={{ maxHeight: "300px", overflowY: "auto" }}>
                        {versions.map((version) => (
                            <ListGroup.Item
                                key={version.id}
                                action
                                as={Link}
                                to={reportVersionRoute.build({ reportId: version.report._id, versionId: version._id })}
                                className="d-flex justify-content-between align-items-center"
                            >
                                <span>
                                    <strong>{version.versionNumber}</strong> {version.versionName}
                                </span>
                                <small className="text-muted ms-2">{formatISODate(version.createdAt)}</small>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
