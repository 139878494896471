import { buildParts, Part, PartConfig } from "app/component/report-version/component/part/Part";
import {
    ReportSectionFieldsFragment,
    ReportVersionFieldsFragment,
    ReportVersionListFieldsFragment,
    ReportVulnerabilityFieldsFragment,
} from "app/api/graph/types";
import { buildTemplateMainConfig } from "app/component/report-version/component/template/template-main";

export type TemplateData = {
    version: ReportVersionFieldsFragment;
    sections: ReportSectionFieldsFragment[];
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
    versions: ReportVersionListFieldsFragment[];
};

export enum Template {
    Main = "main",
    Anonymised = "anonymised",
}

const buildTemplateConfig = (template: Template, data: TemplateData): PartConfig[] => {
    switch (template) {
        case Template.Main:
            return buildTemplateMainConfig(data);
        case Template.Anonymised:
            return buildTemplateMainConfig(data, { anonymised: true });
    }
};

export const buildTemplate = (template: Template, data: TemplateData): Part[] => {
    const config = buildTemplateConfig(template, data);
    return buildParts(config);
};
