import { FC } from "react";
import Container from "react-bootstrap/Container";
import { FaUserPlus, FaUsers } from "react-icons/fa";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import {
    addCompanyUserRoute,
    companyContactsRoute,
    companyInvitesRoute,
    companyUsersRoute,
    createCompanyInviteRoute,
} from "app/route/Routes";
import { Toolbar, ToolbarButtons, ToolbarTitle } from "app/component/util/Toolbar";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { ButtonLink } from "app/component/button/ButtonLink";
import { CompanyUsersTable } from "app/component/company/CompanyUsersTable.tsx";
import { useCompanyPermissions } from "app/api/graph/hook/use-company-permissions.ts";
import { useRouteParams } from "app/route/route.ts";
import { companyGraphId } from "app/api/graph/helpers.ts";

export const CompanyUsersListScreen: FC = () => {
    const { companyId } = useRouteParams(companyUsersRoute);
    const { canCreateUserInvite, canListUserInvites, canAddUser } = useCompanyPermissions(companyGraphId(companyId));

    return (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={companyUsersRoute} />
                <Toolbar>
                    <ToolbarTitle>
                        <h1>Company users</h1>
                    </ToolbarTitle>
                    <ToolbarButtons>
                        <ButtonGroup>
                            {canListUserInvites ? (
                                <ButtonLink variant="info" to={companyInvitesRoute.build({ companyId })}>
                                    <FaUsers /> Invites
                                </ButtonLink>
                            ) : null}
                            {canCreateUserInvite ? (
                                <ButtonLink variant="success" to={createCompanyInviteRoute.build({ companyId })}>
                                    <FaUserPlus /> Invite
                                </ButtonLink>
                            ) : null}
                        </ButtonGroup>
                        <ButtonGroup>
                            <ButtonLink variant="info" to={companyContactsRoute.build({ companyId })}>
                                <FaUsers /> Contacts
                            </ButtonLink>
                        </ButtonGroup>
                        <ButtonGroup className="ms-2">
                            {canAddUser ? (
                                <ButtonLink variant="success" to={addCompanyUserRoute.build({ companyId })}>
                                    <FaUserPlus /> Add
                                </ButtonLink>
                            ) : null}
                        </ButtonGroup>
                    </ToolbarButtons>
                </Toolbar>
                <CompanyUsersTable companyId={companyId} />
            </Container>
        </NavigationScreen>
    );
};
