import { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import { FaImage } from "react-icons/fa";
import { NavigationScreen } from "app/component/screen/NavigationScreen";
import { Company } from "app/api/graph/types";
import { editCompanyRoute, companyRoute, uploadCompanyLogoRoute } from "app/route/Routes";
import { RouteBreadcrumbs } from "app/route/RouteBreadcrumbs";
import { EditCompanyForm } from "app/component/company/EditCompanyForm";
import { useRouteParams } from "app/route/route";
import { ButtonLink } from "app/component/button/ButtonLink";

export const EditCompanyScreen: FC = () => {
    const { companyId } = useRouteParams(editCompanyRoute);
    const [company, setCompany] = useState<Pick<Company, "_id"> | undefined>(undefined);

    return company ? (
        <Navigate to={companyRoute.build({ companyId: company._id })} />
    ) : (
        <NavigationScreen>
            <Container>
                <RouteBreadcrumbs route={editCompanyRoute} />

                <ButtonLink variant="secondary" to={uploadCompanyLogoRoute.build({ companyId })} className="mt-3">
                    <FaImage /> Upload logo
                </ButtonLink>

                <EditCompanyForm companyId={companyId} onUpdate={setCompany} />
            </Container>
        </NavigationScreen>
    );
};
