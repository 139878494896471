import { useCallback, useEffect, useMemo, useState } from "react";

type Result = {
    isPrint: boolean;
};

const printQuery: MediaQueryList = window?.matchMedia("print");

export const usePrint = (): Result => {
    const [isPrint, setPrint] = useState<boolean>(false);

    const handleEvent = useCallback((e: MediaQueryListEvent) => setPrint(e.matches), [setPrint]);

    useEffect(() => {
        printQuery?.addEventListener("change", handleEvent);
        return () => printQuery?.removeEventListener("change", handleEvent);
    }, [handleEvent]);

    return useMemo(
        () => ({
            isPrint,
        }),
        [isPrint],
    );
};
