import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import { CompanyUserListFieldsFragment, useCompanyUsersQuery } from "app/api/graph/types";
import { SelectData, SelectDataProps } from "app/component/form/select/SelectData";

type Model = CompanyUserListFieldsFragment;

type Props = SelectDataProps<Model> & {
    companyId: string;
};

export const SelectCompanyUser: FC<Props> = (props) => {
    const { data, loading, fetchMore, error } = useCompanyUsersQuery({
        variables: { companyId: props.companyId },
        notifyOnNetworkStatusChange: true,
    });

    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }

    return (
        <SelectData
            {...props}
            connection={data?.company?.users}
            label={(value) => `${value?.user?.fullName ?? ""} (${value?.user?.email ?? ""})`}
            isLoading={loading}
            onPaginate={() => fetchMore({ variables: { after: data?.company?.users?.pageInfo?.endCursor } })}
        />
    );
};
