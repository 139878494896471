import { FC } from "react";
import ListGroup, { ListGroupProps } from "react-bootstrap/ListGroup";
import { notEmptyString } from "app/util/array";

type Props = {
    items: Array<string | null | undefined>;
} & ListGroupProps;

export const SimpleListGroup: FC<Props> = ({ items, ...props }) => (
    <ListGroup {...props}>
        {items.filter(notEmptyString).map((item, i) => (
            <ListGroup.Item key={i}>{item}</ListGroup.Item>
        ))}
    </ListGroup>
);
