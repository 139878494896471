import { max, parseISO, formatRelative } from "date-fns";
import { FC, ReactElement, useMemo } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { styled } from "styled-components";
import { Accordion } from "react-bootstrap";
import { ReportVulnerabilityFieldsFragment } from "app/api/graph/types";
import { SeverityBadge } from "app/component/report-vulnerability/component/SeverityBadge";
import { useNow } from "app/hook/use-now";
import { reportVersionVulnerabilityRoute } from "app/route/Routes";
import { formatISODate } from "app/util/format";
import { HtmlContent } from "app/component/util/HtmlContent";
import { notEmpty } from "app/util/array";

type Props = {
    vulnerabilities: ReportVulnerabilityFieldsFragment[];
    title?: string;
    onClick?: (reportVulnerabilityId: string) => void;
    buttons?: ReactElement;
};

const Snippet = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    opacity: 0.7;
`;

export const VulnerabilityListCard: FC<Props> = ({ vulnerabilities, title, buttons }) => {
    const now = useNow();
    const lastUpdatedAt = useMemo(
        () =>
            vulnerabilities.length > 0
                ? max(
                      vulnerabilities
                          ?.map((vulnerability) => [
                              vulnerability.createdAt ? parseISO(vulnerability.createdAt) : undefined,
                              vulnerability.updatedAt ? parseISO(vulnerability.updatedAt) : undefined,
                          ])
                          .flat()
                          .filter(notEmpty) ?? [],
                  )
                : undefined,
        [vulnerabilities],
    );

    return (
        <Accordion className="mb-3" defaultActiveKey={vulnerabilities.length > 0 ? "0" : null}>
            <Accordion.Item eventKey="0">
                <Accordion.Button className="bg-light py-2">
                    <Row className="align-items-center w-100">
                        <Col sm="auto" className="me-auto">
                            {title ?? "Vulnerabilities"} ({vulnerabilities.length})
                        </Col>
                        <Col sm="auto">{buttons}</Col>
                    </Row>
                </Accordion.Button>
                <Accordion.Body className="p-0">
                    <ListGroup variant="flush" style={{ maxHeight: "300px", overflowY: "auto" }}>
                        {vulnerabilities.map((vulnerability) => (
                            <ListGroup.Item
                                key={vulnerability.id}
                                action
                                as={Link}
                                to={reportVersionVulnerabilityRoute.build({
                                    reportId: vulnerability.reportVersion.report._id,
                                    versionId: vulnerability.reportVersion._id,
                                    vulnerabilityId: vulnerability._id,
                                })}
                                className="d-flex justify-content-between align-items-center"
                            >
                                <div>
                                    <div>
                                        <strong>{vulnerability.issueNumber}</strong> {vulnerability.title}
                                    </div>
                                    <Snippet>
                                        <HtmlContent html={vulnerability.issueDetail ?? ""} />
                                    </Snippet>
                                    <div className="text-muted">
                                        {`Discovered: ${formatISODate(vulnerability.dateDiscovered)}; `}
                                        {`Resolved: ${formatISODate(vulnerability.dateResolved)}`}
                                    </div>
                                </div>
                                <SeverityBadge severity={vulnerability.severity} className="ms-2" />
                            </ListGroup.Item>
                        ))}
                        {vulnerabilities.length === 0 ? <ListGroup.Item>No vulnerabilities.</ListGroup.Item> : null}
                    </ListGroup>
                    {lastUpdatedAt ? (
                        <div className="bg-light px-3 py-1 border-top">
                            <small className="text-muted">Last updated {formatRelative(lastUpdatedAt, now)}</small>
                        </div>
                    ) : null}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
