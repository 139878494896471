import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useVerifyResolvedVulnerabilityRiskMutation } from "app/api/graph/types";
import { reportVulnerabilityGraphId } from "app/api/graph/helpers";

type Props = {
    vulnerabilityId: number;
    onVerify?: () => void;
};

export const VerifyResolvedReportVulnerabilityForm: FC<Props> = ({ vulnerabilityId, onVerify }) => {
    const [verify, { loading, error }] = useVerifyResolvedVulnerabilityRiskMutation({
        variables: {
            vulnerabilityId: reportVulnerabilityGraphId(vulnerabilityId),
        },
    });

    return (
        <div>
            {error ? <Alert variant="danger">{error.message}</Alert> : null}
            <Alert variant="warning">Do you really want to verify this vulnerability as resolved?</Alert>
            <Button
                type="submit"
                variant="warning"
                disabled={loading}
                onClick={() => verify().then(() => onVerify?.())}
            >
                Verify resolved
            </Button>
        </div>
    );
};
