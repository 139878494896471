import { Formik, FormikHelpers } from "formik";
import { FC, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import yup from "app/util/yup";
import { useReportSectionQuery, useUpdateReportSectionMutation } from "app/api/graph/types";
import { reportSectionGraphId } from "app/api/graph/helpers";
import { RichTextEditor } from "app/component/form/text-editor/RichTextEditor";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { handleError, handleSuccess } from "app/api/graph/form";
import { StatusAlert } from "app/component/form/StatusAlert";

type Props = {
    sectionId: number;
    onUpdate?: () => void;
};

type FormValues = {
    content?: string;
};

const schema: yup.ObjectSchema<FormValues> = yup.object({
    content: yup.string().required(),
});

export const EditReportSectionForm: FC<Props> = ({ sectionId, onUpdate }) => {
    const { data, loading, error } = useReportSectionQuery({
        variables: { sectionId: reportSectionGraphId(sectionId) },
    });
    const section = data?.reportSection;
    const [updateSection] = useUpdateReportSectionMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            content: section?.content ?? "",
        }),
        [section],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!section) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
        updateSection({ variables: { input: { id: section.id, ...values } } })
            .then(() => onUpdate?.())
            .then(() => handleSuccess(actions))
            .catch((error) => handleError(error, actions))
            .finally(() => actions.setSubmitting(false));

    return (
        <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, setFieldValue, setFieldTouched, values, isSubmitting, errors, touched, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <StatusAlert status={status as unknown} />
                    <Form.Group className="mb-3">
                        <RichTextEditor
                            value={values.content ?? ""}
                            onChange={(value) => setFieldValue("content", value)}
                            onBlur={() => setFieldTouched("content")}
                            isInvalid={touched.content && !!errors.content}
                            singleMode
                        />
                        <Form.Control.Feedback type="invalid">{errors.content}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                        Update
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
