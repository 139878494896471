import { FC, useMemo } from "react";
import Alert from "react-bootstrap/Alert";
import {
    UpdateCompanyContactMutation,
    useCompanyContactQuery,
    useUpdateCompanyContactMutation,
} from "app/api/graph/types";
import { FormValues, CompanyContactForm } from "app/component/company-contact/CompanyContactForm";
import { companyContactGraphId } from "app/api/graph/helpers";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";

type Props = {
    companyContactId: number;
    onUpdate: (
        companyContact: NonNullable<
            NonNullable<UpdateCompanyContactMutation["updateCompanyContact"]>["companyContact"]
        >,
    ) => void;
};

export const EditCompanyContactForm: FC<Props> = ({ companyContactId, onUpdate }) => {
    const { data, loading, error } = useCompanyContactQuery({
        variables: { companyContactId: companyContactGraphId(companyContactId) },
    });
    const companyContact = data?.companyContact;
    const [updateCompanyContact, { error: updateError }] = useUpdateCompanyContactMutation({});

    const initialValues: FormValues = useMemo(
        () => ({
            name: companyContact?.name ?? "",
            address: companyContact?.address ?? undefined,
            companyName: companyContact?.companyName ?? undefined,
            email: companyContact?.email ?? undefined,
            mobile: companyContact?.mobile ?? undefined,
            phone: companyContact?.phone ?? undefined,
            position: companyContact?.position ?? undefined,
        }),
        [companyContact],
    );

    if (loading) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!companyContact) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const onSubmit = (values: FormValues) =>
        updateCompanyContact({ variables: { input: { id: companyContact.id, ...values } } })
            .then((response) => response.data?.updateCompanyContact?.companyContact)
            .then((companyContact) => (companyContact ? onUpdate?.(companyContact) : undefined));

    return <CompanyContactForm initialValues={initialValues} onSubmit={onSubmit} error={updateError} />;
};
