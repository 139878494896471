import { FC, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { mapNodes } from "app/util/graph";
import { useCompanyListQuery } from "app/api/graph/types";
import { LoadingSpinner } from "app/component/util/LoadingSpinner";
import { companyRoute, deleteCompanyRoute, editCompanyRoute } from "app/route/Routes";
import { SortableTableHeader, SortDirection } from "app/component/table/SortableTableHeader";
import { deleteCompanyRole, updateCompanyRole } from "app/api/graph/roles";
import { formatISODateTime } from "app/util/format";
import { useAuth } from "app/api/AuthContext";
import { CompaniesTableFiltersForm, FormValues } from "app/component/company/CompaniesTableFiltersForm";
import { BlockButtonGroup } from "app/component/button/BlockButtonGroup";
import { ButtonLink } from "app/component/button/ButtonLink";

export const CompaniesTable: FC = () => {
    const { roles } = useAuth();
    const [nameOrder, setNameOrder] = useState<SortDirection>("ASC");
    const [createdAtOrder, setCreatedAtOrder] = useState<SortDirection>(undefined);
    const [filters, setFilters] = useState<FormValues>({ name: undefined });
    const { data, loading, error, fetchMore } = useCompanyListQuery({
        variables: { order: { name: nameOrder, createdAt: createdAtOrder }, ...filters },
        notifyOnNetworkStatusChange: true,
    });
    const companies = data?.companies;

    if (loading && !companies) {
        return <LoadingSpinner />;
    }
    if (error) {
        return <Alert variant="danger">{error.message}</Alert>;
    }
    if (!companies) {
        return <Alert variant="danger">Not found.</Alert>;
    }

    const fetchMoreCompanies = () => fetchMore({ variables: { after: companies.pageInfo.endCursor } });
    const showActions = roles?.includes(updateCompanyRole) || roles?.includes(deleteCompanyRole);

    return (
        <Row>
            <Col>
                <Table responsive="sm" bordered className="crud-table">
                    <thead>
                        <tr>
                            <SortableTableHeader direction={nameOrder} onChange={setNameOrder}>
                                Name
                            </SortableTableHeader>
                            <SortableTableHeader direction={createdAtOrder} onChange={setCreatedAtOrder}>
                                Created at
                            </SortableTableHeader>
                            <th>Created by</th>
                            {showActions ? <th>Actions</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {mapNodes(companies, (company) => (
                            <tr key={company.id}>
                                <td>
                                    <Link to={companyRoute.build({ companyId: company._id })}>{company.name}</Link>
                                </td>
                                <td>{formatISODateTime(company.createdAt)}</td>
                                <td>{company.createdBy?.fullName ?? "-"}</td>
                                {showActions ? (
                                    <td>
                                        {roles?.includes(updateCompanyRole) ? (
                                            <ButtonGroup>
                                                <ButtonLink
                                                    to={editCompanyRoute.build({ companyId: company._id })}
                                                    variant="warning"
                                                    size="sm"
                                                    title="Edit"
                                                >
                                                    <FaEdit />
                                                </ButtonLink>
                                            </ButtonGroup>
                                        ) : null}
                                        {roles?.includes(deleteCompanyRole) ? (
                                            <ButtonGroup>
                                                <ButtonLink
                                                    to={deleteCompanyRoute.build({ companyId: company._id })}
                                                    variant="danger"
                                                    size="sm"
                                                    title="Delete"
                                                >
                                                    <FaTrash />
                                                </ButtonLink>
                                            </ButtonGroup>
                                        ) : null}
                                    </td>
                                ) : null}
                            </tr>
                        ))}
                        {companies.edges?.length === 0 ? (
                            <tr>
                                <td colSpan={100}>No companies.</td>
                            </tr>
                        ) : null}
                        {loading ? (
                            <tr>
                                <td colSpan={100}>
                                    <LoadingSpinner />
                                </td>
                            </tr>
                        ) : null}
                        {companies.pageInfo.hasNextPage ? (
                            <tr>
                                <td colSpan={100}>
                                    <BlockButtonGroup>
                                        <Button variant="light" onClick={fetchMoreCompanies}>
                                            Load more
                                        </Button>
                                    </BlockButtonGroup>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </Table>
            </Col>
            <Col md={3}>
                <CompaniesTableFiltersForm initialValues={filters} onSubmit={setFilters} />
            </Col>
        </Row>
    );
};
