import { FC } from "react";
import Alert from "react-bootstrap/Alert";
import { isStatus } from "app/api/graph/form";

type Props = {
    status: unknown;
};

export const StatusAlert: FC<Props> = ({ status }) => {
    if (isStatus(status)) {
        return <Alert variant={status.variant}>{status.message}</Alert>;
    }

    return null;
};
