import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { BLUR_COMMAND, COMMAND_PRIORITY_NORMAL } from "lexical";
import { FC, useEffect } from "react";

type Props = {
    onBlur: () => void;
};

export const OnBlurPlugin: FC<Props> = ({ onBlur }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                BLUR_COMMAND,
                () => {
                    onBlur();
                    return false;
                },
                COMMAND_PRIORITY_NORMAL,
            ),
        );
    }, [editor, onBlur]);

    return null;
};
